import { useTheme } from "@mui/material";
import {
  STUDYO_PRIMARY_GRADIENT,
  STUDYO_SECONDARY_GRADIENT,
} from "../../utils/constants";
import { HoverPointerStyle } from "./HoverPointerStyle";

export function FriendsIcon(props: {
  active: boolean;
  width?: string;
  height?: string;
  clicked: Function;
}) {
  const theme = useTheme();
  return (
    <HoverPointerStyle
      width={props?.width || "30"}
      height={props?.height || "30"}
      viewBox={`0 0 32 32`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        props.clicked();
      }}
    >
      {props.active ? (
        <rect
          width={props?.width || "32"}
          height={props?.height || "32"}
          rx="4.125"
          fill="url(#paint0_linear_59_2302)"
        />
      ) : (
        <rect
          width={props?.width || "32"}
          height={props?.height || "32"}
          rx="4.125"
          fill={theme.palette.primary.light}
        />
      )}

      <path
        d="M20.2317 23.6291V21.935C20.2317 21.0364 19.8747 20.1746 19.2393 19.5392C18.6039 18.9038 17.7421 18.5469 16.8435 18.5469H10.0671C9.16853 18.5469 8.30674 18.9038 7.67133 19.5392C7.03592 20.1746 6.67896 21.0364 6.67896 21.935V23.6291"
        stroke={props.active ? "white" : "#777777"}
        strokeLinecap="round"
      />
      <path
        d="M13.4591 15.1541C15.3303 15.1541 16.8473 13.6371 16.8473 11.7659C16.8473 9.89464 15.3303 8.3777 13.4591 8.3777C11.5878 8.3777 10.0709 9.89464 10.0709 11.7659C10.0709 13.6371 11.5878 15.1541 13.4591 15.1541Z"
        stroke={props.active ? "white" : "#777777"}
        strokeLinecap="round"
      />
      <path
        d="M25.3202 23.6256V21.9316C25.3196 21.1808 25.0697 20.4516 24.6098 19.8583C24.1499 19.2649 23.5059 18.8412 22.779 18.6535"
        stroke={props.active ? "white" : "#777777"}
        strokeLinecap="round"
      />
      <path
        d="M19.3804 8.49098C20.1092 8.67759 20.7552 9.10145 21.2165 9.69574C21.6778 10.29 21.9282 11.021 21.9282 11.7733C21.9282 12.5256 21.6778 13.2565 21.2165 13.8508C20.7552 14.4451 20.1092 14.869 19.3804 15.0556"
        stroke={props.active ? "white" : "#777777"}
        strokeLinecap="round"
      />

      <defs>
        <linearGradient
          id="paint0_linear_59_2302"
          x1="0"
          y1="0"
          x2="32"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={STUDYO_PRIMARY_GRADIENT} />
          <stop offset="1" stopColor={STUDYO_SECONDARY_GRADIENT} />
        </linearGradient>
      </defs>
    </HoverPointerStyle>
  );
}
