//@ts-nocheck
import { Stack, useTheme } from "@mui/material";
import { DAYS_OF_WEEK } from "../../../utils/constants";
import { StudyTypographyMediumSubtitle } from "../../glovebox/StudyTypography";

function ShareScheduleFreeTimes({ daysSelected, setDaysSelected }) {
  const theme = useTheme();
  return (
    <div
      style={{
        padding: "8px",
        border: `1px solid ${theme.palette.secondary.light}`,
        borderRadius: "4px",
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {DAYS_OF_WEEK.map((day) => (
          <StudyTypographyMediumSubtitle
            sx={{
              padding: "8px",
              borderRadius: "4px",
              backgroundColor: daysSelected.includes(day)
                ? theme.palette.primary.light
                : theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.secondary.light,
                cursor: "pointer",
              },
              marginRight: "8px",
            }}
            onClick={async () => {
              var holdDays = daysSelected;
              if (daysSelected.includes(day)) {
                holdDays = daysSelected.filter(
                  (holdDay: any) => day !== holdDay
                );
              } else {
                holdDays = [...holdDays, day];
              }
              setDaysSelected(holdDays);
            }}
          >
            {day}
          </StudyTypographyMediumSubtitle>
        ))}
      </Stack>
    </div>
  );
}

export default ShareScheduleFreeTimes;
