import { useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useHotkeys } from "react-hotkeys-hook";
import { HorizontalContainer } from "../glovebox/HorizontalContainer";
import {
  StudyTypographyMediumText,
  StudyTypographySmallSubtitle,
} from "../glovebox/StudyTypography";
import "./Line.css";

const Block = ({ title, active }: { title: string; active: boolean }) => {
  const theme = useTheme();

  return (
    <motion.div
      style={{
        width: "48px",
        height: "48px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        padding: "12px",
        borderRadius: "4px",
        boxShadow: `1px 1px 1px 1px ${theme.palette.primary.dark}`,
      }}
      animate={{
        backgroundColor: active
          ? theme.palette.success.main
          : theme.palette.primary.main,
      }}
      transition={{ duration: 0.5 }}
    >
      <StudyTypographyMediumText>{title}</StudyTypographyMediumText>
    </motion.div>
  );
};

function ShortcutsBlock() {
  const [hovering, setHovering] = useState<boolean>(false);
  const [addTaskActive, setAddTaskActive] = useState(false);
  const [deleteTaskActive, setDeleteTaskActive] = useState(false);
  const [globalSearchActive, setGlobalSearchActive] = useState(false);

  const theme = useTheme();

  const AddTaskAction = () => {
    setAddTaskActive(true);
    setDeleteTaskActive(false);
    setGlobalSearchActive(false);
    toast.success("task added!");
  };

  const DeleteTaskAction = () => {
    setDeleteTaskActive(true);
    setGlobalSearchActive(false);
    setAddTaskActive(false);

    toast.success("task deleted!");
  };

  const GlobalSearchAction = (event: any) => {
    event.preventDefault();

    setGlobalSearchActive(true);
    setAddTaskActive(false);
    setDeleteTaskActive(false);

    toast.success("global search enabled!");
  };

  useHotkeys(
    "shift+enter",
    () => {
      AddTaskAction();
    },
    [addTaskActive, deleteTaskActive, globalSearchActive]
  );

  useHotkeys(
    "shift+D",
    () => {
      DeleteTaskAction();
    },
    [addTaskActive, deleteTaskActive, globalSearchActive]
  );

  useHotkeys(
    "command+F",
    (event) => {
      GlobalSearchAction(event);
    },
    [addTaskActive, deleteTaskActive, globalSearchActive]
  );

  return (
    <motion.div
      style={{
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        cursor: "pointer",
      }}
    >
      <motion.div
        animate={{
          scale: addTaskActive ? 1.1 : 1,
          rotate: addTaskActive ? [5, -5, 0] : 0,
        }}
        transition={{ type: "spring", stiffness: 200, mass: 0.1 }}
        onClick={() => {
          AddTaskAction();
        }}
        style={{ marginTop: "36px" }}
      >
        <HorizontalContainer sx={{ gap: "18px", cursor: "col-resize" }}>
          <Block title="shift" active={addTaskActive} />
          <Block title="enter" active={addTaskActive} />
        </HorizontalContainer>
      </motion.div>
      <motion.div
        animate={{
          scale: deleteTaskActive ? 1.1 : 1,
          rotate: deleteTaskActive ? [5, -5, 0] : 0,
        }}
        transition={{ type: "spring", stiffness: 200, mass: 0.1 }}
        onClick={() => {
          DeleteTaskAction();
        }}
      >
        <HorizontalContainer sx={{ gap: "18px", cursor: "col-resize" }}>
          <Block title="shift" active={deleteTaskActive} />
          <Block title="D" active={deleteTaskActive} />
        </HorizontalContainer>
      </motion.div>
      <motion.div
        animate={{
          scale: globalSearchActive ? 1.1 : 1,
          rotate: globalSearchActive ? [5, -5, 0] : 0,
        }}
        transition={{ type: "spring", stiffness: 200, mass: 0.1 }}
        onClick={(event: any) => {
          GlobalSearchAction(event);
        }}
      >
        <HorizontalContainer sx={{ gap: "18px", cursor: "col-resize" }}>
          <Block title="⌘" active={globalSearchActive} />
          <Block title="F" active={globalSearchActive} />
        </HorizontalContainer>
      </motion.div>
      <StudyTypographySmallSubtitle style={{ marginBottom: "48px" }}>
        hint. type (shift + enter)
      </StudyTypographySmallSubtitle>
      <Toaster
        position="bottom-right"
        toastOptions={{
          success: {
            style: {
              backgroundColor: theme.palette.primary.main,
              fontSize: "12px",
              color: theme.palette.text.primary,
            },
          },
        }}
      />
    </motion.div>
  );
}

export default ShortcutsBlock;
