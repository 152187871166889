import { gql } from "@apollo/client";
import { client } from "../../utils/apollo";
import { DELETE_TODO } from "../../utils/constants";
import { v4 as uuid } from "uuid";
import moment from "moment";

export const CREATE_TO_DO = gql`
  mutation CreateTodo(
    $title: String!
    $duration: Int!
    $userId: String!
    $uuid: String!
    $categoryId: String!
    $startTime: String
    $dueAt: String
    $event: Boolean
  ) {
    createTodo(
      title: $title
      duration: $duration
      userId: $userId
      uuid: $uuid
      categoryId: $categoryId
      startTime: $startTime
      event: $event
      dueAt: $dueAt
    ) {
      id
    }
  }
`;

export const CREATE_TODO_WITH_CATEGORY = gql`
  mutation CreateTodoWithCategory(
    $title: String!
    $duration: Int!
    $userId: String!
    $category: String!
    $categoryId: String!
    $categoryLabel: String!
    $uuid: String!
    $startTime: String
    $dueAt: String
    $gCalId: String
    $addedUsers: [String!]
    $event: Boolean
  ) {
    createTodoWithCategory(
      title: $title
      duration: $duration
      userId: $userId
      category: $category
      categoryId: $categoryId
      categoryLabel: $categoryLabel
      uuid: $uuid
      startTime: $startTime
      dueAt: $dueAt
      gCalId: $gCalId
      addedUsers: $addedUsers
      event: $event
    ) {
      id
    }
  }
`;

export const UPDATE_TO_DO = gql`
  mutation Mutation(
    $updateTodoId: String!
    $title: String
    $startTime: String
    $duration: Int
    $completed: Boolean
    $deleted: Boolean
    $dueAt: String
  ) {
    updateTodo(
      id: $updateTodoId
      title: $title
      startTime: $startTime
      duration: $duration
      completed: $completed
      deleted: $deleted
      dueAt: $dueAt
    ) {
      id
    }
  }
`;

export const UPDATE_TODO_CATEGORY = gql`
  mutation UpdateTodoCategory(
    $updateTodoCategoryId: String!
    $category: String!
    $categoryId: String!
    $oldCategoryId: String
    $categoryLabel: String!
  ) {
    updateTodoCategory(
      id: $updateTodoCategoryId
      category: $category
      categoryId: $categoryId
      oldCategoryId: $oldCategoryId
      categoryLabel: $categoryLabel
    ) {
      id
    }
  }
`;

export const DELETE_TO_DO = gql`
  mutation DeleteTodo($deleteTodoId: String!) {
    deleteTodo(id: $deleteTodoId) {
      id
    }
  }
`;

export const UPDATE_TODO_CATEGORY_UUID = gql`
  mutation UpdateTodoCategoryUUID(
    $uuid: String!
    $category: String!
    $categoryId: String!
    $oldCategoryId: String
    $categoryLabel: String!
  ) {
    updateTodoCategoryUUID(
      uuid: $uuid
      category: $category
      categoryId: $categoryId
      oldCategoryId: $oldCategoryId
      categoryLabel: $categoryLabel
    ) {
      id
    }
  }
`;

export const UPDATE_TODO_UUID = gql`
  mutation UpdateTodoUUID(
    $uuid: String!
    $title: String
    $startTime: String
    $duration: Int
    $completed: Boolean
    $deleted: Boolean
    $dueAt: String
  ) {
    updateTodoUUID(
      uuid: $uuid
      title: $title
      startTime: $startTime
      duration: $duration
      completed: $completed
      deleted: $deleted
      dueAt: $dueAt
    ) {
      id
    }
  }
`;

export const DELETE_TODO_UUID = gql`
  mutation DeleteTodoUUID($uuid: String!) {
    deleteTodoUUID(uuid: $uuid) {
      id
    }
  }
`;

export const UPDATE_TODO_INVITES = gql`
  mutation UpdateTodoInvites(
    $uuid: String!
    $addedUsers: [String!]
    $unsentUsers: [String!]
  ) {
    updateTodoInvites(
      uuid: $uuid
      addedUsers: $addedUsers
      unsentUsers: $unsentUsers
    ) {
      id
    }
  }
`;

export const PIN_TODOO = gql`
  mutation PinTodo($uuid: String!, $userId: String!, $pinned: Boolean!) {
    pinTodo(uuid: $uuid, userId: $userId, pinned: $pinned) {
      id
    }
  }
`;

export const CREATE_CHILD_TODO = gql`
  mutation CreateTodoReplica(
    $startTime: String!
    $duration: Int!
    $parentId: String!
    $uuid: String!
    $event: Boolean
  ) {
    createTodoReplica(
      startTime: $startTime
      duration: $duration
      parentId: $parentId
      uuid: $uuid
      event: $event
    ) {
      id
    }
  }
`;

export const UPDATE_CHILD_TODO = gql`
  mutation UpdateTodoReplica(
    $startTime: String
    $duration: Int
    $todoId: String!
    $completed: Boolean
  ) {
    updateTodoReplica(
      startTime: $startTime
      duration: $duration
      todoId: $todoId
      completed: $completed
    ) {
      id
    }
  }
`;

export const DELETE_CHILD_TODO = gql`
  mutation DeleteTodoReplica($uuid: String!) {
    deleteTodoReplica(uuid: $uuid) {
      id
    }
  }
`;

export const UPDATE_REOCCURRING_TODOS = gql`
  mutation UpdateReoccuring(
    $todoId: String!
    $completed: Boolean
    $duration: Int
  ) {
    updateReoccuring(
      todoId: $todoId
      completed: $completed
      duration: $duration
    ) {
      id
    }
  }
`;

export const UPDATE_REOCCURRING_STARTTIMES = gql`
  mutation UpdateReoccuringStartTime(
    $uuids: [String]!
    $startTimes: [String!]!
  ) {
    updateReoccuringStartTime(uuids: $uuids, startTimes: $startTimes)
  }
`;

export const NULL_TODO_STARTTIME = gql`
  mutation UpdateTodoStartTimeUUID($uuid: String!) {
    updateTodoStartTimeUUID(uuid: $uuid) {
      id
    }
  }
`;

export const NULL_TODO_COMPLETEDAT = gql`
  mutation NullTodoCompletedAtUUID($uuid: String!) {
    nullTodoCompletedAtUUID(uuid: $uuid) {
      id
    }
  }
`;

export const NULL_REOCCURING_COMPLETEDAT = gql`
  mutation NullTodoReplicaCompletedAt($uuid: String!) {
    nullTodoReplicaCompletedAt(uuid: $uuid) {
      id
    }
  }
`;

export const CREATE_BULK_REOCCURING_TODOS = gql`
  mutation CreateReoccuring(
    $title: String!
    $userId: String!
    $uuid: String!
    $categoryId: String!
    $event: Boolean!
    $todos: [ReoccuringTodoInput!]!
  ) {
    createReoccuring(
      title: $title
      userId: $userId
      uuid: $uuid
      categoryId: $categoryId
      event: $event
      todos: $todos
    ) {
      id
    }
  }
`;
export const editModalUpdateTodo = (
  todo: any,
  todos: any,
  setTodos: any,
  selectedTitle: String,
  due: any,
  start: any,
  duration: any
) => {
  console.log("update todo");
  if (todo.todoId) {
    let changeParent = false;
    let newTodos = [...todos];
    newTodos = newTodos?.map((ctodo) => {
      let newTodo = { ...ctodo };
      if (ctodo.uuid === todo.todoId) {
        if (ctodo.dueAt !== due) {
          newTodo.due = due;
          changeParent = true;
        }
        if (ctodo.title !== selectedTitle) {
          newTodo.title = selectedTitle;
          changeParent = true;
        }
        newTodo.todoReplicas = newTodo.todoReplicas?.map((child: any) => {
          let newChild = { ...child };
          if (child.uuid === todo.uuid) {
            if (child.startTime !== start) {
              newChild.startTime = start;
            }
            if (child.duration !== duration) {
              newChild.duration = duration;
            }
          }
          return newChild;
        });
      }
      return newTodo;
    });
    // console.log(newTodos)
    setTodos(newTodos);
    if (changeParent) {
      client.mutate({
        mutation: UPDATE_TODO_UUID,
        variables: {
          uuid: todo.todoId,
          dueAt: due,
          title: selectedTitle,
        },
      });
    }
    client.mutate({
      mutation: UPDATE_CHILD_TODO,
      variables: {
        startTime: start,
        duration: duration,
        todoId: todo.uuid,
      },
    });
  } else {
    let newTodos = [...todos];
    newTodos = newTodos?.map((ctodo) => {
      let newTodo = { ...ctodo };
      if (ctodo.uuid === todo.uuid) {
        newTodo.startTime = start;
        newTodo.duration = duration;
        newTodo.dueAt = due;
        newTodo.title = selectedTitle;
      }
      return newTodo;
    });
    // console.log(newTodos)
    setTodos(newTodos);
    client.mutate({
      mutation: UPDATE_TODO_UUID,
      variables: {
        startTime: start,
        duration: duration,
        uuid: todo.uuid,
        dueAt: due,
        title: selectedTitle,
      },
    });
  }
};

export const updateTodoTitle = async (
  oldTodo: any,
  editTodoText: string,
  todos: any,
  setTodos: any
) => {
  console.log("update todo title");
  let todoUUID = oldTodo.todoId ? oldTodo.todoId : oldTodo.uuid;
  let newTodos = [...todos];
  newTodos = newTodos?.map((todo) => {
    let newTodo = { ...todo };
    if (todo.uuid === todoUUID) {
      newTodo.title = editTodoText;
    }
    return newTodo;
  });
  setTodos(newTodos);
  return client.mutate({
    mutation: UPDATE_TODO_UUID,
    variables: {
      title: editTodoText,
      uuid: todoUUID,
    },
  });
};

export const updateTodoState = async (
  oldTodo: any,
  completed: boolean,
  deleted: boolean,
  todos: any,
  setTodos: any
) => {
  console.log("update todo state");
  if (completed) {
    if (oldTodo.todoId) {
      let newTodos = [...todos];
      newTodos = newTodos?.map((todo) => {
        let newTodo = { ...todo };
        if (todo.uuid === oldTodo.todoId) {
          newTodo.todoReplicas = newTodo.todoReplicas?.map((child: any) => {
            let newChild = { ...child };
            if (child.uuid === oldTodo.uuid) {
              newChild.completedAt = completed ? new Date() : todo.completedAt;
            }
            return newChild;
          });
        }
        return newTodo;
      });
      setTodos(newTodos);
      return client.mutate({
        mutation: UPDATE_CHILD_TODO,
        variables: {
          todoId: oldTodo.uuid,
          completed: completed,
        },
      });
    } else {
      let newTodos = [...todos];
      newTodos = newTodos?.map((todo) => {
        let newTodo = { ...todo };
        if (todo.uuid === oldTodo.uuid) {
          newTodo.completedAt = completed ? new Date() : todo.completedAt;
          newTodo.deletedAt = deleted ? new Date() : todo.deletedAt;
        }
        return newTodo;
      });
      setTodos(newTodos);
      return client.mutate({
        mutation: UPDATE_TODO_UUID,
        variables: {
          uuid: oldTodo.uuid,
          completed: completed,
        },
      });
    }
  } else {
    if (oldTodo.todoId) {
      let newTodos = [...todos];
      newTodos = newTodos?.map((todo) => {
        let newTodo = { ...todo };
        if (todo.uuid === oldTodo.todoId) {
          newTodo.todoReplicas = newTodo.todoReplicas?.map((child: any) => {
            let newChild = { ...child };
            if (child.uuid === oldTodo.uuid) {
              newChild.completedAt = null;
            }
            return newChild;
          });
        }
        return newTodo;
      });
      setTodos(newTodos);

      return client.mutate({
        mutation: NULL_REOCCURING_COMPLETEDAT,
        variables: {
          uuid: oldTodo.uuid,
        },
      });
    } else {
      let newTodos = [...todos];
      newTodos = newTodos?.map((todo) => {
        let newTodo = { ...todo };
        if (todo.uuid === oldTodo.uuid) {
          newTodo.completedAt = null;
        }
        return newTodo;
      });
      setTodos(newTodos);
      return client.mutate({
        mutation: NULL_TODO_COMPLETEDAT,
        variables: {
          uuid: oldTodo.uuid,
        },
      });
    }
  }
};

export const removeTodoStart = async (
  oldtodo: any,
  todos: any,
  setTodos: any
) => {
  var newTodos = [...todos];
  console.log(newTodos);
  newTodos = newTodos?.map((todo) => {
    if (todo.uuid === oldtodo.uuid) {
      console.log(todo);

      todo.startTime = null;
    }
    return todo;
  });
  setTodos(newTodos);
  return client.mutate({
    mutation: NULL_TODO_STARTTIME,
    variables: {
      uuid: oldtodo.uuid,
    },
  });
};

export const DeleteTodo = async (todo: any, todos: any, setTodos: any) => {
  console.log("delete todo");
  let newTodos = [...todos];
  if (todo.todoId) {
    newTodos = newTodos.map((oldTodo: any) => {
      let newTodo = { ...oldTodo };
      if (oldTodo.uuid === todo.todoId) {
        newTodo.todoReplicas = oldTodo?.todoReplicas?.filter(function (
          child: any
        ) {
          return child.uuid !== todo.uuid;
        });
      }
      return newTodo;
    });
    setTodos(newTodos);
    client.mutate({
      mutation: DELETE_CHILD_TODO,
      variables: {
        uuid: todo.uuid,
      },
    });
  } else {
    newTodos = newTodos?.filter(function (oldTodo: any) {
      return oldTodo.uuid !== todo.uuid;
    });
    setTodos(newTodos);
    if (todo.gCalId) {
      client.mutate({
        mutation: UPDATE_TODO_UUID,
        variables: {
          uuid: todo.uuid,
          deleted: true,
        },
      });
    } else {
      client.mutate({
        mutation: DELETE_TODO_UUID,
        variables: {
          uuid: todo.uuid,
        },
      });
    }
  }
};

export const createTodo = async (
  categoryId: any,
  title: string,
  duration: any,
  dueAt: any,
  startTime: any,
  event: boolean,
  userId: any,
  todos: any,
  setTodos: any
) => {
  let todoUUID = uuid();
  console.log(todos);
  let newTodo = {
    categoryId: categoryId,
    completedAt: null,
    createdAt: null,
    duration: duration ? duration : null,
    id: null,
    dueAt: dueAt ? dueAt : null,
    startTime: startTime ? startTime : null,
    title: title,
    uuid: todoUUID,
    __typename: "Todo",
    event: event,
  };
  let newTodos = [newTodo, ...todos];
  setTodos(newTodos);
  return client.mutate({
    mutation: CREATE_TO_DO,
    variables: {
      title: title,
      duration: duration ? duration : null,
      userId: userId,
      dueAt: dueAt ? dueAt : null,
      startTime: startTime ? startTime : null,
      uuid: todoUUID,
      categoryId: categoryId,
      event: event,
    },
  });
};

export const updateTodoDrag = async (
  dropInfo: any,
  todoId: string,
  todos: any,
  setTodos: any
) => {
  let newTodos = [...todos];
  newTodos = newTodos?.map((todo) => {
    let newTodo = { ...todo };
    if (dropInfo.draggedEl.id) {
      if (todo.uuid === dropInfo.draggedEl.id) {
        newTodo.startTime = dropInfo.dateStr;
        newTodo.duration = dropInfo.draggedEl.duration
          ? dropInfo.draggedEl.duration
          : todo.duration;
      }
    } else {
      if (todo.uuid === todoId) {
        newTodo.startTime = dropInfo.dateStr;
        newTodo.duration = dropInfo.draggedEl.duration
          ? dropInfo.draggedEl.duration
          : todo.duration;
      }
    }

    return newTodo;
  });
  // console.log(newTodos)
  setTodos(newTodos);
  return client.mutate({
    mutation: UPDATE_TODO_UUID,
    variables: {
      startTime: moment(dropInfo.dateStr),
      duration: dropInfo.draggedEl.duration
        ? dropInfo.draggedEl.duration
        : null,
      uuid: dropInfo.draggedEl.id ? dropInfo.draggedEl.id : todoId,
    },
  });
};

export const updateTodoStartTime = async (
  changeInfo: any,
  duration: any,
  todos: any,
  setTodos: any
) => {
  let newTodos = [...todos];
  newTodos = newTodos?.map((todo) => {
    let newTodo = { ...todo };
    if (todo.uuid === changeInfo.event.id) {
      newTodo.startTime = moment(changeInfo.event.start).toISOString();
      newTodo.duration = duration;
    }
    return newTodo;
  });
  // console.log(newTodos)
  setTodos(newTodos);
  return client.mutate({
    mutation: UPDATE_TODO_UUID,
    variables: {
      startTime: moment(changeInfo.event.start).toISOString(),
      duration: duration,
      uuid: changeInfo.event.id,
    },
  });
};

export const updateTodoDuration = async (
  oldTodo: any,
  duration: number,
  todos: any,
  setTodos: any
) => {
  let newTodos = [...todos];
  newTodos = newTodos?.map((todo) => {
    let newTodo = { ...todo };
    if (todo.uuid === oldTodo.uuid) {
      newTodo.duration = duration;
    }
    return newTodo;
  });
  // console.log(newTodos)
  setTodos(newTodos);
  client.mutate({
    mutation: UPDATE_TODO_UUID,
    variables: {
      uuid: oldTodo.uuid,
      duration: duration,
    },
  });
};
