import { useTheme } from "@mui/material";
import {
  STUDYO_PRIMARY_GRADIENT,
  STUDYO_SECONDARY_GRADIENT,
} from "../../utils/constants";
import { HoverPointerStyle } from "./HoverPointerStyle";

export function CalendarIcon(props: {
  active: boolean;
  clicked: Function;
  width?: string;
  height?: string;
}) {
  const theme = useTheme();
  return (
    <HoverPointerStyle
      width={props?.width || "30"}
      height={props?.height || "30"}
      viewBox={`0 0 32 32`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        props.clicked();
      }}
    >
      {props.active ? (
        <rect
          width={props?.width || "32"}
          height={props?.height || "32"}
          rx="4.125"
          fill="url(#paint0_linear_59_2302)"
        />
      ) : (
        <rect
          width={props?.width || "32"}
          height={props?.height || "32"}
          rx="4.125"
          fill={theme.palette.primary.light}
        />
      )}

      <path
        d="M20.6667 10.6666H11.3333C10.597 10.6666 10 11.2636 10 12V21.3333C10 22.0697 10.597 22.6666 11.3333 22.6666H20.6667C21.403 22.6666 22 22.0697 22 21.3333V12C22 11.2636 21.403 10.6666 20.6667 10.6666Z"
        stroke={props.active ? "white" : "#777777"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6676 9.3334V12.0001"
        stroke={props.active ? "white" : "#777777"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3324 9.3334V12.0001"
        stroke={props.active ? "white" : "#777777"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14.6666H22"
        stroke={props.active ? "white" : "#777777"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_59_2302"
          x1="0"
          y1="0"
          x2="32"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={STUDYO_PRIMARY_GRADIENT} />
          <stop offset="1" stopColor={STUDYO_SECONDARY_GRADIENT} />
        </linearGradient>
      </defs>
    </HoverPointerStyle>
  );
}
