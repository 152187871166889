import { Stack, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import { CheckIcon } from "../Icons/CheckIcon";
import { StudyTypographySmallText } from "./StudyTypography";
import { HoverPointerStyle } from "../Icons/HoverPointerStyle";

function FilterTodos({
  setHideOldTodos,
  hideOldTodos,
  handleFilterClick,
  filteredCategories,
  setFilteredCategories,
}: {
  setHideOldTodos: any;
  hideOldTodos: any;
  handleFilterClick: any;
  filteredCategories: any;
  setFilteredCategories: any;
}) {
  const theme = useTheme();
  return (
    <Stack
      flexDirection={"row"}
      alignItems={"center"}
      // padding="4px"
      borderRadius={"4px"}
      style={{ backgroundColor: theme.palette.primary.main }}
    >
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        padding="8px"
        borderRadius={"4px"}
        height={"32px"}
        style={{ backgroundColor: theme.palette.primary.light }}
      >
        <motion.div
          whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
          whileHover={{ rotate: 10 }}
          transition={{
            type: "spring",
            stiffness: 200,
            damping: 8,
          }}
          style={{
            // marginLeft: "12px",
            alignSelf: "center",
            display: "flex",
          }}
        >
          <CheckIcon
            height="18px"
            width="18px"
            active={hideOldTodos}
            clicked={() => {
              setHideOldTodos(!hideOldTodos);
            }}
          />
        </motion.div>
        <StudyTypographySmallText
          style={{
            marginLeft: "12px",
          }}
        >
          hide week old
        </StudyTypographySmallText>
      </Stack>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        padding="8px"
        marginLeft={"12px"}
        borderRadius={"4px"}
        height={"32px"}
        sx={{
          backgroundColor: theme.palette.primary.light,
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={handleFilterClick}
      >
        <StudyTypographySmallText>filter category</StudyTypographySmallText>
      </Stack>
    </Stack>
  );
}

export default FilterTodos;
