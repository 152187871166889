import { gql } from "@apollo/client";

export const GROUP_INFO = gql`
  query GetGroup($uuid: String!) {
    getGroup(uuid: $uuid) {
      id
      users {
        id
        lastName
        email
        firstName
        displayTodoName
        privateTodos
        profileImageURL
        todos {
          id
          title
          duration
          startTime
          completedAt
          deletedAt
          createdAt
          dueAt
          categoryId
          uuid
        }
        active
      }
      addedUsers {
        id
      }
      name
      uuid
    }
  }
`;
