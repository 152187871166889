import { HoverPointerStyle } from "./HoverPointerStyle";
import { useTheme } from "@mui/material";
import {
  STUDYO_PRIMARY_GRADIENT,
  STUDYO_SECONDARY_GRADIENT,
} from "../../utils/constants";

export function PeopleIcon(props: {
  active: boolean;
  clicked: Function;
  width?: string;
  height?: string;
}) {
  const theme = useTheme();

  return (
    <svg
      width={props?.width || "65"}
      height={props?.height || "65"}
      viewBox={`0 0 65 65`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        props.clicked();
      }}
    >
      {props.active ? (
        <rect
          width={props?.width || "65"}
          height={props?.height || "65"}
          rx="4"
          fill="url(#paint0_linear_59_2302)"
        />
      ) : (
        <rect
          width={props?.width || "65"}
          height={props?.height || "65"}
          rx="4"
          fill={theme.palette.primary.light}
        />
      )}

      <svg
        width="65"
        height="65"
        viewBox="0 0 65 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="65"
          height="65"
          rx="6"
          fill={theme.palette.primary.light}
        />
        <path
          d="M41.0956 47.9967V44.5556C41.0956 42.7303 40.3705 40.9798 39.0798 39.6891C37.7891 38.3984 36.0386 37.6733 34.2133 37.6733H20.4489C18.6236 37.6733 16.8731 38.3984 15.5824 39.6891C14.2917 40.9798 13.5666 42.7303 13.5666 44.5556V47.9967"
          stroke={theme.palette.text.primary}
          stroke-linecap="round"
        />
        <path
          d="M27.3388 30.7816C31.1397 30.7816 34.221 27.7003 34.221 23.8993C34.221 20.0984 31.1397 17.0171 27.3388 17.0171C23.5378 17.0171 20.4565 20.0984 20.4565 23.8993C20.4565 27.7003 23.5378 30.7816 27.3388 30.7816Z"
          stroke={theme.palette.text.primary}
          stroke-linecap="round"
        />
        <path
          d="M51.4316 47.9893V44.5482C51.4304 43.0233 50.9229 41.542 49.9886 40.3368C49.0544 39.1316 47.7463 38.2709 46.2699 37.8896"
          stroke={theme.palette.text.primary}
          stroke-linecap="round"
        />
        <path
          d="M39.3665 17.2471C40.8468 17.6261 42.159 18.4871 43.096 19.6942C44.033 20.9014 44.5416 22.3861 44.5416 23.9142C44.5416 25.4424 44.033 26.9271 43.096 28.1342C42.159 29.3414 40.8468 30.2024 39.3665 30.5814"
          stroke={theme.palette.text.primary}
          stroke-linecap="round"
        />
      </svg>

      <defs>
        <linearGradient
          id="paint0_linear_59_2302"
          x1="0"
          y1="0"
          x2="32"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={STUDYO_PRIMARY_GRADIENT} />
          <stop offset="1" stopColor={STUDYO_SECONDARY_GRADIENT} />
        </linearGradient>
      </defs>
    </svg>
  );
}
