import { gql } from "@apollo/client";

export const CREATE_BUG = gql`
  mutation CreateBug(
    $userId: String!
    $description: String!
    $priority: priorityInput!
  ) {
    createBug(userId: $userId, description: $description, priority: $priority) {
      id
    }
  }
`;

export const UPDATE_BUG_PRIORITY = gql`
  mutation UpdateBug($bugId: String!, $priority: priorityInput!) {
    updateBug(bugId: $bugId, priority: $priority) {
      id
    }
  }
`;

export const UPDATE_BUG_DESCRIPTION = gql`
  mutation UpdateBugDescription($bugId: String!, $description: String!) {
    updateBugDescription(bugId: $bugId, description: $description) {
      id
    }
  }
`;

export const DELETE_BUG = gql`
  mutation DeleteBug($bugId: String!) {
    deleteBug(bugId: $bugId) {
      id
    }
  }
`;
