export const HorizontalContainer = (props: {
  sx?: any;
  marginBottom?: any;
  children?: any;
}) => {
  const { sx, marginBottom, children } = props;

  return (
    <div
      style={{
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        marginBottom: marginBottom,
        ...sx,
      }}
    >
      {children}
    </div>
  );
};
