/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import { useEffect, useState } from "react";
import StudyoCowork from "../../assets/StudyoCowork.mp4";
import { createEventId } from "../mainbar/schedule/event-utils";
import "./styles.css";
import { LoaderIcon } from "react-hot-toast";

export function CoworkPreview() {
  const [currentTime, setCurrentTime] = useState(moment().format("HH:mm:ss"));
  const [fullScreen, setFullScreen] = useState(false);
  const [seconds, setSeconds] = useState(0);
  var elem = document.getElementById("schedule-video");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().format("HH:mm:ss"));
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {loaded ? null : <LoaderIcon style={{ width: "48px", height: "48px" }} />}
      <video
        controls={false}
        autoPlay={true}
        playsInline
        muted={false}
        onLoadedData={() => {
          setLoaded(true);
        }}
        id={"schedule-video"}
        loop
        className={fullScreen ? "" : "video"}
        onTimeUpdateCapture={(data) => {
          console.log("i am capture: ", data);
        }}
        onClick={() => {
          setFullScreen(true);
          elem?.requestFullscreen();
        }}
        onTimeUpdate={(data) => {
          console.log(data);
          //@ts-ignore
          var holdSeconds = data?.target?.currentTime;
          setSeconds(holdSeconds);
        }}
        style={{
          width: "80%",
          borderRadius: "12px",
          //   height: "100%",
        }}
      >
        <source
          src={StudyoCowork}
          type="video/mp4"
          style={{ borderRadius: "12px" }}
        />
        <source src="myVideo.mp4" type="video/mp4" />
        <p>
          Your browser doesn't support HTML video. Here is a
          <a href="myVideo.mp4">link to the video</a> instead.
        </p>
      </video>
    </div>
  );
}
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: "All-day event",
    start: todayStr,
  },
  {
    id: createEventId(),
    title: "Timed event",
    start: todayStr + "T12:00:00",
  },
];
