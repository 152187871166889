import { signOut } from "@firebase/auth";
import { Badge, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import useSound from "use-sound";
import EmptyLogo from "../../assets/EmptyLogo";
import { client } from "../../utils/apollo";
import { HotkeyContext, PageContext } from "../../utils/contexts";
import { auth } from "../../utils/firebase";
import { HOTKEYS } from "../../utils/hotkeyHelper";
import { CalendarIcon } from "../Icons/CalendarIcon";
import { ClockIcon } from "../Icons/ClockIcon";
import { LogoutIcon } from "../Icons/LogoutIcon";
import { SettingsIcon } from "../Icons/SettingsIcon";
import { ToDoListIcon } from "../Icons/ToDoListIcon";
import { Spacer } from "../glovebox/Spacer";

export const Sidebar = () => {
  let location = useLocation();
  const navigate = useNavigate();

  const [playSwitchPageSound] = useSound("/p-static/sounds/switch-page.mp3");

  const [iconPressed, setIconPressed] = useState(location.pathname);
  const theme = useTheme();
  const [openOnMobile, setOpenOnMobile] = useState(false);

  const { page, setPage } = useContext(PageContext);
  const { showBadge } = useContext(HotkeyContext);

  useEffect(() => {
    if (openOnMobile) setOpenOnMobile(false);
    playSwitchPageSound();
  }, [page]);

  useHotkeys(HOTKEYS.OPEN_CALENDAR, () => {
    navigate("calendar");
  });
  useHotkeys(HOTKEYS.OPEN_TODO, () => {
    navigate("todos");
  });
  useHotkeys(HOTKEYS.OPEN_SCHEDULE, () => {
    navigate("schedule");
  });
  useHotkeys(HOTKEYS.OPEN_ROOM, () => {
    navigate("cowork");
  });
  useHotkeys(HOTKEYS.OPEN_FRIENDS, () => {
    navigate("friends");
  });
  useHotkeys(HOTKEYS.OPEN_ADDFRIENDS, () => {
    navigate("addfriend");
  });
  useHotkeys(HOTKEYS.OPEN_SETTINGS, () => {
    navigate("settings");
  });

  return (
    <Container openOnMobile={openOnMobile} theme={theme}>
      <div>
        <Spacer height={"16px"} />

        <EmptyLogo />
      </div>

      <div style={{ paddingTop: "1rem" }}>
        <Link to="calendar">
          <Badge
            badgeContent={HOTKEYS.OPEN_CALENDAR}
            color={"primary"}
            invisible={!showBadge}
          >
            <CalendarIcon
              active={location.pathname.includes("/calendar")}
              clicked={() => {
                setIconPressed("/calendar");
              }}
            />
          </Badge>
        </Link>

        <Spacer height={"36px"} />
        <Link to="todos">
          <Badge
            badgeContent={HOTKEYS.OPEN_TODO}
            color={"primary"}
            invisible={!showBadge}
          >
            <ToDoListIcon
              active={location.pathname.includes("/todos")}
              clicked={() => {
                setIconPressed("/todos");
              }}
            />
          </Badge>
        </Link>

        <Spacer height={"36px"} />
        <Link
          to="schedule"
          style={{
            backgroundColor: theme.palette.primary.light,
          }}
        >
          <Badge
            badgeContent={HOTKEYS.OPEN_SCHEDULE}
            color={"primary"}
            invisible={!showBadge}
          >
            <ClockIcon
              active={location.pathname.includes("/schedule")}
              clicked={() => {
                setIconPressed("/schedule");
              }}
            />
          </Badge>
        </Link>
        {/* <Spacer height={"36px"} />

        <Link
          to="session"
          style={{
            backgroundColor: theme.palette.primary.light,
          }}
        >
          <Badge
            badgeContent={HOTKEYS.OPEN_SCHEDULE}
            color={"primary"}
            invisible={!showBadge}
          >
            <NotesIcon
              active={location.pathname.includes("/session")}
              clicked={() => {
                setIconPressed("/session");
              }}
            />
          </Badge>
        </Link> */}

        {/* <Spacer height={"24px"} />
        <Link to="cowork">
          <Badge
            badgeContent={HOTKEYS.OPEN_ROOM}
            color={"primary"}
            invisible={!showBadge}
          >
            <RoomIcon
              active={location.pathname.includes("/cowork")}
              clicked={() => {
                setIconPressed("/cowork");
              }}
            />
          </Badge>
        </Link>

        <Spacer height={"24px"} />
        <Link to="friends">
          <Badge
            badgeContent={HOTKEYS.OPEN_FRIENDS}
            color={"primary"}
            invisible={!showBadge}
          >
            <FriendsIcon
              active={location.pathname.includes("/friends")}
              clicked={() => {
                setIconPressed("/friends");
              }}
            />
          </Badge>
        </Link>

        <Spacer height={"24px"} />
        <Link to="addfriend">
          <Badge
            badgeContent={HOTKEYS.OPEN_ADDFRIENDS}
            color={"primary"}
            invisible={!showBadge}
          >
            <AddFriendsIcon
              active={location.pathname.includes("/addfriend")}
              clicked={() => {
                setIconPressed("/addfriend");
              }}
            />
          </Badge>
        </Link> */}
      </div>
      <div style={{ paddingBottom: "1rem" }}>
        <Link to="settings">
          <Badge
            badgeContent={HOTKEYS.OPEN_SETTINGS}
            color={"primary"}
            invisible={!showBadge}
          >
            <SettingsIcon
              active={location.pathname.includes("/settings")}
              clicked={() => {
                setIconPressed("/settings");
              }}
            />
          </Badge>
        </Link>

        <Spacer height={"16px"} />
        <Link to="/">
          <LogoutIcon
            clicked={() => {
              signOut(auth)
                .then(async () => {
                  // localStorage.setItem('userId', "")
                  // localStorage.setItem("userId", "" + null);
                  // localStorage.setItem("googleUser", "" + null);
                  // localStorage.setItem("user", "" + null);
                  localStorage.clear();
                  client.resetStore();
                  navigate("/login");
                })
                .catch((error: any) => {});
            }}
          />
        </Link>
      </div>
    </Container>
  );
};

const Container = styled.aside<{ openOnMobile: boolean }>`
  display: inline-flex;
  /* box-sizing: border-box; */
  /* width: 50px; */
  flex-direction: column;
  justify-content: space-between;
  padding-right: 1rem;
  padding-left: 1rem;

  /* border-right: 1px solid #101010; */
  height: 100vh;

  @media (max-width: 850px) {
    display: ${({ openOnMobile }) => (openOnMobile ? "block" : "none")};
    background-color: ${(props) => props.theme.palette.primary.main};
    backdrop-filter: blur(7px);
    z-index: 1;
    top: 65px;
    width: 100%;
    height: calc(100% - 65px);
  }
`;
