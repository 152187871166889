import {
  CalendarTodayRounded,
  ChevronRightRounded,
  EventRepeatRounded,
  GroupWorkOutlined,
  HandymanOutlined,
  HeadphonesOutlined,
  ScheduleOutlined,
  ViewKanbanOutlined,
} from "@mui/icons-material";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { isMobile } from "react-device-detect";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router-dom";
import EmptyLogo from "../../assets/EmptyLogo";
import { ThemeContext } from "../../utils/contexts";
import { NUMS } from "../../utils/hotkeyHelper";
import {
  StudyTypographyMediumSubtitle,
  StudyTypographyMediumText,
  StudyTypographyMediumTitle,
  StudyTypographyMega,
} from "../glovebox/StudyTypography";
import { CoworkPreview } from "./CoworkPreview";
import { GCalPreview } from "./GCalPreview";
import { KanbanPreview } from "./KanbanPreview";
import { SchedulePreview } from "./SchedulePreview";
import { SpotifyPreview } from "./SpotifyPreview";

import { STUDYO_GRADIENT } from "../../utils/constants";
import { AIPreview } from "./AIPreview";
import { CalendarPreview } from "./CalendarPreview";
import {
  LeftMainContainer,
  LeftMainToolBox,
  MainContainer,
  RightMainContainer,
  RightMainDivider,
} from "./styles";

const ICON_WIDTH = "18px";
const ICON_HEIGHT = "18px";

const mainPageFeatures = [
  {
    title: "build daily schedule",
    icon: (
      <ScheduleOutlined style={{ width: ICON_WIDTH, height: ICON_HEIGHT }} />
    ),
  },
  {
    title: "kanban todos",
    icon: (
      <ViewKanbanOutlined style={{ width: ICON_WIDTH, height: ICON_HEIGHT }} />
    ),
  },

  {
    title: "import google cal events",
    icon: (
      <EventRepeatRounded style={{ width: ICON_WIDTH, height: ICON_HEIGHT }} />
    ),
  },
  {
    title: "calendar view",
    icon: (
      <CalendarTodayRounded
        style={{ width: ICON_WIDTH, height: ICON_HEIGHT }}
      />
    ),
  },
  // {
  //   title: "add friends / share cal",
  //   icon: (
  //     <GroupAddOutlined style={{ width: ICON_WIDTH, height: ICON_HEIGHT }} />
  //   ),
  // },
  // {
  //   title: "hotkeys for speed",
  //   icon: <BoltRounded style={{ width: ICON_WIDTH, height: ICON_HEIGHT }} />,
  // },
  //   {
  //     title: "kanban todos",
  //     icon: (
  //       <ViewKanbanOutlined style={{ width: ICON_WIDTH, height: ICON_HEIGHT }} />
  //     ),
  //   },
  //   {
  //     title: "drag and drop",
  //     icon: (
  //       <PanToolAltOutlined style={{ width: ICON_WIDTH, height: ICON_HEIGHT }} />
  //     ),
  //   },
  {
    title: "cowork / accountability",
    icon: (
      <GroupWorkOutlined style={{ width: ICON_WIDTH, height: ICON_HEIGHT }} />
    ),
  },
  {
    title: "AI assistant (soon)",
    icon: (
      <HandymanOutlined style={{ width: ICON_WIDTH, height: ICON_HEIGHT }} />
    ),
  },
  {
    title: "spotify jammin (soon)",
    icon: (
      <HeadphonesOutlined style={{ width: ICON_WIDTH, height: ICON_HEIGHT }} />
    ),
  },
];

const mainPageFeatureText = [
  {
    title: "Build your daily schedule",
    description: "In less than 26 SECONDS! Have your whole day planned.",
    index: 1,
    component: <SchedulePreview />,
  },
  {
    title: "Manage todos and categories",
    description: "Birds eye view of all todos and categories",
    index: 2,
    component: <KanbanPreview />,
  },
  {
    title: "Import google cal events",
    description:
      "Unlimited refreshing of google calendar, stay ahead and build!",
    index: 3,
    component: <GCalPreview />,
  },
  {
    title: "Calendar view",
    description: "More aesthetic calendar to track and maintain events + todos",
    index: 4,
    component: <CalendarPreview />,
  },
  // {
  //   title: "Build your daily schedule",
  //   description:
  //     "Build your daily schedule by dragging your items in todo list while creating and managing the todos in your schedule",
  //   index: 4,
  //   component: <SchedulePreview />,
  // },
  // {
  //   title: "Manage todos and categories",
  //   description:
  //     "The classic kanban style for arranging, storing, and managing your todos and categories. Drag and drop todos into their respective categories!",
  //   index: 5,
  //   component: <KanbanPreview />,
  // },
  {
    title: "Cowork w/ friends",
    description:
      "Safely share your calendar slots and cowork with your friends",
    index: 5,
    component: <CoworkPreview />,
  },
  {
    title: "Have AI build your schedule",
    description:
      "Using the best productivity techniques, let AI recommend the perfect daily schedule!",
    index: 6,
    component: <AIPreview />,
  },
  {
    title: "Listen to music w/ friends",
    description:
      "Queue up music and listen with your friends while you cowork!",
    index: 7,
    component: <SpotifyPreview />,
  },
];

export const MainPage = ({
  setThemeValueToLight,
  setThemeValueToGray,
  setThemeValueToDark,
}: {
  setThemeValueToLight: any;
  setThemeValueToGray: any;
  setThemeValueToDark: any;
}) => {
  const [seconds, setSeconds] = useState(0);
  const [featurePicked, setFeaturePicked] = useState(1);
  const [showFeature, setShowFeature] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { themeValue, setThemeValue } = useContext(ThemeContext);

  useHotkeys(NUMS.ONE, () => {
    setFeaturePicked(parseInt(NUMS.ONE));
  });

  useHotkeys(NUMS.TWO, () => {
    setFeaturePicked(parseInt(NUMS.TWO));
  });
  useHotkeys(NUMS.THREE, () => {
    setFeaturePicked(parseInt(NUMS.THREE));
  });
  useHotkeys(NUMS.FOUR, () => {
    setFeaturePicked(parseInt(NUMS.FOUR));
  });
  useHotkeys(NUMS.FIVE, () => {
    setFeaturePicked(parseInt(NUMS.FIVE));
  });
  useHotkeys(NUMS.SIX, () => {
    setFeaturePicked(parseInt(NUMS.SIX));
  });
  useHotkeys(NUMS.SEVEN, () => {
    setFeaturePicked(parseInt(NUMS.SEVEN));
  });
  return (
    <MainContainer>
      {showFeature ? null : (
        <LeftMainContainer>
          <div
            style={{
              backgroundColor: theme.palette.primary.light,
              width: "50px",
              height: "50px",
              borderRadius: "4px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              alignSelf: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <EmptyLogo />
          </div>
          {/* <StudyTypographyMega style={{ marginBottom: "32px" }}>
          Studyo
        </StudyTypographyMega> */}
          <div style={{ width: "100%" }}>
            {mainPageFeatures.map((feature, index) => (
              <LeftMainToolBox
                style={{
                  backgroundColor:
                    featurePicked === index + 1
                      ? theme.palette.primary.dark
                      : theme.palette.primary.light,
                }}
                onClick={() => {
                  setFeaturePicked(index + 1);
                  if (isMobile) {
                    setShowFeature(true);
                  }
                }}
              >
                <div
                  style={{
                    flexDirection: "row",

                    display: "flex",

                    width: "100%",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  {feature.icon}
                  <StudyTypographyMediumText
                    style={{
                      fontSize: "12px",
                      paddingLeft: "16px",
                      fontWeight: "lighter",
                    }}
                  >
                    {feature.title}
                  </StudyTypographyMediumText>
                </div>

                {isMobile ? (
                  <ChevronRightRounded
                    style={{
                      backgroundColor: theme.palette.secondary.light,
                      padding: "2px",
                      borderRadius: "4px",
                      marginLeft: "4px",
                      alignSelf: "center",
                    }}
                  ></ChevronRightRounded>
                ) : (
                  <div
                    style={{
                      backgroundColor: theme.palette.secondary.light,
                      padding: "4px 4px 4px 4px",
                      borderRadius: "4px",
                      marginLeft: "4px",
                      alignSelf: "center",
                    }}
                  >
                    <StudyTypographyMediumText style={{ fontSize: "12px" }}>
                      {index + 1}
                    </StudyTypographyMediumText>
                  </div>
                )}
              </LeftMainToolBox>
            ))}
            <Button
              onClick={() => {
                navigate("/login");
              }}
              fullWidth
              style={{
                background: STUDYO_GRADIENT,
                backgroundColor: "white",
                marginTop: "24px",
                textTransform: "none",
                color: theme.palette.text.primary,
              }}
            >
              login
            </Button>
            <Button
              onClick={() => {
                navigate("/register");
              }}
              fullWidth
              variant="outlined"
              color="secondary"
              style={{
                marginTop: "16px",
                borderColor: STUDYO_GRADIENT,
                color: STUDYO_GRADIENT,
                textTransform: "none",

                //   backgroundColor: "white",
              }}
            >
              signup
            </Button>
          </div>
          <Stack flexDirection={"row"} alignItems="center" display={"flex"}>
            <StudyTypographyMediumSubtitle
              style={{ marginRight: "12px", marginTop: "3px" }}
            >
              ❤️
            </StudyTypographyMediumSubtitle>
            <StudyTypographyMediumSubtitle>
              Built by students for students
            </StudyTypographyMediumSubtitle>
          </Stack>
        </LeftMainContainer>
      )}

      {isMobile && !showFeature ? null : (
        <RightMainContainer>
          {isMobile ? (
            <StudyTypographyMediumTitle
              style={{ marginTop: "36px", marginBottom: "8px" }}
            >
              {mainPageFeatureText[featurePicked - 1]?.title}
            </StudyTypographyMediumTitle>
          ) : (
            <StudyTypographyMega
              style={{ marginTop: "36px", marginBottom: "24px" }}
            >
              {mainPageFeatureText[featurePicked - 1]?.title}
            </StudyTypographyMega>
          )}
          {isMobile ? (
            <Button
              color="secondary"
              onClick={() => {
                setShowFeature(false);
              }}
              sx={{
                marginBottom: "24px",
                textTransform: "none",
                backgroundColor: theme.palette.primary.light,
              }}
            >
              home
            </Button>
          ) : (
            <Typography
              style={{
                width: "75%",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "lighter",
                marginBottom: "40px",
              }}
            >
              {mainPageFeatureText[featurePicked - 1]?.description}
            </Typography>
          )}
          <RightMainDivider />

          {mainPageFeatureText[featurePicked - 1]?.component}
        </RightMainContainer>
      )}
    </MainContainer>
  );
};
