import { ArrowDropDownRounded, WarningAmberRounded } from "@mui/icons-material";
import { Button, ClickAwayListener, Tooltip, useTheme } from "@mui/material";
import { alpha } from "@mui/system";
import { motion } from "framer-motion";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import {
  removeTodoStart,
  updateTodoDrag,
  updateTodoDuration,
  updateTodoState,
  updateTodoTitle,
} from "../../../graphql/mutations/todo";
import { DraggingContext } from "../../../utils/contexts";
import { ItemTypes } from "../../../utils/types";
import { HoverTypography } from "../../HoverTypography";
import { CheckIcon } from "../../Icons/CheckIcon";
import {
  StudyTypographyMediumSubtitle,
  StudyTypographyXSmallSubtitle,
} from "../../glovebox/StudyTypography";

import toast from "react-hot-toast";
import {
  ADD_DUE,
  ADD_START,
  COMPLETE_TODO,
  DELETE_TODO,
  EDIT_TODO,
  LightenDarkenColor,
  MAINBAR_RIGHT_CLICK,
  REMOVE_START,
  timeDuration,
} from "../../../utils/constants";
import {
  ProfileBlockPopper,
  ToolbarBackground,
  ToolbarDurationBox,
  ToolbarToDoBox,
  ToolbarToDoDurationText,
  ToolbarToDoLeftSide,
  ToolbarToDoSubtitle,
  ToolbarToDoTitle,
} from "../../toolbar/styles";
import DurationBox from "../../glovebox/DurationBox";

export const ScheduleToDoBar = ({
  data,
  index,
  toolbar,
  handleDeleteToDo,
  schedule,
  todos,
  setTodos,
  tapped,
  typeCal,
  slotTime,
  duration,
  cowork,
}: // updateTodoList,
{
  data: any;
  index: any;
  toolbar: boolean;
  handleDeleteToDo: any;
  schedule?: any;
  todos: any;
  setTodos: any;
  tapped: any;
  typeCal: any;
  slotTime: any;
  duration: any;
  cowork?: any;
  // updateTodoList: any;
}) => {
  // console.log(data);
  const [editTodo, setEditTodo] = useState(false);
  const [editTodoText, setEditTodoText] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [todos, setTodos] = useState(JSON.parse(localStorage.getItem('todos')!) || []);
  // // const { id: userId } = useUserContext();
  const userId = localStorage.getItem("userId");
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")!) || []
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const [categories, setCategories] = useState<any>(
    JSON.parse(localStorage.getItem("categories")!) || []
  );
  const [clickAnchorEl, setClickAnchorEl] = useState<null | HTMLElement>(null);
  const clickOpen = Boolean(clickAnchorEl);

  const handleClickDate = (event: React.MouseEvent<HTMLElement>) => {
    setDateAnchorEl(dateAnchorEl ? null : event.currentTarget);
  };

  const [dateAnchorEl, setDateAnchorEl] = useState<null | HTMLElement>(null);
  const dateOpen = Boolean(dateAnchorEl);
  // console.log(dateAnchorEl);
  const returnHours = (time: any) => {
    var hours = time.split(":")[0];
    return hours;
  };
  const returnMinutes = (time: any) => {
    var minutes = time.split(":")[1];
    return minutes;
  };

  const handleRightClick = (event: any) => {
    event.preventDefault();
    setClickAnchorEl(anchorEl ? null : event.currentTarget);
    // Trigger your custom action here
  };

  // const [updateTodo, { data: utdata }] = useMutation(UPDATE_TODO_UUID, {
  //   refetchQueries: [
  //     {
  //       query: GET_USER_TODOS,
  //       variables: { userId: userId },
  //     },
  //     "GetUserTodos",
  //   ],
  //   // refetchQueries()
  // });

  const updateTodo = async (todoUUID: string, dropInfo: any) => {
    await updateTodoDrag(dropInfo, todoUUID, todos, setTodos);
  };

  const updateTodoTitleHelper = async (oldTodo: any) => {
    await updateTodoTitle(oldTodo, editTodoText, todos, setTodos);
  };

  const updateTodoDurationHelper = async (todo: any, duration: number) => {
    await updateTodoDuration(todo, duration, todos, setTodos);
  };
  const updateTodoStateHelper = async (
    oldTodo: any,
    completed: boolean,
    deleted: boolean
  ) => {
    await updateTodoState(oldTodo, completed, deleted, todos, setTodos);
  };

  const handleFocus = (event: any) => event.target.select();

  const { isComponentDragging, setIsDragging } = useContext(DraggingContext);
  const removeTodoStartHelper = (todo: any) => {
    toast.promise(
      removeTodoStart(todo, todos, setTodos),
      {
        loading: "adding todo..",
        success: <b>todo saved!</b>,
        error: <b>Could not save.</b>,
      },
      {
        duration: 4000,
        style: {
          fontSize: "12px",
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.primary,
        },
      }
    );
  };

  // console.log(
  //   "I AM LOGGING 7 TIMES IN A ROW BECAUSE OF COMPLETED AT PR. CHECK ME OUT"
  // );

  const todoCategory =
    categories[
      categories.findIndex(
        (category: any) => category.uuid === data?.categoryId
      )
    ];

  const theme = useTheme();
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.BOX,
    item: index,
    canDrag: false,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      setIsDragging(false);

      if (item && dropResult) {
        // var transformedData = data;
        // transformedData["startTime"] = moment()
        //   //@ts-ignore

        //   .set("hour", returnHours(dropResult.name))
        //   //@ts-ignore

        //   .set("minute", returnMinutes(dropResult.name))
        //   .set( "second", 0)
        //   .set("millisecond", 0)
        //   .toISOString();
        // updateTodoList(transformedData);
        // updateTodo({
        //   variables: {
        //     startTime: new Date(
        //       moment()
        //         //@ts-ignore
        //         .set("hour", returnHours(dropResult.name))
        //         //@ts-ignore

        //         .set("minute", returnMinutes(dropResult.name))
        //         .set("second", 0)
        //         .set("millisecond", 0)
        //         .toISOString()
        //     ),
        //     uuid: data?.uuid,
        //   },
        // });
        updateTodo(data?.uuid, dropResult);
        //todoMutation to change the time or add the time to todo item
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));
  const backgroundColor =
    categories[
      categories.findIndex(
        (category: any) => category.uuid === data?.categoryId
      )
    ]?.label;
  useEffect(() => {
    if (isDragging) {
      setIsDragging(true);
    }
  }, [isDragging]);

  useEffect(() => {
    if (data?.userId === userId) {
      localStorage.setItem("todos", JSON.stringify(todos));
    }
  }, [todos]);

  return (
    <ToolbarBackground
      ref={drag}
      onContextMenu={handleRightClick}
      style={{ height: typeCal === "timeGridDay" ? "" : "100%" }}
    >
      <ToolbarToDoBox
        key={data?.uuid}
        style={{
          overflowY: "hidden",
          textOverflow: "ellipsis",
          marginBottom: "2px",
          border: clickOpen ? `2px solid ${theme.palette.text.primary}` : "0px",
          borderRadius: schedule ? "0px" : "4px",
          alignItems: typeCal === "timeGridDay" ? "center" : "flex-start",
          justifyContent: "flex-start",
          display: "flex",
          padding: user?.compact ? "0px 8px 0px 8px" : "8px",
          background: `linear-gradient(90deg, ${alpha(
            "#FF5ACD",
            0.0
          )} 85%, ${alpha(
            `#${LightenDarkenColor(backgroundColor?.substring(1, 7), 0)}`,
            0.7
          )}1400%)`,
        }}
      >
        <ToolbarToDoLeftSide
          style={{
            alignItems: typeCal === "timeGridDay" ? "center" : "flex-start",
          }}
        >
          {typeCal === "timeGridWeek" || cowork ? (
            <div
              style={{
                width: "6px",
                height: "20px",
                marginRight: "8px",
                borderRadius: "4px",
                backgroundColor: backgroundColor ? backgroundColor : "white",
                flexShrink: 0,
              }}
            />
          ) : data?.duration < 30 && slotTime !== "00:05:00" ? null : (
            <div>
              <motion.div
                whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
                whileHover={{ rotate: 10 }}
                transition={{
                  type: "spring",
                  stiffness: 200,
                  damping: 8,
                }}
                style={{ alignItems: "center", display: "flex" }}
                onClick={(event: any) => {
                  event.stopPropagation();
                  updateTodoStateHelper(
                    data,
                    data?.completedAt ? false : true,
                    false
                  );
                }}
              >
                <CheckIcon
                  height={"24px"}
                  width={"24px"}
                  active={data?.completedAt !== null}
                  clicked={() => {}}
                />
                {/* {data?.completedAt} */}
              </motion.div>
              {/* {data?.completedAt} */}
            </div>
          )}

          {editTodo ? (
            <ClickAwayListener
              onClickAway={() => {
                setEditTodo(false);
              }}
            >
              <input
                autoFocus
                style={{
                  border: "0px",
                  backgroundColor: theme.palette.primary.light,
                  width: "100%",
                  height: "30px",
                  paddingTop: "10px",
                  paddingBottom: "10px",

                  paddingLeft: "16px",
                  outlineWidth: 0,
                  borderRadius: "4px",
                  color: theme.palette.text.primary,
                  userSelect: "text",
                }}
                onFocus={handleFocus}
                placeholder={"text new todo.."}
                type={"text"}
                name="title"
                value={editTodoText}
                onChange={(text) => {
                  console.log(text);
                  setEditTodoText(text.target.value);
                }}
              ></input>
            </ClickAwayListener>
          ) : (
            <div
              style={{
                textOverflow: "ellipsis",
                overflowY: "hidden",
                // whiteSpace: "nowrap",
                marginRight: "12px",
              }}
            >
              <ToolbarToDoTitle
                marginLeft={typeCal === "timeGridDay" ? "8px" : "0px"}

                // onDoubleClick={(event: any) => {
                //   event.stopPropagation();

                //   setEditTodo(true);
                //   setEditTodoText(data?.title);
                // }}
              >
                {data?.title}
              </ToolbarToDoTitle>
              {data?.duration > 30 ? (
                <StudyTypographyXSmallSubtitle
                  marginLeft={typeCal === "timeGridDay" ? "8px" : "0px"}
                  marginTop={typeCal === "timeGridWeek" ? "4px" : "0px"}
                >
                  {`${moment(data?.startTime).format("h:mma")} - ${moment(
                    data?.startTime
                  )
                    .add(data?.duration, "minutes")
                    .format("h:mma")}`}
                </StudyTypographyXSmallSubtitle>
              ) : data?.creating && data?.duration > 30 ? (
                <StudyTypographyXSmallSubtitle
                  marginLeft={typeCal === "timeGridDay" ? "8px" : "0px"}
                >
                  {`${moment(data?.startTime).format("h:mma")} - ${moment(
                    data?.startTime
                  )
                    .add(data?.duration)
                    .format("h:mma")}`}
                </StudyTypographyXSmallSubtitle>
              ) : null}
            </div>
          )}
        </ToolbarToDoLeftSide>
        {editTodo ? (
          <form
            onSubmit={(event: any) => {
              event.stopPropagation();
              updateTodoTitleHelper(data);
              setEditTodo(false);
            }}
          >
            <Button
              variant="text"
              color="secondary"
              sx={{
                textTransform: "none",
                marginLeft: "12px",
                padding: "4px",
                backgroundColor: theme.palette.secondary.light,
              }}
              onClick={(event: any) => {
                // updateTodo({
                //   variables: { title: editTodoText, uuid: data?.uuid },
                // });
                event.stopPropagation();
                updateTodoTitleHelper(data);
                setEditTodo(false);
              }}
            >
              save
            </Button>
          </form>
        ) : (
          <ClickAwayListener
            onClickAway={() => {
              setAnchorEl(null);
            }}
          >
            {typeCal === "timeGridDay" &&
            data?.duration &&
            data?.duration >= 30 ? (
              <DurationBox
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                num={data?.duration}
                user={user}
              />
            ) : (
              <div></div>
            )}
          </ClickAwayListener>
        )}
        {/* {tapped ? (
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{
              duration: 0.2,
              type: "spring",
              stiffness: 700,
              damping: 30,
            }}
            style={{ flexDirection: "column", display: "flex" }}
          >
            {MAINBAR_RIGHT_CLICK.map((action) => (
              <Button size="small">
                <StudyTypographyMediumSubtitle style={{ marginLeft: "12px" }}>
                  {action?.name}
                  {getDuration(new Date(data.startTime), new Date(data.endTime))}
                </StudyTypographyMediumSubtitle>
              </Button>
            ))}
          </motion.div>
        ) : null} */}

        <ProfileBlockPopper
          open={open}
          anchorEl={anchorEl}
          placement={"bottom-end"}
          modifiers={[{ name: "arrow", enabled: true }]}
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            margin: 0,
            paddingRight: 0,
            paddingTop: "8px",
            zIndex: 100,
          }}
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          <div
            style={{
              alignItems: "center",
              display: "inline-grid",
              justifyContent: "center",
              backgroundColor: theme.palette.primary.main,
              border: `1px solid ${theme.palette.secondary.light}`,
              padding: "12px",
              borderRadius: "4px",
            }}
          >
            {timeDuration.map((time) => (
              <ToolbarToDoDurationText
                key={time}
                style={{
                  padding: "4px",
                  textAlign: "center",
                }}
                onClick={(event: any) => {
                  // updateTodo({
                  //   variables: { duration: time, uuid: data?.uuid },
                  // });
                  event.stopPropagation();
                  setAnchorEl(null);
                  updateTodoDurationHelper(data, time);
                }}
              >
                {time} min
              </ToolbarToDoDurationText>
            ))}
          </div>
        </ProfileBlockPopper>
        {typeCal === "timeGridDay" &&
        (data?.duration >= 30 || slotTime === "00:05:00") ? (
          <Tooltip title={todoCategory?.name} placement={"top"}>
            <div
              // onClick={handleCategoryClick}
              style={{
                whiteSpace: "nowrap",
                width: "18px",
                height: "18px",
                border: `2px solid ${
                  todoCategory?.label ? todoCategory?.label : "white"
                }`,
                borderRadius: "4px",
                marginLeft: "12px",
              }}
            ></div>
          </Tooltip>
        ) : null}
        <ClickAwayListener
          onClickAway={() => {
            setClickAnchorEl(null);
          }}
        >
          <ProfileBlockPopper
            open={clickOpen}
            anchorEl={clickAnchorEl}
            placement={toolbar ? "bottom-end" : "bottom"}
            modifiers={[{ name: "arrow", enabled: true }]}
            style={{
              backgroundColor: theme.palette.primary.main,
              border: `1px solid ${theme.palette.secondary.light}`,
            }}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          >
            <div
              style={{
                alignItems: "center",
                display: "inline-grid",
                justifyContent: "center",
              }}
            >
              {MAINBAR_RIGHT_CLICK.map((item) => {
                return (
                  <HoverTypography
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "8px",
                      paddingRight: "8px",

                      borderRadius: "4px",
                      color:
                        item.name === "delete todo"
                          ? theme.palette.error.dark
                          : theme.palette.text.primary,
                    }}
                    onClick={(event: any) => {
                      setClickAnchorEl(null);
                      if (item.name === EDIT_TODO) {
                        setEditTodo(true);
                        setEditTodoText(data?.title);
                      } else if (item.name === REMOVE_START) {
                        removeTodoStartHelper(data);
                      } else if (item.name === COMPLETE_TODO) {
                        // updateTodo({
                        //   variables: {
                        //     uuid: data?.uuid,
                        //     completed: true,
                        //   },
                        // });
                        updateTodoStateHelper(
                          data,
                          data?.completedAt ? false : true,
                          false
                        );
                      } else if (item.name === DELETE_TODO) {
                        // updateTodo({
                        //   variables: {
                        //     uuid: data?.uuid,
                        //     deleted: true,
                        //   },
                        // });
                        // updateTodoState(data?.uuid, true, false)
                        handleDeleteToDo(data);
                      } else if (item.name === ADD_START) {
                        handleClickDate(event);
                      } else if (item.name === ADD_DUE) {
                        handleClickDate(event);
                      }
                    }}
                  >
                    {item.icon}
                    <StudyTypographyMediumSubtitle
                      style={{
                        paddingTop: "6px",
                        paddingBottom: "6px",
                        borderRadius: "4px",
                        paddingLeft: "8px",
                        color:
                          item.name === "delete todo"
                            ? theme.palette.error.dark
                            : theme.palette.text.primary,
                      }}
                    >
                      {item.name}
                    </StudyTypographyMediumSubtitle>
                  </HoverTypography>
                );
              })}
            </div>
          </ProfileBlockPopper>
        </ClickAwayListener>
        {moment(data?.startTime).add(8, "hours").toISOString() <
          moment().toISOString() &&
        !data?.completedAt &&
        !cowork ? (
          <WarningAmberRounded
            color="error"
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginLeft: typeCal === "timeGridDay" ? "12px" : "0px",
              height: "18px",
              width: "18px",
              padding: "0px",
            }}
          />
        ) : null}
      </ToolbarToDoBox>
    </ToolbarBackground>
  );
};
