import { ClickAwayListener, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { updateTodoState } from "../../../graphql/mutations/todo";
import { CheckIcon } from "../../Icons/CheckIcon";
import { XIcon } from "../../sidebar/Icons";

import { toast } from "react-hot-toast";
import {
  CalendarViewTaskContainer,
  CalendarViewTaskLabel,
  StyledBox,
  StyledCloseButton,
  StyledDialog,
  StyledDialogTopBar,
  StyledHeader,
} from "./styles";
var Sherlock = require("sherlockjs");

const monthsOfYear = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const CalendarViewModal = ({
  open,
  onClose,
  day,
  handleSelectTask,
  handleOpenEditModal,
  todos,
  setTodos,
}: {
  open: any;
  onClose: any;
  day: any;
  handleSelectTask: any;
  handleOpenEditModal?: any;
  todos: any;
  setTodos: any;
}) => {
  const theme = useTheme();
  // console.log(day);
  // const { id: userId } = useUserContext();
  const userId = localStorage.getItem("userId");
  // const [todos, setTodos] = useState(JSON.parse(localStorage.getItem('todos')!) || [])
  // console.log(todos)
  const [startText, setStartText] = useState("");
  const [dueText, setDueText] = useState("");

  const [start, setStart] = useState(null);
  const [due, setDue] = useState(null);

  const [editTodo, setEditTodo] = useState(false);
  const [editTodoText, setEditTodoText] = useState("");
  const [typeInput, setTypeInput] = useState("todo");
  const [title, setTitle] = useState("");
  const user = JSON.parse(localStorage.getItem("user")!);
  const inputRef = useRef<any>(null);
  const [rightClickTask, setRightClickTask] = useState<any>();
  const [clickAnchorEl, setClickAnchorEl] = useState<null | HTMLElement>(null);
  const clickOpen = Boolean(clickAnchorEl);
  const handleRightClick = (event: any, taskId: any) => {
    event.preventDefault();
    setRightClickTask(rightClickTask === taskId ? null : taskId);
    setClickAnchorEl(
      clickAnchorEl === event.currentTarget ? null : event.currentTarget
    );
    // Trigger your custom action here
  };

  useEffect(() => {
    localStorage.setItem("todos", JSON.stringify(todos));
    // console.log("all todos: ", setAllTodos);
  }, [todos]);
  // console.log("all todos: ", allTodos);
  // const [updateTodo, { data: utdata }] = useMutation(UPDATE_TODO_UUID, {
  //   refetchQueries: [
  //     {
  //       query: GET_USER_TODOS,
  //       variables: { userId: userId },
  //     },
  //     "GetUserTodos",
  //   ],
  //   // refetchQueries()
  // });
  const updateTodoStateHelper = (
    oldTodo: any,
    completed: boolean,
    deleted: boolean
  ) => {
    toast.promise(
      updateTodoState(oldTodo, completed, deleted, todos, setTodos),
      {
        loading: "updating todo..",
        success: <b>todo saved!</b>,
        error: <b>Could not save.</b>,
      },
      {
        duration: 4000,
        style: {
          fontSize: "12px",
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.primary,
        },
      }
    );
  };
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="archive-task-modal"
      aria-describedby="modal-modal-description"
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <StyledBox>
        <StyledDialogTopBar>
          <StyledHeader
            style={{
              marginLeft: 0,
            }}
          >
            {`${monthsOfYear[day?.day.getMonth()]} ${day?.day.getDate()}`}
          </StyledHeader>
          <StyledCloseButton onClick={onClose}>
            <XIcon />
          </StyledCloseButton>
        </StyledDialogTopBar>
        {day?.tasks?.length !== 0 ? (
          todos
            .filter(
              (todo: any) =>
                (moment(todo?.startTime).date() === day?.date &&
                  moment(todo?.startTime).year() === moment(day?.day).year() &&
                  moment(todo?.startTime).month() ===
                    moment(day?.day).month()) ||
                (moment(todo?.dueAt).date() === day?.date &&
                  moment(todo?.dueAt).month() === moment(day?.day).month() &&
                  moment(todo?.dueAt).year() === moment(day?.day).year() &&
                  (todo?.dueAt === null || todo?.dueAt))
            )
            .sort((a: any, b: any) => {
              // console.log(a);
              // console.log(b);
              return (
                new Date(a.startTime).valueOf() -
                new Date(b.startTime).valueOf()
              );
            })
            .map((task: any, index: any) => (
              <ClickAwayListener
                onClickAway={() => {
                  setRightClickTask("");
                }}
              >
                <CalendarViewTaskContainer
                  style={{
                    // border:
                    //   task?.id === rightClickTask
                    //     ? `2px solid ${theme.palette.text.primary}`
                    //     : "0px",
                    marginLeft: "0px",
                    marginRight: "0px",
                    marginBottom: "6px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "8px",
                  }}
                  onClick={() => {
                    handleSelectTask(task);
                    handleOpenEditModal(true);
                    onClose();
                  }}
                  key={task?.uuid}
                  onContextMenu={(event: any) => {
                    handleRightClick(event, task?.uuid);
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <motion.div
                      whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
                      whileHover={{ rotate: 10 }}
                      transition={{
                        type: "spring",
                        stiffness: 200,
                        damping: 8,
                      }}
                      style={{
                        marginLeft: "12px",
                        alignSelf: "center",
                        display: "flex",
                      }}
                      onClick={(event: any) => {
                        event.preventDefault();
                        event.stopPropagation();
                        updateTodoStateHelper(
                          task,
                          task?.completedAt ? false : true,
                          false
                        );
                      }}
                    >
                      <CheckIcon
                        height={"24px"}
                        width={"24px"}
                        active={task?.completedAt !== null}
                        clicked={(event: any) => {
                          // updateTodo({
                          //   variables: {
                          //     uuid: task?.uuid,
                          //     completed: task?.completedAt ? false : true,
                          //   },
                          // });
                        }}
                      />
                    </motion.div>
                    {/* {editTodo && rightClickTask === task?.id ? (
                      <ClickAwayListener
                        onClickAway={() => {
                          console.log("YOOOOO");
                          setEditTodo(false);
                        }}
                      >
                        <input
                          autoFocus
                          style={{
                            border: "0px",
                            backgroundColor: theme.palette.primary.light,
                            width: "100%",
                            height: "30px",
                            paddingTop: "10px",
                            paddingBottom: "10px",

                            paddingLeft: "16px",
                            outlineWidth: 0,
                            borderRadius: "4px",
                            color: theme.palette.text.primary,
                          }}
                          placeholder={"text new todo.."}
                          type={"text"}
                          name="title"
                          value={editTodoText}
                          onChange={(text) => {
                            setEditTodoText(text.target.value);
                          }}
                        ></input>
                      </ClickAwayListener>
                    ) : ( */}
                    <CalendarViewTaskLabel
                      style={{ fontSize: "14px", marginLeft: "12px" }}
                    >
                      {task?.title}
                    </CalendarViewTaskLabel>
                    {/* )} */}
                  </div>
                  <div
                    style={{
                      justifyContent: "flex-end",
                      display: "inline",
                      alignItems: "flex-end",
                      textAlign: "right",
                      paddingRight: "12px",
                    }}
                  >
                    {moment(task?.startTime).isValid() ? (
                      <CalendarViewTaskLabel
                        style={{
                          fontSize: "12px",
                          color: theme.palette.action.active,
                          textAlign: "right",
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        {`START: ${moment(task?.startTime).format("h:mmA")}`}
                      </CalendarViewTaskLabel>
                    ) : null}
                    {new Date(task?.dueAt).getDate() === day.date &&
                    (task?.dueAt !== null || task?.dueAt !== undefined) &&
                    moment(task?.dueAt).isValid() ? (
                      <CalendarViewTaskLabel
                        style={{
                          fontSize: "12px",
                          color: theme.palette.error.main,
                          textAlign: "right",
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        {`DUE: ${moment(task?.dueAt).format("h:mmA")}`}
                      </CalendarViewTaskLabel>
                    ) : null}
                  </div>
                </CalendarViewTaskContainer>
              </ClickAwayListener>
            ))
        ) : (
          <CalendarViewTaskLabel>No Tasks Are Due Today</CalendarViewTaskLabel>
        )}
      </StyledBox>
    </StyledDialog>
  );
};

export default CalendarViewModal;
