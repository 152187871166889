import { MoreHorizOutlined } from "@mui/icons-material";
import { Button, Grid, Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDrag } from "react-dnd";
import {
  DeleteFriend,
  DELETE_FRIEND,
} from "../../../graphql/mutations/friends";
import { client } from "../../../utils/apollo";
import { ItemTypes } from "../../../utils/types";
import {
  StudyTypographyMediumTitle,
  StudyTypographySmallSubtitle,
} from "../../glovebox/StudyTypography";
import { FriendContainer } from "./FriendContainer";

export const FriendsToolbar = ({
  user,
  setUser,
  handleSetNewGroup,
}: {
  user: any;
  setUser: any;
  handleSetNewGroup: any;
}) => {
  const [selectedFriends, setSelectedFriends] = useState<string[]>([]);
  // const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')!) || {});
  // const [friends, setFriends] = useState(user?.friends || []);
  useEffect(() => {
    // console.log(user)
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  const handleSelectFriend = (friendId: string) => {
    if (selectedFriends.includes(friendId)) {
      let newSetFriends = selectedFriends.filter(function (item) {
        return item !== friendId;
      });
      setSelectedFriends(newSetFriends);
    } else {
      setSelectedFriends([...selectedFriends, friendId]);
    }
  };

  const deleteFriendHelper = async (friendId: string) => {
    await DeleteFriend(friendId, user, setUser);
  };

  const theme = useTheme();

  return (
    <div
      style={{
        width: "100%",
        height: "90vh",
        overflowY: "scroll",
        padding: isMobile ? "16px" : "0px",
      }}
    >
      <Grid
        container
        rowSpacing={2}
        columnSpacing={isMobile ? 0 : 2}
        direction={isMobile ? "column" : "row"}
        width={"100%"}
        style={{ marginBottom: isMobile ? "50px" : "0px" }}
      >
        {user?.friends?.length > 0 ? (
          user?.friends?.map((friend: any, index: number) => {
            let selected = selectedFriends.includes(friend.id);
            return (
              <FriendContainer
                data={friend}
                handleSetNewGroup={handleSetNewGroup}
              />
            );
          })
        ) : (
          <FriendContainer
            data={{
              firstName: "Add",
              lastName: "Friend",
              email: "click here to go to add friends page",
            }}
            handleSetNewGroup={handleSetNewGroup}
          />
        )}
      </Grid>
    </div>
  );
};

{
  /* {selected ? (
                    friend.todos ? (
                      friend.todos.map((todo: any, index: number) => {
                        return (
                          <div key={index}>
                            <p>Title:{todo.title}</p>
                            <text>
                              Start Time: {todo.startTime} -- Duration:{" "}
                              {todo.duration}
                            </text>
                          </div>
                        );
                      })
                    ) : (
                      <div>Your firend has no todos!</div>
                    )
                  ) : (
                    <div></div>
                  )} */
}
