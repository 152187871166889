/* @ts-ignore */
import { AccessTimeRounded } from "@mui/icons-material";
import { alpha, Box, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useOutletContext } from "react-router-dom";
import {
  DAYS_OF_WEEK,
  MONTHS_OF_YEAR,
  STAGGER_CHILDREN,
  STAGGER_CONTAINER,
  STUDYO_GRADIENT,
} from "../../../utils/constants";
import { EditToDoModal } from "../../glovebox/EditToDoModal";
import { AddIcon } from "../../Icons/AddIcon";
import { ChevronDown } from "../../sidebar/Icons";
import CalendarViewAddEventModal from "./CalendarViewAddEventModal";
import CalendarViewModal from "./CalendarViewModal";
import {
  CalendaListItem,
  CalendarDayOfMonthLabel,
  CalendarDayOfWeekBar,
  CalendarDayOfWeekLabel,
  CalendarLeftArrowIcon,
  CalendarRightArrowIcon,
  CalendarTopBar,
  CalendarTopBarLeftSide,
  CalendarViewContainer,
  CalendarViewDayContainer,
  CalendarViewDayTopBar,
  CalendarViewDMonthContainer,
  CalendarViewLabel,
  CalendarViewModalContainer,
  CalendarViewMonthDayToggle,
  CalendarViewShowDate,
  CalendarViewStatusNotifier,
  CalendarViewTaskContainer,
  CalendarViewTaskIcon,
  CalendarViewTaskLabel,
  CalendarViewTaskShowMore,
  CalendarViewWarningIcon,
  CalendarViewWeekendContainer,
  CalendarViewWeekendIconCheckmark,
  StyledList,
  StyledPopper,
  TaskTemplateLabelWrapper,
} from "./styles";

const CalendarMain = () => {
  const [todos, setTodos, user, setUser, categories, setCategories] =
    useOutletContext<any>();
  const [allTodos, setAllTodos] = useState<any[]>([]);

  // const { columns, onCalendarDateChange } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [calendarView, setCalendarView] = useState("MONTH");
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const [openCalendarAddModal, setOpenCalendarAddModal] = useState(false);

  const [dateSelected, setDateSelected] = useState();
  const open = Boolean(anchorEl);
  // const [todos, setTodos] = useState<any>([]);
  // const [todos, setTodos] = useState(
  //   JSON.parse(localStorage.getItem("todos")!) || []
  // );
  const [editTodo, setEditTodo] = useState(false);
  const [trackDate, setTrackDate] = useState(new Date());
  const [day, setDay] = useState(new Date().getDate());
  const [hoverDate, setHoverDate] = useState();
  const [currentDaysOfMonth, setCurrentDaysOfMonth] = useState<any>();
  const [week, setWeek] = useState<any>([]);
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any>();
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const handleClick = (event: { currentTarget: React.SetStateAction<null> }) =>
    setAnchorEl(anchorEl ? null : event.currentTarget);
  // const { id: userId } = useUserContext();
  const userId = localStorage.getItem("userId");

  const theme = useTheme();

  // const [getTodoData, { data, error }] = useLazyQuery(GET_USER_TODOS, {
  //   variables: {
  //     userId: userId,
  //   },
  //   onCompleted: (data) => {
  //     console.log(data);
  //     setTodos(data?.getUserTodos);
  //   },
  // });

  useEffect(() => {
    localStorage.setItem("todos", JSON.stringify(todos));
    // console.log(
    //   todos
    //     ?.map((parent: any) => {
    //       if (parent.todoReplicas) {
    //         let children = parent.todoReplicas.map((child: any) => ({
    //           ...child,
    //           title: parent.title,
    //         }));
    //         return [parent, ...children];
    //       }
    //       return [parent];
    //     }) // Merge parent and children into an array
    // ?.reduce((prev: any, curr: any) => prev.concat(curr), null) // Concatenate all arrays into a single array)
    // );
    if (todos.length !== 0) {
      const holdAllTodos = todos
        ?.map((parent: any) => {
          if (parent.todoReplicas && parent.todoReplicas.length > 0) {
            let children = parent.todoReplicas?.map((child: any) => ({
              ...child,
              title: parent.title,
            }));
            return [parent, ...children];
          }
          return [parent];
        }) // Merge parent and children into an array
        ?.reduce((prev: any, curr: any) => prev.concat(curr));
      setAllTodos(holdAllTodos); // Concatenate all arrays into a single array
    }
  }, [todos]);

  // const handleCalendarDatesChange = (date) => {
  //   const holdFromDate = new Date(date);
  //   // so that if there are previous days on the calendar not in the month then it will get those tasks as well
  //   holdFromDate.setDate(holdFromDate.getDate() - 6);
  //   setFromDate(holdFromDate);
  //   setToDate(new Date(date.getFullYear(), date.getMonth() + 1, 6));
  // };
  // const [categories, setCategories] = useState<any>(
  //   JSON.parse(localStorage.getItem("categories")!) || []
  // );

  const CALENDAR_VIEWS = { MONTH: "MONTH", WEEK: "WEEK" };
  const handleTaskClose = () => {
    setOpenTaskModal(false);
  };

  const getTasks = (days: string | any[], tasks: string | any[]) => {
    if (tasks) {
      if (calendarView === "WEEK") {
        for (let i = 0; i < tasks.length; i += 1) {
          for (let j = 0; j < days.length; j += 1) {
            const holdStartDate = new Date(tasks[i].startTime);
            const holdDueDate = new Date(tasks[i].dueAt);
            if (
              (days?.[j]?.day.getDate() === holdStartDate.getDate() &&
                days?.[j]?.day.getMonth() === holdStartDate.getMonth() &&
                days?.[j]?.day.getFullYear() === holdStartDate.getFullYear()) ||
              (days?.[j]?.day.getDate() === holdDueDate.getDate() &&
                days?.[j]?.day.getMonth() === holdDueDate.getMonth() &&
                days?.[j]?.day.getFullYear() === holdDueDate.getFullYear())
            ) {
              days[j].tasks.push(tasks[i]);
            }
          }
        }
      } else {
        const holdCurrentDaysOfMonth = days;
        for (let i = 0; i < tasks.length; i += 1) {
          for (let j = 0; j < days.length; j += 1) {
            const holdStartDate = new Date(tasks[i].startTime);
            const holdDueDate = new Date(tasks[i].dueAt);

            if (
              (days?.[j]?.day.getDate() === holdStartDate.getDate() &&
                days?.[j]?.day.getMonth() === holdStartDate.getMonth() &&
                days?.[j]?.day.getFullYear() === holdStartDate.getFullYear()) ||
              (days?.[j]?.day.getDate() === holdDueDate.getDate() &&
                days?.[j]?.day.getMonth() === holdDueDate.getMonth() &&
                days?.[j]?.day.getFullYear() === holdDueDate.getFullYear())
            ) {
              holdCurrentDaysOfMonth[j].tasks.push(tasks[i]);
            }
          }
        }
        setCurrentDaysOfMonth(holdCurrentDaysOfMonth);
      }
    }
  };

  const handleSelectTask = (select: any) => {
    setSelectedTask(select);
    setOpenTaskModal(true);
  };
  // console.log(categories);

  const getDaysOfWeek = (tasks: any) => {
    const daysOfWeek = [];
    const trackedDate = trackDate;

    const dayOfWeek = trackDate.getDay();
    for (let i = dayOfWeek; i >= 0; i -= 1) {
      const holdDay = new Date(trackedDate);
      holdDay.setDate(holdDay.getDate() - i);
      daysOfWeek.push({ date: holdDay.getDate(), tasks: [], day: holdDay });
    }
    for (let i = 0; i < 6 - dayOfWeek; i += 1) {
      let holdDay = new Date(trackedDate);
      holdDay = new Date(holdDay.setDate(holdDay.getDate() + 1 + i));
      daysOfWeek.push({ date: holdDay.getDate(), tasks: [], day: holdDay });
    }
    setWeek(daysOfWeek);
    getTasks(daysOfWeek, tasks);
  };

  const getDaysOfMonth = (tasks: any) => {
    const daysOfMonth = [];
    const dayOfWeek = new Date(year, month, 1).getDay();

    for (let i = dayOfWeek - 1; i >= 0; i--) {
      const numDay = new Date(year, month, 0).getDate();
      const numDate = new Date(year, month - 1, numDay - i);

      daysOfMonth.push({ date: numDay - i, tasks: [], day: numDate });
    }
    const lastDateOfMonth = new Date(year, month + 1, 0).getDate();
    for (let i = 1; i <= lastDateOfMonth; i++) {
      const currentMonthDay = new Date(year, month, i);
      daysOfMonth.push({ date: i, tasks: [], day: currentMonthDay });
    }
    const lastDayOfMonth = new Date(year, month + 1, 0).getDay();
    for (let i = 0; i < 6 - lastDayOfMonth; i++) {
      const numDay = new Date(year, month + 1, 1 + i).getDate();
      const numDate = new Date(year, month + 1, 1 + i);
      daysOfMonth.push({ date: numDay, tasks: [], day: numDate });
    }
    setCurrentDaysOfMonth(daysOfMonth);
    getTasks(daysOfMonth, tasks);
  };

  //   const handleSelectTask = (taskID) => {
  //     setTaskId(taskID);
  //     setOpenCalendarModal(false);
  //     setOpenTaskModal(true);
  //   };

  useEffect(() => {
    setWeek(null);
    setCurrentDaysOfMonth(null);
    if (calendarView === "WEEK") {
      getDaysOfWeek(allTodos);
    } else if (calendarView === "MONTH") {
      getDaysOfMonth(allTodos);
    }
  }, [day, month, calendarView, todos, allTodos]);

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        overflowY: "auto",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      }}
    >
      <CalendarViewModalContainer>
        <CalendarViewModal
          open={openCalendarModal}
          onClose={() => {
            setOpenCalendarModal(false);
          }}
          handleSelectTask={handleSelectTask}
          handleOpenEditModal={setEditTodo}
          day={dateSelected}
          todos={allTodos}
          setTodos={setAllTodos}
        />
        <CalendarViewAddEventModal
          open={openCalendarAddModal}
          onClose={() => {
            setOpenCalendarAddModal(false);
          }}
          todos={todos}
          setTodos={setTodos}
          day={dateSelected}
        />
        <EditToDoModal
          todo={selectedTask}
          todos={todos}
          setTodos={setTodos}
          openEdit={editTodo}
          setOpenEditTodo={setEditTodo}
          title={selectedTask?.title}
        />
        <CalendarTopBar>
          <CalendarTopBarLeftSide>
            <CalendarViewShowDate>{`${MONTHS_OF_YEAR[month]} ${year}`}</CalendarViewShowDate>
            <CalendarLeftArrowIcon
              onClick={() => {
                const holdDate = new Date(trackDate).setDate(
                  new Date(trackDate).getDate() - 7
                );

                if (calendarView === "WEEK") {
                  const compareDates = new Date(holdDate);
                  if (compareDates.getMonth() !== trackDate.getMonth()) {
                    // onCalendarDateChange(
                    //   new Date(
                    //     compareDates.getFullYear(),
                    //     compareDates.getMonth(),
                    //     1
                    //   )
                    // );
                  }
                  setWeek(null);
                  setCurrentDaysOfMonth(null);
                  setTrackDate(new Date(holdDate));
                  setMonth(new Date(holdDate).getMonth());
                  setYear(new Date(holdDate).getFullYear());
                  setDay(new Date(holdDate).getDate());
                }
                if (calendarView === "MONTH") {
                  setWeek(null);
                  setCurrentDaysOfMonth(null);
                  // onCalendarDateChange(
                  //   new Date(
                  //     month === 0 ? year - 1 : year,
                  //     month === 0 ? 11 : month - 1,
                  //     1
                  //   )
                  // );

                  setMonth(month === 0 ? 11 : month - 1);
                  setYear(month === 0 ? year - 1 : year);
                }
              }}
            />
            <CalendarRightArrowIcon
              onClick={() => {
                const holdDate = new Date(trackDate).setDate(
                  new Date(trackDate).getDate() + 7
                );
                if (calendarView === "WEEK") {
                  const compareDates = new Date(holdDate);
                  if (compareDates.getMonth() !== trackDate.getMonth()) {
                    // onCalendarDateChange(
                    //   new Date(
                    //     compareDates.getFullYear(),
                    //     compareDates.getMonth(),
                    //     1
                    //   )
                    // );
                  }
                  setWeek(null);
                  setCurrentDaysOfMonth(null);
                  setTrackDate(new Date(holdDate));
                  setMonth(new Date(holdDate).getMonth());
                  setYear(new Date(holdDate).getFullYear());
                  setDay(new Date(holdDate).getDate());
                }
                if (calendarView === "MONTH") {
                  setWeek(null);
                  setCurrentDaysOfMonth(null);
                  // onCalendarDateChange(
                  //   new Date(
                  //     month === 11 ? year + 1 : year,
                  //     month === 11 ? 0 : month + 1,
                  //     1
                  //   )
                  // );
                  setMonth(month === 11 ? 0 : month + 1);
                  setYear(month === 11 ? year + 1 : year);
                }
              }}
            />
            {/* @ts-ignore */}

            <CalendarViewMonthDayToggle open={open} onClick={handleClick}>
              <TaskTemplateLabelWrapper>
                <CalendarViewLabel>{calendarView}</CalendarViewLabel>
              </TaskTemplateLabelWrapper>

              <ChevronDown />
            </CalendarViewMonthDayToggle>
            <StyledPopper
              open={open}
              anchorEl={anchorEl}
              placement="bottom-start"
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined} // modifiers={{
              //   flip: {
              //     enabled: false,
              //   },
              //   preventOverflow: {
              //     enabled: false,
              //     boundariesElement: "scrollParent",
              //   },
              // }}
            >
              <StyledList>
                {Object.keys(CALENDAR_VIEWS).map((decision, i) => (
                  <CalendaListItem
                    key={i}
                    //   toggle={decision === calendarView}
                    onClick={() => {
                      setCalendarView(decision);
                      setMonth(new Date().getMonth());
                      setYear(new Date().getFullYear());
                    }}
                  >
                    <CalendarViewLabel>{decision}</CalendarViewLabel>
                  </CalendaListItem>
                ))}
                <CalendarViewWeekendContainer>
                  <CalendarViewWeekendIconCheckmark />
                  <CalendarViewLabel>Show weekends</CalendarViewLabel>
                </CalendarViewWeekendContainer>
              </StyledList>
            </StyledPopper>
          </CalendarTopBarLeftSide>

          {isMobile ? null : (
            <CalendarViewStatusNotifier>
              <CalendarViewWarningIcon />
              <CalendarViewLabel>
                Click on day to interact with tasks
              </CalendarViewLabel>
            </CalendarViewStatusNotifier>
          )}
        </CalendarTopBar>
        {calendarView === "MONTH" || isMobile ? (
          <Fragment>
            {currentDaysOfMonth && (
              <motion.div
                variants={STAGGER_CONTAINER}
                initial="hidden"
                animate="visible"
                style={{
                  scrollbarWidth: "none",

                  overflowY: "scroll",
                  overflowX: "hidden",
                  width: "100%",
                }}
              >
                <div style={{ width: "100%", flex: 1 }}>
                  <CalendarViewContainer>
                    <CalendarDayOfWeekBar>
                      {DAYS_OF_WEEK.map((day) => (
                        <CalendarViewLabel key={day}>{day}</CalendarViewLabel>
                      ))}
                    </CalendarDayOfWeekBar>
                  </CalendarViewContainer>

                  <CalendarViewDMonthContainer view={calendarView}>
                    {/* <AnimatePresence> */}
                    {currentDaysOfMonth?.map((taskObject: any, index: any) => {
                      const todayDate = new Date();
                      return (
                        <motion.div variants={STAGGER_CHILDREN}>
                          <CalendarViewDayContainer
                            view={calendarView}
                            key={`${taskObject?.day.getMonth()} ${taskObject?.day.getDate()}`}
                            onClick={() => {
                              setDateSelected(taskObject);
                              setOpenCalendarModal(true);
                            }}
                            onMouseEnter={() => {
                              setHoverDate(taskObject?.date);
                            }}
                          >
                            <CalendarViewDayTopBar>
                              {taskObject?.day.getMonth() ===
                                todayDate.getMonth() &&
                              taskObject?.day.getDate() ===
                                todayDate.getDate() &&
                              taskObject?.day.getFullYear() ===
                                todayDate.getFullYear() ? (
                                <CalendarDayOfMonthLabel
                                  style={{
                                    backgroundColor:
                                      theme.palette.action.active,
                                    color: theme.palette.primary.main,
                                  }}
                                >
                                  {taskObject?.date}
                                </CalendarDayOfMonthLabel>
                              ) : (
                                <CalendarViewLabel>
                                  {taskObject?.date}
                                </CalendarViewLabel>
                              )}
                            </CalendarViewDayTopBar>

                            {taskObject?.tasks?.map(
                              (task: any, index: number) => {
                                // console.log(task);
                                const content = (
                                  <CalendarViewTaskContainer
                                    onClick={(event: any) => {
                                      setDateSelected(taskObject);
                                      setOpenCalendarModal(true);
                                    }}
                                  >
                                    {taskObject?.day.getMonth() ===
                                      moment(task?.dueAt).month() &&
                                    taskObject?.day.getDate() ===
                                      moment(task?.dueAt).date() &&
                                    taskObject?.day.getFullYear() ===
                                      moment(task?.dueAt).year() ? (
                                      <div
                                        style={{
                                          width: "6px",
                                          height: "20px",
                                          marginRight: "12px",
                                          borderRadius: "4px",
                                          backgroundColor:
                                            categories[
                                              categories.findIndex(
                                                (category: any) =>
                                                  category?.uuid ===
                                                  task?.categoryId
                                              )
                                            ]?.label.substring(0, 1) === "#"
                                              ? categories[
                                                  categories.findIndex(
                                                    (category: any) =>
                                                      category?.uuid ===
                                                      task?.categoryId
                                                  )
                                                ]?.label
                                              : "white",
                                        }}
                                      />
                                    ) : (
                                      // <AccessTimeRounded
                                      //   style={{
                                      //     width: "20px",
                                      //     height: "20px",
                                      //     padding: "5px",
                                      //     paddingRight: "12px",
                                      //     color: theme.palette.error.light,
                                      //   }}
                                      // />
                                      <div
                                        style={{
                                          width: "6px",
                                          height: "20px",
                                          marginRight: "12px",
                                          borderRadius: "4px",
                                          backgroundColor:
                                            categories[
                                              categories.findIndex(
                                                (category: any) =>
                                                  category?.uuid ===
                                                  task?.categoryId
                                              )
                                            ]?.label.substring(0, 1) === "#"
                                              ? categories[
                                                  categories.findIndex(
                                                    (category: any) =>
                                                      category?.uuid ===
                                                      task?.categoryId
                                                  )
                                                ]?.label
                                              : "white",
                                        }}
                                      />
                                      // <CalendarViewTaskIcon />
                                    )}

                                    <CalendarViewTaskLabel>
                                      {task.title}
                                    </CalendarViewTaskLabel>
                                  </CalendarViewTaskContainer>
                                );
                                const showMore = (
                                  <CalendarViewTaskShowMore
                                    onClick={() => {
                                      setDateSelected(taskObject);
                                      setOpenCalendarModal(true);
                                    }}
                                  >
                                    {`${taskObject?.tasks.length - 3} more...`}
                                  </CalendarViewTaskShowMore>
                                );
                                if (index < 3) {
                                  return content;
                                }
                                if (index === 3) {
                                  return showMore;
                                }
                                return null;
                              }
                            )}
                            {taskObject?.date === hoverDate ? (
                              <motion.div
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  position: "absolute",
                                  bottom: "8px",
                                  right: "8px",
                                  borderRadius: "4px",
                                }}
                                whileInView={{
                                  rotate: [20, 0],
                                  scale: [1, 1.1, 1],
                                }}
                                // animate={{ rotate: 180, scale: 1 }}
                                transition={{
                                  type: "spring",
                                  stiffness: 80,
                                  damping: 20,
                                }}
                                onClick={(event: any) => {
                                  event.stopPropagation();
                                  setOpenCalendarAddModal(true);
                                  setDateSelected(taskObject);
                                }}
                              >
                                <AddIcon clicked={(event: any) => {}} />
                              </motion.div>
                            ) : null}
                          </CalendarViewDayContainer>
                        </motion.div>
                      );
                    })}
                    {/* </AnimatePresence> */}
                  </CalendarViewDMonthContainer>
                </div>
              </motion.div>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {week && (
              <motion.div
                variants={STAGGER_CONTAINER}
                initial="hidden"
                animate="visible"
              >
                <div>
                  <CalendarViewContainer>
                    <CalendarDayOfWeekBar>
                      {DAYS_OF_WEEK.map((day, i) => {
                        const todayDate = new Date();
                        return week?.[i]?.day.getMonth() ===
                          todayDate.getMonth() &&
                          week?.[i]?.day.getDate() === todayDate.getDate() &&
                          week?.[i]?.day.getFullYear() ===
                            todayDate.getFullYear() ? (
                          <CalendarDayOfWeekLabel
                            style={{
                              background: STUDYO_GRADIENT,
                              backgroundColor: "white",
                              color: "black",
                            }}
                            key={day}
                          >{`${day} ${week?.[i]?.date}`}</CalendarDayOfWeekLabel>
                        ) : (
                          <CalendarDayOfWeekLabel
                            sx={{
                              background:
                                week?.[i]?.day.getDate() === hoverDate
                                  ? STUDYO_GRADIENT
                                  : theme.palette.primary.main,
                              backgroundColor:
                                week?.[i]?.day.getDate() === hoverDate
                                  ? "white"
                                  : null,
                              color:
                                week?.[i]?.day.getDate() === hoverDate
                                  ? "black"
                                  : null,

                              "&:hover": {
                                background: STUDYO_GRADIENT,
                              },
                            }}
                            key={day}
                          >{`${day} ${week?.[i]?.date}`}</CalendarDayOfWeekLabel>
                        );
                      })}
                    </CalendarDayOfWeekBar>
                  </CalendarViewContainer>

                  <CalendarViewDMonthContainer view={calendarView}>
                    {week?.map((dayOfWeek: any, index: any) => (
                      <motion.div variants={STAGGER_CHILDREN}>
                        <CalendarViewDayContainer
                          view={calendarView}
                          key={`${dayOfWeek?.day.getMonth()} ${dayOfWeek?.day.getDate()}`}
                          onClick={() => {
                            setDateSelected(dayOfWeek);
                            setOpenCalendarModal(true);
                          }}
                          onMouseEnter={() => {
                            setHoverDate(dayOfWeek?.day.getDate());
                          }}
                        >
                          {dayOfWeek?.tasks?.map((task: any, i: number) => {
                            const content = (
                              <CalendarViewTaskContainer
                                style={{
                                  marginBottom:
                                    calendarView === "WEEK" ? "8px" : "2px",
                                }}
                                onClick={(event: any) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  setEditTodo(true);
                                  setOpenCalendarModal(false);
                                  setSelectedTask(task);
                                }}
                              >
                                {dayOfWeek?.day.getMonth() ===
                                  moment(task?.dueAt).month() &&
                                dayOfWeek?.day.getDate() ===
                                  moment(task?.dueAt).date() &&
                                dayOfWeek?.day.getFullYear() ===
                                  moment(task?.dueAt).year() ? (
                                  <div
                                    style={{
                                      width: "6px",
                                      height: "20px",
                                      marginRight: "12px",
                                      borderRadius: "4px",
                                      backgroundColor:
                                        categories[
                                          categories.findIndex(
                                            (category: any) =>
                                              category?.uuid ===
                                              task?.categoryId
                                          )
                                        ]?.label.substring(0, 1) === "#"
                                          ? categories[
                                              categories.findIndex(
                                                (category: any) =>
                                                  category?.uuid ===
                                                  task?.categoryId
                                              )
                                            ]?.label
                                          : "white",
                                    }}
                                  />
                                ) : (
                                  // <AccessTimeRounded
                                  //   style={{
                                  //     width: "20px",
                                  //     height: "20px",
                                  //     padding: "5px",
                                  //     paddingRight: "12px",
                                  //     color: theme.palette.error.light,
                                  //   }}
                                  // />
                                  <div
                                    style={{
                                      width: "6px",
                                      height: "20px",
                                      marginRight: "12px",
                                      borderRadius: "4px",
                                      backgroundColor:
                                        categories[
                                          categories.findIndex(
                                            (category: any) =>
                                              category?.uuid ===
                                              task?.categoryId
                                          )
                                        ]?.label.substring(0, 1) === "#"
                                          ? categories[
                                              categories.findIndex(
                                                (category: any) =>
                                                  category?.uuid ===
                                                  task?.categoryId
                                              )
                                            ]?.label
                                          : "white",
                                    }}
                                  />
                                  // <CalendarViewTaskIcon />
                                )}
                                <CalendarViewTaskLabel
                                  sx={{
                                    fontSize:
                                      calendarView === "WEEK"
                                        ? "14px"
                                        : "100px",
                                    color: "white",
                                    display: "-webkit-box",
                                    overflow: "hidden",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 3,
                                  }}
                                >
                                  {task.title}
                                </CalendarViewTaskLabel>
                              </CalendarViewTaskContainer>
                            );
                            const showMore = (
                              <CalendarViewTaskShowMore
                                onClick={() => {
                                  setDateSelected(dayOfWeek);
                                  setOpenCalendarModal(true);
                                }}
                              >
                                Show more...
                              </CalendarViewTaskShowMore>
                            );
                            if (i < 30) {
                              return content;
                            }
                            if (i === 30) {
                              return showMore;
                            }
                            return null;
                          })}
                          {dayOfWeek?.date === hoverDate ? (
                            <motion.div
                              style={{
                                width: "32px",
                                height: "32px",
                                position: "absolute",
                                bottom: "8px",
                                right: "8px",
                                borderRadius: "4px",
                              }}
                              whileInView={{
                                rotate: [20, 0],
                                scale: [1, 1.1, 1],
                              }}
                              // animate={{ rotate: 180, scale: 1 }}
                              transition={{
                                type: "spring",
                                stiffness: 80,
                                damping: 20,
                              }}
                              onClick={(event: any) => {
                                event.stopPropagation();
                                setOpenCalendarAddModal(true);
                                setDateSelected(dayOfWeek);
                              }}
                            >
                              <AddIcon clicked={(event: any) => {}} />
                            </motion.div>
                          ) : null}
                        </CalendarViewDayContainer>
                      </motion.div>
                    ))}
                  </CalendarViewDMonthContainer>
                </div>
              </motion.div>
            )}
          </Fragment>
        )}
      </CalendarViewModalContainer>
    </div>
  );
};

export default CalendarMain;
