import { Box, Button, Modal, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useHotkeys } from "react-hotkeys-hook";
import { HorizontalContainer } from "../glovebox/HorizontalContainer";
import {
  StudyTypographyMediumText,
  StudyTypographySmallSubtitle,
} from "../glovebox/StudyTypography";
import "./Line.css";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: "90%",
  height: "90%",
  boxShadow: 24,
};

function MobileAppBlock() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  return (
    <motion.div
      style={{
        backgroundColor: "transparent",
        // width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <Box
        component="img"
        src={require("../../assets/StudyoAppDemo.png")}
        style={{
          height: "300px",
          //   width: "100%",
          objectFit: "contain",
          alignSelf: "center",
        }}
      />
      <Button
        size="medium"
        style={{
          textTransform: "none",
          backgroundColor: theme.palette.primary.main,
          height: "24px",
          width: "50%",
          padding: "12px",
          color: theme.palette.text.primary,
        }}
        fullWidth
        onClick={handleOpen}
      >
        full screen
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            component="img"
            src={require("../../assets/StudyoAppDemo.png")}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              alignSelf: "center",
            }}
          />
        </Box>
      </Modal>
    </motion.div>
  );
}

export default MobileAppBlock;
