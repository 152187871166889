import { USER_GOOGLE_CAL_TODOS } from "../graphql/queries/todo";
import { client } from "./apollo";
import { getMyGoogleCalendars, getMyGoogleCalendarsList } from "./calendarApi";
import { v4 as uuid } from "uuid";
import { UPDATE_CATEGORY_TODOS } from "../graphql/mutations/categories";
import {
  UPDATE_USER_BULK_TODOS,
  UPDATE_USER_SYNC,
} from "../graphql/mutations/user";

function diff_minutes(dt2: any, dt1: any) {
  // console.log(dt2, dt1);
  var diff = (new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

type Todo = {
  gCalId: any;
  duration: any;
  startTime: any;
  title: any;
  uuid: any;
};

export const getGoogleCalendars = async (token: any) => {
  const data = await getMyGoogleCalendars(token);
  return data;
};

// export const getGoogleCalendarEvents = async (token: string, first: boolean, syncToken: string, userId: string, calendars: any) => {
export const getGoogleCalendarEvents = async (
  token: string,
  first: boolean,
  userId: string,
  calendars: any
) => {
  // console.log(calendars)
  // console.log(userId)
  const newGoogleTodosPromise = getMyGoogleCalendarsList(
    token,
    first,
    calendars
  );
  const oldGoogleTodosPromise = client.query({
    query: USER_GOOGLE_CAL_TODOS,
    variables: {
      userId: userId,
    },
    fetchPolicy: "network-only",
  });
  return Promise.all([newGoogleTodosPromise, oldGoogleTodosPromise])
    .then(async ([newGoogleTodos, oldGoogleTodos]) => {
      let data = await newGoogleTodos;
      // console.log(data);
      const filteredData = data.map((newTodo: any) => {
        let newTodos: Todo[] = newTodo?.map((todo: any) => {
          if (todo.summary) {
            return {
              title: todo.summary,
              startTime: todo.start.dateTime ? todo.start.dateTime : null,
              duration: todo.start.dateTime
                ? diff_minutes(todo.end.dateTime, todo.start.dateTime)
                : null,
              uuid: uuid(),
              gCalId: todo.id,
              event: true,
            };
          }
        });
        // let newTodos: any[] = []
        const oldTodos = oldGoogleTodos.data.getUserGoogleCalTodos;
        if (oldTodos) {
          newTodos = newTodos?.filter(function (todo: any) {
            // console.log(todo);
            return oldTodos?.every(
              (googleTodo: any) => googleTodo.gCalId !== todo.gCalId
            );
          });
        }
        return newTodos;
        // return []
      });

      return filteredData;
    })
    .catch((error: any) => {
      return error.message;
    });
};

// export const addGoogleCalendarEvents = async (syncToken: string, userId: string, categoryId: string, newTodos: any[]) => {
export const addGoogleCalendarEvents = async (
  userId: string,
  categoryId: string,
  newTodos: any[]
) => {
  const todosMutatiion = client.mutate({
    mutation: UPDATE_CATEGORY_TODOS,
    variables: {
      uuid: categoryId,
      todos: newTodos,
    },
  });
  // const syncMutation = client.mutate({
  //     mutation: UPDATE_USER_SYNC,
  //     variables: {
  //         updateUserId: userId,
  //         syncToken: syncToken,
  //     }
  // });
  // return Promise.all([syncMutation, todosMutatiion]).then(() => {
  return Promise.all([todosMutatiion]).then(async () => {
    let newTodoIds = newTodos.map((todo: any) => {
      return todo.uuid;
    });
    return client.mutate({
      mutation: UPDATE_USER_BULK_TODOS,
      variables: {
        userId: userId,
        addTodoIds: newTodoIds,
      },
    });
    // res.status(200).send('Successfully updated user todos');
    // return {'newTodos': newTodos, 'syncToken': newGoogleTodos.nextSyncToken}
    // return 'Success';
  });
};
