import { CalendarTodayOutlined } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  ClickAwayListener,
  Tooltip,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  ADD_DUE,
  ADD_START,
  COMPLETE_TODO,
  DELETE_TODO,
  EDIT_TODO,
  MAINBAR_RIGHT_CLICK,
  REMOVE_START,
  hexToRgbA,
  timeDuration,
} from "../../../utils/constants";
import { HoverTypography } from "../../HoverTypography";
import DurationBox from "../../glovebox/DurationBox";
import { StudyTypographyMediumSubtitle } from "../../glovebox/StudyTypography";
import {
  ProfileBlockPopper,
  SwitchCategoryContainer,
  ToolbarBackground,
  ToolbarToDoBox,
  ToolbarToDoDurationText,
  ToolbarToDoLeftSide,
  ToolbarToDoSubtitle,
  ToolbarToDoTitle,
} from "../../toolbar/styles";

export const SyllabusTodo = ({
  data,
  setAllTodos,
  allTodos,
}: // updateTodoList,
{
  data: any;
  setAllTodos: any;
  allTodos: any;
}) => {
  const [editTodo, setEditTodo] = useState(false);
  const [editTodoText, setEditTodoText] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [todo, setTodo] = useState<any>(data);
  // const { user, setUser } = useContext(UserContext);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")!) || []
  );
  const [categories, setCategories] = useState<any>(
    JSON.parse(localStorage.getItem("categories")!) || []
  );
  useEffect(() => {
    localStorage.setItem("categories", JSON.stringify(categories));
  }, [categories]);
  // const [todos, setTodos] = useState(JSON.parse(localStorage.getItem('todos')!) || [])
  // // const { id: userId } = useUserContext();
  const [value, setValue] = useState<any>(moment().format("HH:MM"));
  const open = Boolean(anchorEl);
  const [dateAnchorEl, setDateAnchorEl] = useState<null | HTMLElement>(null);
  const handleClickDate = (event: React.MouseEvent<HTMLElement>) => {
    setDateAnchorEl(dateAnchorEl ? null : event.currentTarget);
  };

  const handleTodoUpdate = (updatedTodo: any) => {
    // Clone the current allTodos to avoid direct state mutation
    const updatedAllTodos = [...allTodos];

    // Loop through each sub-array to find the todo
    for (let i = 0; i < updatedAllTodos.length; i++) {
      for (let j = 0; j < updatedAllTodos[i].length; j++) {
        if (updatedAllTodos[i][j].title === updatedTodo.title) {
          // Update the found todo
          updatedAllTodos[i][j] = updatedTodo;
          setAllTodos(updatedAllTodos);
          return;
        }
      }
    }
  };

  const [categoryAnchorEl, setCategoryAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const handleCategoryClick = (event: React.MouseEvent<HTMLElement>) => {
    setCategoryAnchorEl(categoryAnchorEl ? null : event.currentTarget);
  };
  const categoryOpen = Boolean(categoryAnchorEl);
  const theme = useTheme();
  const [clickAnchorEl, setClickAnchorEl] = useState<null | HTMLElement>();
  const clickOpen = Boolean(clickAnchorEl);

  const handleRightClick = (event: any) => {
    event.preventDefault();
    setClickAnchorEl(clickAnchorEl ? null : event.currentTarget);
    // Trigger your custom action here
  };

  const handleFocus = (event: any) => event.target.select();

  const selectedColor = hexToRgbA(theme.palette.success.main);

  return (
    <ToolbarBackground
      onContextMenu={handleRightClick}
      onDoubleClick={isMobile ? handleRightClick : setValue}
    >
      <ToolbarToDoBox
        key={data?.uuid}
        style={{
          height: "32px",
          marginBottom: "4px",
          borderRadius: "4px",
          alignItems: "center",
          padding: user?.compact ? "2px 8px 2px 8px" : "8px",
          backgroundColor: data?.selected
            ? selectedColor
            : theme.palette.primary.light,
          border: data?.selected
            ? `1px solid ${theme.palette.success.main}`
            : "0px solid white",
          cursor: "pointer",
        }}
        onClick={() => {
          handleTodoUpdate({ ...data, selected: !data?.selected });
        }}
      >
        <ToolbarToDoLeftSide>
          {editTodo ? (
            <ClickAwayListener
              onClickAway={() => {
                setEditTodo(false);
              }}
            >
              <input
                autoFocus
                suppressContentEditableWarning={true}
                contentEditable={true}
                onFocus={handleFocus}
                style={{
                  border: "0px",
                  backgroundColor: theme.palette.primary.light,
                  width: "100%",
                  height: "24px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "16px",
                  outlineWidth: 0,
                  borderRadius: "4px",
                  color: theme.palette.text.primary,
                }}
                placeholder={"text new todo.."}
                type={"text"}
                name="title"
                value={editTodoText}
                onChange={(text) => {
                  setEditTodoText(text.target.value);
                }}
              ></input>
            </ClickAwayListener>
          ) : (
            <div
              style={{
                marginLeft: "12px",
                textAlign: "left",
                flexShrink: "0",
                overflow: "hidden",
                width: "95%",
              }}
            >
              <ToolbarToDoTitle
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  textAlign: "flex-start",
                  width: "100%",
                }}
                onDoubleClick={() => {
                  setEditTodo(true);
                  setEditTodoText(data?.title);
                }}
              >
                {data?.title}
              </ToolbarToDoTitle>
              <Breadcrumbs>
                {moment(data?.startTime).date() === new Date().getDate() ? (
                  <ToolbarToDoSubtitle>
                    {moment(data?.startTime).format("h:mma")}
                  </ToolbarToDoSubtitle>
                ) : moment(data?.startTime) &&
                  moment(data?.startTime).isValid() ? (
                  <ToolbarToDoSubtitle>
                    {moment(data?.startTime).format("M/DD")}
                  </ToolbarToDoSubtitle>
                ) : null}
                {moment(data?.dueAt).date() === new Date().getDate() ? (
                  <ToolbarToDoSubtitle
                    style={{ color: theme.palette.error.light }}
                  >
                    {moment(data?.startTime).format("h:mma")}
                  </ToolbarToDoSubtitle>
                ) : (data?.dueAt && moment(data?.dueAt).isValid()) ||
                  (moment(data?.dueAt) &&
                    !data?.completedAt &&
                    moment(data?.dueAt).isValid()) ? (
                  <ToolbarToDoSubtitle
                    style={{ color: theme.palette.error.light }}
                  >
                    {moment(data?.dueAt).format("M/DD")}
                  </ToolbarToDoSubtitle>
                ) : null}
              </Breadcrumbs>
            </div>
          )}
        </ToolbarToDoLeftSide>
        {editTodo ? (
          <form onSubmit={() => {}}>
            <Button
              variant="text"
              color="secondary"
              sx={{ textTransform: "none" }}
              onClick={() => {
                // updateTodo({
                //   variables: { title: editTodoText, uuid: data?.uuid },
                // });
                setEditTodo(false);
              }}
            >
              save
            </Button>
          </form>
        ) : (
          <ClickAwayListener
            onClickAway={() => {
              setAnchorEl(null);
            }}
          >
            <DurationBox
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              num={data?.duration}
              user={user}
            />
          </ClickAwayListener>
        )}

        <ProfileBlockPopper
          open={open}
          anchorEl={anchorEl}
          placement={"bottom-start"}
          modifiers={[{ name: "arrow", enabled: true }]}
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            margin: 0,
            paddingRight: 0,
            zIndex: 10000,
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          <div
            style={{
              alignItems: "center",
              display: "inline-grid",
              justifyContent: "center",
              backgroundColor: theme.palette.primary.main,
              border: `1px solid ${theme.palette.secondary.light}`,
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            {timeDuration.map((time) => (
              <ToolbarToDoDurationText
                style={{
                  padding: "8px",
                  textAlign: "center",
                  color: theme.palette.text.primary,
                }}
                onClick={(event: any) => {
                  event?.stopPropagation();
                  handleTodoUpdate({ ...data, duration: time });
                  setAnchorEl(null);
                }}
              >
                {time} min
              </ToolbarToDoDurationText>
            ))}
          </div>
        </ProfileBlockPopper>
        <ClickAwayListener
          onClickAway={() => {
            setCategoryAnchorEl(null);
          }}
        >
          <Tooltip title={data?.categoryName} placement={"top"}>
            <div
              onClick={handleCategoryClick}
              style={{
                whiteSpace: "nowrap",
                width: "14px",
                height: "14px",
                border: `2px solid ${data?.categoryColor}`,
                borderRadius: "4px",
                marginLeft: "12px",
              }}
            ></div>
          </Tooltip>
        </ClickAwayListener>
        <ClickAwayListener
          onClickAway={() => {
            setClickAnchorEl(null);
          }}
        >
          <ProfileBlockPopper
            open={clickOpen}
            anchorEl={clickAnchorEl}
            placement={"bottom-end"}
            modifiers={[{ name: "arrow", enabled: true }]}
            style={{
              backgroundColor: "rgba(0,0,0,0)",
              paddingTop: "8px",
            }}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          >
            <div
              style={{
                alignItems: "center",
                display: "inline-grid",
                justifyContent: "center",
                backgroundColor: theme.palette.primary.main,
                border: `1px solid ${theme.palette.secondary.light}`,
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              {MAINBAR_RIGHT_CLICK.map((item) => {
                return (
                  <HoverTypography
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      paddingBottom: "4px",
                      paddingTop: "4px",

                      borderRadius: "4px",
                      color:
                        item.name === "delete todo"
                          ? theme.palette.error.dark
                          : theme.palette.text.primary,
                    }}
                    onClick={(event: any) => {
                      // setClickAnchorEl(null);
                      if (item.name === EDIT_TODO) {
                        setEditTodo(true);
                        setEditTodoText(data?.title);
                      } else if (item.name === REMOVE_START) {
                      } else if (item.name === COMPLETE_TODO) {
                        // updateTodo({
                        //   variables: {
                        //     uuid: data?.uuid,
                        //     completed: true,
                        //   },
                        // });
                      } else if (item.name === DELETE_TODO) {
                        // updateTodo({
                        //   variables: {
                        //     uuid: data?.uuid,
                        //     deleted: true,
                        //   },
                        // });
                        // updateTodoState(data?.uuid, true, false)
                      } else if (item.name === ADD_START) {
                        handleClickDate(event);
                      } else if (item.name === ADD_DUE) {
                        handleClickDate(event);
                      }
                    }}
                  >
                    {item.icon}
                    <StudyTypographyMediumSubtitle
                      style={{
                        paddingTop: "6px",
                        paddingBottom: "6px",
                        borderRadius: "4px",
                        paddingLeft: "8px",
                        color:
                          item.name === "delete todo"
                            ? theme.palette.error.dark
                            : theme.palette.text.primary,
                      }}
                    >
                      {item.name}
                    </StudyTypographyMediumSubtitle>
                  </HoverTypography>
                );
              })}
            </div>
          </ProfileBlockPopper>
        </ClickAwayListener>
        {/* <ClickAwayListener
              onClickAway={() => {
                setClickAnchorEl(null);
              }}
            >
              <ProfileBlockPopper
                open={dateOpen}
                anchorEl={clickAnchorEl}
                placement={toolbar ? "bottom-end" : "bottom"}
                modifiers={[{ name: "arrow", enabled: true }]}
                style={{
                  backgroundColor: theme.palette.secondary.light,
                  padding: "4px",
                  color: theme.palette.text.primary,
                  alignItems: "center",
                  display: "flex",
                }}
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
              >
                <StyledTimePicker
                  use12Hours
                  format="h:mm a"
                  onChange={(date) => {
                    setValue(date);
                  }}
                  open={timeOpen}
                  placeholder={""}
                  showNow={false}
                  suffixIcon={null}
                  onOk={(date) => {
                    updateTodoStartTime(dayjs(date).toDate(), data?.uuid);
                    setClickAnchorEl(null);
                    setDateAnchorEl(null);
                    setTimeOpen(false);
                  }}
                  style={{
                    backgroundColor: theme.palette.secondary.light,
                    color: "white",
                    border: "none",
                    opacity: 0,
                    width: "0px",
                    padding: "0px",
                  }}
                  popupStyle={{
                    backgroundColor: "rgba(0,0,0,0)",
                    color: theme.palette.text.primary,
                  }}
                />
                <StudyTypographyMediumSubtitle
                  sx={{
                    padding: "4px",
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setTimeOpen(!timeOpen);
                  }}
                >
                  Select time
                </StudyTypographyMediumSubtitle>
                <CloseRounded
                  sx={{
                    width: "16px",
                    height: "16px",
                    marginLeft: "12px",
                    borderRadius: "2px",
                    padding: "4px",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      cursor: "pointer",
                    },
                  }}
                  onClick={(event: any) => {
                    event.preventDefault();
                    setDateAnchorEl(null);
                  }}
                />
              </ProfileBlockPopper>
            </ClickAwayListener> */}
      </ToolbarToDoBox>
    </ToolbarBackground>
  );
};
