import { HoverPointerStyle } from "./HoverPointerStyle";
import { useTheme } from "@mui/material";
import {
  STUDYO_PRIMARY_GRADIENT,
  STUDYO_SECONDARY_GRADIENT,
} from "../../utils/constants";

export function RoomIcon(props: {
  active: boolean;
  clicked: Function;
  width?: string;
  height?: string;
}) {
  const theme = useTheme();

  return (
    <HoverPointerStyle
      width={props?.width || "30"}
      height={props?.height || "30"}
      viewBox={`0 0 32 32`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        props.clicked();
      }}
    >
      {props.active ? (
        <rect
          width={props?.width || "32"}
          height={props?.height || "32"}
          rx="4"
          fill="url(#paint0_linear_59_2302)"
        />
      ) : (
        <rect
          width={props?.width || "32"}
          height={props?.height || "32"}
          rx="4"
          fill={theme.palette.primary.light}
        />
      )}

      <path
        d="M15.232 8.68053C15.0531 8.53208 14.8208 8.47083 14.5935 8.51344C11.5288 9.08565 9.08546 11.5291 8.51319 14.5935C8.4708 14.8204 8.53161 15.053 8.68028 15.232C8.83156 15.4142 9.05391 15.5185 9.2904 15.5185H13.0895C13.4015 15.5185 13.6863 15.3296 13.8155 15.0373C14.0567 14.4907 14.4908 14.0568 15.0375 13.8154C15.3298 13.6864 15.5187 13.4015 15.5187 13.0894V9.29062C15.5184 9.05421 15.414 8.83192 15.232 8.68053ZM14.8934 13.0896C14.8934 13.1551 14.8516 13.2141 14.7848 13.2437C14.0953 13.5481 13.548 14.0954 13.2436 14.7849C13.214 14.8519 13.155 14.8935 13.0895 14.8935H9.29037C9.24034 14.8935 9.19315 14.8712 9.161 14.8325C9.14377 14.8117 9.11631 14.768 9.12754 14.7082C9.65264 11.8957 11.8954 9.65294 14.7079 9.12783C14.7176 9.12609 14.7267 9.12521 14.7356 9.12521C14.7812 9.12521 14.8149 9.1469 14.8324 9.16129C14.871 9.19344 14.8933 9.24053 14.8933 9.29055L14.8934 13.0896Z"
        fill={props.active ? "white" : "#777777"}
        stroke={props.active ? "white" : "#777777"}
        strokeWidth="0.4"
      />
      <path
        d="M17.4064 8.51344C17.1794 8.47104 16.9469 8.53219 16.7679 8.68052C16.5857 8.83191 16.4815 9.05427 16.4815 9.29054V13.0894C16.4815 13.4012 16.6702 13.6861 16.9627 13.8154C17.5094 14.0566 17.9433 14.4907 18.1846 15.0371C18.3135 15.3295 18.5985 15.5183 18.9106 15.5183H22.7097C22.9462 15.5183 23.1685 15.4139 23.3198 15.2318C23.4683 15.0529 23.5291 14.8202 23.4869 14.5933C22.9144 11.5291 20.4711 9.0857 17.4064 8.51344ZM22.8388 14.8326C22.8067 14.8714 22.7596 14.8935 22.7095 14.8935H18.9104C18.8449 14.8935 18.7858 14.8518 18.7563 14.785C18.4519 14.0955 17.9045 13.5482 17.215 13.2439C17.148 13.2142 17.1063 13.1551 17.1063 13.0896V9.29055C17.1063 9.24052 17.1285 9.19355 17.1671 9.16129C17.188 9.14406 17.2322 9.11671 17.2914 9.12772C20.1039 9.65283 22.3467 11.8956 22.872 14.7083C22.8835 14.7681 22.8561 14.812 22.8388 14.8326Z"
        fill={props.active ? "white" : "#777777"}
        stroke={props.active ? "white" : "#777777"}
        strokeWidth="0.4"
      />
      <path
        d="M22.7094 16.4817H18.9103C18.5982 16.4817 18.3134 16.6706 18.1843 16.9629C17.9431 17.5095 17.509 17.9434 16.9623 18.1848C16.6699 18.3139 16.4811 18.5988 16.4811 18.9108V22.7096C16.4811 22.946 16.5855 23.1684 16.7675 23.3196C16.9091 23.4371 17.0843 23.4998 17.2641 23.4998C17.3113 23.4998 17.3588 23.4956 17.4061 23.4867C20.4705 22.9145 22.9142 20.4711 23.4864 17.4067C23.5288 17.1797 23.468 16.9472 23.3193 16.7682C23.1683 16.5862 22.946 16.4817 22.7094 16.4817ZM22.8722 17.2921C22.3469 20.1046 20.1043 22.3473 17.2916 22.8727C17.2322 22.883 17.188 22.8565 17.167 22.8391C17.1284 22.807 17.1062 22.7599 17.1062 22.7099V18.9108C17.1062 18.8453 17.1479 18.7862 17.2147 18.7566C17.9042 18.4522 18.4516 17.9049 18.756 17.2154C18.7855 17.1484 18.8446 17.1069 18.9101 17.1069H22.7092C22.7592 17.1069 22.8064 17.1291 22.8386 17.1678C22.8559 17.1883 22.8834 17.2322 22.8722 17.2921Z"
        fill={props.active ? "white" : "#777777"}
        stroke={props.active ? "white" : "#777777"}
        strokeWidth="0.4"
      />
      <path
        d="M15.0372 18.1849C14.4905 17.9434 14.0566 17.5096 13.8152 16.963C13.6863 16.6706 13.4013 16.4818 13.0892 16.4818L9.29041 16.4817C9.0539 16.4817 8.83157 16.5861 8.68028 16.7682C8.53173 16.9471 8.47091 17.1798 8.5132 17.4067C9.08541 20.4711 11.5288 22.9148 14.5935 23.4867C14.6407 23.4957 14.6882 23.4998 14.7354 23.4998C14.9149 23.4998 15.0903 23.4372 15.232 23.3197C15.4142 23.1683 15.5184 22.9459 15.5184 22.7096V18.9106C15.5184 18.5987 15.3297 18.3138 15.0374 18.1847L15.0372 18.1849ZM14.8933 22.7097C14.8933 22.7597 14.871 22.8067 14.8324 22.8389C14.8116 22.8562 14.768 22.8837 14.7081 22.8725C11.8953 22.3474 9.65258 20.1047 9.12747 17.2922C9.11635 17.2323 9.14371 17.1886 9.16093 17.1678C9.19308 17.129 9.24016 17.1069 9.2903 17.1069H13.0894C13.1549 17.1069 13.214 17.1486 13.2435 17.2154C13.5479 17.9049 14.0953 18.4523 14.7847 18.7566C14.8518 18.7862 14.8935 18.8453 14.8935 18.9108L14.8934 22.7099L14.8933 22.7097Z"
        fill={props.active ? "white" : "#777777"}
        stroke={props.active ? "white" : "#777777"}
        strokeWidth="0.4"
      />

      <defs>
        <linearGradient
          id="paint0_linear_59_2302"
          x1="0"
          y1="0"
          x2="32"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={STUDYO_PRIMARY_GRADIENT} />
          <stop offset="1" stopColor={STUDYO_SECONDARY_GRADIENT} />
        </linearGradient>
      </defs>
    </HoverPointerStyle>
  );
}
