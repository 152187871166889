import React from "react";
import { LoaderIcon } from "react-hot-toast";
import {
  StudyTypographySmallSubtitle,
  StudyTypographySmallText,
} from "../../glovebox/StudyTypography";

function AIFinishScreen({
  selectedTodos,
  updateTodosMessage,
  updateTodosObject,
}: {
  selectedTodos: any;
  updateTodosMessage: any;
  updateTodosObject: any;
}) {
  return (
    <div
      style={{
        width: "100%",
        height: "500px",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {updateTodosObject ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <StudyTypographySmallSubtitle style={{ marginTop: "12px" }}>
            {updateTodosMessage}
          </StudyTypographySmallSubtitle>
          {updateTodosObject.map((todo: any) => {
            return (
              <div
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <StudyTypographySmallText style={{ marginTop: "12px" }}>
                  {`title: ${todo.title}`}
                </StudyTypographySmallText>
                <StudyTypographySmallSubtitle>
                  {`start: ${todo.startTime}`}
                </StudyTypographySmallSubtitle>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          <LoaderIcon
            style={{
              width: "36px",
              height: "36px",
            }}
          />
          <StudyTypographySmallText style={{ marginTop: "12px" }}>
            loading new schedule
          </StudyTypographySmallText>
        </div>
      )}
    </div>
  );
}

export default AIFinishScreen;
