import { Box, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import moment from "moment";
import { useState } from "react";
import { CheckmarkIcon, LoaderIcon } from "react-hot-toast";
import EmptyLogo from "../../assets/EmptyLogo";
import { SimpleToDo } from "../mainpage/SimpleToDo";
import "./Line.css";
import { StudyTypographySmallText } from "../glovebox/StudyTypography";

function ImportCalBlock() {
  const [clicked, setClicked] = useState<boolean>(false);
  const [done, setDone] = useState(false);

  const theme = useTheme();

  const randomTodos = [
    "go to grocery store",
    "text mene about ideas",
    "brain dump",
    "design sesh",
    "code code code",
    "design, code, build repeat",
    "🎥",
    "movie night w/ suite",
    "love you all",
  ];

  return (
    <motion.div
      style={{
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
      }}
      animate={clicked ? "open" : "closed"}
      transition={{ duration: 3 }}
      onAnimationEnd={() => {
        setDone(true);
      }}
    >
      {!clicked ? (
        <CheckmarkIcon
          style={{
            position: "absolute",
            top: "18px",
            right: "24px",
          }}
        />
      ) : (
        <LoaderIcon
          style={{
            position: "absolute",
            top: "18px",
            right: "24px",
            width: "18px",
            height: "18px",
          }}
        />
      )}
      <motion.div
        animate={{
          scale: clicked || !done ? 1 : 7,
          y: clicked || !done ? 0 : 125,
        }}
        transition={{ duration: clicked ? 0.3 : 3 }}
        // whileHover={{ scale: [1, 1.05, 1], rotate: [0, -10, 10, 0] }}
      >
        <Box
          component="img"
          src={require("../Icons/GCalIcon.png")}
          onClick={async () => {
            setClicked(true);
            await setTimeout(() => setClicked(false), 3000);
          }}
          sx={{
            width: 36,
            height: 36,
            marginTop: "12px",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        ></Box>
      </motion.div>

      <motion.div
        initial={false}
        style={{ width: "100%", padding: "12px" }}
        variants={{
          open: {
            y: [-130, -30, -30],
            scale: [0.1, 0.9, 0.9],
          },
          closed: {
            y: [-30, -30, 155, 0],
            scale: [0.9, 0.5, 0, 0],
          },
        }}
        transition={{
          type: "spring",
          stiffness: 100,
          mass: 0.1,
          duration: 3,
        }}
      >
        {[0, 1, 2].map((index: number) => (
          <SimpleToDo
            data={{
              title:
                randomTodos[
                  Math.round(Math.random() * (randomTodos.length - 1))
                ],
              duration: 30,
              startTime: moment().add(30 * index, "minutes"),
              dueAt: moment()
                .add(30 * index, "minutes")
                .add(1, "day"),
              uuid: "1",
              backgroundColor: theme.palette.primary.main,
            }}
          />
        ))}
      </motion.div>
      {clicked || !done ? null : (
        <motion.div
          animate={{
            opacity: clicked || !done ? 0 : 1,
          }}
          transition={{ duration: 3 }}
        >
          <StudyTypographySmallText>hint. tap me ^</StudyTypographySmallText>
        </motion.div>
      )}

      <div style={{ marginBottom: "24px" }}>
        <EmptyLogo sx={{ width: "36px", height: "36px" }} />
      </div>

      {/* {["10%", "20%", "30%", "40%", "50%"].map((number) => (
        <MovingLine width={"100vw"} x1="0" y1={number} x2="100%" y2={number} />
      ))} */}
    </motion.div>
  );
}

export default ImportCalBlock;
