const Colors = {
  primary: "#004916",
  secondary: "#93FF00",
  tertiary: "#DCFFAF",
  lightestGray: "#F5F5F7",
  lightGray: "#E9E9E9",
  gray: "#E7E7E7",
  darkGray: "#777777",
  dark: "#545454",
  black: "#000000",
  white: "#ffffff",
  disabled: "#E5E5E5",
  success: "#30D08D",
  successLight: "#E8FBF3",
  error: "#E74959",
  errorLight: "#FCEAED",
  warning: "#F5C500",
  warningLight: "#FDFAE6",
  disabledOpacity: "80%",
  touchedOpacity: 0.2,
  genesisOpacity: 0.3,
};

const Font = {
  title1: 44,
  title2: 32,
  title3: 24,

  //HAD TO ADJUST FOR FIGMA DIMENSIONS --> 375 / 428 * size on figma
  genesisXLarge: 48,
  genesisColorText: 30,
  genesisLarge: 21,
  genesisIcon: 20,
  genesisRegular: 14,
  genesisSmall: 12,
  large: 19,
  regular: 17,
  small: 14,
  micro: 8,
};

const FontWeight = {
  thin: "300",
  normal: "500",
  bold: "700",
  bolder: "800",
};

const Elevation = {
  low: 2,
  medium: 4,
  high: 6,
};

const Spacing = {
  magnificent: 300,
  xlarge: 64,
  larger: 56,
  genesisLarge: 50,
  genesisBorderRadius: 12,
  large: 48,
  base: 24,
  small: 16,
  smaller: 12,
  tiny: 8,
  micro: 4,
  borderRadius: 5,
  lineWidth: 1,
};

const Icons = {
  loginIcon: 200,
  huge: 128,
  hundred: 100,
  xlarge: 64,
  large: 48,
  medium: 36,
  small: 24,
  tiny: 12,
};

const Opacity = {
  disabled: 0.38,
};

const Logo = {
  large: 65,
  medium: 50,
};

const FontFamily = {
  avenir: "Avenir Next",
};

export default {
  Colors,
  Font,
  FontWeight,
  FontFamily,
  Spacing,
  Icons,
  Logo,
  Elevation,
  Opacity,
};
