import { Stack, useTheme } from "@mui/material";
import React, { useRef } from "react";
import { CustomInput } from "../toolbar/styles";
import { StudyTypographyMediumText } from "./StudyTypography";

function TimePicker({
  time,
  setTime,
  title,
}: {
  time: any;
  setTime: any;
  title: any;
}) {
  const theme = useTheme();
  const inputRef = useRef<any>(null);
  return (
    <Stack
      flexDirection={"row"}
      alignItems={"center"}
      padding="6px"
      borderRadius={"4px"}
      width={"120px"}
      style={{ backgroundColor: theme.palette.primary.light }}
    >
      <StudyTypographyMediumText
        style={{
          marginRight: "12px",
          width: "100px",
          backgroundColor: theme.palette.primary.dark,
          borderRadius: "4px",
          padding: "4px",
        }}
      >
        {title}
      </StudyTypographyMediumText>
      <CustomInput
        ref={inputRef}
        aria-label="Demo input"
        placeholder={`ex. tomorrow 3PM`}
        onChange={(text) => {
          setTime(text.currentTarget.value);
        }}
        autoFocus
        style={{
          fontSize: "10px",
          padding: "0px",
          height: "32px",
        }}
        value={time}
      />
    </Stack>
  );
}

export default TimePicker;
