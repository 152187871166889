import React, { useState } from "react";
import { motion } from "framer-motion";
import LightningLine from "../glovebox/LightningLine";
import { useTheme } from "@mui/material";

function PowerGridLines() {
  const width = 900;
  const height = 600;
  const padding = 30;
  const numLines = 28;
  const path =
    "M316 0V10C316 12.2091 314.209 14 312 14H5C2.79086 14 1 15.7909 1 18V80";
  const theme = useTheme();
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        bottom: 0,
        left: 0,
        zIndex: 0,
      }}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <svg
        // class="animated-streaming_grid__LB6Qj"
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        width={width}
      >
        <g
          opacity="0.2"
          stroke={theme.palette.text.primary}
          strokeDasharray={"2 2"}
        >
          {Array.from({ length: numLines }).map((num, i) => (
            <line x2={width} y1={`${i * padding}`} y2={`${i * padding}`} />
          ))}
          {Array.from({ length: numLines }).map((num, i) => (
            <line
              x1={`${padding * i}`}
              x2={`${padding * i}`}
              y1={"2.18557e-08"}
              y2={height}
            />
          ))}
        </g>
        <g opacity="1">
          {Array.from({ length: numLines }).map((num, i) => (
            <line
              stroke={"url(#pulse-1)"}
              x2={width}
              y1={`${i * padding}`}
              y2={`${i * padding}`}
            />
          ))}
          {Array.from({ length: numLines }).map((num, i) => (
            <line
              stroke={"url(#pulse-2)"}
              x1={`${padding * i}`}
              x2={`${padding * i}`}
              y1={"2.18557e-08"}
              y2={height}
            />
          ))}
          <defs>
            <motion.linearGradient
              animate={{
                x1: [width, width / 2],
                x2: [width * 2, width],
                y1: [0, height * 2],
                y2: [0, height],
              }}
              transition={{
                duration: 5,
                repeat: Infinity,
              }}
              id="pulse-2"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2EB9DF" stopOpacity="0" />
              <stop stopColor="#2EB9DF" />
              <stop offset="1" stopColor="#9E00FF" stopOpacity="0" />
            </motion.linearGradient>
          </defs>
          <defs>
            <motion.linearGradient
              animate={{
                x1: [0, width * 2],
                x2: [0, width],
                y1: [height, height / 2],
                y2: [height * 2, height],
              }}
              transition={{
                duration: 5,
                repeat: Infinity,
              }}
              id="pulse-1"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2EB9DF" stopOpacity="0" />
              <stop stopColor="#2EB9DF" />
              <stop offset="1" stopColor="#9E00FF" stopOpacity="0" />
            </motion.linearGradient>
          </defs>
        </g>
      </svg>
    </div>
  );
}

export default PowerGridLines;
