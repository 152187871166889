import { ClickAwayListener, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import { STAGGER_CHILDREN } from "../../utils/constants";
import { v4 as uuid } from "uuid";

import {
  ToolbarBackground,
  ToolbarToDoBox,
  ToolbarToDoCreateInput,
  ToolbarToDoLeftSide,
} from "../toolbar/styles";
import { toast } from "react-hot-toast";
import { client } from "../../utils/apollo";
import { createTodo, CREATE_TO_DO } from "../../graphql/mutations/todo";
import { AddIcon } from "../Icons/AddIcon";
import { isMobile } from "react-device-detect";

export const QuickAddBar = ({
  category,
  todos,
  setTodos,
  setShowAddTodo,
}: // updateTodoList,
{
  category: any;
  todos: any;
  setTodos: any;
  setShowAddTodo: any;
  // updateTodoList: any;
}) => {
  const [title, setTitle] = useState("");

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")!) || []
  );
  const [categories, setCategories] = useState<any>(
    JSON.parse(localStorage.getItem("categories")!) || []
  );

  const createTodoHelper = () => {
    toast.promise(
      createTodo(
        category.uuid,
        title,
        30,
        null,
        null,
        false,
        user.id,
        todos,
        setTodos
      ),
      {
        loading: "adding todo..",
        success: <b>todo saved!</b>,
        error: <b>Could not save.</b>,
      },
      {
        duration: 4000,
        style: {
          fontSize: "12px",
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.primary,
        },
      }
    );
    setTitle("");
  };
  useEffect(() => {
    localStorage.setItem("categories", JSON.stringify(categories));
  }, [categories]);
  // const [todos, setTodos] = useState(JSON.parse(localStorage.getItem('todos')!) || [])
  // // const { id: userId } = useUserContext();
  const [isPressed, setIsPressed] = useState<any>(false);

  const [value, setValue] = useState<any>(moment().format("HH:MM"));
  const [timeOpen, setTimeOpen] = useState(false);
  const [remove, setRemove] = useState(false);
  const handleChange = (newValue: any) => {
    setValue(newValue);
  };

  const theme = useTheme();
  return (
    <motion.div variants={STAGGER_CHILDREN}>
      <ToolbarBackground
      // ref={drag}
      // onContextMenu={handleRightClick}
      // onDoubleClick={isMobile ? handleRightClick : setValue}
      >
        <ToolbarToDoBox
          style={{
            height: "40px",
            marginBottom: "4px",
            border: "0px",
            borderRadius: "4px",
            alignItems: "center",
            padding: user?.compact ? "2px 8px 2px 8px" : "8px",
          }}
        >
          <div
            style={{
              width: "100%",
              marginLeft: "12px",
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <ClickAwayListener
              onClickAway={() => {
                setIsPressed(false);
                setShowAddTodo(false);
              }}
            >
              <form
                onSubmit={(event: any) => {
                  event?.preventDefault();
                  createTodoHelper();
                }}
                style={{ width: "100%" }}
              >
                <input
                  autoFocus
                  suppressContentEditableWarning={true}
                  contentEditable={true}
                  // onFocus={handleFocus}
                  draggable={true}
                  onDragStart={(event) => event.preventDefault()}
                  style={{
                    border: "0px",
                    backgroundColor: theme.palette.primary.light,
                    width: "100%",
                    height: "15px",
                    alignSelf: "center",
                    alignItems: "center",
                    display: "flex",
                    // paddingTop: "10px",
                    // paddingBottom: "10px",
                    // paddingLeft: "12px",
                    padding: "0px",
                    margin: "0px",
                    outlineWidth: 0,
                    // borderRadius: "4px",
                    fontSize: "12px",
                    fontWeight: "lighter",
                    fontStyle: "Space Grotesk",
                    color: theme.palette.text.primary,
                  }}
                  onClick={(event: any) => {
                    event?.preventDefault();
                    event?.stopPropagation();
                  }}
                  placeholder={"add new todo..."}
                  onFocus={() => {
                    setIsPressed(true);
                  }}
                  type={"text"}
                  name="title"
                  value={title}
                  onChange={(text) => {
                    setTitle(text.target.value);
                  }}
                ></input>
              </form>
            </ClickAwayListener>
            {isPressed || isMobile ? (
              <motion.div
                animate={{ x: [100, 0] }}
                transition={{
                  type: "spring",
                  stiffness: 300,
                  mass: 0.3,
                }}
                style={{ display: "flex" }}
              >
                <AddIcon
                  clicked={() => {
                    createTodoHelper();
                  }}
                />
              </motion.div>
            ) : null}
          </div>
        </ToolbarToDoBox>
      </ToolbarBackground>
    </motion.div>
  );
};
