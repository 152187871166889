import { ApolloProvider } from "@apollo/client";
import "./App.css";

import { createTheme, ThemeProvider } from "@mui/material";
import styled from "styled-components";
import Router from "./routes";
// import { LogoOnlyLayout } from './layouts/LogoOnlyLayout'
import { useCallback, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useHotkeys } from "react-hotkeys-hook";
import useSound from "use-sound";
import "../src/styles/body.css";
import { MainComponent2 } from "./components/MainComponent2";
import { client } from "./utils/apollo";
import {
  HotkeyContext,
  PageContext,
  ThemeContext,
  UserProvider,
} from "./utils/contexts";
import { HOTKEYS } from "./utils/hotkeyHelper";

function App() {
  const [themeValue, setThemeValue] = useState<String>("gray");
  const [page, setPage] = useState("Schedule");
  const [showMainPage, setShowMainPage] = useState(false);
  const [showBadge, setShowBadge] = useState(true);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")!) || []
  );
  const [open, setOpen] = useState(true);
  const localTheme = localStorage.getItem("theme");
  const shouldPlayIntro = window.location.pathname === "/";
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--dynamic-color",
      localTheme === "light"
        ? lightTheme.palette.primary.light
        : localTheme === "dark"
        ? darkTheme.palette.primary.light
        : grayTheme.palette.primary.light
    );
  }, [themeValue]);
  const [introEnded, setIntroEnded] = useState(!shouldPlayIntro);
  useEffect(() => {
    if (!shouldPlayIntro) return;

    const script = document.createElement("script");

    script.src = "/p-static/js/stars.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const onIntroEnd = useCallback(() => {
    localStorage.setItem("v1:intro-completed", "true");
    setIntroEnded(true);
  }, []);
  const soundUrl = "/p-static/sounds/cereal-killa.mp3";

  //@ts-ignore
  const [play, { stop, isPlaying }] = useSound(soundUrl);
  useHotkeys(
    HOTKEYS.SHOW_SHORTCUTS,
    () => {
      setShowBadge(!showBadge);
    },
    [showBadge]
  );

  const setThemeValueToLight = () => {
    setThemeValue("light");
  };

  const setThemeValueToGray = () => {
    setThemeValue("gray");
  };

  const setThemeValueToDark = () => {
    setThemeValue("dark");
  };
  // const [user, setUser] = useState(() => {
  //   const
  // })
  // const user = localStorage.getItem("userId");
  return (
    // <SessionProvider>
    <Wrapper>
      <ApolloProvider client={client}>
        <HelmetProvider>
          <UserProvider>
            <ThemeContext.Provider value={{ themeValue, setThemeValue }}>
              <ThemeProvider
                theme={
                  localTheme === "light"
                    ? lightTheme
                    : localTheme === "dark"
                    ? darkTheme
                    : grayTheme
                }
              >
                <HotkeyContext.Provider value={{ showBadge, setShowBadge }}>
                  <PageContext.Provider
                    value={{ page, setPage, showMainPage, setShowMainPage }}
                  >
                    <Helmet title={`Studyo | ${page}`}></Helmet>
                    <MainComponent2 introEnded={introEnded}>
                      <Router
                        setThemeValueToLight={setThemeValueToLight}
                        setThemeValueToGray={setThemeValueToGray}
                        setThemeValueToDark={setThemeValueToDark}
                      />
                    </MainComponent2>
                  </PageContext.Provider>
                </HotkeyContext.Provider>
              </ThemeProvider>
            </ThemeContext.Provider>
          </UserProvider>
        </HelmetProvider>
      </ApolloProvider>
    </Wrapper>
    // </SessionProvider>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #232529;

  canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 0;
  }
`;

const lightTheme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "& +.MuiInputBase-root": {
            marginTop: 0,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#FFFFFF",
      light: "#ebebeb",
      dark: "#959191",
    },
    secondary: {
      light: "#d7d9e0",
      dark: "#c1b9b9",
      main: "#434349",
    },
    background: {
      default: "#ffffff",
    },
    text: {
      primary: "#494343",
      secondary: "#888",
    },
    action: {
      active: "#6384F7",
      // active: "#1e88a3",
    },
    warning: {
      light: "#78FBB4",
      main: "#6384F7",
    },
    error: {
      main: "#E74959",
      light: "#E74959",
      dark: "#E74959",
    },
    success: {
      main: "#30D08D",
      light: "#30D08D",
    },
  },
});

const grayTheme = createTheme({
  palette: {
    primary: {
      main: "#232529",
      light: "#30343C",
      dark: "#666666",
    },
    secondary: {
      light: "#3E4249",
      main: "#ffffff",
    },
    background: {
      default: "#cccccc",
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
    },
    warning: {
      light: "#78FBB4",
      main: "#6384F7",
    },
    error: {
      main: "#E74959",
      light: "#E74959",
      dark: "#E74959",
    },
    action: {
      active: "#6384F7",
      // active: "#3AC4E8",
    },
    success: {
      main: "#30D08D",
      light: "#30D08D",
    },
  },
});

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#000000",
      light: "#262629",
      dark: "#343639",
    },
    secondary: {
      light: "#444448",
      main: "#ffffff",
    },
    background: {
      default: "#ffffff",
    },
    warning: {
      light: "#78FBB4",
      main: "#6384F7",
    },
    error: {
      main: "#E74959",
      light: "#E74959",
      dark: "#E74959",
    },
    text: {
      primary: "#ffffff",
      secondary: "#7d7d85",
    },
    action: {
      active: "#6384F7",
      // active: "#3AC4E8",
    },
    success: {
      main: "#30D08D",
      light: "#30D08D",
    },
  },
});

export default App;
