import { alpha, Box, Button, Grid, Stack, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Xarrow, { Xwrapper } from "react-xarrows";
import EmptyLogo from "../../assets/EmptyLogo";
import BrownLogo from "../../assets/Universities/BrownLogo.png";
import CornellLogo from "../../assets/Universities/CornellLogo.png";
import HarvardLogo from "../../assets/Universities/HarvardLogo.png";
import MITLogo from "../../assets/Universities/MITLogo.png";
import PrincetonLogo from "../../assets/Universities/PrincetonLogo.png";
import StanfordLogo from "../../assets/Universities/StanfordLogo.png";
import UCBerkeleyLogo from "../../assets/Universities/UCBerkeleyLogo.png";
import UPennLogo from "../../assets/Universities/UPennLogo.png";
import { STUDYO_GRADIENT } from "../../utils/constants";
import { ThemeContext } from "../../utils/contexts";
import {
  StudyTypographyMediumSubtitle,
  StudyTypographyMegaSubtitle,
  StudyTypographyMegaTitle,
  StudyTypographySmallText,
} from "../glovebox/StudyTypography";
import AIBlock from "../homepage/AIBlock";
import BottomCard from "../homepage/BottomCard";
import DemoCard from "../homepage/DemoCard";
import EnergyConnector from "../homepage/EnergyConnector";
import ImportCalBlock from "../homepage/ImportCalBlock";
import TitleCard from "../homepage/InfoCard";
import MobileAppBlock from "../homepage/MobileAppBlock";
import ShareScheduleBlock from "../homepage/ShareScheduleBlock";
import ShortcutsBlock from "../homepage/ShortcutsBlock";
import StudyoVideo from "../homepage/StudyoVideo";
import VideoCard from "../homepage/VideoCard";
import { CheckIcon } from "../Icons/CheckIcon";
import { SegmentedControl } from "../SegmentedControl";
import { SimpleToDo } from "./SimpleToDo";
import Slider from "./Slider";
import { useHotkeys } from "react-hotkeys-hook";

export const HomePage = ({
  setThemeValueToLight,
  setThemeValueToGray,
  setThemeValueToDark,
}: {
  setThemeValueToLight: any;
  setThemeValueToGray: any;
  setThemeValueToDark: any;
}) => {
  const [organize, setOrganize] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const theme = useTheme();
  const constraintsRef = useRef(null);
  const checkBox = useRef(null);
  const navigate = useNavigate();
  // const [seconds, setSeconds] = useState(0);
  const [localTheme, setLocalTheme] = useState(localStorage.getItem("theme"));

  const { themeValue, setThemeValue } = useContext(ThemeContext);

  useHotkeys(
    "enter",
    () => {
      setOrganize(!organize);
    },
    [organize]
  );

  useEffect(() => {
    const handleResize = () => {
      // Add your media query condition
      const mediaQuery = window.matchMedia("(max-width: 1100px)");
      setIsSmallScreen(mediaQuery.matches);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Call the handleResize function initially to set the state
    handleResize();

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 7,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    vertical: false,
  };

  const [xPos, setXPos] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);

  const handlePrev = () => {
    if (xPos === 0) {
      setXPos(-slideWidth * (universities.length - 1));
    } else {
      setXPos(xPos + slideWidth);
    }
  };

  const handleNext = () => {
    if (xPos === -slideWidth * (universities.length - 1)) {
      setXPos(0);
    } else {
      setXPos(xPos - slideWidth);
    }
  };

  const universities = [
    StanfordLogo,
    PrincetonLogo,
    MITLogo,
    HarvardLogo,
    BrownLogo,
    UCBerkeleyLogo,
    CornellLogo,
    UPennLogo,
  ];
  const randomTodos = [
    "go to grocery store",
    "text mene about ideas",
    "brain dump",
    "design sesh",
    "code code code",
    "design, code, build repeat",
    "🎥",
    "movie night w/ suite",
    "love you all",
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        alignItems: "center",
        position: "relative",
      }}
    >
      {/* {organize && !isSmallScreen ? <HoldMovingLines /> : null} */}

      <Box
        sx={{
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "row",
          flex: 1,
          width: "100%",
          height: "100vh",
          zIndex: 1,
        }}
      >
        {isSmallScreen ? null : (
          <Box
            sx={{
              width: "90vw",
              height: "100vh",
              marginLeft: "12px",
              overflowY: "clip",
              alignItems: "flex-start",
              justifyContent: "flex-end",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {isMobile
              ? null
              : Array.from(Array(20).keys()).map((index) => {
                  //   const randomNum = Math.round(Math.random());
                  console.log(
                    randomTodos[Math.random() * randomTodos.length - 1]
                  );
                  return (
                    <div
                      ref={constraintsRef}
                      style={{
                        position: "relative",
                        height: "100vh",
                        width: "85%",
                      }}
                    >
                      <motion.div
                        style={{
                          width: "90%",
                          marginBottom: "12px",

                          // position: "absolute",
                          // left: "12px",
                          // bottom: organize
                          //   ? `${64 * index}px`
                          //   : `${Math.random() * 100}vh`,
                          // zIndex: 0,
                        }}
                        drag
                        whileInView={{
                          rotate: organize ? 0 : Math.random() * 30 - 15,
                        }}
                        // dragConstraints={constraintsRef}
                        whileHover={{ rotate: organize ? 0 : 5, scale: 1.1 }}
                      >
                        <SimpleToDo
                          data={{
                            title:
                              randomTodos[
                                Math.round(
                                  Math.random() * (randomTodos.length - 1)
                                )
                              ],
                            duration: 30,
                            startTime: moment().add(30 * index, "minutes"),
                            dueAt: moment()
                              .add(30 * index, "minutes")
                              .add(1, "day"),
                            uuid: "1",
                          }}
                        />
                      </motion.div>
                    </div>
                  );
                })}
          </Box>
        )}
        <motion.div
          style={{
            width: isSmallScreen ? "100%" : "40%",
            height: "100vh",
            flexShrink: "0",
            alignItems: "center",
            alignSelf: "center",
            justifyContent: "center",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
          // ref={constraintsRef}
        >
          <Box
            sx={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              height: "100%",
              marginBottom: "36px",
              marginTop: "36px",
              flexDirection: "column",
            }}
          >
            <Box></Box>
            <Box
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                zIndex: 100,
              }}
            >
              <div
                style={{
                  backgroundColor: theme.palette.primary.light,
                  width: "50px",
                  height: "50px",
                  borderRadius: "4px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  alignSelf: "center",
                }}
              >
                <EmptyLogo />
              </div>
              <StudyTypographyMegaSubtitle style={{ marginTop: "12px" }}>
                Studyo
              </StudyTypographyMegaSubtitle>
              <StudyTypographyMegaTitle
                style={{
                  marginTop: "24px",
                  fontSize: isMobile ? "32px" : "48px",
                  fontWeight: "700",
                  textAlign: "center",
                  width: "95%",
                }}
              >
                todo list + calendar + cowork.
              </StudyTypographyMegaTitle>
              {/* <StudyTypographyMegaTitle
                style={{
                  marginTop: "24px",
                  fontSize: isMobile ? "32px" : "48px",
                  fontWeight: "700",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                calendar x cowork.
              </StudyTypographyMegaTitle> */}
              <Xwrapper>
                <Stack
                  flexDirection={"row"}
                  alignItems="center"
                  justifyItems={"center"}
                  marginTop={"36px"}
                >
                  <motion.div
                    // whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
                    // whileHover={{ rotate: 10 }}
                    whileInView={{
                      rotate: organize ? [0] : [2, -2, 0],
                      scale: organize ? [1] : [1, 1.05, 1],
                    }}
                    transition={{
                      type: "spring",
                      stiffness: 100,
                      damping: 8,
                      repeat: organize ? 0 : Infinity,
                    }}
                    id="check-box"
                    style={{
                      marginRight: "12px",
                      alignSelf: "center",
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingBottom: "12px",
                    }}
                    onClick={() => {
                      setOrganize(!organize);
                    }}
                  >
                    <CheckIcon active={organize} clicked={() => {}} />
                  </motion.div>
                  <StudyTypographyMegaSubtitle
                    style={{ paddingBottom: "12px", fontWeight: "normal" }}
                  >
                    organize chaos w/ studyo
                  </StudyTypographyMegaSubtitle>
                </Stack>
                {!organize ? (
                  <Xarrow
                    startAnchor={"left"}
                    endAnchor={"bottom"}
                    end={"check-box"}
                    start={"click-me"}
                    gridBreak={"50%"}
                    curveness={3}
                    color={theme.palette.primary.light}
                    headSize={4}
                  />
                ) : null}
              </Xwrapper>
              {!organize ? (
                <StudyTypographyMediumSubtitle
                  id={"click-me"}
                  style={{
                    marginTop: "24px",
                    paddingLeft: "12px",
                    height: "24px",
                  }}
                >
                  click or press enter :)
                </StudyTypographyMediumSubtitle>
              ) : (
                <StudyTypographyMediumSubtitle
                  id={"click-me"}
                  style={{
                    marginTop: "24px",
                    paddingLeft: "12px",
                    height: "24px",
                  }}
                >
                  {" "}
                </StudyTypographyMediumSubtitle>
              )}
            </Box>
            {organize ? (
              <motion.div
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  // marginTop: "36px",
                  position: "relative",
                  bottom: "100px",
                  width: "350px",
                  height: "50px",
                }}
              >
                <Button
                  onClick={() => {
                    navigate("/register");
                  }}
                  fullWidth
                  style={{
                    background: STUDYO_GRADIENT,
                    backgroundColor: "white",
                    marginRight: "12px",
                    textTransform: "none",
                    color: theme.palette.text.primary,
                  }}
                >
                  register
                </Button>
                <Button
                  onClick={() => {
                    navigate("/login");
                  }}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  style={{
                    borderColor: `linear-gradient(135deg, ${alpha(
                      "#FBDA61",
                      0.5
                    )} 0%, ${alpha("#FF5ACD", 0.5)} 100%)`,
                    color: `linear-gradient(135deg, ${alpha(
                      "#FBDA61",
                      0.5
                    )} 0%, ${alpha("#FF5ACD", 0.5)} 100%)`,
                    //   backgroundColor: "white",
                    marginRight: "12px",
                    textTransform: "none",
                  }}
                >
                  login
                </Button>
                <Button
                  onClick={() => {
                    navigate("/features");
                  }}
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  style={{
                    borderColor: STUDYO_GRADIENT,
                    color: STUDYO_GRADIENT,
                    //   backgroundColor: "white",
                    textTransform: "none",
                  }}
                >
                  features!
                </Button>
              </motion.div>
            ) : (
              <Box sx={{ height: "50px" }}></Box>
            )}
          </Box>
          {organize ? (
            <div
              style={{
                alignItems: "center",
                marginBottom: "24px",
                display: "flex",
                gap: "8px",
                flexDirection: "column",
                position: "absolute",
                alignSelf: "center",
                bottom: "24px",
              }}
            >
              <div
                style={{
                  height: "30px",
                  width: "12px",
                  borderRadius: "6px",
                  border: `2px solid ${theme.palette.text.primary}`,
                  backgroundColor: "rgba(0,0,0,0)",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <motion.div
                  style={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "5px",
                    backgroundColor: theme.palette.text.primary,
                    marginTop: "3px",
                  }}
                  animate={{ y: [0, 16] }}
                  transition={{
                    repeat: Infinity,
                    repeatType: "reverse",
                    type: "spring",
                    stiffness: 40,
                    mass: 0.2,
                  }}
                ></motion.div>
              </div>
              <StudyTypographySmallText>scroll :)</StudyTypographySmallText>
            </div>
          ) : null}
        </motion.div>
        {isSmallScreen ? null : (
          <Box
            sx={{
              width: "90vw",
              height: "100vh",
              marginRight: "12px",
              overflowY: "clip",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {isMobile
              ? null
              : Array.from(Array(20).keys()).map((index) => {
                  //   const randomNum = Math.round(Math.random());
                  return (
                    <div
                      ref={constraintsRef}
                      style={{
                        position: "relative",
                        height: "100vh",
                        width: "77%",
                      }}
                    >
                      <motion.div
                        style={{
                          // position: "absolute",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                          display: "flex",
                          right: "12px",

                          marginBottom: "12px",
                          bottom: organize
                            ? `${64 * index}px`
                            : `${Math.random() * 100}vh`,
                          zIndex: 0,
                        }}
                        drag
                        whileInView={{
                          rotate: organize ? 0 : Math.random() * 30 - 15,
                        }}
                        whileHover={{ rotate: organize ? 0 : 5, scale: 1.1 }}
                        transition={{
                          type: "spring",
                          stiffness: 200,
                          damping: 20,
                        }}
                      >
                        <SimpleToDo
                          data={{
                            title:
                              randomTodos[
                                Math.round(
                                  Math.random() * (randomTodos.length - 1)
                                )
                              ],
                            duration: 30,
                            startTime: moment().add(30 * index, "minutes"),
                            dueAt: moment()
                              .add(30 * index, "minutes")
                              .add(1, "day"),
                            uuid: "1",
                          }}
                        />
                      </motion.div>
                    </div>
                  );
                })}
          </Box>
        )}
      </Box>
      {/* {!isMobile && organize
        ? null
        : Array.from(Array(organize ? 3 : 20).keys()).map((index) => {
            //   const randomNum = Math.round(Math.random());
            return (
              <motion.div
                style={{
                  width: isMobile ? "100vw" : "300px",
                  position: "absolute",
                  left: isMobile ? "0px" : `${Math.random() * 100}vw`,
                  top: organize ? `${64 * index}px` : `${Math.random()}px`,
                  zIndex: 0,
                }}
                drag
                whileInView={{
                  rotate: organize ? 0 : Math.random() * 30 - 15,
                }}
                dragConstraints={constraintsRef}
                whileHover={{ rotate: organize ? 0 : 5, scale: 1.1 }}
                transition={{
                  type: "spring",
                  stiffness: 200,
                  damping: 20,
                }}
              >
                <SimpleToDo
                  data={{
                    title:
                      randomTodos[
                        Math.round(Math.random() * (randomTodos.length - 1))
                      ],
                    duration: 30,
                    startTime: new Date(),
                    dueAt: new Date(),
                    uuid: "1",
                  }}
                />
              </motion.div>
            );
          })} */}
      {organize ? (
        <Box sx={{ width: "90%", marginTop: "124px", zIndex: 1 }}>
          <StudyTypographyMegaTitle
            style={{ fontSize: "64px", color: theme.palette.secondary.light }}
          >
            Every productive day starts with Studyo
          </StudyTypographyMegaTitle>
          <StudyTypographyMegaTitle
            style={{
              fontSize: "64px",
              color: theme.palette.primary.dark,
              marginBottom: "24px",
            }}
          >
            Check out our unique features
          </StudyTypographyMegaTitle>
          <SegmentedControl
            id={"theme"}
            onSegment1Tap={() => {
              document
                .querySelector('meta[name="theme-color"]')
                ?.setAttribute("content", "#E9EbED");
              localStorage.setItem("theme", "light");
              setLocalTheme("light");
              setThemeValue("light");
            }}
            onSegment2Tap={() => {
              document
                .querySelector('meta[name="theme-color"]')
                ?.setAttribute("content", "#232529");

              localStorage.setItem("theme", "gray");
              setLocalTheme("gray");
              setThemeValue("");
            }}
            onSegment3Tap={() => {
              document
                .querySelector('meta[name="theme-color"]')
                ?.setAttribute("content", "#000000");

              localStorage.setItem("theme", "dark");
              setLocalTheme("dark");
              setThemeValue("dark");
            }}
            defaultSegment={
              localTheme === "light" ? 0 : localTheme === "dark" ? 2 : 1
            }
            backgroundColor={"white"}
            position={0}
            width={275}
            padding={""}
            isSelected={false}
          />
          <div style={{ marginBottom: "36px" }}></div>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <TitleCard />
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <VideoCard>
                <StudyoVideo />
              </VideoCard>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            sx={{ marginTop: "0px", marginBottom: "124px" }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <DemoCard title="Import gcal">
                <ImportCalBlock />
              </DemoCard>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DemoCard title="Share schedule">
                <ShareScheduleBlock />
              </DemoCard>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DemoCard title="Shortcuts">
                <ShortcutsBlock />
              </DemoCard>
            </Grid>
          </Grid>
          <StudyTypographyMegaTitle
            style={{
              fontSize: "64px",
              color: theme.palette.secondary.light,
              marginBottom: "64px",
            }}
          >
            Trusted by students @ top universities
          </StudyTypographyMegaTitle>
          <Slider />

          <StudyTypographyMegaTitle
            style={{
              fontSize: "64px",
              color: theme.palette.secondary.light,
              marginTop: "124px",
            }}
          >
            If we built this in 10 weeks
          </StudyTypographyMegaTitle>
          <StudyTypographyMegaTitle
            style={{
              fontSize: "64px",
              color: theme.palette.primary.dark,
              // marginBottom: "64px",
            }}
          >
            Imagine what we can do with 1 YEAR!
          </StudyTypographyMegaTitle>
          <Grid container sx={{ marginTop: "0px", paddingTop: "0px" }}>
            <Grid item xs={12} sm={6} md={12}>
              <EnergyConnector />
            </Grid>
          </Grid>
          <Grid
            container
            // spacing={4}
            columnSpacing={4}
            sx={{ marginTop: "0px", marginBottom: "48px", paddingTop: "0px" }}
          >
            <Grid item xs={12} sm={6} md={6} sx={{ paddingTop: "0px" }}>
              <DemoCard title="AI Scheduler">
                <AIBlock />
              </DemoCard>
            </Grid>
            <Grid item xs={12} sm={6} md={6} sx={{ paddingTop: "0px" }}>
              <DemoCard title="Mobile App">
                <MobileAppBlock />
              </DemoCard>
            </Grid>
          </Grid>
          <Grid container spacing={4} style={{ marginBottom: "64px" }}>
            <Grid item xs={12} sm={6} md={12}>
              <BottomCard color={theme.palette.primary.main} />
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </Box>
  );
};
