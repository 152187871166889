import { useState } from "react";
import StudyoKanban from "../../assets/StudyoKanban.mp4";
import { StudyTypographyMediumText } from "../glovebox/StudyTypography";
import { LoaderIcon } from "react-hot-toast";

export const KanbanPreview = () => {
  const [seconds, setSeconds] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);
  const [loaded, setLoaded] = useState(false);

  var elem = document.getElementById("schedule-video");

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {loaded ? null : <LoaderIcon style={{ width: "48px", height: "48px" }} />}
      {/* <StudyTypographyMediumText>{`seconds: ${seconds.toFixed(
        0
      )}`}</StudyTypographyMediumText> */}
      <video
        controls={false}
        autoPlay={true}
        playsInline
        muted={false}
        onLoadedData={() => {
          setLoaded(true);
        }}
        id={"schedule-video"}
        loop
        className={fullScreen ? "" : "video"}
        onTimeUpdateCapture={(data) => {
          console.log("i am capture: ", data);
        }}
        onClick={() => {
          setFullScreen(true);
          elem?.requestFullscreen();
        }}
        onTimeUpdate={(data) => {
          console.log(data);
          //@ts-ignore
          var holdSeconds = data?.target?.currentTime;
          setSeconds(holdSeconds);
        }}
        style={{
          width: "80%",
          borderRadius: "12px",
          //   height: "100%",
        }}
      >
        <source
          src={StudyoKanban}
          type="video/mp4"
          style={{ borderRadius: "12px" }}
        />
        <source src="myVideo.mp4" type="video/mp4" />
        <p>
          Your browser doesn't support HTML video. Here is a
          <a href="myVideo.mp4">link to the video</a> instead.
        </p>
      </video>
    </div>
  );
};
