import axios from "axios";
import OpenAI from "openai";
import { ChatCompletionMessageParam } from "openai/resources/chat";
import {
  UPDATE_TODO_UUID,
  updateTodoStartTime,
} from "../graphql/mutations/todo";
import { client } from "./apollo";
import { useState } from "react";

export const sendMessageToGPT = async (message: string) => {
  const openai = new OpenAI({
    dangerouslyAllowBrowser: true,
    apiKey: "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",
  });

  console.log(message);

  const systemMessage: ChatCompletionMessageParam = {
    role: "system",
    content:
      "You are a helpful assistant with fourty years of ecxperience of building perfect schedules using the best productivity hacks and principles. You will be given a list of tasks and you will have to schedule them in the most efficient way possible.",
  };

  const userMessage: ChatCompletionMessageParam = {
    role: "user",
    content: message,
  };

  const chatCompletion = await openai.chat.completions
    .create({
      messages: [systemMessage, userMessage],
      model: "gpt-3.5-turbo",
    })
    .then((res) => {
      console.log(res);
      const response = res.choices[0].message.content;
      // Regex to extract JSON and message
      const regex = /```json([\s\S]*?)```([\s\S]*)$/;
      const match = response?.match(regex);

      // Check if the regex found the JSON and message
      if (match) {
        // Extract and parse the JSON into an object
        const jsonString = match[1].trim();
        const jsonObject = JSON.parse(jsonString);

        // Extract the message
        const message = match[2].trim();

        console.log("JSON Object:", jsonObject);
        console.log("Message:", message);
        return { object: jsonObject, message: message };
      } else {
        console.log("Could not extract JSON and message.");
      }
    })
    .catch((err) => {
      console.log(err);
    });
  console.log(chatCompletion);
  return chatCompletion;
};
