import {
  Typography,
  ButtonGroup,
  Popper,
  List,
  styled,
  css,
  Dialog,
  Box,
  Divider,
  Button,
  alpha,
} from "@mui/material";
import {
  CheckBox,
  ChevronLeft,
  CloseRounded,
  ErrorOutline,
} from "@mui/icons-material";
import { ListItem } from "@mui/material";
import { ToDoListIcon } from "../../Icons/ToDoListIcon";
import { XIcon } from "../../sidebar/Icons";
import { isMobile } from "react-device-detect";
import { STUDYO_GRADIENT } from "../../../utils/constants";

export const CalendarViewContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  border: `1px solid ${theme.palette.primary.light}`,
  borderRadius: "8px 8px 0px 0px",
  backgroundColor: theme.palette.primary.main,
  "&::-webkit-scrollbar": {
    height: "5px",
    // width: "5px",
    background: theme.palette.primary.main,
    "-webkit-border-radius": "1ex",
  },

  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.secondary.light,
    "-webkit-border-radius": "1ex",
  },

  "&::-webkit-scrollbar-corner": {
    background: theme.palette.primary.main,
  },
}));

export const CalendarViewLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: "Space Grotesk",
  fontSize: "14px",
  maxLines: "1",
}));

export const CalendaListItem = styled(ListItem)(({ theme }) => ({
  height: "35px",
  marginTop: "3px",
  borderRadius: "4px",
  backgroundColor: theme.palette.primary.main,
  "&:first-child": {
    marginTop: "0",
  },
  "&:hover": {
    background: theme.palette.primary.dark,
    cursor: "pointer",
  },
}));

// ${({ toggle }) =>
// toggle &&
// `
// &::before {
// content: '';
// position: absolute;
// inset: 0;
// border-radius: 4px;
// background: linear-gradient(270deg, ${violetColors.violet350} -5.62%, ${violetColors.violet950} 45.92%, ${highlightBlue} 103.12%);
// -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
// -webkit-mask-composite: xor;
// mask-composite: exclude;
// padding: 1px;
// }
// `}

export const CalendarViewDayContainer = styled("div")<{ view: any }>(
  ({ theme, view }) => ({
    backgroundColor: theme.palette.primary.main,
    justifyContent: "center",
    alignItems: "center",
    borderStyle: "solid",
    borderWidth: "0 0.5px 1px 0.5px",
    borderColor: theme.palette.primary.light,
    // paddingTop: view === "WEEK" ? "8px" : "0px",
    width: "100%",
    // height: "100%",
    // justifyContent: "start",
    height: view === "WEEK" ? "100%" : "210px",
    marginBottom: "0px",
    "&:hover": {
      cursor: "pointer",
    },
  })
);

// `
//   background-color: ;
//   justify-content: center;
//   align-items: center;
//   border-style: solid;
//   border-width: 0 0.5px 1px 0.5px;
//   border-color: ${greyColors.grey86};
//   padding-top: ${(props) => (props.view === "WEEK" ? "8px" : "0")};
//   margin-bottom: 0;
// `;

export const CalendarViewDMonthContainer = styled("div")<{ view: any }>`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: ${isMobile ? "repeat(1, 1fr)" : "repeat(7, 1fr)"};
  grid-template-rows: ${(props) =>
    props.view === "WEEK" ? "repeat(1, 1fr)" : "repeat(6, 1fr)"};
  width: 100%;
  justify-content: start;
  height: 80vh;
`;

export const CalendarViewTaskIcon = styled((props) => (
  <ToDoListIcon active={false} clicked={() => {}} />
))``;

export const CalendarViewShowDate = styled(Typography)(({ theme }) => ({
  fontFamily: "Space Grotesk",
  fontSize: "14px",
  width: "125px",
  color: theme.palette.text.primary,
  maxLines: "1",
}));

export const CalendarViewTaskShowMore = styled(Typography)(({ theme }) => ({
  fontFamily: "Space Grotesk",
  fontSize: "12px",
  color: theme.palette.action.active,
  maxLines: "1",
  marginLeft: "8px",
  "&:hover": {
    cursor: "pointer",
  },
}));

export const CalendarDayOfMonthLabel = styled(Typography)(({ theme }) => ({
  fontFamily: "Space Grotesk",
  fontSize: "14px",
  background: STUDYO_GRADIENT,

  backgroundColor: "white",
  color: theme.palette.primary.main,

  borderRadius: "4px",
  maxLines: 1,
  alignSelf: "center",
}));

export const CalendarDayOfWeekLabel = styled(Typography)(({ theme }) => ({
  fontFamily: "Space Grotesk",
  fontSize: "14px",
  color: theme.palette.text.primary,
  borderRadius: "4px",
  marginRight: "20px",
  marginLeft: "20px",
  padding: "4px",
  maxLines: "1",
  alignSelf: "center",
}));

export const CalendarViewModalContainer = styled("div")`
  flex: 1;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
`;

export const CalendarTopBar = styled("div")`
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CalendarTopBarLeftSide = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const CalendarViewStatusNotifier = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  backgroundColor: theme.palette.primary.main,
  border: `1px solid ${theme.palette.action.active}`,
  borderRadius: "4px",
  paddingLeft: "12px",
  paddingRight: "12px",
  height: "36px",
}));

export const ButtonGroupStyled = styled(ButtonGroup)`
  min-height: 40px;
`;

export const CalendarViewMonthDayToggle = styled(ButtonGroupStyled)(
  ({ theme }) => ({
    fontFamily: "Space Grotesk",
    borderRadius: "4px 4px 0px 0px",
    paddingLeft: "4px",
    paddingRight: "4px",
    backgroundColor: theme.palette.primary.light,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    textAlign: "left",
    height: "36px",
    marginLeft: "24px",
    "&:hover": {
      cursor: "pointer",
    },
  })
);
export const TaskTemplateLabelWrapper = styled("div")`
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  margin-right: 8px;
  padding-right: 4px;
  padding-left: 4px;
`;

export const StyledPopper = styled(Popper)`
  z-index: 999;
  .MuiPaper-rounded {
    border-radius: 0 6px 6px 6px;
    background: transparent;
  }
`;

export const StyledList = styled(List)(({ theme }) => ({
  minWidth: "186px",
  backgroundColor: theme.palette.primary.light,
  padding: "6px",
  zIndex: "1",
  borderRadius: "0px 6px 6px 6px",
  position: "relative",
}));
//   &:after {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     margin: 0.1em;
//     z-index: -1;
//     border-radius: inherit;
//     background: linear-gradient(180deg, #1e1e1e 0%, #141414 109.19%);
//   }

export const CalendarViewWarningIcon = styled(ErrorOutline)(({ theme }) => ({
  color: theme.palette.action.active,
  marginRight: "8px",
}));

export const CalendarLeftArrowIcon = styled(ChevronLeft)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  justifySelf: "end",
  backgroundColor: theme.palette.primary.light,
  padding: "4px",
  borderRadius: "5px",
  width: "24px",
  height: "24px",
  "&:hover": {
    cursor: "pointer",
  },
}));
export const CalendarDayOfWeekBar = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-around",
  paddingTop: "8px",
  paddingBottom: "8px",
  borderBottom: "0.5px solid",
  borderColor: theme.palette.primary.light,
}));

export const CalendarViewWeekendIconCheckmark = styled(CheckBox)(
  ({ theme }) => ({
    width: "16px",
    height: "16px",
    padding: "2px",
    backgroundColor: theme.palette.action.active,
    borderRadius: "4px",
    marginRight: "16px",
    color: "white",
  })
);

export const CalendarViewWeekendContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
`;

export const CalendarViewDayTopBar = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  marginBottom: "4px",
}));

export const CalendarViewTaskContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  margin: "6px",
  display: "flex",
  flexDirection: "row",
  borderRadius: "4px",
  alignItems: "center",
  alignContent: "center",
  padding: "8px",
  // whiteSpace: "nowrap",
}));

export const CalendarViewTaskLabel = styled(Typography)(({ theme }) => ({
  width: "100%",
  fontFamily: "Space Grotesk",
  fontSize: "12px",
  color: theme.palette.text.primary,
  alignSelf: "center",
  justifySelf: "center",
  // whiteSpace: "nowrap",
  boxSizing: "border-box",
  display: "flex",
  maxLines: "3",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export const CalendarRightArrowIcon = styled(CalendarLeftArrowIcon)`
  margin-left: 4px;
  transform: rotate(180deg);
`;

export const scrollBarStyles = css`
  :hover {
    &::-webkit-scrollbar {
      display: block;
    }
  }
  &::-webkit-scrollbar {
    display: none;
    position: absolute;
    z-index: 999;
    width: 20px;
    background: transparent;
    border-radius: 0 4px 4px 0;
    outline: none;
  }
  &::-webkit-scrollbar-track {
    background: #606060;
    background-clip: padding-box;
    border: 8px solid rgba(0, 0, 0, 0);
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    border: 8px solid rgba(0, 0, 0, 0);
    background: #c4c4c4;
    background-clip: padding-box;
  }
`;

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  display: "inline-block",
  flexDirection: "column",
  borderRadius: "12px",
  backgroundColor: "rgba(0, 0, 0, 0)",
}));

export const StyledChildren = styled("div")``;

export const StyledDialogTopBar = styled("div")(({ theme }) => ({
  flexDirection: "row",
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "18px",
  paddingBottom: "16px",
  borderBottom: `0.5px dashed ${theme.palette.primary.dark}`,
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  width: isMobile ? "70vw" : "525px",
  background: theme.palette.primary.main,
  padding: "16px",
  borderRadius: "12px",
  border: `4px solid ${theme.palette.primary.main}`,
}));

export const StyledCloseButton = styled(CloseRounded)(({ theme }) => ({
  transform: "rotate(90deg)",
  background: theme.palette.primary.light,
  width: "18px",
  height: "18px",
  padding: "8px",
  borderRadius: "6px",
  "&:path": {
    fill: "#5E5E5E",
  },
  "&:hover": {
    background: theme.palette.secondary.light,
    cursor: "pointer",
  },
}));

// `
//   transform: rotate(90deg);
//   background: black;
//   width: 18px;
//   height: 18px;
//   padding: 8px;
//   border-radius: 6px;
//   path {
//     fill: "#5E5E5E";
//   }
//   :hover {
//     background: rgba(122, 122, 122, 0.2);
//     cursor: pointer;
//   }
// `;

// export const StyledCloseButton = styled(CloseRounded)`
//   transform: rotate(90deg);
//   background: black;
//   width: 18px;
//   height: 18px;
//   padding: 8px;
//   border-radius: 6px;
//   path {
//     fill: "#5E5E5E";
//   }
//   :hover {
//     background: rgba(122, 122, 122, 0.2);
//     cursor: pointer;
//   }
// `;

export const StyledHeader = styled(Typography)(({ theme }) => ({
  fontFamily: "Space Grotesk",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: "700",
  textAlign: "left",
  color: theme.palette.text.primary,
  marginLeft: "24px",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledDivider = styled(Divider)`
  && {
    background-color: #363636;
    width: 444px;
    height: 1px;
    margin: 0 auto;
    margin-top: 28px;
  }
`;

export const StyledBody = styled(Typography)`
  && {
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: #c4c4c4;
    margin-top: 14px;
  }
`;

export const StyledWarningMessage = styled(Typography)(({ theme }) => ({
  fontFamily: "Space Grotesk",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "14px",
  letterSpacing: "0.01em",
  color: theme.palette.error.main,
  marginLeft: "24px",
  marginTop: "14px",
}));

export const StyledButtonsContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 23px 24px 0;
`;

export const StyledCancelButton = styled(Button)`
  && {
    padding: 8px 30px;
    width: 107px;
    height: 39px;
    background: #232323;
    border-radius: 35px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;

    :hover {
      background: rgba(35, 35, 35, 0.5);
    }
  }
`;

export const StyledArchiveTaskButton = styled(Button)`
  && {
    background: linear-gradient(
      270deg,
      #ccbbff -5.62%,
      #7427ff 45.92%,
      #00baff 103.12%
    );
    min-height: 0;
    min-width: 144px;
    height: 40px;
    display: flex;
    padding: 4px 2px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: #ffffff;
    opacity: 0.8;
    transition: opacity 0.25s;
    margin-left: 12px;

    button {
      padding: 4px 8px;
      background: rgba(20, 20, 20, 1);
      font-size: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    :hover {
      opacity: 1;
    }
  }
`;

export const StyledArchivedLabel = styled(Typography)`
  && {
    margin-left: 6px;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: -1%;
  }
`;
