// import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// import { useFormik, Form, FormikProvider } from "formik";
// import { Icon } from "@iconify/react";
// import eyeFill from "@iconify/icons-eva/eye-fill";
// import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Button,
  Modal,
  Box,
  useTheme,
  alpha,
} from "@mui/material";
// import { LoadingButton } from "@mui/lab";
import { CheckBoxRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useUserContext } from "../../../utils/contexts";
import { client } from "../../../utils/apollo";
import { GET_USER_INFO, GET_USER_SIGNIN } from "../../../graphql/queries/user";
import { GET_USER_GOOGLE_CATEGORIES } from "../../../graphql/queries/category";
import {
  addGoogleCalendarEvents,
  getGoogleCalendarEvents,
} from "../../../utils/addGoogleCalTodos";
import { getToken } from "../../../utils/tokens";
import { StudyTypographyMediumSubtitle } from "../../glovebox/StudyTypography";
import { STUDYO_GRADIENT } from "../../../utils/constants";

// ----------------------------------------------------------------------

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
  p: 4,
};

export default function LoginForm({
  handleFormChange,
}: {
  handleFormChange: any;
}) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const auth = getAuth();
  const [loading, setLoading] = useState(false);
  const { setId, setGoogleUser } = useUserContext();
  const [showPassword, setShowPassword] = useState(false);
  const [wrongPassword, setWrongPassword] = useState("");
  const [resetSent, setResetSent] = useState(false);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  // const LoginSchema = Yup.object().shape({
  //   email: Yup.string()
  //     .email("Email must be a valid email address")
  //     .required("Email is required"),
  //   password: Yup.string().required("Password is required"),
  // });

  // const formik = useFormik({
  //   initialValues: {
  //     email: "",
  //     password: "",
  //     remember: true,
  //   },
  //   validationSchema: LoginSchema,
  //   onSubmit: (values) => {
  //     console.log("LOGGING IN: ", values);
  //   },
  // });

  // const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleSignIn = (event: any) => {
    event.preventDefault();
    // event.stopPropagration();
    setLoading(true);
    setStatus("authenticating...");
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // console.log(userCredential);
        const user = userCredential.user;
        const userInfoPromise = client.query({
          query: GET_USER_SIGNIN,
          variables: { email: email },
        });
        const userCategoriesPromise = client.query({
          query: GET_USER_GOOGLE_CATEGORIES,
          variables: { email: email },
          fetchPolicy: "network-only",
        });
        Promise.all([userInfoPromise, userCategoriesPromise])
          // Promise.all([userInfoPromise])
          .then(async ([{ data: signinData }, userCategories]) => {
            // .then(async ([{ data: signinData }]) => {
            setId(signinData?.getUserEmail?.id);
            setGoogleUser(user);
            localStorage.setItem("userId", signinData?.getUserEmail?.id);
            localStorage.setItem("googleUser", JSON.stringify(user));
            // async function getUserGoogleEvents() {
            if (signinData?.getUserEmail?.rtoken) {
              setStatus("syncing with your Google Calenander...");
              const token = await getToken(signinData?.getUserEmail?.rtoken);
              const newGoogleTodos = await getGoogleCalendarEvents(
                token,
                false,
                signinData.getUserEmail.id,
                userCategories.data.getUserGoogleCategories?.map((cal: any) => {
                  return cal.uuid;
                })
                // signinData?.getUserEmail?.googleCals
              );
              // .then(async (newGoogleTodos: any) => {

              // const addNewTodos = newGoogleTodos?.map(
              // for (let newTodos of newGoogleTodos) {
              newGoogleTodos?.forEach(async function (
                newTodos: any,
                index: any
              ) {
                if (newTodos?.length > 0) {
                  // setStatus('Updating your profile...');
                  setStatus("Updating your profile...");
                  return addGoogleCalendarEvents(
                    signinData?.getUserEmail?.id,
                    userCategories.data.getUserGoogleCategories[index].uuid,
                    // signinData?.getUserEmail?.googleCals[index],
                    newTodos
                  );
                }
              });
              // async (newTodos: any, index: any) => {
              // }
              // );
              // return Promise.all(addNewTodos)
              // });
            }
            // }
            // await getUserGoogleEvents()
            // .then(async () => {
            setStatus("fetching your pofile...");
            await client
              .query({
                query: GET_USER_INFO,
                variables: {
                  getUserId: signinData?.getUserEmail?.id,
                  userId: signinData?.getUserEmail?.id,
                  getUserTodosUserId2: signinData?.getUserEmail?.id,
                  getUserGroupInvitesUserId3: signinData?.getUserEmail?.id,
                },
              })
              .then(({ data: queryData }) => {
                // console.log(queryData);
                localStorage.setItem(
                  "categories",
                  JSON.stringify(queryData.getUserCategories)
                );
                // console.log(JSON.stringify(queryData.getUserTodos));
                localStorage.setItem("user", JSON.stringify(queryData.getUser));
                localStorage.setItem(
                  "todos",
                  JSON.stringify(queryData.getUserTodos)
                );
                localStorage.setItem(
                  "todos",
                  JSON.stringify(queryData.getUserTodos)
                );
                localStorage.setItem(
                  "groupInvites",
                  JSON.stringify(queryData.getUserGroupInvites)
                );
                navigate("/app/schedule");
              })
              .catch((queryError) => {
                setError(queryError.code + ": " + queryError.message);
                setLoading(false);
              });
          });
      })
      // .catch((signInError) => {
      //   // console.log(signInError);
      //   setError(signInError.code + ": " + signInError.message);
      //   setLoading(false);
      // });
      // })
      .catch((signInError) => {
        // console.log(signInError);
        setError(signInError.code);
        setLoading(false);
      });
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const theme = useTheme();
  return (
    // <FormikProvider value={formik}>
    <form autoComplete="on" noValidate onSubmit={handleSignIn}>
      <div style={{ width: "100%" }}>
        <input
          autoFocus
          autoComplete="username"
          style={{
            border: "0px",
            backgroundColor: theme.palette.primary.light,
            width: "100%",
            height: "60px",
            marginBottom: "16px",
            padding: "0px",
            paddingLeft: "16px",
            // paddingTop: "10px",
            // paddingBottom: "10px",
            boxSizing: "border-box",
            outlineWidth: 0,
            borderRadius: "4px",
            color: theme.palette.text.primary,
          }}
          placeholder={"enter email.."}
          type={"text"}
          name="title"
          value={email}
          onChange={(text) => {
            setEmail(text.target.value);
          }}
        ></input>
        <input
          style={{
            border: "0px",
            backgroundColor: theme.palette.primary.light,
            width: "100%",
            height: "60px",
            padding: "0px",
            paddingLeft: "16px",
            boxSizing: "border-box",

            marginBottom: "16px",
            outlineWidth: 0,
            borderRadius: "4px",
            color: theme.palette.text.primary,
          }}
          autoComplete="password"
          placeholder={"enter password.."}
          type={"password"}
          name="Password"
          value={password}
          onChange={(text) => {
            setPassword(text.target.value);
          }}
        ></input>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="outlined"
          loading={loading}
          style={{
            width: "100%",
            height: "42.25px",
            display: "flex",
            background: STUDYO_GRADIENT,
            backgroundColor: "white",
            textTransform: "none",
            color: theme.palette.text.primary,
          }}
          onClick={handleSignIn}
        >
          {loading ? "" : "login"}
        </LoadingButton>
        {!error && status ? (
          <StudyTypographyMediumSubtitle
            style={{ marginTop: "16px", marginBottom: "16px" }}
          >
            {status}
          </StudyTypographyMediumSubtitle>
        ) : (
          <div />
        )}
        {error ? (
          <StudyTypographyMediumSubtitle
            style={{
              marginTop: "16px",
              marginBottom: "16px",
              color: theme.palette.error.main,
            }}
          >
            {error}
          </StudyTypographyMediumSubtitle>
        ) : (
          <div />
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "16px",
          }}
        >
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleFormChange();
            }}
          >
            create account
          </a>
        </div>

        {/* <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Button
          variant="text"
          color="info"
          onClick={() => {
            setOpen(true);
          }}
        >
          Change password?
        </Button>
      </Stack> */}

        {wrongPassword !== "" ? (
          <Typography sx={{ marginTop: 2, color: "red" }}>
            {wrongPassword}
          </Typography>
        ) : null}

        <Modal
          open={open}
          onClose={() => {
            setResetSent(false);
            setOpen(false);
          }}
        >
          <Box sx={modalStyle}>
            <Typography variant="h4" sx={{ marginBottom: "32px" }}>
              Enter email to reset password
            </Typography>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              sx={{
                marginBottom: "16px",
                backgroundColor: theme.palette.primary.main,
              }}
              onChange={(text) => {
                setEmail(text.currentTarget.value);
              }}
              // error={Boolean(touched.email && errors.email)}
              // helperText={touched.email && errors.email}
            ></TextField>
            <Button fullWidth variant="contained" onClick={() => {}}>
              Send reset email
            </Button>
            {resetSent ? (
              <div
                style={{
                  flexDirection: "row",
                  marginTop: "16px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <Icon>
                <CheckBoxRounded
                  style={{ color: "#37BC64", marginRight: "8px" }}
                />
              </Icon> */}
                <Typography>Email successfully sent</Typography>
              </div>
            ) : null}
          </Box>
        </Modal>
      </div>
    </form>
    // </FormikProvider>
  );
}
