import React from "react";
import { ToolbarDurationBox } from "../toolbar/styles";
import { StudyTypographySmallSubtitle } from "./StudyTypography";
import { ArrowDropDownRounded } from "@mui/icons-material";

function DurationBox({
  anchorEl,
  setAnchorEl,
  num,
  user,
}: {
  anchorEl: any;
  setAnchorEl: any;
  num: any;
  user?: any;
}) {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  return (
    <ToolbarDurationBox
      onClick={(event: any) => {
        event.preventDefault();
        event.stopPropagation();
        handleClick(event);
      }}
      style={{
        padding: user?.compact ? "0px 4px 0px 4px" : "4px",
        marginLeft: "0px",
      }}
    >
      <StudyTypographySmallSubtitle style={{ marginLeft: "4px" }}>
        {num}
        {/* {getDuration(new Date(data.startTime), new Date(data.endTime))} */}
      </StudyTypographySmallSubtitle>
      <ArrowDropDownRounded style={{ padding: 0, margin: 0 }} />
    </ToolbarDurationBox>
  );
}

export default DurationBox;
