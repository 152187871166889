import { StudyTypographyMediumTitle } from "../../glovebox/StudyTypography";

function SessionMain() {
  return (
    <div>
      <StudyTypographyMediumTitle>SessionMain</StudyTypographyMediumTitle>{" "}
    </div>
  );
}

export default SessionMain;
