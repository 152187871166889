import { Badge, useTheme } from "@mui/material";
import { HoverPointerStyle } from "./HoverPointerStyle";
import { HOTKEYS } from "../../utils/hotkeyHelper";
import { useContext, useRef } from "react";
import { HotkeyContext } from "../../utils/contexts";
import { isMobile } from "react-device-detect";
import {
  STUDYO_PRIMARY_GRADIENT,
  STUDYO_SECONDARY_GRADIENT,
} from "../../utils/constants";

export function NotificationIcon(props: {
  active: boolean;
  clicked: Function;
  width?: string;
  height?: string;
}) {
  const theme = useTheme();

  const { showBadge } = useContext(HotkeyContext);
  return (
    <Badge
      badgeContent={HOTKEYS.OPEN_NOTIFICATION}
      color={"primary"}
      invisible={!showBadge || isMobile}
    >
      <HoverPointerStyle
        width={props?.width || "32"}
        height={props?.height || "32"}
        viewBox={`0 0 32 32`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => {
          props.clicked();
        }}
      >
        {props.active ? (
          <rect
            width={props?.width || "32"}
            height={props?.height || "32"}
            rx="4.125"
            fill="url(#paint0_linear_59_2302)"
          />
        ) : (
          <rect
            width={props?.width || "32"}
            height={props?.height || "32"}
            rx="4.125"
            fill={theme.palette.primary.light}
          />
        )}

        {/* <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="32"
          height="32"
          rx="4.125"
          fill={props.active ? "white" : "#777777"}
        /> */}
        <path
          d="M20.8022 12.8007C20.8022 11.5275 20.2964 10.3064 19.3961 9.40609C18.4958 8.50579 17.2747 8 16.0015 8C14.7282 8 13.5072 8.50579 12.6069 9.40609C11.7066 10.3064 11.2008 11.5275 11.2008 12.8007C11.2008 18.4015 8.80042 20.0018 8.80042 20.0018H23.2025C23.2025 20.0018 20.8022 18.4015 20.8022 12.8007Z"
          stroke={props.active ? "white" : "#777777"}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.3852 23.2031C17.2446 23.4456 17.0427 23.6469 16.7997 23.7868C16.5568 23.9268 16.2814 24.0004 16.001 24.0004C15.7207 24.0004 15.4453 23.9268 15.2023 23.7868C14.9594 23.6469 14.7575 23.4456 14.6168 23.2031"
          stroke={props.active ? "white" : "#777777"}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        {/* </svg> */}

        <defs>
          <linearGradient
            id="paint0_linear_59_2302"
            x1="0"
            y1="0"
            x2="32"
            y2="32"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={STUDYO_PRIMARY_GRADIENT} />
            <stop offset="1" stopColor={STUDYO_SECONDARY_GRADIENT} />
          </linearGradient>
        </defs>
      </HoverPointerStyle>
    </Badge>
  );
}
