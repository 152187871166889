/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from "@apollo/client";
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";
import { Backdrop, Box, CircularProgress, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GROUP_INFO } from "../../../graphql/queries/group";
import StyleGuide from "../../StyleGuide";
import AddIcon from "@mui/icons-material/Add";
import {
  MainbarCalendarScheduleTimeComponent,
  MainbarContainer,
} from "../styles";
import { CoworkContainer } from "./styles";
import { UserCalendar } from "./UserCalendar";
import { isMobile } from "react-device-detect";

// const todoIds = [
//   "9f8dae4e-ce92-4c3b-b310-6696a7087877",
//   userId,
// ];

const tasks = [
  {
    title: "Lunch break",
    color: "#EB426B",
    startTime: "11:30",
    duration: "30",
    pin: true,
  },
  {
    title: "Dinner break",
    color: "#EB426B",
    startTime: "9:30",
    duration: "120",
    pin: true,
  },
  {
    title: "Gym session",
    color: StyleGuide.Colors.success,
    startTime: "10:00",
    duration: "90",
    pin: true,
  },
  {
    title: "Bool w/ frands",
    color: StyleGuide.Colors.success,
    startTime: "9:00",
    duration: "120",
    pin: true,
  },

  {
    title: "Meeeee time",
    color: StyleGuide.Colors.success,
    startTime: "18:00",
    duration: "60",
    pin: true,
  },
];

const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 0.05,
    },
  },
};
const child = {
  hidden: {
    y: "100vw",
  },
  visible: {
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      mass: 0.3,
      // remove delay: 0.3,
    },
  },
};

export const CoworkMain = ({
  group,
  loading,
}: {
  group: any;
  loading: boolean;
}) => {
  // console.log(id);
  const [userIndex, setUserIndex] = useState(0);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")!) || []
  );
  // useEffect(() => {
  //   localStorage.setItem("user", JSON.stringify(user));
  // }, [user]);
  // console.log(data);
  // const [todos, setTodos] = useState<any>([]);
  const [todos, setTodos] = useState(
    JSON.parse(localStorage.getItem("todos")!) || []
  );
  // const { id: userId } = useUserContext();
  const userId = localStorage.getItem("userId");
  const filteredUsers = group?.users?.filter(
    (groupUser: any, index: any) =>
      groupUser !==
      group?.users[
        group?.users.findIndex((groupUser: any) => groupUser.id === user.id)
      ]
  );
  // const [getTodoData, { data, error }] = useLazyQuery(GET_USER_TODOS, {
  //   variables: {
  //     userId: userId,
  //   },
  //   onCompleted: (data) => {
  //     console.log(data);
  //     setTodos(data?.getUserTodos);
  //   },
  // });

  // useEffect(() => {
  //   localStorage.setItem("todos", JSON.stringify(todos));

  // getTodoData();
  // }, [todos]);
  // console.log(group)
  const theme = useTheme();
  const CalendarInput = ({ time, index }: { time: string; index: number }) => {
    return (
      <MainbarCalendarScheduleTimeComponent>
        <Box
          style={{
            border: "1px",
            backgroundColor: theme.palette.primary.light,
            width: "100%",
            height: "45px",
            paddingTop: "10px",
            paddingBottom: "10px",

            paddingLeft: "16px",
            outlineWidth: 0,
            borderRadius: "4px",
            color: theme.palette.text.primary,
          }}
        ></Box>
      </MainbarCalendarScheduleTimeComponent>
      // </motion.div>
    );
  };
  // console.log(group.users)
  return (
    <MainbarContainer>
      <CoworkContainer>
        <ChevronLeftRounded
          onClick={() => {
            if (userIndex > 0) {
              setUserIndex(userIndex - 1);
            }
          }}
          sx={{
            marginRight: "16px",
            width: "32px",
            height: "50px",
            borderRadius: "4px",
            color: theme.palette.primary.main,
          }}
        />
        <ChevronLeftRounded
          onClick={() => {
            if (userIndex > 0) {
              setUserIndex(userIndex - 1);
            }
          }}
          sx={{
            marginRight: "16px",
            width: "32px",
            height: "50px",
            borderRadius: "4px",
            color: userIndex > 0 ? "white" : theme.palette.primary.light,
            position: "absolute",
            top: "45%",

            "&:hover": {
              backgroundColor: theme.palette.primary.light,
              cursor: userIndex > 0 ? "pointer" : "no-drop",
            },
          }}
        />

        {/* {data?.getGroup?.users */}
        {user && group?.users && !isMobile ? (
          <UserCalendar
            user={
              group?.users[
                group?.users.findIndex(
                  (groupUser: any) => groupUser.id === user.id
                )
              ]
            }
          />
        ) : null}
        {filteredUsers && filteredUsers[userIndex] ? (
          <UserCalendar user={filteredUsers[userIndex]} />
        ) : null}
        <ChevronRightRounded
          onClick={() => {
            if (userIndex + 1 < filteredUsers?.length) {
              setUserIndex(userIndex + 1);
            }
          }}
          sx={{
            // marginLeft: "16px",
            width: "32px",
            height: "50px",
            borderRadius: "4px",
            color: theme.palette.primary.main,
          }}
        />
        <ChevronRightRounded
          onClick={() => {
            if (userIndex + 1 < filteredUsers?.length) {
              setUserIndex(userIndex + 1);
            }
          }}
          sx={{
            marginRight: "16px",
            width: "32px",
            height: "50px",
            borderRadius: "4px",
            color:
              userIndex + 1 < filteredUsers?.length
                ? "white"
                : theme.palette.primary.light,
            position: "absolute",
            right: "0px",
            top: "45%",

            "&:hover": {
              backgroundColor: theme.palette.primary.light,
              cursor:
                userIndex + 1 < filteredUsers?.length ? "pointer" : "no-drop",
            },
          }}
        />
      </CoworkContainer>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </MainbarContainer>
  );
};
