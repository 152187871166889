import { Draggable } from "@fullcalendar/interaction";
import {
  Add,
  DeleteOutlineOutlined,
  EditOutlined,
  RemoveRounded,
} from "@mui/icons-material";
import { Button, ClickAwayListener, Stack, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  UPDATE_CATEGORY_UUID,
  deleteTodoCategory,
} from "../../graphql/mutations/categories";
import { CREATE_TODO_WITH_CATEGORY } from "../../graphql/mutations/todo";
import { client } from "../../utils/apollo";
import { STAGGER_CONTAINER } from "../../utils/constants";
import { HoverTypography } from "../HoverTypography";
import { AddIcon } from "../Icons/AddIcon";
import { QuickAddBar } from "../glovebox/QuickAddBar";
import {
  StudyTypographyMediumSubtitle,
  StudyTypographySmallTitle,
} from "../glovebox/StudyTypography";
import {
  CustomInput,
  ProfileBlockPopper,
  ToolbarToDoCreateInput,
  ToolbarTodoContainer,
} from "../toolbar/styles";
import { ToDoBar } from "./ToDo";

const toolbarRightClickItems = [
  {
    name: "edit category",
    icon: <EditOutlined style={{ width: "20px" }} />,
    index: 0,
  },
  {
    name: "delete category",
    icon: <DeleteOutlineOutlined style={{ width: "20px" }} />,
    index: 1,
  },
];

export const ToolbarToDoContainer = ({
  // categoryTitle,
  handleDeleteToDo,
  // categoryTodos,
  // categoryUUID,
  category,
  categoryIndex,
  categories,
  setCategories,
  showInput,
  todos,
  setTodos,
  hideOldTodos,
}: {
  // categoryTitle: string;
  handleDeleteToDo: any;
  // categoryTodos: any;
  // categoryUUID: string;
  category: any;
  categoryIndex: number;
  categories: any[];
  setCategories: Function;
  showInput?: any;
  todos: any;
  setTodos: any;
  hideOldTodos: any;
}) => {
  const [showCompleted, setShowCompleted] = useState(false);

  const [editCategory, setEditCategory] = useState(false);
  const [editCategoryText, setEditCategoryText] = useState("");
  const [editCategoryLabel, setEditCategoryLabel] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const user = JSON.parse(localStorage.getItem("user")!);

  const [categoryAnchorEl, setCategoryAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const handleCategoryClick = (event: React.MouseEvent<HTMLElement>) => {
    setCategoryAnchorEl(categoryAnchorEl ? null : event.currentTarget);
  };
  const categoryOpen = Boolean(categoryAnchorEl);
  // console.log(todos)
  const categoryTodos = todos?.filter(function (todo: any) {
    return todo?.categoryId === category?.uuid;
  });
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("completed")) {
      setShowCompleted(true);
    } else {
      setShowCompleted(false);
    }
  }, [location]);
  // console.log(categories[categoryIndex].uuid, categoryTodos, categories)
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const [title, setTitle] = useState("");
  const todosLength = todos.filter(
    (item: any) =>
      item?.categoryId === category?.uuid &&
      item.completedAt === null &&
      item?.event === false
  ).length;
  const [showPinned, setShowPinned] = useState(true);
  const [showAddTodo, setShowAddTodo] = useState(false);

  // const [showPinned, setShowPinned] = useState(todosLength > 20 ? false : true);
  // console.log(
  //   categories[categories.findIndex((item) => item?.uuid === category?.uuid)]
  // );
  // const [categories, setCategories] = useState(JSON.parse(localStorage.getItem('categories')!) || [])
  // console.log(JSON.parse(localStorage.getItem('todos')!))
  // const [todos, setTodos] = useState(
  //   JSON.parse(localStorage.getItem("todos")!) || []
  // );
  // const { id: userId } = useUserContext();
  const userId = localStorage.getItem("userId");
  const inputRef = useRef<any>(null);
  // console.log(todos)
  // console.log(category)
  // console.log(categoryTitle, categoryUUID)

  // useEffect(() => {
  //   getTodoData();
  // }, []);
  // const [getTodoData, { data, loading, error }] = useLazyQuery(GET_USER_TODOS, {
  //   variables: {
  //     userId: userId,
  //   },
  //   onCompleted: (data) => {
  //     localStorage.setItem("todos", JSON.stringify(data?.getUserTodos));
  //     setTodos(data?.getUserTodos);
  //   },
  // });

  useEffect(() => {
    localStorage.setItem("categories", JSON.stringify(categories));
  }, [categories]);

  useEffect(() => {
    // console.log(todos);
    localStorage.setItem("todos", JSON.stringify(todos));
    // console.log(JSON.parse(localStorage.getItem("todos")!));
  }, [todos]);

  // const [createTodo, { data: ctdata }] = useMutation(CREATE_TO_DO, {
  //   variables: {
  //     title,
  //     duration: 30,
  //     userId: userId,
  //     dueAt: moment().add(2, "day").toISOString(),
  //     uuid: uuid(),
  //   },
  // });
  // const [createTodoCategory, { data: catdata }] = useMutation(CREATE_TODO_WITH_CATEGORY, {
  //   variables: {
  //     title,
  //     duration: 30,
  //     userId: userId,
  //     dueAt: moment().add(2, "day").toISOString(),
  //     uuid: uuid(),
  //     category: categoryTitle,
  //     categoryId: categoryUUID,
  //   },
  // });

  const deleteTodoCategoryHelper = async (categoryId: any) => {
    if (categoryId !== user.baseCategoryId) {
      await deleteTodoCategory(
        categoryId,
        user.baseCategoryId,
        categories,
        setCategories,
        todos,
        setTodos
      );
    } else {
      console.log("Cannot delete that category! That is your base category.");
    }
  };

  const createTodoCategory = () => {
    if (title) {
      let updatedCategories = [...categories];
      let todoUUID = uuid();
      let newTodo = {
        categoryId: category.uuid,
        completedAt: null,
        createdAt: null,
        dueAt: null,
        duration: 30,
        todoReplicas: [],
        id: null,
        startTime: null,
        event: false,
        title: title,
        uuid: todoUUID,
        __typename: "Todo",
      };
      let newTodos = [newTodo, ...todos];
      // updatedCategories[categoryIndex].todos =
      //   updatedCategories[categoryIndex].todos !== undefined
      //     ? [...updatedCategories[categoryIndex].todos, newTodo]
      //     : [newTodo];
      // setCategories(updatedCategories);
      setTodos(newTodos);
      client.mutate({
        mutation: CREATE_TODO_WITH_CATEGORY,
        variables: {
          title,
          duration: 30,
          userId: userId,
          dueAt: null,
          uuid: todoUUID,
          event: false,
          category: category?.name,
          categoryId: category?.uuid,
          categoryLabel: category?.label,
        },
      });
    }
  };
  const theme = useTheme();

  useEffect(() => {
    let draggableEl = document.getElementById(
      `external-events-${categoryIndex}`
    );

    // console.log(draggableEl);
    //@ts-ignore
    new Draggable(draggableEl, {
      itemSelector: ".fc-event",
      eventData: function (eventEl) {
        // console.log("I AM THE EVENT ", eventEl.getAttribute("id"));
        let id = eventEl.getAttribute("id");
        // console.log(id);
        return {
          id: id,
        };
      },
    });
  }, []);

  const updateCategory = (categoryId: string) => {
    // console.log(newCategoryName)
    // console.log(categories)
    let updatedCategories = [...categories];
    let categoryInd = updatedCategories.findIndex(
      (holdCategory) => holdCategory.uuid === category.uuid
    );
    let updatedCategory = {
      ...updatedCategories[categoryInd],
    };
    updatedCategory.name = editCategoryText;
    updatedCategories[categoryInd] = updatedCategory;
    setCategories(updatedCategories);
    client
      .mutate({
        mutation: UPDATE_CATEGORY_UUID,
        variables: {
          name: editCategoryText,
          uuid: category.uuid,
        },
      })
      .then(() => {
        // setCategoryName('');
      });
  };
  const updateCategoryLabel = (categoryId: string, color: string) => {
    // console.log(newCategoryName)
    // console.log(categories)
    let updatedCategories = [...categories];
    let categoryInd = updatedCategories.findIndex(
      (holdCategory) => holdCategory.uuid === category.uuid
    );
    let updatedCategory = { ...updatedCategories[categoryInd] };
    updatedCategory.label = color;
    updatedCategories[categoryInd] = updatedCategory;
    setCategories(updatedCategories);
    client
      .mutate({
        mutation: UPDATE_CATEGORY_UUID,
        variables: {
          uuid: categoryId,
          label: color,
        },
      })
      .then(() => {
        // setCategoryName('');
      });
  };
  // const updateTodoCategory = (categoryId : string, oldCategory: boolean) => {
  //   let updatedCategories = [...categories]
  //   // updatedCategories[categoryIndex].name = editCategory
  //   setCategories(updatedCategories)
  //   client.mutate({
  //     mutation: UPDATE_CATEGORY_UUID,
  //     variables: {
  //       name: editCategoryText,
  //       uuid: updatedCategories[categoryIndex].uuid,
  //       oldCategoryId: oldCategory ? 'true' : null,
  //     }
  //   }).then(() => {
  //     // setCategoryName('');
  //   });
  // }

  const handleRightClick = (event: any) => {
    event.preventDefault();
    setAnchorEl(anchorEl ? null : event.currentTarget);
    // Trigger your custom action here
  };

  // const deleteTodo = (todoId: string) => {
  //   let updatedCategories = [...categories]
  //   updatedCategories[categoryIndex].todos = updatedCategories[categoryIndex].todos !== undefined ? [...updatedCategories[categoryIndex].todos, newTodo] : [newTodo];
  //   setCategories(updatedCategories);
  //   setTodos(newTodos);
  //   console.log(newTodos);
  //   client.mutate({
  //     mutation: UPDATE_TODO_UUID,
  //     variables: {
  //       uuid: todoId,

  //     }
  //   })
  // }
  // console.log(categories[categoryIndex].todos)

  // useEffect(() => {
  //   let draggableEl = document.getElementById("external-events");
  //   //@ts-ignore
  //   new Draggable(draggableEl, {
  //     itemSelector: ".fc-event",
  //     eventData: function (eventEl: any) {
  //       // console.log("I AM THE EVENT ", eventEl.getAttribute("id"));
  //       let id = eventEl.getAttribute("id");
  //       // console.log(id);
  //       return {
  //         id: id,
  //       };
  //     },
  //   });
  // }, []);

  return (
    <ToolbarTodoContainer>
      <motion.div
        transition={{ type: "spring", stiffness: 100, mass: 0.3, duration: 2 }}
        style={{
          borderRadius: "5px",
          padding: "12px",
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flex: "0 0 30vw",
          marginBottom: "4px",
          marginLeft: "0px",
          paddingLeft: open ? "12px" : "0px",
          paddingRight: open ? "12px" : "0px",
          backgroundColor: theme.palette.primary.main,
          border: open ? `2px solid ${theme.palette.text.primary}` : "0px",
        }}
        // onClick={() => {
        //   setShowPinned(!showPinned);
        // }}
        onContextMenu={handleRightClick}
      >
        {editCategory || category?.name === "" || category === null ? (
          <ClickAwayListener
            onClickAway={() => {
              setEditCategory(false);
            }}
          >
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
              width="100%"
              flex={1}
            >
              <div
                style={{
                  width: "5px",
                  height: "20px",
                  marginRight: "12px",
                  borderRadius: "4px",
                  backgroundColor:
                    category?.label === "Google Calendar"
                      ? "white"
                      : category?.label,
                }}
              />
              <form
                onSubmit={() => {
                  setEditCategory(false);
                  updateCategory(category?.uuid);
                }}
                style={{ width: "100%" }}
              >
                <input
                  autoFocus
                  style={{
                    border: "0px",
                    backgroundColor: theme.palette.primary.main,
                    width: "100%",
                    height: "30px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    outlineWidth: 0,
                    borderRadius: "4px",
                    color: theme.palette.text.primary,
                  }}
                  placeholder={"add category name.."}
                  type={"text"}
                  name="title"
                  value={editCategoryText}
                  onChange={(text) => {
                    setEditCategoryText(text.target.value);
                    console.log(categoryIndex);
                    console.log(category);
                  }}
                  onClick={(event: any) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                ></input>
              </form>

              <Button
                variant="text"
                color="secondary"
                size="small"
                sx={{
                  textTransform: "none",
                  marginLeft: "12px",
                  backgroundColor: theme.palette.primary.light,
                }}
                onClick={() => {
                  setEditCategory(false);
                  updateCategory(category?.uuid);
                }}
              >
                save
              </Button>
            </Stack>
          </ClickAwayListener>
        ) : (
          <Stack
            flexDirection="row"
            alignItems="space-between"
            justifyContent={"space-between"}
            width="100%"
          >
            <Stack
              flexDirection="row"
              alignItems={"center"}
              justifyContent="center"
            >
              <div
                style={{
                  width: "5px",
                  height: "20px",
                  marginRight: "12px",
                  borderRadius: "4px",
                  backgroundColor:
                    category?.label === "Google Calendar"
                      ? "white"
                      : category?.label,
                }}
              />
              <StudyTypographySmallTitle
                onClick={(event: any) => {
                  setEditCategory(true);
                  setEditCategoryText(category?.name);
                  console.log(category);
                  event?.stopPropagation();
                }}
                style={{ marginRight: "12px" }}
              >
                {category?.name}
              </StudyTypographySmallTitle>
              <StudyTypographySmallTitle style={{ marginRight: "12px" }}>
                {`-`}
              </StudyTypographySmallTitle>
              <StudyTypographySmallTitle style={{ marginRight: "12px" }}>
                {todosLength}
              </StudyTypographySmallTitle>
            </Stack>
            <Stack flexDirection="row" alignItems={"center"}>
              {!showAddTodo ? (
                <Add
                  sx={{
                    width: "18px",
                    height: "18px",
                    padding: "4px",
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.light,
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setShowAddTodo(true);
                  }}
                />
              ) : (
                <RemoveRounded
                  sx={{
                    width: "18px",
                    height: "18px",
                    padding: "4px",
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.light,
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setShowAddTodo(false);
                  }}
                />
              )}

              {/* {showPinned ? <ArrowDropDownRounded /> : <ArrowDropUpRounded />} */}
            </Stack>
          </Stack>
        )}
      </motion.div>
      <ClickAwayListener
        onClickAway={() => {
          setAnchorEl(null);
        }}
      >
        <ProfileBlockPopper
          open={open}
          anchorEl={anchorEl}
          placement={"bottom-end"}
          modifiers={[{ name: "arrow", enabled: true }]}
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            paddingTop: "8px",
          }}
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          <div
            style={{
              alignItems: "center",
              display: "inline-grid",
              justifyContent: "center",
              padding: "8px",
              borderRadius: "4px",
              backgroundColor: theme.palette.primary.main,
              border: `1px solid ${theme.palette.secondary.light}`,
            }}
          >
            {toolbarRightClickItems.map((item) => {
              return (
                <HoverTypography
                  key={item.name}
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    paddingBottom: "4px",
                    borderRadius: "4px",
                    color:
                      item.name === "delete category"
                        ? theme.palette.error.dark
                        : theme.palette.text.primary,
                  }}
                  onClick={() => {
                    setAnchorEl(null);
                    if (item.name === "edit category") {
                      setEditCategory(true);
                      setEditCategoryText(item?.name);
                    } else if (item.name === "delete category") {
                      deleteTodoCategoryHelper(category.uuid);
                    }
                  }}
                >
                  {item.icon}
                  <StudyTypographyMediumSubtitle
                    style={{
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      borderRadius: "4px",
                      paddingLeft: "8px",
                      color:
                        item.name === "delete category"
                          ? theme.palette.error.dark
                          : theme.palette.text.primary,
                    }}
                  >
                    {item.name}
                  </StudyTypographyMediumSubtitle>
                </HoverTypography>
              );
            })}
          </div>
        </ProfileBlockPopper>
      </ClickAwayListener>
      {showAddTodo ? (
        <QuickAddBar
          category={category}
          todos={todos}
          setTodos={setTodos}
          setShowAddTodo={setShowAddTodo}
          // updateTodoList={updateTodoList}
        />
      ) : null}
      {showPinned ? (
        <div>
          <Fragment>
            {categories[categoryIndex] && (
              <motion.div
                variants={STAGGER_CONTAINER}
                initial="hidden"
                animate="visible"
                id={`external-events-${categoryIndex}`}
              >
                {categoryTodos
                  ?.filter((todo: any) =>
                    showCompleted
                      ? true
                      : hideOldTodos
                      ? todo?.completedAt === null &&
                        moment(todo?.createdAt).toISOString() >=
                          moment(new Date())
                            .subtract(1, "week")
                            .toISOString() &&
                        todo?.event === false
                      : todo?.completedAt === null && todo?.event === false
                  )
                  .map((task: any, index: number) => {
                    return (
                      <ToDoBar
                        data={task}
                        key={index}
                        index={index}
                        toolbar
                        handleDeleteToDo={handleDeleteToDo}
                        todos={todos}
                        setTodos={setTodos}
                        // updateTodoList={updateTodoList}
                      />
                    );
                  })}
              </motion.div>
            )}
          </Fragment>
          {showInput ? (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                var newTodos = [
                  {
                    title,
                    duration: 30,
                    userId: userId,
                    dueAt: null,
                  },
                  ...todos,
                ];
                localStorage.setItem("todos", JSON.stringify(newTodos));
                setTodos(newTodos);
                // createTodo();
                createTodoCategory();
                setTitle("");
              }}
            >
              <ToolbarToDoCreateInput style={{ marginBottom: "10px" }}>
                <CustomInput
                  ref={inputRef}
                  aria-label="Demo input"
                  placeholder="Type something…"
                  onChange={(text) => {
                    setTitle(text.currentTarget.value);
                  }}
                  value={title}
                />
                <AddIcon
                  clicked={() => {
                    var newTodos = [
                      {
                        title,
                        duration: 30,
                        userId: userId,
                      },
                      ...todos,
                    ];
                    setTodos(newTodos);
                    // createTodo();
                    createTodoCategory();
                    setTitle("");
                  }}
                />
              </ToolbarToDoCreateInput>
            </form>
          ) : null}
        </div>
      ) : null}
    </ToolbarTodoContainer>
  );
};
