// material
import { Card, Container, Link, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
// layouts
import { useState } from "react";
import { isMobile } from "react-device-detect";
// components
import { Link as RouterLink, useLocation } from "react-router-dom";
import Logo from "../assets/EmptyLogo";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";
import { RegisterForm } from "../components/authentication/register";
import { HorizontalContainer } from "../components/glovebox/HorizontalContainer";
import {
  StudyTypographyMediumSubtitle,
  StudyTypographyMega,
} from "../components/glovebox/StudyTypography";
import { AddFriendsIcon } from "../components/Icons/AddFriendsIcon";
import { CalendarIcon } from "../components/Icons/CalendarIcon";
import { SettingsIcon } from "../components/Icons/SettingsIcon";
import Page from "../components/Page";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  flex: 1,
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: "480px",
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  height: "97%",
  justifyContent: "center",
  backgroundColor: theme.palette.primary.light,
  alignItems: "center",
  margin: theme.spacing(2, 2, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  width: "70%",
  margin: "auto",
  display: "flex",
  height: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  // paddingTop: "96px",
  // paddingBottom: "96px",
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 9,
  height: "75px",
  width: "100%",
  display: "flex",
  alignSelf: "center",
  alignItems: "center",
  position: "absolute",
  textAlign: "right",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

// ----------------------------------------------------------------------

export default function Login() {
  const location = useLocation();
  const [form, setForm] = useState(location.pathname.split("/")[1]);

  const handleFormChange = () => {
    setForm(form === "login" ? "register" : "login");
  };

  return (
    //@ts-ignore
    <RootStyle title="Social Passport | Login">
      <HeaderStyle>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </HeaderStyle>

      <MHidden width="mdDown">
        <SectionStyle>
          <div
            style={{
              // width: "100%",
              alignItems: "flex-start",
              flexDirection: "column",
              display: "inline-flex",
              margin: "32px",
            }}
          >
            <StudyTypographyMega sx={{ mb: 5 }}>
              The future of productivity
            </StudyTypographyMega>
            <HorizontalContainer marginBottom={"12px"}>
              <CalendarIcon active={true} clicked={() => {}} />
              <StudyTypographyMediumSubtitle sx={{ paddingLeft: 2 }}>
                Drag and drop to build daily schedule
              </StudyTypographyMediumSubtitle>
            </HorizontalContainer>
            <HorizontalContainer marginBottom={"12px"}>
              <AddFriendsIcon active={true} clicked={() => {}} />
              <StudyTypographyMediumSubtitle sx={{ paddingLeft: 2 }}>
                Invite friends to cowork
              </StudyTypographyMediumSubtitle>
            </HorizontalContainer>
            <HorizontalContainer marginBottom={"12px"}>
              <SettingsIcon active={true} clicked={() => {}} />
              <StudyTypographyMediumSubtitle sx={{ paddingLeft: 2 }}>
                Google calendar and spotify integrations for the vibez
              </StudyTypographyMediumSubtitle>
            </HorizontalContainer>
          </div>

          <img
            style={{ marginTop: "36px" }}
            src={require("../assets/RoomStudyingTransparent.png")}
            alt="login"
            width="75%"
          />
        </SectionStyle>
      </MHidden>
      <Container maxWidth="lg">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <StudyTypographyMega>
              Sign in to get productive!
            </StudyTypographyMega>
            <StudyTypographyMediumSubtitle sx={{ color: "text.secondary" }}>
              Enter your details below.
            </StudyTypographyMediumSubtitle>
          </Stack>
          {/* <AuthSocial /> */}

          {form === "login" ? (
            //@ts-ignore
            <LoginForm handleFormChange={handleFormChange} />
          ) : (
            //@ts-ignore
            <RegisterForm handleFormChange={handleFormChange} />
          )}
          {isMobile ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <Link
                variant="button"
                sx={{ margin: 0, padding: 0, marginTop: "30px" }}
                onClick={() => {
                  if (form === "login") {
                    setForm("register");
                  } else {
                    setForm("login");
                  }
                }}
              >
                {form === "login" ? "create new account?" : "login"}
              </Link>
            </div>
          ) : null}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
