import {
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  CalendarTodayOutlined,
  DeleteOutlineOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { Button, ClickAwayListener, Stack, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { Fragment, useEffect, useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import {
  UPDATE_CATEGORY_UUID,
  deleteTodoCategory,
} from "../../../graphql/mutations/categories";
import { createTodo } from "../../../graphql/mutations/todo";
import { client } from "../../../utils/apollo";
import { ARRAY_OF_COLORS, STAGGER_CONTAINER } from "../../../utils/constants";
import { HoverTypography } from "../../HoverTypography";
import { AddIcon } from "../../Icons/AddIcon";
import { EditToDoModal } from "../../glovebox/EditToDoModal";
import {
  StudyTypographyMediumSubtitle,
  StudyTypographySmallTitle,
} from "../../glovebox/StudyTypography";
import {
  CustomInput,
  ProfileBlockPopper,
  ToolbarToDoCreateInput,
} from "../../toolbar/styles";
import { TodoBar2 } from "./TodoBar2";
import { CustomBox, CustomColor, TodoContainer } from "./styles";
// import EmojiPicker from "emoji-picker-react";

const toolbarRightClickItems = [
  {
    name: "edit category",
    icon: <EditOutlined style={{ width: "20px" }} />,
    index: 0,
  },
  {
    name: "delete category",
    icon: <DeleteOutlineOutlined style={{ width: "20px" }} />,
    index: 1,
  },
];

export const ToDoContainer = ({
  // categoryTitle,
  handleDeleteToDo,
  // categoryTodos,
  // categoryUUID,
  category,
  categoryIndex,
  categories,
  setCategories,
  showInput,
  todos,
  setTodos,
}: {
  // categoryTitle: string;
  handleDeleteToDo: any;
  // categoryTodos: any;
  // categoryUUID: string;
  category: any;
  categoryIndex: number;
  categories: any[];
  setCategories: Function;
  showInput?: any;
  todos: any;
  setTodos: any;
}) => {
  const [showCompleted, setShowCompleted] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any>();
  const [editTodo, setEditTodo] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [editCategoryText, setEditCategoryText] = useState("");
  const [editCategoryLabel, setEditCategoryLabel] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const user = JSON.parse(localStorage.getItem("user")!);
  const [duration, setDuration] = useState(30);

  const [categoryAnchorEl, setCategoryAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const handleCategoryClick = (event: React.MouseEvent<HTMLElement>) => {
    setCategoryAnchorEl(categoryAnchorEl ? null : event.currentTarget);
  };
  const categoryOpen = Boolean(categoryAnchorEl);
  const [categoryTodos, setCategoryTodos] = useState(
    todos?.filter(function (todo: any) {
      return todo?.categoryId === categories[categoryIndex].uuid;
    })
  );
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("completed")) {
      setShowCompleted(true);
    } else {
      setShowCompleted(false);
    }
  }, [location]);
  // console.log(categories[categoryIndex].uuid, categoryTodos, categories)
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const [title, setTitle] = useState("");
  const [showPinned, setShowPinned] = useState(isMobile ? false : true);
  // const [categories, setCategories] = useState(JSON.parse(localStorage.getItem('categories')!) || [])
  const userId = localStorage.getItem("userId");
  const inputRef = useRef<any>(null);

  useEffect(() => {
    localStorage.setItem("categories", JSON.stringify(categories));
  }, [categories]);

  useEffect(() => {
    localStorage.setItem("todos", JSON.stringify(todos));
    setCategoryTodos(
      todos?.filter(function (todo: any) {
        return todo?.categoryId === categories[categoryIndex].uuid;
      })
    );
  }, [todos]);

  const deleteTodoCategoryHelper = async (categoryId: any) => {
    if (categoryId !== user.baseCategoryId) {
      await deleteTodoCategory(
        categoryId,
        user.baseCategoryId,
        categories,
        setCategories,
        todos,
        setTodos
      );
    } else {
      console.log("Cannot delete that category! That is your base category.");
    }
  };

  const theme = useTheme();

  const updateCategory = (categoryId: string) => {
    // console.log(newCategoryName)
    // console.log(categories)
    let updatedCategories = [...categories];
    let updatedCategory = { ...updatedCategories[categoryIndex] };
    updatedCategory.name = editCategoryText;
    updatedCategories[categoryIndex] = updatedCategory;
    setCategories(updatedCategories);
    client
      .mutate({
        mutation: UPDATE_CATEGORY_UUID,
        variables: {
          name: editCategoryText,
          uuid: updatedCategories[categoryIndex].uuid,
        },
      })
      .then(() => {
        // setCategoryName('');
      });
  };
  const updateCategoryLabel = (categoryId: string, color: string) => {
    // console.log(newCategoryName)
    // console.log(categories)
    let updatedCategories = [...categories];
    let updatedCategory = { ...updatedCategories[categoryIndex] };
    updatedCategory.label = color;
    updatedCategories[categoryIndex] = updatedCategory;
    setCategories(updatedCategories);
    client
      .mutate({
        mutation: UPDATE_CATEGORY_UUID,
        variables: {
          uuid: categoryId,
          label: color,
        },
      })
      .then(() => {
        // setCategoryName('');
      });
  };

  const handleRightClick = (event: any) => {
    event.preventDefault();
    setAnchorEl(anchorEl ? null : event.currentTarget);
    // Trigger your custom action here
  };
  return (
    <TodoContainer>
      <ToolbarToDoCreateInput
        style={{
          marginBottom: "10px",
          border: open ? `2px solid ${theme.palette.text.primary}` : "0px",
        }}
        onClick={() => {
          setShowPinned(!showPinned);
        }}
        onContextMenu={handleRightClick}
      >
        {editCategory || category?.name === "" || category === null ? (
          <ClickAwayListener
            onClickAway={() => {
              setEditCategory(false);
            }}
          >
            <form
              onSubmit={() => {
                setEditCategory(false);
                updateCategory(category?.uuid);
              }}
              style={{ width: "100%" }}
            >
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
                width="100%"
                flex={1}
              >
                <input
                  autoFocus
                  style={{
                    border: "0px",
                    backgroundColor: theme.palette.primary.light,
                    width: "100%",
                    height: "30px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    outlineWidth: 0,
                    borderRadius: "4px",
                    color: theme.palette.text.primary,
                  }}
                  placeholder={"add category name.."}
                  type={"text"}
                  name="title"
                  value={editCategoryText}
                  onChange={(text) => {
                    setEditCategoryText(text.target.value);
                  }}
                ></input>
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  sx={{
                    textTransform: "none",
                    marginLeft: "12px",
                    padding: "4px",
                    backgroundColor: theme.palette.secondary.light,
                  }}
                  onClick={() => {
                    setEditCategory(false);
                    updateCategory(category?.uuid);
                  }}
                >
                  save
                </Button>
              </Stack>
            </form>
          </ClickAwayListener>
        ) : (
          <>
            <>
              {category.label === "Google Calendar" ? (
                <div onClick={handleCategoryClick}>
                  <CalendarTodayOutlined
                    style={{
                      padding: 0,
                      margin: 0,
                      width: "18px",
                      height: "18px",
                    }}
                  />
                </div>
              ) : (
                <div
                  onClick={(event: any) => {
                    handleCategoryClick(event);
                    event.stopPropagation();
                  }}
                  style={{
                    width: "14px",
                    height: "14px",
                    border: `2px solid ${category?.label}`,
                    borderRadius: "4px",
                  }}
                ></div>
              )}
              <ClickAwayListener
                onClickAway={() => {
                  setCategoryAnchorEl(null);
                }}
              >
                <ProfileBlockPopper
                  open={categoryOpen}
                  anchorEl={categoryAnchorEl}
                  placement={"bottom-start"}
                  modifiers={[{ name: "arrow", enabled: true }]}
                  style={{
                    backgroundColor: "rgba(0,0,0,0)",
                    margin: 0,
                    paddingRight: 0,
                    paddingTop: "8px",
                    zIndex: 100,
                  }}
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                >
                  <CustomBox
                    sx={{
                      flexGrow: 1,
                      backgroundColor: theme.palette.primary.main,
                      border: `1px solid ${theme.palette.secondary.light}`,
                      borderRadius: "4px",
                    }}
                  >
                    {ARRAY_OF_COLORS.map((color: any) => (
                      <CustomColor>
                        <div
                          onClick={(event: any) => {
                            event.stopPropagation();
                            event.preventDefault();

                            updateCategoryLabel(category?.uuid, color);
                            handleCategoryClick(event);
                          }}
                          style={{
                            width: "18px",
                            height: "18px",
                            backgroundColor: color,
                            borderRadius: "4px",
                            margin: "4px",
                          }}
                        ></div>
                      </CustomColor>
                    ))}
                    {/* <EmojiPicker /> */}
                  </CustomBox>
                </ProfileBlockPopper>
              </ClickAwayListener>
              <StudyTypographySmallTitle
                onClick={(event: any) => {
                  event.stopPropagation();
                  setEditCategory(true);
                  setEditCategoryText(category?.name);
                }}
              >
                {category?.name}
              </StudyTypographySmallTitle>
            </>
            {showPinned ? <ArrowDropDownRounded /> : <ArrowDropUpRounded />}
          </>
        )}
      </ToolbarToDoCreateInput>
      <ClickAwayListener
        onClickAway={() => {
          setAnchorEl(null);
        }}
      >
        <ProfileBlockPopper
          open={open}
          anchorEl={anchorEl}
          placement={"bottom-start"}
          modifiers={[{ name: "arrow", enabled: true }]}
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            paddingTop: "8px",
          }}
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          <div
            style={{
              alignItems: "center",
              display: "inline-grid",
              justifyContent: "center",
              backgroundColor: theme.palette.primary.main,
              border: `1px solid ${theme.palette.secondary.light}`,
              padding: "8px",
              borderRadius: "4px",
            }}
          >
            {toolbarRightClickItems.map((item) => {
              return (
                <HoverTypography
                  key={item.name}
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    paddingBottom: "4px",
                    borderRadius: "4px",
                    color:
                      item.name === "delete category"
                        ? theme.palette.error.dark
                        : theme.palette.text.primary,
                  }}
                  onClick={() => {
                    setAnchorEl(null);
                    if (item.name === "edit category") {
                      setEditCategory(true);
                      setEditCategoryText(categories[categoryIndex]?.name);
                    } else if (item.name === "delete category") {
                      deleteTodoCategoryHelper(category.uuid);
                    }
                  }}
                >
                  {item.icon}
                  <StudyTypographyMediumSubtitle
                    style={{
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      borderRadius: "4px",
                      paddingLeft: "8px",
                      color:
                        item.name === "delete category"
                          ? theme.palette.error.dark
                          : theme.palette.text.primary,
                    }}
                  >
                    {item.name}
                  </StudyTypographyMediumSubtitle>
                </HoverTypography>
              );
            })}
          </div>
        </ProfileBlockPopper>
      </ClickAwayListener>
      {showPinned ? (
        <div>
          <Fragment>
            {categories[categoryIndex] && (
              <motion.div
                variants={STAGGER_CONTAINER}
                initial="hidden"
                animate="visible"
              >
                {categoryTodos
                  ?.filter(
                    (todo: any) =>
                      showCompleted
                        ? todo?.deletedAt === null && todo?.event === false
                        : (todo?.completedAt === null ||
                            todo?.completedAt === undefined) &&
                          (todo?.deletedAt === null ||
                            todo?.deletedAt === undefined) &&
                          (todo?.event === false || todo?.event === undefined)
                    // &&
                    //   moment(todo?.createdAt).toISOString() >
                    //     moment(new Date()).startOf("week").toISOString()
                  )
                  .map((task: any, index: number) => {
                    // console.log(categories[categoryIndex]);
                    return (
                      // <motion.div variants={STAGGER_DRAG_CHILDREN}>
                      <Draggable
                        key={task?.uuid}
                        draggableId={task?.uuid}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={() => {
                              setSelectedTask(task);
                              setEditTodo(true);
                            }}
                          >
                            <TodoBar2
                              data={task}
                              key={task?.uuid}
                              index={index}
                              toolbar
                              handleDeleteToDo={handleDeleteToDo}
                              todos={todos}
                              setTodos={setTodos}
                              // updateTodoList={updateTodoList}
                            />
                            {selectedTask ? (
                              <EditToDoModal
                                todo={selectedTask}
                                todos={todos}
                                setTodos={setTodos}
                                openEdit={
                                  editTodo && selectedTask?.uuid === task?.uuid
                                }
                                setOpenEditTodo={setEditTodo}
                                title={selectedTask?.title}
                              />
                            ) : null}
                          </div>
                        )}
                      </Draggable>
                      // </motion.div>
                    );
                  })}
              </motion.div>
            )}
          </Fragment>
          {showInput ? (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                var newTodos = [
                  {
                    title,
                    duration: 30,
                    userId: userId,
                    dueAt: null,
                  },
                  ...todos,
                ];
                localStorage.setItem("todos", JSON.stringify(newTodos));
                setTodos(newTodos);
                // createTodo();
                createTodo(
                  category.uuid,
                  title,
                  duration,
                  null,
                  null,
                  false,
                  userId,
                  todos,
                  setTodos
                );
                setTitle("");
              }}
            >
              <ToolbarToDoCreateInput style={{ marginBottom: "10px" }}>
                <CustomInput
                  ref={inputRef}
                  aria-label="Demo input"
                  placeholder="Add new todo..."
                  onChange={(text) => {
                    setTitle(text.currentTarget.value);
                  }}
                  value={title}
                />
                <AddIcon
                  clicked={() => {
                    var newTodos = [
                      {
                        title,
                        duration: 30,
                        userId: userId,
                      },
                      ...todos,
                    ];
                    setTodos(newTodos);
                    createTodo(
                      category.uuid,
                      title,
                      duration,
                      null,
                      null,
                      false,
                      userId,
                      todos,
                      setTodos
                    );
                    setTitle("");
                  }}
                />
              </ToolbarToDoCreateInput>
            </form>
          ) : null}
        </div>
      ) : null}
    </TodoContainer>
  );
};
