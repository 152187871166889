import { CloseRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { alpha, Badge, Box, Grid, Tooltip, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDrop } from "react-dnd";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  CREATE_GROUP,
  DELETE_GROUP,
  leaveGroup,
} from "../../../graphql/mutations/group";
import {
  groupRequest,
  LEAVE_GROUP,
  USER_GROUP_INVITE,
} from "../../../graphql/mutations/user";
import { client } from "../../../utils/apollo";
import { random_rgba } from "../../../utils/constants";
import { ItemTypes } from "../../../utils/types";
import {
  StudyTypographyMediumTitle,
  StudyTypographyMegaSubtitle,
  StudyTypographyMegaTitle,
  StudyTypographySmallTitle,
} from "../../glovebox/StudyTypography";
import { AddIcon } from "../../Icons/AddIcon";
import { PeopleIcon } from "../../Icons/PeopleIcon";
import {
  CustomInput,
  ToolbarContainer,
  ToolbarToDoCreateInput,
} from "../../toolbar/styles";
import { SmallCoworkGroup } from "../cowork/SmallCoworkGroup";
import { AddFriendsToGroupBox, ContentIcon } from "./styles";

const commonStyles = {
  borderStyle: "dashed",
  m: 2,
  border: 2,
  width: "300px",
  height: "450px",
  alignItems: "center",
  justify: "center",
};

export const FriendsMain = ({
  user,
  setUser,
  newGroup,
  groupInvites,
  setGroupInvites,
  removeFriend,
}: {
  user: any;
  setUser: any;
  newGroup: any;
  groupInvites: any;
  setGroupInvites: any;
  removeFriend: any;
}) => {
  const [showGroups, setShowGroups] = useState(isMobile ? false : true);
  const [groups, setGroups] = useState(user.groups ? user.groups : []);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  // const [newGroup, setNewGroup] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [name, setName] = useState("");
  const [group, setGroup] = useState(newGroup);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const inputRef = useRef<any>(null);

  const [recievedAnchorEl, setRecievedAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const recievedOpen = Boolean(recievedAnchorEl);

  const handleRecievedClick = (event: React.MouseEvent<HTMLElement>) => {
    setRecievedAnchorEl(recievedAnchorEl ? null : event.currentTarget);
  };

  // useEffect(() => {
  //   localStorage.setItem("groupInvites", JSON.stringify(groupInvites));
  // }, [groupInvites]);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.BOX,
    drop: () => ({ name: "Dustbin" }),
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));
  const theme = useTheme();

  // useEffect(() => {
  //   localStorage.setItem("user",JSON.stringify(user))
  // })

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  const isActive = canDrop && isOver;
  let backgroundColor = theme.palette.primary.main;
  if (isActive) {
    backgroundColor = theme.palette.success.dark;
  } else if (canDrop) {
    backgroundColor = "darkkhaki";
  }
  // console.log("I SHOULD BE SHOWING ALL THE PEOPLE", group);
  const leaveGroupHelper = async (groupUUID: any) => {
    await leaveGroup(groupUUID, user, setUser);
  };

  const groupRequestHelper = async (group: any, accept: boolean) => {
    await groupRequest(
      group,
      accept,
      user,
      setUser,
      groupInvites,
      setGroupInvites
    );
  };

  const createRoom = () => {
    setLoading(true);
    let groupUUID = uuid();
    let newGroup = {
      id: null,
      name: title,
      uuid: groupUUID,
      users: [{ __typeName: "User", id: user.id }],
      __typename: "Group",
    };
    let newUser = { ...user };
    let newGroups = [newGroup, ...newUser.groups];
    newUser.groups = newGroups;
    setUser(newUser);

    client
      .mutate({
        mutation: CREATE_GROUP,
        variables: {
          name: title,
          userId: user.id,
          addedUsers: group?.map((addedUser: any) => {
            return addedUser.id;
          }),
          uuid: groupUUID,
        },
      })
      .then(({ data: groupData }) => {
        // let newUser = { ...user };
        // newUser.groups = [groupData.createGroup, ...newUser.groups];
        // console.log(newUser);
        // setUser(newUser);
        localStorage.setItem("group", JSON.stringify(groupData.createGroup));
        setLoading(false);
        navigate(`/app/cowork/${groupUUID}`);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  return (
    <ToolbarContainer>
      {group?.length !== 0 ? (
        <AddFriendsToGroupBox
          ref={drop}
          filled={false}
          style={{ overflowY: "scroll", backgroundColor }}
        >
          {/* <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          > */}
          <Grid container spacing={2} direction={"row"} width={"100%"}>
            {group?.map((friend: any, index: any) => {
              return (
                <Grid item xs={4} width={"100%"} key={index}>
                  <Badge
                    badgeContent={
                      <CloseRounded style={{ width: "12px", height: "12px" }} />
                    }
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    color="primary"
                    sx={{ "&:hover": { cursor: "pointer" } }}
                    onClick={() => {
                      console.log(group);
                      console.log(
                        group.findIndex(
                          (person: any) => person.id === friend.id
                        )
                      );
                      const index = group.findIndex(
                        (person: any) => person.id === friend.id
                      );
                      removeFriend(group.splice(index, 1));
                    }}
                  >
                    <div
                      draggable
                      style={{
                        backgroundColor: theme.palette.primary.light,
                        cursor: "pointer",
                        width: "100%",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        padding: "16px",
                        borderRadius: "4px",
                      }}
                    >
                      {friend.profileImageURL ? (
                        <img
                          src={`https://${process.env.REACT_APP_PUBLIC_AWS_S3_BUCKET_NAME}.s3.amazonaws.com/${friend.id}`}
                          // {...register('image', { required: true })}
                          style={{
                            borderRadius: "4px",
                            height: "30px",
                            width: "30px",
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <div
                          // src={imageSrc}
                          style={{
                            borderRadius: "4px",
                            height: "30px",
                            width: "30px",
                            cursor: "pointer",
                            background: `linear-gradient(135deg, ${alpha(
                              random_rgba(),
                              1
                            )} 0%, ${alpha(random_rgba(), 1)} 100%)`,
                            backgroundColor: "white",
                          }}
                        />
                      )}
                      <StudyTypographyMediumTitle>
                        {`${friend.firstName[0]} ${friend.lastName[0]}`}
                      </StudyTypographyMediumTitle>
                    </div>
                  </Badge>
                </Grid>
              );
            })}
          </Grid>
          {/* </div> */}
        </AddFriendsToGroupBox>
      ) : (
        <AddFriendsToGroupBox
          ref={drop}
          filled={false}
          style={{ backgroundColor: backgroundColor }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PeopleIcon active={false} clicked={() => {}} />
            <StudyTypographySmallTitle
              style={{ marginTop: "16px", textAlign: "center" }}
            >
              Drag friend here to start a new group!
            </StudyTypographySmallTitle>
          </div>
        </AddFriendsToGroupBox>
      )}

      <form
        onSubmit={(event) => {
          event.preventDefault();
          // const uniqueId = uuid();

          // var newTodos = [
          //   {
          //     title,
          //     duration,
          //     userId: userId,
          //     id: uniqueId,
          //   },
          //   ...todos,
          // ];
          // localStorage.setItem("todos", JSON.stringify(newTodos));
          // setTodos(newTodos);
          // console.log(todos);
          // createTodo();
          // setTitle("");
        }}
      >
        <ToolbarToDoCreateInput style={{ marginTop: "16px" }}>
          <CustomInput
            ref={inputRef}
            aria-label="Demo input"
            placeholder="name group.."
            onChange={(text) => {
              setTitle(text.currentTarget.value);
            }}
            value={title}
          />
          <Tooltip title={"copy shareable link"} placement={"top"}>
            <AddIcon disabled={!title} clicked={createRoom} />
          </Tooltip>
        </ToolbarToDoCreateInput>
      </form>
      {/* <LoadingButton
        fullWidth
        loading={loading}
        style={{
          textTransform: "none",
          background: `linear-gradient(135deg, ${alpha(
            "#FBDA61",
            0.5
          )} 0%, ${alpha("#FF5ACD", 0.5)} 100%)`,
          backgroundColor: "white",
          marginTop: "16px",
        }}
      >
        create room
      </LoadingButton> */}
      <StudyTypographyMegaSubtitle
        style={{ marginTop: "16px", marginBottom: "16px" }}
      >
        Rooms
      </StudyTypographyMegaSubtitle>
      {user.groups?.map((group: any, index: any) => {
        return (
          <Box sx={{ marginBottom: "12px", "&:hover": { cursor: "pointer" } }}>
            <SmallCoworkGroup
              data={group}
              mutation={leaveGroupHelper}
              invite={false}
              handleSetNewGroup={() => {}}
            />
            {/* <Button
              fullWidth
              style={{
                textTransform: "none",
                background: `linear-gradient(135deg, ${alpha(
                  "#FBDA61",
                  0.5
                )} 0%, ${alpha("#FF5ACD", 0.5)} 100%)`,
                backgroundColor: "white",
                marginTop: "16px",
              }}
              onClick={() => {
                navigate(`/app/cowork/${group.id}`);
              }}
            >
              {group.name}
            </Button> */}
          </Box>
        );
      })}

      {groupInvites ? (
        <div>
          <StudyTypographyMegaSubtitle
            style={{ marginTop: "16px", marginBottom: "16px" }}
          >
            Room Invites
          </StudyTypographyMegaSubtitle>
          {groupInvites?.map((group: any, index: any) => {
            return (
              <Box
                sx={{
                  marginBottom: "12px",
                  "&:hover": { cursor: "pointer" },
                }}
              >
                <SmallCoworkGroup
                  data={group}
                  mutation={groupRequestHelper}
                  invite={true}
                  handleSetNewGroup={() => {}}
                />
              </Box>
            );
          })}
        </div>
      ) : (
        <div />
      )}

      {/* <ToolbarToDoCreateInput
        style={{
          marginBottom: "10px",
          border: open ? `2px solid ${theme.palette.text.primary}` : "0px",
        }}
        onClick={() => {
          setShowGroups(!showGroups);
        }}
        // onContextMenu={handleRightClick}
      >
        <>
          <ToolbarToDoCreateInput style={{ marginBottom: "10px" }}>
            <CustomInput
              ref={inputRef}
              aria-label="Demo input"
              placeholder="Type Groups Name…"
              onChange={(text: any) => {
                setName(text.currentTarget.value);
              }}
              value={name}
            />
            <AddIcon
              clicked={() => {
                if (name && newGroup) {
                  const uniqueId = uuid();
                  // setTodos(newTodos);
                  setName("");
                }
              }}
            />
          </ToolbarToDoCreateInput>
           <StudyTypographySmallTitle>
                        Create New Group!
                    </StudyTypographySmallTitle> 
        </>
      </ToolbarToDoCreateInput> */}
      {/* <ToolbarToDoCreateInput
        style={{
          marginBottom: "10px",
          border: open ? `2px solid ${theme.palette.text.primary}` : "0px",
        }}
        onClick={() => {
          setShowGroups(!showGroups);
        }}
        // onContextMenu={handleRightClick}
      >
        <>
          <StudyTypographySmallTitle>Groups</StudyTypographySmallTitle>
          {showGroups ? <ArrowDropDownRounded /> : <ArrowDropUpRounded />}
        </>
      </ToolbarToDoCreateInput>
      {groups && showGroups ? (
        groups.map((group: any, index: number) => {
          return (
            <Box display="flex">
              <Card variant="outlined">
                <CardContent>
                  <StudyTypographySmallTitle>
                    {group.name}
                  </StudyTypographySmallTitle>
                </CardContent>
                <CardActions>
                                <Button size="small" variant="contained" onClick={() => {acceptRequest(request.id)}}>Accept</Button>
                                <Button size="small" variant="contained" onClick={() => {rejectRequest(request.id)}}>Reject</Button>
                            </CardActions>
              </Card>
            </Box>
          );
        })
      ) : (
        <div />
      )} */}
    </ToolbarContainer>
  );
};
// const friends = user.todos
//     console.log(user)
//     const [editTodo, setEditTodo] = useState(false);
//     const [editTodoText, setEditTodoText] = useState("");
//     const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
//     // // const { id: userId } = useUserContext();
//     const userId = localStorage.getItem("userId");
//     const open = Boolean(anchorEl);
//     const handleClick = (event: React.MouseEvent<HTMLElement>) => {
//         setAnchorEl(anchorEl ? null : event.currentTarget);
//     };

//   const toolbarRightClickItems = [
//     {
//       name: "edit todo",
//       icon: <EditOutlined style={{ width: "20px" }} />,
//       index: 0,
//     },
//     {
//       name: "completed todo",
//       icon: <CheckCircleOutlineOutlined style={{ width: "20px" }} />,
//       index: 1,
//     },
//     {
//       name: "delete todo",
//       icon: <DeleteOutlineOutlined style={{ width: "20px" }} />,
//       index: 2,
//     },
//   ];

//   const mainbarRightClickItems = [
//     {
//       name: "edit todo",
//       icon: <EditOutlined style={{ width: "20px" }} />,
//       index: 0,
//     },
//     {
//       name: "remove todo",
//       icon: <RemoveCircleOutlineOutlined style={{ width: "20px" }} />,
//       index: 1,
//     },
//     {
//       name: "completed todo",
//       icon: <CheckCircleOutlineOutlined style={{ width: "20px" }} />,
//       index: 2,
//     },
//     {
//       name: "delete todo",
//       icon: <DeleteOutlineOutlined style={{ width: "20px" }} />,
//       index: 3,
//     },
//   ];

//   const [clickAnchorEl, setClickAnchorEl] = useState<null | HTMLElement>(null);
//   const clickOpen = Boolean(clickAnchorEl);

//   const returnHours = (time: any) => {
//     var hours = time.split(":")[0];
//     return hours;
//   };
//   const returnMinutes = (time: any) => {
//     var minutes = time.split(":")[1];
//     return minutes;
//   };

//   const handleRightClick = (event: any) => {
//     event.preventDefault();
//     setClickAnchorEl(anchorEl ? null : event.currentTarget);
//     // Trigger your custom action here
//   };

//   const { isComponentDragging, setIsDragging } = useContext(DraggingContext);

//   const theme = useTheme();
//   const [{ isDragging }, drag] = useDrag(() => ({
//     type: ItemTypes.BOX,
//     item: index,
//     end: (item, monitor) => {
//       const dropResult = monitor.getDropResult();
//       setIsDragging(false);

//       if (item && dropResult) {
//         // console.log("I AM THE ID: ", data?.id);
//         // console.log("I AM THE data: ", data);
//         // var transformedData = data;
//         // transformedData["startTime"] = moment()
//         //   //@ts-ignore

//         //   .set("hour", returnHours(dropResult.name))
//         //   //@ts-ignore

//         //   .set("minute", returnMinutes(dropResult.name))
//         //   .set( "second", 0)
//         //   .set("millisecond", 0)
//         //   .toISOString();
//         // updateTodoList(transformedData);
//         // updateTodo({
//         //   variables: {
//         //     startTime: new Date(
//         //       moment()
//         //         //@ts-ignore
//         //         .set("hour", returnHours(dropResult.name))
//         //         //@ts-ignore

//         //         .set("minute", returnMinutes(dropResult.name))
//         //         .set("second", 0)
//         //         .set("millisecond", 0)
//         //         .toISOString()
//         //     ),
//         //     uuid: data?.uuid,
//         //   },
//         // });
//         //todoMutation to change the time or add the time to todo item
//       }
//     },
//     collect: (monitor) => ({
//       isDragging: monitor.isDragging(),
//       handlerId: monitor.getHandlerId(),
//     }),
//   }));

//   useEffect(() => {
//     if (isDragging) {
//       setIsDragging(true);
//     }
//   }, [isDragging]);
//     function updateTodo(arg0: { variables: { uuid: any; completed: boolean; }; }) {
//         throw new Error("Function not implemented.");
//     }

//     return (
//         <ToolbarBackground ref={drag} onContextMenu={handleRightClick}>
//       <ToolbarToDoBox
//         key={data?.uuid}
//         style={{
//           height: toolbar ? "40px" : `${data?.duration * 1.5}px`,
//           marginBottom: toolbar ? "4px" : "0px",
//           border: clickOpen ? `2px solid ${theme.palette.text.primary}` : "0px",
//           borderRadius: "4px",
//           alignItems: "center",
//         }}
//       >
//         <ToolbarToDoLeftSide>
//           <motion.div
//             whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
//             whileHover={{ rotate: 10 }}
//             transition={{
//               type: "spring",
//               stiffness: 200,
//               damping: 8,
//             }}
//           >
//             <CheckIcon
//               height={"24px"}
//               width={"24px"}
//               active={data?.completedAt}
//               clicked={() => {
//                 updateTodo({
//                   variables: {
//                     uuid: data?.uuid,
//                     completed: data?.completedAt ? false : true,
//                   },
//                 });
//               }}
//             />
//           </motion.div>
//           {editTodo ? (
//             <ClickAwayListener
//               onClickAway={() => {
//                 setEditTodo(false);
//               }}
//             >
//               <input
//                 autoFocus
//                 style={{
//                   border: "0px",
//                   backgroundColor: theme.palette.primary.light,
//                   width: "100%",
//                   height: "30px",
//                   paddingTop: "10px",
//                   paddingBottom: "10px",

//                   paddingLeft: "16px",
//                   outlineWidth: 0,
//                   borderRadius: "4px",
//                   color: theme.palette.text.primary,
//                 }}
//                 placeholder={"text new todo.."}
//                 type={"text"}
//                 name="title"
//                 value={editTodoText}
//                 onChange={(text) => {
//                   setEditTodoText(text.target.value);
//                 }}
//               ></input>
//             </ClickAwayListener>
//           ) : (
//             <div>
//               <ToolbarToDoTitle marginLeft={"8px"}>
//                 {data?.title}
//               </ToolbarToDoTitle>
//               {moment(data?.startTime).date() === new Date().getDate() ? (
//                 <ToolbarToDoSubtitle marginLeft={"8px"}>
//                   {moment(data?.startTime).format("h:mma")}
//                 </ToolbarToDoSubtitle>
//               ) : null}
//             </div>
//           )}
//         </ToolbarToDoLeftSide>
//         {editTodo ? (
//           <Button
//             variant="text"
//             color="secondary"
//             onClick={() => {
//               updateTodo({
//                 variables: { title: editTodoText, uuid: data?.uuid },
//               });
//             }}
//           >
//             save
//           </Button>
//         ) : (
//           <ClickAwayListener
//             onClickAway={() => {
//               setAnchorEl(null);
//             }}
//           >
//             <ToolbarDurationBox onClick={handleClick}>
//               <StudyTypographyMediumSubtitle style={{ marginLeft: "4px" }}>
//                 {data?.duration}
//                 {/* {getDuration(new Date(data.startTime), new Date(data.endTime))} */}
//               </StudyTypographyMediumSubtitle>
//               <ArrowDropDownRounded style={{ padding: 0, margin: 0 }} />
//             </ToolbarDurationBox>
//           </ClickAwayListener>
//         )}

//         <ProfileBlockPopper
//           open={open}
//           anchorEl={anchorEl}
//           placement={"bottom-end"}
//           modifiers={[{ name: "arrow", enabled: true }]}
//           style={{
//             backgroundColor: "rgba(0,0,0,0)",
//             margin: 0,
//             paddingRight: 0,
//           }}
//           nonce={undefined}
//           onResize={undefined}
//           onResizeCapture={undefined}
//         >
//           <div
//             style={{
//               alignItems: "center",
//               display: "inline-grid",
//               justifyContent: "center",
//               backgroundColor: theme.palette.secondary.light,
//               padding: "12px",
//               borderRadius: "4px",
//             }}
//           >
//             {timeDuration.map((time) => (
//               <ToolbarToDoDurationText
//                 style={{
//                   padding: "4px",
//                   textAlign: "center",
//                 }}
//                 onClick={() => {
//                   updateTodo({
//                     variables: { duration: time, uuid: data?.uuid },
//                   });
//                 }}
//               >
//                 {time} min
//               </ToolbarToDoDurationText>
//             ))}
//           </div>
//         </ProfileBlockPopper>
//         <ClickAwayListener
//           onClickAway={() => {
//             setClickAnchorEl(null);
//           }}
//         >
//           <ProfileBlockPopper
//             open={clickOpen}
//             anchorEl={clickAnchorEl}
//             placement={toolbar ? "bottom-end" : "bottom"}
//             modifiers={[{ name: "arrow", enabled: true }]}
//             style={{
//               backgroundColor: theme.palette.secondary.light,
//             }}
//             nonce={undefined}
//             onResize={undefined}
//             onResizeCapture={undefined}
//           >
//             <div
//               style={{
//                 alignItems: "center",
//                 display: "inline-grid",
//                 justifyContent: "center",
//               }}
//             >
//               {toolbar
//                 ? toolbarRightClickItems.map((item) => {
//                     function updateTodo(arg0: { variables: { uuid: any; completed: boolean; }; }) {
//                         throw new Error("Function not implemented.");
//                     }

//                     return (
//                       <HoverTypography
//                         style={{
//                           flexDirection: "row",
//                           display: "flex",
//                           alignItems: "center",
//                           paddingLeft: "8px",
//                           paddingRight: "8px",

//                           borderRadius: "4px",
//                           color:
//                             item.name === "delete todo"
//                               ? theme.palette.error.main
//                               : theme.palette.text.primary,
//                         }}
//                         onClick={() => {
//                           setClickAnchorEl(null);
//                           if (item.index === 0) {
//                             setEditTodo(true);
//                             setEditTodoText(data?.title);
//                           } else if (item.index === 1) {
//                             console.log("WHAT THE FUCK");
//                             console.log(item.index);
//                             updateTodo({
//                               variables: {
//                                 uuid: data?.uuid,
//                                 completed: true,
//                               },
//                             });
//                           } else if (item.index === 2) {
//                             console.log("HOLY SHIT");

//                             updateTodo({
//                               variables: {
//                                 uuid: data?.uuid,
//                                 deleted: true,
//                               },
//                             });
//                           }
//                         }}
//                       >
//                         {item.icon}
//                         <StudyTypographyMediumSubtitle
//                           style={{
//                             paddingTop: "6px",
//                             paddingBottom: "6px",
//                             borderRadius: "4px",
//                             paddingLeft: "8px",
//                             color:
//                               item.name === "delete todo"
//                                 ? theme.palette.error.main
//                                 : theme.palette.text.primary,
//                           }}
//                         >
//                           {item.name}
//                         </StudyTypographyMediumSubtitle>
//                       </HoverTypography>
//                     );
//                   })
//                 : mainbarRightClickItems.map((item) => {
//                     function updateTodo(arg0: { variables: { uuid: any; startTime: null; }; }) {
//                         throw new Error("Function not implemented.");
//                     }

//                     return (
//                       <HoverTypography
//                         style={{
//                           flexDirection: "row",
//                           display: "flex",
//                           alignItems: "center",
//                           paddingLeft: "8px",
//                           paddingRight: "8px",

//                           borderRadius: "4px",
//                           color:
//                             item.name === "delete todo"
//                               ? theme.palette.error.main
//                               : theme.palette.text.primary,
//                         }}
//                         onClick={() => {
//                           setClickAnchorEl(null);
//                           if (item.index === 0) {
//                             setEditTodo(true);
//                             setEditTodoText(data?.title);
//                           } else if (item.index === 1) {
//                             updateTodo({
//                               variables: {
//                                 uuid: data?.uuid,
//                                 startTime: null,
//                               },
//                             });
//                           } else if (item.index === 2) {
//                             updateTodo({
//                               variables: {
//                                 uuid: data?.uuid,
//                                 completed: true,
//                               },
//                             });
//                           } else if (item.index === 3) {
//                             updateTodo({
//                               variables: {
//                                 uuid: data?.uuid,
//                                 deleted: true,
//                               },
//                             });
//                           }
//                         }}
//                       >
//                         {item.icon}
//                         <StudyTypographyMediumSubtitle
//                           style={{
//                             paddingTop: "6px",
//                             paddingBottom: "6px",
//                             borderRadius: "4px",
//                             paddingLeft: "8px",
//                             color:
//                               item.name === "delete todo"
//                                 ? theme.palette.error.main
//                                 : theme.palette.text.primary,
//                           }}
//                         >
//                           {item.name}
//                         </StudyTypographyMediumSubtitle>
//                       </HoverTypography>
//                     );
//                   })}
//             </div>
//           </ProfileBlockPopper>
//         </ClickAwayListener>
//       </ToolbarToDoBox>
//     </ToolbarBackground>
//   );
// };
//     )
// }
