//@ts-nocheck
import { FileCopyOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material";
import moment from "moment";
import { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Document, Page, pdfjs } from "react-pdf";
import { v4 as uuid } from "uuid";
import { CREATE_TO_DO } from "../../graphql/mutations/todo";
import { ADD_USER_CATEGORY } from "../../graphql/mutations/user";
import { client } from "../../utils/apollo";
import { sendSyllabusToGPT } from "../../utils/sendSyllabusToGPT";
import { SyllabusTodo } from "../mainbar/todos/SyllabusTodo";
import Modal from "./Modal";
import {
  StudyTypographyMediumSubtitle,
  StudyTypographyMediumTitle,
  StudyTypographySmallText,
  StudyTypographySmallTitle,
} from "./StudyTypography";
import { HorizontalContainer } from "./HorizontalContainer";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function DropZone() {
  const [pdfFiles, setPdfFiles] = useState<string[]>([]);
  const [uploadedPdfObjects, setUploadedPdfObjects] = useState<any[]>([]);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState<string | null>(null);
  const [allPdfsStructured, setAllPdfsStructured] = useState([]);
  const [allTodos, setAllTodos] = useState<any[][]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<any[][]>([]);

  const user = JSON.parse(localStorage.getItem("user")!);

  const handlePdfClick = (pdfUrl: string) => {
    setSelectedPdfUrl(pdfUrl);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      disabled: selectedPdfUrl,
      // accept: { "image/*": [] },
      onDrop: async (acceptedFiles) => {
        const pdfUrls = await Promise.all(
          acceptedFiles.map(async (file) => {
            const pdfUrl = URL.createObjectURL(file);
            return pdfUrl;
          })
        );
        setPdfFiles(pdfUrls);

        // Load the PDFs and group the pages for all the uploaded files
        for (const pdfUrl of pdfUrls) {
          const pdf = await pdfjs.getDocument(pdfUrl).promise;

          let pagesTextArray = [];
          for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
            const page = await pdf.getPage(pageNumber);
            const textContent = await page.getTextContent();
            const text = textContent.items.map((item) => item.str).join(" ");
            pagesTextArray.push(text);
          }

          const groupedPdfPages = groupPagesInSetsOfThirty(pagesTextArray);
          setAllPdfsStructured((prev) => [...prev, ...groupedPdfPages]);
        }
      },
    });
  const theme = useTheme();
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: theme.palette.primary.light,
    borderStyle: "dashed",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    outline: "none",
    transition: "border .24s ease-in-out",
    alignSelf: "center",
    // width: "100%",
  };

  const focusedStyle = {
    borderColor: theme.palette.action.active,
  };

  const acceptStyle = {
    borderColor: theme.palette.success.main,
  };

  const rejectStyle = {
    borderColor: theme.palette.error.main,
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
    alignItems: "center",
    width: "100%",
    alignSelf: "center",
    justifyContent: "space-between",
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: "4px",
    marginBottom: 8,
    // marginRight: 8,
    width: 150,
    height: 150,
    padding: 4,
    boxSizing: "border-box",
    // alignItems: "center",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
    cursor: "pointer",
    borderRadius: "4px",
    // alignItems: "center",
    // alignSelf: "center",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const handlePdfLoadSuccess = async (pdf: any) => {
    let pagesTextArray = [];

    for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
      const page = await pdf.getPage(pageNumber);
      const textContent = await page.getTextContent();
      const text = textContent.items.map((item) => item.str).join(" ");
      pagesTextArray.push(text);
    }

    const pdfObject = {
      pdfIndex: uploadedPdfObjects.length + 1,
      pages: pagesTextArray,
    };

    setUploadedPdfObjects((prevPdfObjects) => [...prevPdfObjects, pdfObject]);
  };

  const constructChatGPTPromptForPages = (pagesTexts: string[]) => {
    return `
  Please analyze the following class syllabus pages and extract all relevant events and todos that are one of the following Types: assignments, exams, quizzes, projects, homeworks, office hours, or anything important. For lectures, only create todos for one week in a generic structure.
  
  ${pagesTexts.map((text, index) => `Page ${index + 1}: ${text}`).join("\n\n")}
  
  For each event, create todos with the following structure:
  
  - categoryName: Title of the class.
  - categoryColor: A uniform hexadecimal sexy pastel color for the class.
  - title: {Event type} - Event title.
  - duration: Event duration in minutes.
  - startTime: Event start time. If ambiguous set to null.
  - priority: Priority (LOW, MEDIUM, HIGH, URGENT).
  - event: True for calendar event, false for todo.
  - dueAt: Due date of the assignment.
  - selected: true
  
  Ensure the output will never be cut off for being too long. Return the output structured as follows:

  1. A JSON array of all the todos enclosed with triple backticks and labeled as 'json'.
  Format: \\\`\\\`\\\`json\n{the returned json}\\\`\\\`\\\`
 
  2. After all of part 1, include a very small message with how many todos and what category was created
    `;
  };

  const groupPagesInSetsOfThirty = (pages: string[]): string[][] => {
    let groupedPages = [];
    groupedPages.push(pages.slice(0, 28));
    return groupedPages;
  };

  const handleScanClick = async () => {
    setIsLoading(true);
    for (let pagesGroup of allPdfsStructured) {
      const prompt = constructChatGPTPromptForPages(pagesGroup);
      sendSyllabusToGPT(prompt).then((response) => {
        setIsLoading(false);
        setAllTodos((prevTodos) => [...prevTodos, response.object]);
        setMessage((prevTodos) => [...prevTodos, response.message]);
      });
    }
  };

  const handleAddTodos = () => {
    allTodos.forEach((todoCategory) => {
      console.log("todo category: ", todoCategory);
      const selectedTodos = todoCategory.filter((todo) => todo.selected);
      const categoryId = uuid();

      console.log("selected todos: ", selectedTodos);
      client
        .mutate({
          mutation: ADD_USER_CATEGORY,
          variables: {
            userId: user.id,
            category: todoCategory[0].categoryName,
            categoryLabel: todoCategory[0].categoryColor,
            uuid: categoryId,
          },
        })
        .then((category) => {
          selectedTodos.forEach((todo) => {
            const todoId = uuid();
            client.mutate({
              mutation: CREATE_TO_DO,
              variables: {
                title: todo.title,
                duration: todo?.duration > 0 ? todo?.duration : 0,
                userId: user.id,
                uuid: todoId,
                categoryId: categoryId,
                startTime: moment(todo.startTime),
                event: false,
                dueAt: moment(todo.dueAt),
              },
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const pdfThumbs = pdfFiles.map((pdfUrl, index) => (
    <div
      style={thumb}
      key={index}
      onClick={(event: any) => {
        event.stopPropagation();
        event.preventDefault();
        handlePdfClick(pdfUrl);
      }} // Add this click handler
    >
      <div style={thumbInner}>
        <Document file={pdfUrl} onLoadSuccess={handlePdfLoadSuccess}>
          <Page pageNumber={1} width={150} />
        </Document>
      </div>
    </div>
  ));

  const handleCloseModal = () => {
    setSelectedPdfUrl(null);
  };

  return (
    <div
      style={{
        width: "100%",
        // display: "flex",
        // alignItems: "center",
        flexDirection: "column",
      }}
    >
      {/* @ts-ignore */}
      {allTodos?.[0] ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {allTodos.map((todoCategory, index) => {
            return (
              <div
                style={{
                  width: "100%",
                  marginBottom: "18px",
                }}
              >
                <StudyTypographyMediumSubtitle marginBottom={"12px"}>
                  {`Message: ${message[index]}`}
                </StudyTypographyMediumSubtitle>
                <HorizontalContainer marginBottom={"12px"}>
                  <div
                    style={{
                      backgroundColor: todoCategory?.[0].categoryColor,
                      width: "4px",
                      height: "18px",
                      borderRadius: "2px",
                      marginRight: "12px",
                    }}
                  />
                  <StudyTypographySmallTitle>
                    {todoCategory?.[0]?.categoryName}
                  </StudyTypographySmallTitle>
                </HorizontalContainer>

                {todoCategory?.map((todo) => (
                  <SyllabusTodo
                    data={todo}
                    allTodos={allTodos}
                    setAllTodos={setAllTodos}
                  />
                ))}
              </div>
            );
          })}
        </div>
      ) : (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {pdfFiles.length > 0 ? (
            <div style={thumbsContainer}>{pdfThumbs}</div>
          ) : (
            <div
              style={{
                // width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                height: "200px",
              }}
            >
              <FileCopyOutlined
                style={{
                  backgroundColor: theme.palette.primary.light,
                  height: "64px",
                  width: "48px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  padding: "12px",
                  marginBottom: "12px",
                  color: theme.palette.primary.dark,
                }}
              />
              <StudyTypographySmallText>
                Drag in files, or click to select
              </StudyTypographySmallText>
            </div>
          )}
          {selectedPdfUrl && (
            <Modal
              title="PDF Preview"
              open={!!selectedPdfUrl}
              handleOpen={handleCloseModal}
            >
              {/* Display the selected PDF */}
              {selectedPdfUrl && (
                <iframe
                  style={{
                    border: "0px solid rgba(0,0,0,0)",
                    borderRadius: "12px",
                  }}
                  src={selectedPdfUrl}
                  title="Selected PDF"
                  width="100%"
                  height="500px" // Adjust the height as needed
                />
              )}
            </Modal>
          )}
        </div>
      )}

      <div
        style={{
          width: "100%",
          justifyContent: "flex-end",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <LoadingButton
          variant="text"
          color="primary"
          loading={isLoading}
          sx={{
            backgroundColor: theme.palette.primary.light,
            height: "100%",
            "&:hover": {
              backgroundColor: theme.palette.secondary.light,
            },
            color: theme.palette.text.primary,
            textTransform: "none",
          }}
          onClick={allTodos?.[0] ? handleAddTodos : handleScanClick}
        >
          {allTodos?.[0] ? "Add todos" : "Scan"}
        </LoadingButton>
      </div>
    </div>
  );
}
export default DropZone;
