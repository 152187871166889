import { useLazyQuery } from "@apollo/client";
import { AddRounded, RemoveRounded } from "@mui/icons-material";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import {
  alpha,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  SEND_FRIEND_REQUEST,
  UNSEND_FRIEND_REQUEST,
} from "../../../graphql/mutations/friends";
import { GET_USERS } from "../../../graphql/queries/user";
import { client } from "../../../utils/apollo";
import { random_rgba } from "../../../utils/constants";
import { AddFriendsIcon } from "../../Icons/AddFriendsIcon";
import { MainbarContainer } from "../styles";

export const SearchBar = ({
  user,
  setUser,
  friends,
  recieved,
  sent,
  sendRequest,
  unsendRequest,
}: {
  user: any;
  setUser: any;
  friends: any;
  recieved: any;
  sent: any;
  sendRequest: any;
  unsendRequest: any;
}) => {
  const [allUsers, setAllUsers] = useState(
    JSON.parse(localStorage.getItem("users")!) || []
  );
  const [searchInput, setSearchInput] = useState("");
  // const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')!) || {});
  const [searchedUsers, setSearchedUsers] = useState<any[]>([]);

  const [getUsers, { data: usersData, loading }] = useLazyQuery(GET_USERS, {
    onCompleted: (data) => {
      setAllUsers(data.getUsers);
      localStorage.setItem("users", JSON.stringify(data.getUsers));
    },
  });

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const theme = useTheme();

  useEffect(() => {
    if (searchInput.length > 0) {
      const res = allUsers.filter((possible: any) => {
        if (
          !recieved.includes(possible.id) &&
          !friends.includes(possible.id) &&
          possible.id !== user.id
        ) {
          let fullName = possible.firstName + " " + possible.lastName;
          return (
            possible.email.toLowerCase().includes(searchInput.toLowerCase()) ||
            fullName.toLowerCase().includes(searchInput.toLowerCase()) ||
            possible.lastName
              .toLowerCase()
              .includes(searchInput.toLowerCase()) ||
            possible.firstName.toLowerCase().includes(searchInput.toLowerCase())
          );
        }
        return null;
      });
      setSearchedUsers(res);
    } else {
      setSearchedUsers([]);
    }
  }, [searchInput]);

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  return (
    <MainbarContainer>
      <input
        style={{
          backgroundColor: theme.palette.primary.light,
          height: "60px",
          width: "100%",
          flex: 1,
          border: 0,
          outlineWidth: 0,
          padding: "16px",
          boxSizing: "border-box",
          outline: "none",
          borderRadius: "4px",
          color: theme.palette.text.primary,
        }}
        placeholder="Search for fiends using their email!"
        type="text"
        name="title"
        value={searchInput}
        onChange={handleSearch}
      ></input>
      <TableContainer
        sx={{ overflow: "scroll", height: "90vh", marginBottom: "75px" }}
      >
        <Table sx={{ borderColor: "red" }}>
          <TableHead>
            <TableRow
              sx={{ borderBottom: `2px solid ${theme.palette.primary.light}` }}
            >
              <TableCell align="left">Profile</TableCell>

              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Sent?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{}}>
            {searchedUsers.map((searched) => {
              console.log(searched);
              return (
                <TableRow
                  key={searched.name}
                  sx={{
                    // "&:last-child td, &:last-child th": { border: 0 },
                    borderBottom: `2px solid ${theme.palette.primary.light}`,
                  }}
                >
                  <TableCell align="left">
                    {searched.profileImageURL ? (
                      <img
                        src={searched?.profileImageURL}
                        // {...register('image', { required: true })}
                        style={{
                          borderRadius: "4px",
                          width: "50px",
                          height: "50px",
                          cursor: "pointer",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          borderRadius: "4px",
                          width: "50px",
                          height: "50px",
                          cursor: "pointer",
                          background: `linear-gradient(135deg, ${alpha(
                            random_rgba(),
                            1
                          )} 0%, ${alpha(random_rgba(), 1)} 100%)`,
                          backgroundColor: "white",
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {searched.firstName} {searched.lastName}
                  </TableCell>
                  <TableCell align="left">{searched.email}</TableCell>
                  <TableCell align="left">
                    {!sent.includes(searched.id) ? (
                      <AddRounded
                        // active={!friends.includes(searched.id)}
                        sx={{
                          backgroundColor: theme.palette.primary.light,
                          padding: "4px",
                          borderRadius: "4px",
                          "&:hover": {
                            cursor: "pointer",
                            backgroundColor: theme.palette.secondary.light,
                          },
                        }}
                        onClick={() => {
                          sendRequest(searched);
                        }}
                      />
                    ) : (
                      <RemoveRounded
                        sx={{
                          backgroundColor: theme.palette.primary.light,
                          padding: "4px",
                          borderRadius: "4px",
                          "&:hover": {
                            cursor: "pointer",
                            backgroundColor: theme.palette.secondary.light,
                          },
                        }}
                        onClick={() => {
                          unsendRequest(searched.id);
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </MainbarContainer>
  );
};

// <table style={{ width: "100%" }}>
//   <tr
//     style={{
//       display: "flex",
//       justifyContent: "flex-start",
//       alignItems: "center",
//       width: "100%",
//     }}
//   >
//     <th>User</th>
//     <th>Email</th>
//   </tr>
//   {searchedUsers ? (
//     searchedUsers.map((possible, index) => {
//       return (
//         <tr>
//           <td>
//             {possible.firstName} {possible.lastName}
//           </td>
//           <td>{possible.email}</td>
//           {!sent.includes(possible.id) ? (
//             <AddFriendsIcon
//               active={!friends.includes(possible.id)}
//               clicked={() => {
//                 sendRequest(possible.id);
//               }}
//             />
//           ) : (
//             <CancelScheduleSendIcon
//               onClick={() => {
//                 unsendRequest(possible.id);
//               }}
//             />
//           )}
//         </tr>
//       );
//     })
//   ) : (
//     <div></div>
//   )}
// </table>

// {searchedUsers.map((possible, index) => {
//   return (
//     <Typography>Hello</Typography>
//     <TableRow
//       key={possible.name}
//       sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
//     >
//       <TableCell align="left">Platon</TableCell>
//       <TableCell align="left">{possible.email}</TableCell>
//       <TableCell align="left">
//         {!sent.includes(possible.id) ? (
//           <AddFriendsIcon
//             active={!friends.includes(possible.id)}
//             clicked={() => {
//               sendRequest(possible.id);
//             }}
//           />
//         ) : (
//           <CancelScheduleSendIcon
//             onClick={() => {
//               unsendRequest(possible.id);
//             }}
//           />
//         )}
//       </TableCell>
//     </TableRow>
//   );
// })}
