import { MoreHorizOutlined } from "@mui/icons-material";
import { alpha, Box, Grid, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { random_rgba } from "../../../utils/constants";
import { StudyTypographyMediumTitle } from "../../glovebox/StudyTypography";
import { ProfileBlockPopper } from "../../toolbar/styles";

export const CoworkGroup = ({
  data,
  leaveGroup,
  handleSetNewGroup,
  height,
}: {
  data: any;
  leaveGroup: any;
  handleSetNewGroup: any;
  height?: any;
}) => {
  const [groups, setGroups] = useState(data);
  const [group, setGroup] = useState(
    JSON.parse(localStorage.getItem("group")!) || {}
  );
  const navigate = useNavigate();
  //   console.log(group);
  useEffect(() => {
    localStorage.setItem("group", JSON.stringify(group));
  }, [group]);

  const [recievedAnchorEl, setRecievedAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const recievedOpen = Boolean(recievedAnchorEl);

  const handleRecievedClick = (event: React.MouseEvent<HTMLElement>) => {
    setRecievedAnchorEl(recievedAnchorEl ? null : event.currentTarget);
  };

  const theme = useTheme();
  return (
    <Grid item xs={4} width={"100%"} key={data?.id} data-testid={`box`}>
      <div
        onClick={() => {
          // console.log(data);
          if (data?.uuid) {
            navigate(`${data.uuid}`);
          } else {
            navigate("/app/friends");
          }
        }}
        style={{
          backgroundColor: theme.palette.primary.main,
          border: `3px solid ${theme.palette.primary.light}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "16px",
          borderRadius: "4px",
          flexDirection: "column",
          marginBottom: "8px",
          // height: height ? height : "200px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <MoreHorizOutlined
            onClick={(event: any) => {
              event.stopPropagation();
              handleRecievedClick(event);
            }}
            sx={{
              padding: "4px",

              "&:hover": {
                backgroundColor: theme.palette.primary.light,
                borderRadius: "4px",
                cursor: "pointer",
              },
            }}
          />
        </div>
        <ProfileBlockPopper
          open={recievedOpen}
          anchorEl={recievedAnchorEl}
          placement={"bottom"}
          modifiers={[{ name: "arrow", enabled: true }]}
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            margin: 0,
            paddingRight: 0,
            zIndex: 100,
            paddingTop: "8px",
          }}
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "inline-grid",
              justifyContent: "center",
              backgroundColor: theme.palette.primary.main,
              border: `1px solid ${theme.palette.secondary.light}`, // padding: "8px",
              borderRadius: "4px",
              margin: "4px",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "inline-grid",
                justifyContent: "center",
                backgroundColor: theme.palette.primary.main,
                padding: "8px 16px 8px 16px",
                borderRadius: "4px 4px 0px 4px",
                margin: "4px",
                "&:hover": {
                  backgroundColor: theme.palette.secondary.light,

                  cursor: "pointer",
                },
              }}
              onClick={(event: any) => {
                event.stopPropagation();
                leaveGroup(data.uuid);
                // mutation(data.uuid)
                setRecievedAnchorEl(null);
              }}
            >
              Leave
            </Box>
          </Box>
        </ProfileBlockPopper>
        <div
          // src={require("../../Icons/ProfileMemoji.png")}
          style={{
            borderRadius: "12px",
            height: "50px",
            width: "50px",
            cursor: "pointer",
            margin: "16px",
            background: `linear-gradient(135deg, ${alpha(
              random_rgba(),
              1
            )} 0%, ${alpha(random_rgba(), 1)} 100%)`,
            backgroundColor: "white",
          }}
        />
        <StudyTypographyMediumTitle style={{ marginBottom: "32px" }}>
          {data.name}
        </StudyTypographyMediumTitle>
        {/* <AvatarGroup
          max={4}
          sx={{ color: theme.palette.primary.main, border: 0 }}
        >
          {group.users.map((user: any) => (
            <ThemedAvatar
              alt="Remy Sharp"
              sizes="small"
              sx={{
                border: 0,
                color: theme.palette.text.primary,
              }}
            >{`${user.firstName[0]}${user.lastName[0]}`}</ThemedAvatar>
          ))}
        </AvatarGroup> */}
        {/* {group?.users?.map((item: any, index: any) => {
          client
            .query({
              query: GROUP_INFO,
              variables: {
                uuid: item.uuid,
              },
            })
            .then(({ data: groupData }) => {
              setGroup(groupData.getGroup);
            });
          return (
            <div
              key={index}
              style={{ flex: "0 0 300px", width: "400px", marginRight: "16px" }}
            >
              <StudyTypographyMediumSubtitle>
                {item.firstName} {item.lastName}
              </StudyTypographyMediumSubtitle>
               <CoworkSchedule groupUser={item} /> 
            </div>
          );
        })} */}
      </div>
    </Grid>
  );
};
