
import { gql } from "@apollo/client";
import { User } from "@auth0/auth0-react";
import { async } from "@firebase/util";
import { client } from "../../utils/apollo";
import { LEAVE_GROUP } from "./user";

export const CREATE_GROUP = gql`
    mutation CreateGroup($userId: String!, $name: String!, $uuid: String!, $addedUsers: [String!]) {
        createGroup(userId: $userId, name: $name, uuid: $uuid, addedUsers: $addedUsers) {
            id
            users {
                id
                lastName
                email
                firstName
                displayTodoName
                privateTodos
                todos {
                    id
                    title
                    startTime
                    uuid
                    duration
                }
                active
            }
            name
        }
    }
`;

export const UPDATE_GROUP = gql`
    mutation UpdateGroup($updateGroupId: String!, $name: String, $addedUsers: [String!], $unsentUsers: [String!]) {
        updateGroup(id: $updateGroupId, name: $name, addedUsers: $addedUsers, unsentUsers: $unsentUsers) {
            id
        }
    }
`;

export const DELETE_GROUP = gql`
    mutation DeleteGroup($deleteGroupId: String!) {
        deleteGroup(id: $deleteGroupId) {
            id
        }
    }
`;

export const leaveGroup = async (groupId: string, user: any, setUser: any)  => {
    let deleteGroup = false;
    let newUser = { ...user };
    let newGroups = newUser.groups?.filter(function (item: any) {
      if (item.uuid === groupId && item.users.length === 1) {
        deleteGroup = true;
      }
      return item.uuid !== groupId;
    });
    newUser.groups = newGroups;
    setUser(newUser);
    if (deleteGroup) {
      client.mutate({
        mutation: DELETE_GROUP,
        variables: {
          deleteGroupId: groupId,
        },
      });
    } else {
      client.mutate({
        mutation: LEAVE_GROUP,
        variables: {
          userId: user.id,
          groupId: groupId,
        },
      });
    }
}

export const updateGroup = async (friendId: string, group: any, setGroup: any) => {
    let newGroup = { ...group };
    newGroup.addedUsers = [
      { id: friendId, __typeName: "User" },
      ...newGroup.addedUsers,
    ];
    // console.log(newGroup);
    setGroup(newGroup);
    return client.mutate({
      mutation: UPDATE_GROUP,
      variables: {
        updateGroupId: group.uuid,
        addedUsers: [friendId],
      },
    });
}