// import * as Yup from "yup";
import { useState } from "react";
// import { Icon } from "@iconify/react";
// import { useFormik, Form, FormikProvider } from "formik";
// import eyeFill from "@iconify/icons-eva/eye-fill";
// import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { useNavigate } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  alpha,
  useTheme,
} from "@mui/material";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
// import Email from "next-auth/providers/email";
// import { LoadingButton } from "@mui/lab";
import { client } from "../../../utils/apollo";
import {
  CREATE_USER,
  UPDATE_USER_BULK_TODOS,
} from "../../../graphql/mutations/user";
import { useUserContext } from "../../../utils/contexts";
import { auth } from "../../../utils/firebase";
import { GET_USER_SIGNIN, GET_USER_INFO } from "../../../graphql/queries/user";
import ReactLoading from "react-loading";
import { LoadingButton } from "@mui/lab";
import { v4 as uuid } from "uuid";
import {
  baseCategories,
  baseTodo,
  baseUser,
  categories,
  categoryTutorialTodos,
  tutorialTodos,
} from "../../../utils/bulkData";
import { UPDATE_CATEGORY_TODOS_BULK } from "../../../graphql/mutations/categories";
import { StudyTypographyMediumSubtitle } from "../../glovebox/StudyTypography";
import { STUDYO_GRADIENT } from "../../../utils/constants";
// ----------------------------------------------------------------------

export default function RegisterForm({
  handleFormChange,
}: {
  handleFormChange: any;
}) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const phoneRegExp =
    /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  // const RegisterSchema = Yup.object().shape({
  //   firstName: Yup.string()
  //     .min(2, "Too Short!")
  //     .max(50, "Too Long!")
  //     .required("First name required"),
  //   lastName: Yup.string()
  //     .min(2, "Too Short!")
  //     .max(50, "Too Long!")
  //     .required("Last name required"),
  //   phone: Yup.string()
  //     .min(10, "Too short!")
  //     .matches(phoneRegExp, "Phone number is not valid")
  //     .required("Phone number is required"),
  //   email: Yup.string()
  //     .email("Email must be a valid email address")
  //     .required("Email is required"),
  //   password: Yup.string().required("Password is required"),
  // });

  // const formik = useFormik({
  //   initialValues: {
  //     firstName: "",
  //     lastName: "",
  //     phone: "",
  //     email: "",
  //     password: "",
  //   },
  //   validationSchema: RegisterSchema,
  //   onSubmit: (values) => {
  //     console.log("WE ARE REGISTERING: ", values);
  //   },
  // });
  // const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const { setId, setGoogleUser } = useUserContext();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  // const auth = getAuth();
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setLoading(true);
    // event.stopPropagration();
    const { data } = await client.query({
      query: GET_USER_SIGNIN,
      variables: { email: email },
      fetchPolicy: "network-only",
    });
    if (!data.getUserEmail) {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredetial: any) => {
          const user = userCredetial.user;
          localStorage.setItem("googleUser", JSON.stringify(user));
          const baseCategoryId = uuid();
          const tutorialCategoyId = uuid();
          const rightCategoryId = uuid();
          const initCategories = categories(
            baseCategoryId,
            tutorialCategoyId,
            rightCategoryId
          );
          await client
            .mutate({
              mutation: CREATE_USER,
              variables: {
                email: email,
                firstName: firstName,
                lastName: lastName,
                categoryId: baseCategoryId,
                categories: initCategories,
              },
            })
            .then(async ({ data: mutateData }) => {
              localStorage.setItem("userId", mutateData.createUser.id);
              const tutorialBulkTodos = tutorialTodos;
              const rightClickBulkTodos = categoryTutorialTodos;
              const tutorial = client.mutate({
                mutation: UPDATE_CATEGORY_TODOS_BULK,
                variables: {
                  uuid: tutorialCategoyId,
                  todos: tutorialBulkTodos,
                },
              });
              const right = client.mutate({
                mutation: UPDATE_CATEGORY_TODOS_BULK,
                variables: {
                  uuid: rightCategoryId,
                  todos: rightClickBulkTodos,
                },
              });
              Promise.all([tutorial, right]).then(async () => {
                const tutorialBulkTodosObjects = tutorialTodos.map((todo) => {
                  return baseTodo(todo, tutorialCategoyId);
                });
                const rightTodosObjects = categoryTutorialTodos.map((todo) => {
                  return baseTodo(todo, rightCategoryId);
                });
                const baseTodos =
                  tutorialBulkTodosObjects.concat(rightTodosObjects);
                const todoIds = baseTodos.map((todo: any) => {
                  return todo.uuid;
                });
                await client
                  .mutate({
                    mutation: UPDATE_USER_BULK_TODOS,
                    variables: {
                      userId: mutateData.createUser.id,
                      addTodoIds: todoIds,
                    },
                  })
                  .then(() => {
                    localStorage.setItem(
                      "user",
                      JSON.stringify(
                        baseUser(
                          baseCategoryId,
                          email,
                          firstName,
                          lastName,
                          mutateData.createUser.id
                        )
                      )
                    );
                    localStorage.setItem("todos", JSON.stringify(baseTodos));
                    localStorage.setItem(
                      "categories",
                      JSON.stringify(
                        baseCategories(
                          baseCategoryId,
                          tutorialCategoyId,
                          rightCategoryId
                        )
                      )
                    );
                    navigate("/app/schedule");
                  })
                  .catch((error: any) => {
                    console.log(error.message);
                  });
              });
            })
            .catch((err) => {
              setLoading(false);
              const errorCode = err.code;
              const errorMessage = err.message;
              setError(`${errorCode} : ${errorMessage}`);
            });
        })
        .catch((error) => {
          setLoading(false);
          const errorCode = error.code;
          const errorMessage = error.message;
          setError(`${errorCode} : ${errorMessage}`);
        });
    } else {
      setLoading(false);
      setError(
        "A user with that email already exists! Sign in or use a different email address."
      );
    }
  };

  return (
    // <FormikProvider value={formik}>
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <div
        style={{ flexDirection: "row", display: "flex", alignItems: "center" }}
      >
        <input
          autoFocus
          style={{
            border: "0px",
            backgroundColor: theme.palette.primary.light,
            width: "100%",
            height: "60px",
            marginBottom: "16px",
            marginRight: "8px",
            padding: "0px",
            paddingLeft: "16px",
            // paddingTop: "10px",
            // paddingBottom: "10px",
            boxSizing: "border-box",
            outlineWidth: 0,
            borderRadius: "4px",
            color: theme.palette.text.primary,
          }}
          placeholder={"enter first name.."}
          type={"text"}
          name="title"
          value={firstName}
          onChange={(text) => {
            setFirstName(text.target.value);
          }}
        ></input>
        <input
          autoFocus
          style={{
            border: "0px",
            backgroundColor: theme.palette.primary.light,
            width: "100%",
            height: "60px",
            marginBottom: "16px",
            marginLeft: "8px",

            padding: "0px",
            paddingLeft: "16px",
            // paddingTop: "10px",
            // paddingBottom: "10px",
            boxSizing: "border-box",
            outlineWidth: 0,
            borderRadius: "4px",
            color: theme.palette.text.primary,
          }}
          placeholder={"enter last name.."}
          type={"text"}
          name="title"
          value={lastName}
          onChange={(text) => {
            setLastName(text.target.value);
          }}
        ></input>
      </div>
      <input
        autoFocus
        style={{
          border: "0px",
          backgroundColor: theme.palette.primary.light,
          width: "100%",
          height: "60px",
          marginBottom: "16px",
          padding: "0px",
          paddingLeft: "16px",
          // paddingTop: "10px",
          // paddingBottom: "10px",
          boxSizing: "border-box",
          outlineWidth: 0,
          borderRadius: "4px",
          color: theme.palette.text.primary,
        }}
        placeholder={"enter email.."}
        type={"text"}
        name="title"
        value={email}
        onChange={(text) => {
          setEmail(text.target.value);
        }}
      ></input>
      <input
        autoFocus
        style={{
          border: "0px",
          backgroundColor: theme.palette.primary.light,
          width: "100%",
          height: "60px",
          padding: "0px",
          paddingLeft: "16px",
          boxSizing: "border-box",

          marginBottom: "16px",
          outlineWidth: 0,
          borderRadius: "4px",
          color: theme.palette.text.primary,
        }}
        placeholder={"enter password.."}
        type={"password"}
        name="Password"
        value={password}
        onChange={(text) => {
          setPassword(text.target.value);
        }}
      ></input>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="outlined"
        loading={loading}
        style={{
          width: "100%",
          display: "flex",
          background: STUDYO_GRADIENT,
          backgroundColor: "white",
          textTransform: "none",
          color: theme.palette.text.primary,
        }}
        onClick={handleSubmit}
      >
        {loading ? "" : "login"}
      </LoadingButton>
      {error ? (
        <StudyTypographyMediumSubtitle
          style={{
            marginTop: "16px",
            marginBottom: "16px",
            color: theme.palette.error.main,
          }}
        >
          {error}
        </StudyTypographyMediumSubtitle>
      ) : (
        <div />
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "16px",
          textTransform: "none",
        }}
      >
        <a
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleFormChange();
          }}
        >
          login
        </a>
      </div>
    </form>
    // </FormikProvider>
  );
}
