import { useTheme } from "@mui/material";
import {
  STUDYO_PRIMARY_GRADIENT,
  STUDYO_SECONDARY_GRADIENT,
} from "../../utils/constants";
import { HoverPointerStyle } from "./HoverPointerStyle";

export function LogoutIcon(props: {
  clicked: Function;
  width?: string;
  height?: string;
}) {
  const theme = useTheme();
  return (
    <HoverPointerStyle
      width={props?.width || "30"}
      height={props?.height || "30"}
      viewBox={`0 0 32 32`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        props.clicked();
      }}
    >
      <rect
        width={props?.width || "32"}
        height={props?.height || "32"}
        rx="4"
        fill={theme.palette.primary.light}
      />
      <path
        d="M13.8223 22.5435H10.9152C10.5297 22.5435 10.16 22.3903 9.88738 22.1177C9.61479 21.8452 9.46165 21.4754 9.46165 21.0899V10.9152C9.46165 10.5297 9.61479 10.16 9.88738 9.88737C10.16 9.61478 10.5297 9.46164 10.9152 9.46164H13.8223"
        stroke="#E74959"
        strokeLinecap="round"
      />
      <path
        d="M18.907 19.6356L22.5408 16.0017L18.907 12.3679"
        stroke="#E74959"
        strokeLinecap="round"
      />
      <path
        d="M22.5422 16.0007H13.821"
        stroke="#E74959"
        strokeLinecap="round"
      />

      <defs>
        <linearGradient
          id="paint0_linear_59_2302"
          x1="0"
          y1="0"
          x2="32"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={STUDYO_PRIMARY_GRADIENT} />
          <stop offset="1" stopColor={STUDYO_SECONDARY_GRADIENT} />
        </linearGradient>
      </defs>
    </HoverPointerStyle>
  );
}
