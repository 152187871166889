import { useTheme } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
import EmptyLogo from "../../assets/EmptyLogo";

function EnergyConnector() {
  const width = 600;
  const height = 600;
  const path =
    "M600 0V10C316 12.2091 314.209 14 312 14H5C2.79086 15 1 15.7909 1 18V600";
  const theme = useTheme();
  return (
    <div
      data-version="v1"
      style={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      <EmptyLogo
        sx={{
          width: 80,
          height: 80,
          position: "absolute",
          marginRight: "20px",
          marginTop: "16px",
        }}
      />
      <svg
        fill="none"
        height="264"
        role="img"
        viewBox="0 0 891 264"
        width="891"
        data-lines="true"
        // style={{ paddingBottom: "64px" }}
        aria-label="A bunch of connecting lines that form into the CPU, with the text Powered By on top of the the CPU. Gradient lines are animating along the drawn lines, dissolving into the CPU in the center."
      >
        <path
          d="M388 96L388 68C388 65.7909 386.209 64 384 64L310 64"
          stroke={theme.palette.text.primary}
          stroke-opacity="0.1"
        ></path>
        <path
          d="M388 150L73 150C70.7909 150 69 151.791 69 154L69 174"
          stroke={theme.palette.text.primary}
          stroke-opacity="0.1"
        ></path>
        <g>
          <path
            d="M484 130L822 130C824.209 130 826 131.791 826 134L826 264"
            stroke={theme.palette.text.primary}
            stroke-opacity="0.1"
          ></path>
          <path
            d="M484 130L822 130C824.209 130 826 131.791 826 134L826 264"
            stroke="url(#pulsation-right2)"
            stroke-width="2"
          ></path>
        </g>
        <g>
          <path
            d="M388 130L5.00002 130C2.79088 130 1.00001 131.791 1.00001 134L1.00001 264"
            stroke={theme.palette.text.primary}
            stroke-opacity="0.1"
          ></path>
          <path
            d="M388 130L5.00002 130C2.79088 130 1.00001 131.791 1.00001 134L1.00001 264"
            stroke="url(#pulsation-left1)"
            stroke-linecap="round"
            stroke-width="2"
          ></path>
        </g>
        <g>
          <path
            d="M484 150L633 150C635.209 150 637 151.791 637 154L637 236C637 238.209 635.209 240 633 240L488 240C485.791 240 484 241.791 484 244L484 264"
            stroke={theme.palette.text.primary}
            stroke-opacity="0.1"
          ></path>
          <path
            d="M484 150L633 150C635.209 150 637 151.791 637 154L637 236C637 238.209 635.209 240 633 240L488 240C485.791 240 484 241.791 484 244L484 264"
            stroke="url(#pulsation-right3)"
            stroke-linecap="round"
            stroke-width="2"
          ></path>
        </g>
        <g>
          <path
            d="M388 184L388 194C388 196.209 386.209 198 384 198L77 198C74.7909 198 73 199.791 73 202L73 264"
            stroke={theme.palette.text.primary}
            stroke-opacity="0.1"
          ></path>
          <path
            d="M388 184L388 194C388 196.209 386.209 198 384 198L77 198C74.7909 198 73 199.791 73 202L73 264"
            stroke="url(#pulsation-left2)"
            stroke-linecap="round"
            stroke-width="2"
          ></path>
        </g>
        <path
          d="M412 96L412 64"
          stroke={theme.palette.text.primary}
          stroke-opacity="0.1"
        ></path>

        <g>
          <path
            d="M412 263.5L412 184"
            stroke={theme.palette.text.primary}
            stroke-opacity="0.1"
          ></path>
          <path
            d="M412 263.5L412 184"
            stroke="url(#pulsation-right1)"
            stroke-linecap="round"
            stroke-width="2"
          ></path>
        </g>
        <g>
          {/* <rect x="400" width="1000" height="1000" rx="15" fill={theme.palette.text.primary} /> */}
          {/* <rect
            x="0"
            width="1000"
            height="1000"
            rx="15"
            fill="url(#pulsation-left1)"
          /> */}

          <path
            d="M484 96L484 84C484 84 509.791 84 512 84L886 84C888.209 84 890 85.7909 890 88L890 264"
            stroke={theme.palette.text.primary}
            stroke-opacity="0.1"
          ></path>
          <path
            d="M484 96L484 84C484 84 509.791 84 512 84L886 84C888.209 84 890 85.7909 890 88L890 264"
            stroke="url(#pulsation-right1)"
            stroke-width="2"
          ></path>
          <defs>
            <motion.linearGradient
              animate={{
                x1: [width, 0],
                x2: [width + 60, 0 + 60],
                y1: [height, 0],
                y2: [height + 60, 0 + 60],
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
              }}
              id="pulsation-right1"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2EB9DF" stopOpacity="0" />
              <stop stopColor="#2EB9DF" />
              <stop offset="1" stopColor="#9E00FF" stopOpacity="0" />
            </motion.linearGradient>
            <motion.linearGradient
              animate={{
                x1: [width, 0],
                x2: [width + 60, 0 + 60],
                y1: [height, 0],
                y2: [height + 60, 0 + 60],
              }}
              transition={{
                duration: 2.5,
                repeat: Infinity,
              }}
              id="pulsation-right2"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2EB9DF" stopOpacity="0" />
              <stop stopColor="#2EB9DF" />
              <stop offset="1" stopColor="#9E00FF" stopOpacity="0" />
            </motion.linearGradient>
            <motion.linearGradient
              animate={{
                x2: [400, 488],
                x1: [450, 475],
                y2: [285, 150],
                y1: [260, 120],
              }}
              transition={{
                duration: 6.5,
                repeat: Infinity,
              }}
              id="pulsation-right3"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF4A81" stopOpacity="0" />
              <stop stopColor="#FF4A81" />
              <stop offset="1" stopColor="#9E00FF" stopOpacity="0" />
            </motion.linearGradient>
            <motion.linearGradient
              animate={{
                x1: [0 + 80, width + 80],
                x2: [0 + 80, width],
                y1: [height, height],
                y2: [height + 80, height],
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
              }}
              id="pulsation-left1"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#DF6CF6" stopOpacity="0" />
              <stop stopColor="#DF6CF6" />
              <stop offset="1" stopColor="#FF4A81" stopOpacity="0" />
            </motion.linearGradient>
            <motion.linearGradient
              animate={{
                x1: [0, width + 80],
                x2: [0, width],
                y1: [height, height],
                y2: [height + 80, height],
              }}
              transition={{
                duration: 7.5,
                repeat: Infinity,
              }}
              id="pulsation-left2"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF7432" stopOpacity="0" />
              <stop stopColor="#FF7432" />
              <stop offset="1" stopColor="#F7CC4B" stopOpacity="0" />
            </motion.linearGradient>

            <linearGradient
              gradientUnits="userSpaceOnUse"
              id="pink-pulse-2"
              x1="490"
              y1="266"
              x2="490"
              y2="284"
            >
              <stop stop-color="#FF4A81" stop-opacity="0"></stop>
              <stop offset="0.0564843" stop-color="#FF4A81"></stop>
              <stop offset="0.4616" stop-color="#DF6CF6"></stop>
              <stop offset="1" stop-color="#0196FF" stop-opacity="0"></stop>
            </linearGradient>
            <linearGradient
              gradientUnits="userSpaceOnUse"
              id="orange-pulse-1"
              x1="826"
              y1="270"
              x2="826"
              y2="340"
            >
              <stop stop-color="#FF7432" stop-opacity="0"></stop>
              <stop offset="0.0550784" stop-color="#FF7432"></stop>
              <stop offset="0.373284" stop-color="#F7CC4B"></stop>
              <stop offset="1" stop-color="#F7CC4B" stop-opacity="0"></stop>
            </linearGradient>
          </defs>
        </g>
        <path
          d="M436 96L436 0"
          stroke="url(#paint1_linear_341_27683)"
          stroke-opacity="0.1"
        ></path>
        <path
          d="M436 214L436 184"
          stroke={theme.palette.text.primary}
          stroke-opacity="0.1"
        ></path>
        <path
          d="M460 96L460 64"
          stroke={theme.palette.text.primary}
          stroke-opacity="0.1"
        ></path>
        <path
          d="M460 239L460 184"
          stroke={theme.palette.text.primary}
          stroke-opacity="0.1"
        ></path>
        {/* <path
          d="M484 96L484 24C484 21.7909 485.791 20 488 20L554 20"
          stroke="url(#paint2_linear_341_27683)"
          stroke-opacity="0.1"
        ></path> */}
        <path
          d="M484 184L484 210C484 212.209 485.791 214 488 214L560 214"
          stroke={theme.palette.text.primary}
          stroke-opacity="0.1"
        ></path>
        <path
          d="M508 184L508 193C508 195.209 509.791 197 512 197L560 197"
          stroke={theme.palette.text.primary}
          stroke-opacity="0.1"
        ></path>
        <circle
          cx="460"
          cy="64"
          fill={theme.palette.primary.light}
          r="4"
        ></circle>
        <circle
          cx="412"
          cy="64"
          fill={theme.palette.primary.light}
          r="4"
        ></circle>
        <circle
          cx="412"
          cy="64"
          r="3.5"
          stroke={theme.palette.text.primary}
          stroke-opacity="0.1"
        ></circle>
        <circle
          cx="460"
          cy="64"
          r="3.5"
          stroke={theme.palette.text.primary}
          stroke-opacity="0.1"
        ></circle>
        <circle
          cx="308"
          cy="64"
          fill={theme.palette.primary.light}
          r="4"
        ></circle>
        <circle
          cx="308"
          cy="64"
          r="3.5"
          stroke={theme.palette.text.primary}
          stroke-opacity="0.1"
        ></circle>
        <circle
          cx="69"
          cy="173"
          fill={theme.palette.primary.light}
          r="4"
        ></circle>
        <circle
          cx="69"
          cy="173"
          r="3.5"
          stroke={theme.palette.text.primary}
          stroke-opacity="0.1"
        ></circle>
        <circle
          cx="436"
          cy="214"
          fill={theme.palette.primary.light}
          r="4"
        ></circle>
        <circle
          cx="436"
          cy="214"
          r="3.5"
          stroke={theme.palette.text.primary}
          stroke-opacity="0.1"
        ></circle>
        <circle
          cx="460"
          cy="240"
          fill={theme.palette.primary.light}
          r="4"
        ></circle>
        <circle
          cx="460"
          cy="240"
          r="3.5"
          stroke={theme.palette.text.primary}
          stroke-opacity="0.1"
        ></circle>
        <circle
          cx="560"
          cy="214"
          fill={theme.palette.primary.light}
          r="4"
        ></circle>
        <circle
          cx="560"
          cy="214"
          r="3.5"
          stroke={theme.palette.text.primary}
          stroke-opacity="0.1"
        ></circle>
        <circle
          cx="560"
          cy="197"
          fill={theme.palette.primary.light}
          r="4"
        ></circle>
        <circle
          cx="560"
          cy="197"
          r="3.5"
          stroke={theme.palette.text.primary}
          stroke-opacity="0.1"
        ></circle>
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint0_linear_341_27683"
            x1="412.5"
            x2="412.5"
            y1="-3.27835e-08"
            y2="96"
          >
            <stop stop-opacity="0"></stop>
            <stop offset="1"></stop>
          </linearGradient>
          <motion.linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint1_linear_341_27683"
            x1="436.5"
            x2="436.5"
            y1="-3.27835e-08"
            y2="96"
          >
            <stop stop-opacity="0"></stop>
            <stop offset="1"></stop>
          </motion.linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint2_linear_341_27683"
            x1="554"
            x2="484"
            y1="20"
            y2="96"
          >
            <stop stop-opacity="0"></stop>
            <stop offset="1"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="blue-pulse-1"
            x1="83"
            y1="320"
            x2="83"
            y2="415"
          >
            <stop stop-color="#2EB9DF" stop-opacity="0"></stop>
            <stop offset="0.05" stop-color="#2EB9DF"></stop>
            <stop offset="1" stop-color="#2EB9DF" stop-opacity="0"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="blue-pulse-2"
            x1="83"
            y1="267.5"
            x2="83"
            y2="300"
          >
            <stop stop-color="#2EB9DF" stop-opacity="0"></stop>
            <stop offset="0.05" stop-color="#2EB9DF"></stop>
            <stop offset="1" stop-color="#2EB9DF" stop-opacity="0"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="pink-pulse-1"
            x1="412"
            y1="264"
            x2="412"
            y2="304"
          >
            <stop stop-color="#FF4A81" stop-opacity="0"></stop>
            <stop offset="0.030" stop-color="#FF4A81"></stop>
            <stop offset="0.27" stop-color="#DF6CF6"></stop>
            <stop offset="1" stop-color="#0196FF" stop-opacity="0"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="pink-pulse-2"
            x1="490"
            y1="266"
            x2="490"
            y2="284"
          >
            <stop stop-color="#FF4A81" stop-opacity="0"></stop>
            <stop offset="0.0564843" stop-color="#FF4A81"></stop>
            <stop offset="0.4616" stop-color="#DF6CF6"></stop>
            <stop offset="1" stop-color="#0196FF" stop-opacity="0"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="orange-pulse-1"
            x1="826"
            y1="270"
            x2="826"
            y2="340"
          >
            <stop stop-color="#FF7432" stop-opacity="0"></stop>
            <stop offset="0.0550784" stop-color="#FF7432"></stop>
            <stop offset="0.373284" stop-color="#F7CC4B"></stop>
            <stop offset="1" stop-color="#F7CC4B" stop-opacity="0"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="orange-pulse-2"
            x1="868"
            y1="280"
            x2="868"
            y2="440"
          >
            <stop stop-color="#FF7432" stop-opacity="0"></stop>
            <stop offset="0.0531089" stop-color="#FF7432"></stop>
            <stop offset="0.415114" stop-color="#F7CC4B"></stop>
            <stop offset="1" stop-color="#F7CC4B" stop-opacity="0"></stop>
          </linearGradient>
        </defs>
      </svg>
      {/* <div
        style={{
          position: "absolute",
          offsetPath: `path("${path}")`,
          width: 12,
          height: 12,
          backgroundColor: theme.palette.action.active,
          borderRadius: 12,
          border: `1px solid ${theme.palette.text.primary}`,
          //   offsetDistance: ((e.))
        }}
      ></div>
      <svg
        // class="animated-streaming_grid__LB6Qj"
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        width={width}
      >
        <path
          d={path}
          stroke="#C7C7C7"
          strokeWidth={"2.2"}
          fill="rgba(0,0,0,0)"
          strokeLinecap="round"
        />
      </svg> */}
    </div>
  );
}

export default EnergyConnector;
