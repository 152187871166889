import { styled } from "@mui/material/styles";
import { Box, Button, Card, Stack, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { isMobile } from "react-device-detect";
import {
  StudyTypographyMediumTitle,
  StudyTypographyMegaTitle,
} from "../glovebox/StudyTypography";
import { select } from "d3-selection";
import EmptyLogo from "../../assets/EmptyLogo";
import { HorizontalContainer } from "../glovebox/HorizontalContainer";
import { Link } from "react-router-dom";
import { STUDYO_GRADIENT } from "../../utils/constants";

const RootStyle = styled(Card)(({ theme, background }) => ({
  position: "relative", // Added this
  width: "100%",
  display: "flex",
  flexDirection: "column",
  height: isMobile || window.innerWidth < 425 ? null : 500,
  justifyContent: "space-between",
  boxShadow: "none",
  textAlign: "flex-start",
  alignItems: "flex-start",
  alignContent: "flex-start",
  paddingTop: "24px",
  backgroundColor: theme.palette.primary.light,
  borderRadius: "12px",
}));

function RandomLine({ hovered }) {
  const theme = useTheme();
  const ref = useRef();
  const [points, setPoints] = useState([]);

  useEffect(() => {
    if (ref.current) {
      const width = ref.current.clientWidth;
      const height = ref.current.clientHeight;
      const numPoints = 5;

      let lastX = 0;
      let counter = 0;

      const generatePoint = () => {
        if (counter === 0) {
          counter++;
          return [0, height];
        }
        if (counter === numPoints - 1) {
          counter = 0;
          return [width, height];
        }

        const widthPerQuadrant = width / numPoints;
        const minX = counter * widthPerQuadrant;
        const maxX = (counter + 1) * widthPerQuadrant;
        const newX = minX + Math.random() * (maxX - minX);
        const y = counter % 2 === 0 ? height : Math.random() * height;
        counter++;
        return [newX, y];
      };

      // Generate points only once
      if (points.length === 0) {
        setPoints(Array.from({ length: numPoints }).map(() => generatePoint()));
      }
    }
  }, [ref.current]);

  useEffect(() => {
    if (points.length > 0) {
      const svg = d3.select(ref.current);
      const lineGenerator = d3.line().curve(d3.curveCardinal);
      let path = svg.select("path");
      if (path.empty()) {
        path = svg.append("path");
      }

      path
        .datum(points)
        .attr("d", lineGenerator)
        .attr("stroke", hovered ? theme.palette.action.active : "rgba(0,0,0,0)")
        .attr("stroke-width", 5)
        .attr("stroke-dasharray", "100, 2")
        .attr(
          "fill",
          hovered ? "rgba(99,132,247, 0.2)" : "rgba(128,128,128, 0.2)"
        );

      if (hovered) {
        const totalLength = path.node().getTotalLength();
        path
          .attr("stroke-dasharray", totalLength + " " + totalLength)
          .attr("stroke-dashoffset", totalLength)
          .transition()
          .duration(1000)
          .ease(d3.easeLinear)
          .attr("stroke-dashoffset", 0);
      } else {
        path.interrupt();
      }
    }
  }, [hovered, points]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        bottom: 0,
        left: 0,
        zIndex: 0,
      }}
    >
      <svg ref={ref} width="100%" height="100%"></svg>
    </div>
  );
}

export default function TitleCard() {
  const [isHovered, setHovered] = useState(false);
  const theme = useTheme();

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        flexGrow: 1,
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      onMouseEnter={() => {
        setHovered(true);
      }}
    >
      <RootStyle>
        <HorizontalContainer
          sx={{
            gap: "18px",
            zIndex: 1,
            paddingLeft: "24px",
          }}
        >
          <EmptyLogo />
          <StudyTypographyMegaTitle sx={{ zIndex: 1 }}>
            Studyo
          </StudyTypographyMegaTitle>
        </HorizontalContainer>
        <Stack
          sx={{
            width: "100%",
            padding: "24px 0px 24px 24px",
            position: "relative",
            zIndex: 1,
          }}
        >
          <StudyTypographyMegaTitle
            variant="h3"
            color="grey.800"
            sx={{
              alignSelf: isMobile || window.innerWidth < 425 ? "center" : null,
              marginBottom: "8px",
              textAlign: isMobile || window.innerWidth < 425 ? "center" : null,
            }}
          >
            Seamless..
          </StudyTypographyMegaTitle>
          <StudyTypographyMegaTitle
            variant="h3"
            color="grey.800"
            sx={{
              alignSelf: isMobile || window.innerWidth < 425 ? "center" : null,
              marginBottom: "8px",
              textAlign: isMobile || window.innerWidth < 425 ? "center" : null,
            }}
          >
            Elegant..
          </StudyTypographyMegaTitle>
          <StudyTypographyMegaTitle
            variant="h3"
            color="grey.800"
            sx={{
              alignSelf: isMobile || window.innerWidth < 425 ? "center" : null,
              marginBottom: "8px",
              textAlign: isMobile || window.innerWidth < 425 ? "center" : null,
            }}
          >
            Groundbreaking..
          </StudyTypographyMegaTitle>
          <StudyTypographyMediumTitle
            variant="body2"
            sx={{
              opacity: 0.72,
              width: "70%",
              textAlign: isMobile || window.innerWidth < 425 ? "center" : null,
              marginBottom:
                isMobile || window.innerWidth < 425 ? "16px" : "0px",
            }}
          >
            An extension of the mind to plan your daily schedule
          </StudyTypographyMediumTitle>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          marginBottom={"48px"}
          zIndex={1}
          paddingLeft={"24px"}
        >
          <Link
            to="/register"
            style={{
              textDecoration: "none",
              color: "text.secondary",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: theme.palette.text.primary,
                backgroundColor: STUDYO_GRADIENT,
              }}
            >
              create account
            </Button>
          </Link>
          <Link to="/features">
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: theme.palette.text.primary,
                backgroundColor: STUDYO_GRADIENT,
              }}
            >
              features
            </Button>
          </Link>
        </Stack>

        <RandomLine hovered={isHovered} />
      </RootStyle>
    </motion.div>
  );
}
