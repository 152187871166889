import { createContext, ReactNode, useContext, useState } from "react";

interface DragContext {
  isDragging: boolean;
  setIsDragging: Function;
}

type Props = {
  children: ReactNode;
};

export const HotkeyContext = createContext<any>(null);

export const PageContext = createContext<any>(null);

export const ThemeContext = createContext<any>(null);

// export const UserContext = createContext(null);

export const DraggingContext = createContext<any>(null);

interface UserState {
  id: string;
  setId: (id: string) => void;
  googleUser: any;
  setGoogleUser: (googleUser: any) => void;
  // token: string;
  // email: string;
}

const UserContext = createContext<UserState>({
  id: "",
  setId: () => {},
  googleUser: null,
  setGoogleUser: () => {},
  // token: "test",
  // email: "test@studyo.com"
});

export function useUserContext() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useContext(UserContext);
}

export function UserProvider({ children }: Props) {
  const [id, setCurrentId] = useState<string>("");
  const [googleUser, setUser] = useState<any>(null);

  const setId = (id: string) => {
    setCurrentId(id);
  };

  const setGoogleUser = (user: any) => {
    setUser(user);
  };

  const value = {
    id,
    setId,
    googleUser,
    setGoogleUser,
  };

  return (
    <>
      <UserContext.Provider value={value}>{children}</UserContext.Provider>
    </>
  );
}
