import { HoverPointerStyle } from "./HoverPointerStyle";
import { useTheme } from "@mui/material";
import {
  STUDYO_PRIMARY_GRADIENT,
  STUDYO_SECONDARY_GRADIENT,
} from "../../utils/constants";

export function GCalendarIcon(props: {
  active: boolean;
  clicked: Function;
  width?: string;
  height?: string;
}) {
  const theme = useTheme();

  return (
    <HoverPointerStyle
      width={props?.width || "65"}
      height={props?.height || "65"}
      viewBox={`0 0 65 65`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        props.clicked();
      }}
    >
      {props.active ? (
        <rect
          width={props?.width || "65"}
          height={props?.height || "65"}
          rx="4"
          fill="url(#paint0_linear_59_2302)"
        />
      ) : (
        <rect
          width={props?.width || "65"}
          height={props?.height || "65"}
          rx="4"
          fill={theme.palette.primary.light}
        />
      )}

      <svg
        width="65"
        height="65"
        viewBox="0 0 65 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="65"
          height="65"
          rx="6"
          fill={theme.palette.primary.light}
        />
        <path
          d="M45.2222 16.7774H18.7778C16.6914 16.7774 15 18.4688 15 20.5552V46.9997C15 49.0861 16.6914 50.7774 18.7778 50.7774H45.2222C47.3086 50.7774 49 49.0861 49 46.9997V20.5552C49 18.4688 47.3086 16.7774 45.2222 16.7774Z"
          stroke="#777777"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M39.5582 13V20.5556"
          stroke="#777777"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.4418 13V20.5556"
          stroke="#777777"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15 28.1107H49"
          stroke="#777777"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <defs>
        <linearGradient
          id="paint0_linear_59_2302"
          x1="0"
          y1="0"
          x2="32"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={STUDYO_PRIMARY_GRADIENT} />
          <stop offset="1" stopColor={STUDYO_SECONDARY_GRADIENT} />
        </linearGradient>
      </defs>
    </HoverPointerStyle>
  );
}
