import {
  AccessAlarmRounded,
  AccountBoxOutlined,
  ArrowDropDownRounded,
  CalendarMonthOutlined,
  GroupAddOutlined,
  IosShare,
  ListAltOutlined,
  MeetingRoomOutlined,
  PeopleAltOutlined,
  SettingsOutlined,
} from "@mui/icons-material";

import {
  Badge,
  Button,
  ClickAwayListener,
  Stack,
  Tooltip,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import moment from "moment";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation } from "react-router-dom";
import {
  DeleteTodo,
  NULL_REOCCURING_COMPLETEDAT,
  NULL_TODO_COMPLETEDAT,
  UPDATE_CHILD_TODO,
  UPDATE_REOCCURRING_TODOS,
  UPDATE_TODO_UUID,
  removeTodoStart,
  updateTodoTitle,
} from "../../graphql/mutations/todo";
import { client } from "../../utils/apollo";
import { PriorityInput, timeDuration } from "../../utils/constants";
import { HotkeyContext, PageContext, ThemeContext } from "../../utils/contexts";
import { HOTKEYS } from "../../utils/hotkeyHelper";
import { CheckIcon } from "../Icons/CheckIcon";
import {
  StudyTypographyMediumSubtitle,
  StudyTypographySmallText,
} from "../glovebox/StudyTypography";
import {
  CalendarViewLabel,
  CalendarViewStatusNotifier,
  CalendarViewWarningIcon,
  StyledBox,
  StyledCloseButton,
  StyledDialog,
  StyledDialogTopBar,
  StyledHeader,
} from "../mainbar/calendar/styles";
import { XIcon } from "../sidebar/Icons";
import {
  CustomInput,
  ProfileBlockPopper,
  ToolbarDurationBox,
  ToolbarToDoDurationText,
} from "../toolbar/styles";
import DurationBox from "./DurationBox";
var Sherlock = require("sherlockjs");

export const EditToDoModal = ({
  todo,
  openEdit,
  setOpenEditTodo,
  setTodos,
  todos,
  title,
}: {
  todo: any;
  openEdit: any;
  setOpenEditTodo: any;
  setTodos: any;
  todos: any;
  title: any;
}) => {
  const [currentTodo, setCurrentTodo] = useState(todo);
  // console.log(currentTodo, currentTodo.completedAt);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElPriority, setAnchorElPriority] = useState<null | HTMLElement>(
    null
  );
  const [openSearch, setOpenSearch] = useState(false);
  const [pages, setPages] = useState<string[]>(["home"]);
  const [selectedTitle, setSelectedTitle] = useState(
    title
    // todo?.todoId
    //   ? todos.filter((curr: any) => {
    //       return curr.uuid === todo.todoId;
    //     }).title
    //   : todo.title
  );
  const [duration, setDuration] = useState(todo?.duration);
  const [editTodo, setEditTodo] = useState(false);
  const [editTodoText, setEditTodoText] = useState(todo?.title);
  const [startText, setStartText] = useState<any>(
    moment(todo?.startTime).isValid()
      ? moment(todo?.startTime).format("MM/DD H:MMa")
      : null
  );
  const [dueText, setDueText] = useState<any>(
    moment(todo?.dueAt).isValid()
      ? moment(todo?.dueAt).format("MM/DD H:MMa")
      : null
  );
  const [start, setStart] = useState<any>(
    moment(todo?.startTime).format("MM/DD H:MMa")
  );
  const [due, setDue] = useState<any>(
    moment(todo?.dueAt).format("MM/DD H:MMa")
  );
  const [isReoccurring, setIsReoccurring] = useState(false);
  const location = useLocation();
  const [inputValue, setInputValue] = useState("");
  const [durationAnchorEl, setDurationAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const durationOpen = Boolean(durationAnchorEl);

  const handleDurationClick = (event: React.MouseEvent<HTMLElement>) => {
    setDurationAnchorEl(durationAnchorEl ? null : event.currentTarget);
  };

  useEffect(() => {
    localStorage.setItem("todos", JSON.stringify(todos));
    let currTodo = { ...todo };
    if (todo?.todoId) {
      currTodo = todos.filter((curr: any) => {
        return curr?.uuid === todo.todoId;
      })[0];
    }
    setCurrentTodo(todo);
    // console.log(currTodo)
    setIsReoccurring(currTodo?.reoccurring);
    setSelectedTitle(currTodo?.title);
    setDuration(
      currTodo?.reoccurring && todo?.todoReplicas
        ? todo?.todoReplicas[0]?.duration
        : todo?.duration
    );
    setStart(moment(todo?.startTime).isValid() ? todo?.startTime : null);
    setStartText(
      moment(todo?.startTime).isValid()
        ? moment(todo?.startTime).format("M/D hh:mma")
        : null
    );
    setDue(moment(currTodo?.dueAt).isValid() ? currTodo?.dueAt : null);
    setDueText(
      moment(currTodo?.dueAt).isValid()
        ? moment(currTodo?.dueAt).format("M/D hh:mma")
        : null
    );
  }, [todo]);

  // console.log(isReoccurring);
  // console.log(todo.completedAt)

  const theme = useTheme();

  const [priority, setPriority] = useState({
    name: "Low",
    // icon:
    color: theme.palette.warning.light,
    priority: PriorityInput.LOW,
  });
  // const user = JSON.parse(localStorage.getItem("user")!);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")!) || []
  );
  const [search, setSearch] = useState("");

  // const { id: userId } = useUserContext();
  const userId = localStorage.getItem("userId");
  const { showBadge, setShowBadge } = useContext(HotkeyContext);
  const { themeValue, setThemeValue } = useContext(ThemeContext);
  // const { user, setUser } = useContext(UserContext);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const [notificationAnchorEl, setNotificationAnchorEl] =
    useState<null | HTMLElement>(null);

  const notificationOpen = Boolean(notificationAnchorEl);

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(notificationAnchorEl ? null : event.currentTarget);
  };
  const handleNotificationClickByHotkey = (notificationRef: any) => {
    setNotificationAnchorEl(notificationAnchorEl ? null : notificationRef);
  };

  const [searchAnchorEl, setSearchAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const searchOpen = Boolean(searchAnchorEl);

  const handleSearchClick = (event: React.MouseEvent<HTMLElement>) => {
    setSearchAnchorEl(searchAnchorEl ? null : event.currentTarget);
  };

  // useEffect(() => {
  //   const down = (e: any) => {
  //     e.preventDefault();
  //     if (e.key === "f" && e.metaKey) {
  //       setOpenSearch((openSearch) => !openSearch);
  //     }
  //   };

  //   document.addEventListener("keydown", down);
  //   return () => document.removeEventListener("keydown", down);
  // }, []);
  useHotkeys("command+f", (event: any) => {
    event.preventDefault();
    setOpenSearch(!openSearch);
  });
  // console.log(openSearch);

  const [titleAnchorEl, setTitleAnchorEl] = useState<null | HTMLElement>(null);
  const [titleAnchorElPriority, setTitleAnchorElPriority] =
    useState<null | HTMLElement>(null);

  const titleOpen = Boolean(titleAnchorEl);
  const priorityOpen = Boolean(titleAnchorElPriority);
  const handleTitleClick = (event: React.MouseEvent<HTMLElement>) => {
    setTitleAnchorEl(titleAnchorEl ? null : event.currentTarget);
  };

  const handlePriorityClick = (event: React.MouseEvent<HTMLElement>) => {
    setTitleAnchorElPriority(
      titleAnchorElPriority ? null : event.currentTarget
    );
  };

  const handleTitleClickAway = () => {
    setTitleAnchorEl(null);
  };
  useHotkeys(
    HOTKEYS.OPEN_NOTIFICATION,
    (event: any) => {
      if (notificationAnchorEl === null) {
        handleNotificationClickByHotkey(notificationRef.current);
      } else {
        handleNotificationClickByHotkey(notificationRef.current);
      }
    },
    [notificationAnchorEl]
  );
  const handleClickAway = () => {
    setAnchorEl(null);
  };
  // const [todos, setTodos] = useState(
  //   JSON.parse(localStorage.getItem("todos")!) || []
  // );

  // useEffect(() => {
  //   console.log(todo);
  // }, []);

  const updateReoccurringTodo = (editTodo: any) => {
    console.log(editTodo);
    let changeTitle = false;
    let parentTodo = editTodo.uuid;
    if (editTodo.title !== selectedTitle) {
      console.log("change title");
      changeTitle = true;
    }
    let newTodos = [...todos];
    newTodos = newTodos.map((oldTodo: any) => {
      let newTodo = { ...oldTodo };
      if (newTodo.uuid === editTodo.todoId || newTodo.uuid === editTodo.uuid) {
        if (changeTitle) {
          newTodo.title = selectedTitle;
          parentTodo = newTodo.uuid;
        }
        newTodo.todoReplicas = newTodo.todoReplicas?.map((child: any) => {
          let newChild = { ...child };
          newChild.duration = duration;
          return newChild;
        });
      }
      return newTodo;
    });
    setTodos(newTodos);
    if (changeTitle) {
      client.mutate({
        mutation: UPDATE_TODO_UUID,
        variables: {
          uuid: parentTodo,
          title: selectedTitle,
        },
      });
    }
    client.mutate({
      mutation: UPDATE_REOCCURRING_TODOS,
      variables: {
        todoId: parentTodo,
        duration: duration,
      },
    });
  };

  const nullTodoStart = (todo: any) => {
    toast.promise(
      removeTodoStart(todo, todos, setTodos),
      {
        loading: "updating todo..",
        success: <b>start time update saved!</b>,
        error: <b>Could not save.</b>,
      },
      {
        duration: 4000,
        style: {
          fontSize: "12px",
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.primary,
        },
      }
    );
  };

  const updateTodo = (todo: any) => {
    console.log("update todo");
    let reoccurring = false;
    let timeChange = 0;
    let parentId = "";
    if (todo.todoId) {
      let changeParent = false;
      let newTodos = [...todos];
      newTodos = newTodos?.map((ctodo) => {
        let newTodo = { ...ctodo };
        if (ctodo.uuid === todo.todoId) {
          if (ctodo.dueAt !== due) {
            newTodo.due = due;
            changeParent = true;
          }
          if (ctodo.title !== selectedTitle) {
            newTodo.title = selectedTitle;
            changeParent = true;
          }
          newTodo.todoReplicas = newTodo.todoReplicas?.map((child: any) => {
            let newChild = { ...child };
            if (child.uuid === todo.uuid) {
              if (child.startTime !== start) {
                newChild.startTime = start;
              }
              if (child.duration !== duration) {
                newChild.duration = duration;
              }
            }
            return newChild;
          });
        }
        return newTodo;
      });
      // console.log(newTodos)
      setTodos(newTodos);
      if (changeParent) {
        client.mutate({
          mutation: UPDATE_TODO_UUID,
          variables: {
            uuid: todo.todoId,
            dueAt: due,
            title: selectedTitle,
          },
        });
      }
      client.mutate({
        mutation: UPDATE_CHILD_TODO,
        variables: {
          startTime: start,
          duration: duration,
          todoId: todo.uuid,
        },
      });
    } else {
      let newTodos = [...todos];
      newTodos = newTodos?.map((ctodo) => {
        let newTodo = { ...ctodo };
        if (ctodo.uuid === todo.uuid) {
          newTodo.startTime = start;
          newTodo.duration = duration;
          newTodo.dueAt = due;
          newTodo.title = selectedTitle;
        }
        return newTodo;
      });
      // console.log(newTodos)
      setTodos(newTodos);
      client.mutate({
        mutation: UPDATE_TODO_UUID,
        variables: {
          startTime: start,
          duration: duration,
          uuid: todo.uuid,
          dueAt: due,
          title: selectedTitle,
        },
      });
    }
    // console.log(todo, start, due, duration, selectedTitle);
  };

  // useEffect(() => {
  //   localStorage.setItem("todos", todos);
  // }, [todos]);

  // useEffect(() => {
  //   localStorage.setItem("user", user);
  // }, [user]);

  // const allTodos = todos
  //   .map((parent: any) => {
  //     if (parent.todoReplicas) {
  //       let children = parent.todoReplicas.map((child: any) => ({
  //         ...child,
  //         title: parent.title,
  //       }));
  //       return [parent, ...children];
  //     }
  //     return [parent];
  //   }) // Merge parent and children into an array
  //   .reduce((prev: any, curr: any) => prev.concat(curr));

  // const imageSrc = user?.profileImageURL;

  const setPageWithUrl = (path: any) => {
    if (path.includes("app/schedule")) {
      return "Schedule";
    } else if (path.includes("app/calendar")) {
      return "Calendar";
    } else if (path.includes("app/todos")) {
      return "Todos";
    } else if (path.includes("app/cowork")) {
      return "Cowork Space";
    } else if (path.includes("app/friends")) {
      return "Friends";
    } else if (path.includes("app/addfriend")) {
      return "Add Friends";
    } else if (path.includes("app/settings")) {
      return "Settings";
    }
  };

  const { page, setPage, showMainPage, setShowMainPage } =
    useContext(PageContext);

  useEffect(() => {
    setPage(setPageWithUrl(location.pathname));
  }, [location]);

  const listItems = [
    {
      name: "Profile",
      icon: (
        <AccountBoxOutlined style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "settings",
    },
    {
      name: "Friends",
      icon: (
        <PeopleAltOutlined style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "friends",
    },
    {
      name: "Settings",
      icon: (
        <SettingsOutlined style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "settings",
    },
  ];
  const notificationRef = useRef(null);
  let inputRef: any = useRef(null);
  //   useHotkeys(HOTKEYS.GLOBAL_SEARCH, () => {
  //     //@ts-ignore
  //     inputRef?.current?.focus();
  //     setIsFocused(true);
  //   });

  const priorityItems = [
    {
      name: "Low",
      // icon:
      color: theme.palette.warning.light,
      priority: PriorityInput.LOW,
    },
    {
      name: "Medium",
      // icon:
      color: theme.palette.warning.main,

      priority: PriorityInput.MEDIUM,
    },
    {
      name: "High",
      // icon:
      color: theme.palette.error.light,

      priority: PriorityInput.HIGH,
    },
    {
      name: "Urgent",
      // icon:
      color: theme.palette.error.dark,

      priority: PriorityInput.URGENT,
    },
  ];

  const titleListItems = [
    {
      name: "Calendar",
      icon: (
        <CalendarMonthOutlined
          style={{ color: theme.palette.secondary.dark }}
        />
      ),
      to: "/app/calendar",
    },
    {
      name: "Todos",
      icon: <ListAltOutlined style={{ color: theme.palette.secondary.dark }} />,
      to: "/app/todos",
    },
    {
      name: "Schedule",
      icon: (
        <AccessAlarmRounded style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "/app/schedule",
    },
    {
      name: "Cowork",
      icon: (
        <MeetingRoomOutlined style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "/app/cowork",
    },
    {
      name: "Friends",
      icon: (
        <PeopleAltOutlined style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "/app/friends",
    },
    {
      name: "AddFriends",
      icon: (
        <GroupAddOutlined style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "/app/addfriend",
    },
    {
      name: "Settings",
      icon: (
        <SettingsOutlined style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "/app/settings",
    },
  ];
  // console.log(priority);

  const mobileTitleListItems = [
    {
      name: "Calendar",
      icon: (
        <CalendarMonthOutlined
          style={{ color: theme.palette.secondary.dark }}
        />
      ),
      to: "/app/calendar",
    },
    {
      name: "Todos",
      icon: <ListAltOutlined style={{ color: theme.palette.secondary.dark }} />,
      to: "/app/todos",
    },
    {
      name: "Schedule",
      icon: <ListAltOutlined style={{ color: theme.palette.secondary.dark }} />,
      to: "/app/schedule",
    },
    {
      name: "Cowork",
      icon: (
        <MeetingRoomOutlined style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "/app/cowork",
    },
    {
      name: "Friends",
      icon: (
        <PeopleAltOutlined style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "/app/friends",
    },
    {
      name: "AddFriends",
      icon: (
        <GroupAddOutlined style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "/app/addfriend",
    },
    {
      name: "Settings",
      icon: (
        <SettingsOutlined style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "/app/settings",
    },
  ];

  const copyScheduleToClipboard = async () => {
    // console.log(availableSchedule);
    const stringStart = moment(start).format("H:mma");
    const endString = moment(start).add(duration, "minutes").format("H:mma");
    let message = `Title: ${selectedTitle} \nTime: ${stringStart} - ${endString}\n\nPowered by mystudyo.com`;
    navigator.clipboard.writeText(message);
  };

  const updateTodoTitleHelper = (oldTodo: any) => {
    toast.promise(
      updateTodoTitle(oldTodo, editTodoText, todos, setTodos),
      {
        loading: "adding todo..",
        success: <b>todo saved!</b>,
        error: <b>Could not save.</b>,
      },
      {
        duration: 4000,
        style: {
          fontSize: "12px",
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.primary,
        },
      }
    );
  };

  const updateTodoStateHelper = (
    oldTodo: any,
    completed: boolean,
    deleted: boolean
  ) => {
    // console.log("here");
    console.log("update todo state");
    if (completed) {
      if (oldTodo.todoId) {
        let newTodos = [...todos];
        newTodos = newTodos?.map((ctodo) => {
          let newTodo = { ...ctodo };
          if (ctodo.uuid === oldTodo.todoId) {
            newTodo.todoReplicas = newTodo.todoReplicas?.map((child: any) => {
              let newChild = { ...child };
              if (child.uuid === oldTodo.uuid) {
                newChild.completedAt = completed
                  ? new Date()
                  : ctodo.completedAt;
                let newCurrentTodo = { ...currentTodo };
                newCurrentTodo.completedAt = completed
                  ? new Date()
                  : child.completedAt;
                setCurrentTodo(newCurrentTodo);
              }
              return newChild;
            });
          }
          return newTodo;
        });
        setTodos(newTodos);
        toast.promise(
          client.mutate({
            mutation: UPDATE_CHILD_TODO,
            variables: {
              todoId: oldTodo.uuid,
              completed: completed,
            },
          }),

          {
            loading: "updating todo..",
            success: <b>todo saved!</b>,
            error: <b>Could not save.</b>,
          },
          {
            duration: 4000,
            style: {
              fontSize: "12px",
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.text.primary,
            },
          }
        );
      } else {
        let newTodos = [...todos];
        newTodos = newTodos?.map((ctodo) => {
          let newTodo = { ...ctodo };
          if (ctodo.uuid === oldTodo.uuid) {
            newTodo.completedAt = completed ? new Date() : ctodo.completedAt;
            newTodo.deletedAt = deleted ? new Date() : ctodo.deletedAt;
            let newCurrentTodo = { ...currentTodo };
            newCurrentTodo.completedAt = completed
              ? new Date()
              : ctodo.completedAt;
            setCurrentTodo(newCurrentTodo);
          }
          return newTodo;
        });
        setTodos(newTodos);
        toast.promise(
          client.mutate({
            mutation: UPDATE_TODO_UUID,
            variables: {
              uuid: oldTodo.uuid,
              completed: completed,
            },
          }),

          {
            loading: "updating todo..",
            success: <b>todo saved!</b>,
            error: <b>Could not save.</b>,
          },
          {
            duration: 4000,
            style: {
              fontSize: "12px",
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.text.primary,
            },
          }
        );
      }
    } else {
      if (oldTodo.todoId) {
        let newTodos = [...todos];
        newTodos = newTodos?.map((ctodo) => {
          let newTodo = { ...ctodo };
          if (ctodo.uuid === oldTodo.todoId) {
            newTodo.todoReplicas = newTodo.todoReplicas?.map((child: any) => {
              let newChild = { ...child };
              if (child.uuid === oldTodo.uuid) {
                newChild.completedAt = null;
                let newCurrentTodo = { ...currentTodo };
                newCurrentTodo.completedAt = null;
                setCurrentTodo(newCurrentTodo);
              }
              return newChild;
            });
          }
          return newTodo;
        });
        setTodos(newTodos);
        toast.promise(
          client.mutate({
            mutation: NULL_REOCCURING_COMPLETEDAT,
            variables: {
              uuid: oldTodo.uuid,
            },
          }),

          {
            loading: "updating todo..",
            success: <b>todo saved!</b>,
            error: <b>Could not save.</b>,
          },
          {
            duration: 4000,
            style: {
              fontSize: "12px",
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.text.primary,
            },
          }
        );
      } else {
        let newTodos = [...todos];
        newTodos = newTodos?.map((ctodo) => {
          let newTodo = { ...ctodo };
          if (ctodo.uuid === oldTodo.uuid) {
            newTodo.completedAt = null;
            let newCurrentTodo = { ...currentTodo };
            newCurrentTodo.completedAt = null;
            setCurrentTodo(newCurrentTodo);
          }
          return newTodo;
        });
        setTodos(newTodos);
        toast.promise(
          client.mutate({
            mutation: NULL_TODO_COMPLETEDAT,
            variables: {
              uuid: oldTodo.uuid,
            },
          }),

          {
            loading: "updating todo..",
            success: <b>todo saved!</b>,
            error: <b>Could not save.</b>,
          },
          {
            duration: 4000,
            style: {
              fontSize: "12px",
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.text.primary,
            },
          }
        );
      }
    }
  };

  const showTextForTitle = () => {
    if (page === "Calendar") {
      return showMainPage ? "Todays Events" : "Monthly Calendar";
    } else if (page === "Schedule") {
      return showMainPage ? "Show Todos" : "Show Schedule";
    }
  };

  const ref = useRef<HTMLDivElement | null>(null);

  const popPage = useCallback(() => {
    setPages((pages) => {
      const x = [...pages];
      x.splice(-1, 1);
      return x;
    });
  }, []);
  const activePage = pages[pages.length - 1];

  const isHome = activePage === "home";

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (isHome || inputValue.length) {
        return;
      }

      if (e.key === "Backspace") {
        e.preventDefault();
        popPage();
      }
    },
    [inputValue.length, isHome, popPage]
  );

  function bounce() {
    if (ref.current) {
      ref.current.style.transform = "scale(0.96)";
      setTimeout(() => {
        if (ref.current) {
          ref.current.style.transform = "";
        }
      }, 100);

      setInputValue("");
    }
  }

  return (
    <StyledDialog
      open={openEdit}
      onClose={() => {
        setOpenEditTodo(false);
      }}
      aria-labelledby="archive-task-modal"
      aria-describedby="modal-modal-description"
      style={{
        zIndex: 1,
        backgroundColor: "transparent",
        borderRadius: "12px",
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <StyledBox
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <StyledDialogTopBar>
          {editTodo ? (
            <ClickAwayListener
              onClickAway={() => {
                console.log(editTodoText);
                updateTodoTitleHelper(todo);
                setEditTodo(false);
                setEditTodoText("");
              }}
            >
              <Stack alignItems={"row"} flexDirection="row" width={"100%"}>
                <motion.div
                  whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
                  whileHover={{ rotate: 10 }}
                  transition={{
                    type: "spring",
                    stiffness: 200,
                    damping: 8,
                  }}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    marginRight: "12px",
                  }}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    updateTodoStateHelper(
                      currentTodo,
                      currentTodo?.completedAt ? false : true,
                      false
                    );
                  }}
                >
                  <CheckIcon
                    height={"24px"}
                    width={"24px"}
                    active={currentTodo?.completedAt !== null}
                    clicked={() => {}}
                  />
                  {/* {data?.completedAt} */}
                </motion.div>
                <input
                  ref={inputRef}
                  aria-label="Demo input"
                  onChange={(text) => {
                    setEditTodoText(text.currentTarget.value);
                  }}
                  style={{
                    border: "0px",
                    backgroundColor: theme.palette.primary.light,
                    width: "100%",
                    height: "30px",

                    outlineWidth: 0,
                    borderRadius: "4px",
                    color: theme.palette.text.primary,
                    userSelect: "text",
                    marginRight: "12px",
                  }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                  }}
                  autoFocus
                  value={selectedTitle}
                />
              </Stack>
            </ClickAwayListener>
          ) : (
            <Stack alignItems={"row"} flexDirection="row">
              <motion.div
                whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
                whileHover={{ rotate: 10 }}
                transition={{
                  type: "spring",
                  stiffness: 200,
                  damping: 8,
                }}
                style={{
                  alignItems: "center",
                  display: "flex",
                  marginRight: "12px",
                }}
                onClick={(event: any) => {
                  event.stopPropagation();
                  updateTodoStateHelper(
                    currentTodo,
                    currentTodo?.completedAt ? false : true,
                    false
                  );
                }}
              >
                <CheckIcon
                  height={"24px"}
                  width={"24px"}
                  active={currentTodo?.completedAt !== null}
                  clicked={() => {}}
                />
                {/* {data?.completedAt} */}
              </motion.div>
              <StyledHeader
                style={{
                  marginLeft: 0,
                }}
                onClick={(e: any) => {
                  e.stopPropagation();
                  setEditTodo(true);
                  setEditTodoText(todo?.title);
                }}
              >
                {todo?.title}
              </StyledHeader>
            </Stack>
          )}

          <StyledCloseButton
            onClick={() => {
              setOpenEditTodo(false);
              // setIsReoccurring(false);
            }}
          >
            <XIcon />
          </StyledCloseButton>
        </StyledDialogTopBar>
        {isReoccurring ? (
          <CalendarViewStatusNotifier
            style={{ marginBottom: "12px", padding: "12px" }}
          >
            <CalendarViewWarningIcon />
            <CalendarViewLabel>
              Editing this todo will change all other reoccurring. Edit
              individual event on the calendar.
            </CalendarViewLabel>
          </CalendarViewStatusNotifier>
        ) : (
          <div />
        )}
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          borderRadius={"4px"}
          marginBottom={"12px"}
          width={"100%"}
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          <StudyTypographySmallText
            marginRight={"12px"}
            width={"80px"}
            flexShrink={0}
          >{`TITLE: `}</StudyTypographySmallText>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            padding="6px"
            borderRadius={"4px"}
            width={"100%"}
            style={{ backgroundColor: theme.palette.primary.light }}
          >
            <CustomInput
              ref={inputRef}
              aria-label="Demo input"
              placeholder={`ex. tomorrow 3PM`}
              onChange={(text) => {
                setSelectedTitle(text.currentTarget.value);
              }}
              autoFocus
              style={{
                fontSize: "10px",
                padding: "0px",
                height: "32px",
                flex: 1,
              }}
              value={selectedTitle}
            />
          </Stack>
        </Stack>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          borderRadius={"4px"}
          marginBottom={"12px"}
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          <StudyTypographySmallText
            marginRight={"12px"}
            width={"80px"}
            flexShrink={0}
          >{`DURATION: `}</StudyTypographySmallText>

          <DurationBox
            anchorEl={durationAnchorEl}
            setAnchorEl={setDurationAnchorEl}
            num={duration}
          />
          <ProfileBlockPopper
            open={durationOpen}
            anchorEl={durationAnchorEl}
            placement={"bottom-start"}
            modifiers={[{ name: "arrow", enabled: true }]}
            style={{
              backgroundColor: "rgba(0,0,0,0)",
              margin: 0,
              paddingRight: 0,
              paddingTop: "8px",
              zIndex: 100,
            }}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          >
            <div
              style={{
                alignItems: "center",
                display: "inline-grid",
                justifyContent: "center",
                backgroundColor: theme.palette.primary.main,
                border: `1px solid ${theme.palette.secondary.light}`,
                padding: "12px",
                borderRadius: "4px",
              }}
            >
              {timeDuration.map((time: any, index: any) => (
                <ToolbarToDoDurationText
                  style={{
                    padding: "4px",
                    textAlign: "center",
                  }}
                  key={index}
                  onClick={(event: any) => {
                    // updateTodo({
                    //   variables: { duration: time, uuid: data?.uuid },
                    // });
                    event.stopPropagation();
                    setDuration(time);
                    setDurationAnchorEl(null);
                  }}
                >
                  {time} min
                </ToolbarToDoDurationText>
              ))}
            </div>
          </ProfileBlockPopper>
        </Stack>
        {!isReoccurring ? (
          <div>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              borderRadius={"4px"}
              marginBottom={"12px"}
              style={{ backgroundColor: theme.palette.primary.main }}
            >
              <StudyTypographySmallText
                marginRight={"12px"}
                width={"80px"}
                flexShrink={0}
              >{`START: `}</StudyTypographySmallText>
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                padding="6px"
                borderRadius={"4px"}
                width={"100%"}
                style={{ backgroundColor: theme.palette.primary.light }}
              >
                <CustomInput
                  ref={inputRef}
                  aria-label="Demo input"
                  placeholder={`ex. tomorrow 3PM`}
                  onChange={(text) => {
                    setStartText(text.currentTarget.value);
                    var sherlocked = Sherlock.parse(text.currentTarget.value);
                    console.log(sherlocked);
                    setStart(sherlocked.startDate);
                  }}
                  style={{
                    fontSize: "10px",
                    padding: "0px",
                    height: "32px",
                  }}
                  value={startText}
                />
              </Stack>

              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                padding="8px"
                marginLeft={"12px"}
                borderRadius={"4px"}
                sx={{
                  backgroundColor: theme.palette.primary.light,
                  flexShrink: 0,
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <StudyTypographySmallText>
                  {`${
                    moment(start).isValid()
                      ? moment(start).format("M/D hh:mma")
                      : "none"
                  }`}
                </StudyTypographySmallText>
              </Stack>
            </Stack>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              borderRadius={"4px"}
              marginBottom={"12px"}
              style={{ backgroundColor: theme.palette.primary.main }}
            >
              <StudyTypographySmallText
                marginRight={"12px"}
                width={"80px"}
                flexShrink={0}
              >{`DUE: `}</StudyTypographySmallText>
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                padding="6px"
                borderRadius={"4px"}
                width={"100%"}
                style={{ backgroundColor: theme.palette.primary.light }}
              >
                <CustomInput
                  ref={inputRef}
                  aria-label="Demo input"
                  placeholder={`ex. tomorrow 3PM`}
                  onChange={(text) => {
                    setDueText(text.currentTarget.value);
                    var sherlocked = Sherlock.parse(text.currentTarget.value);
                    console.log(sherlocked);
                    setDue(sherlocked.startDate);
                  }}
                  style={{
                    fontSize: "10px",
                    padding: "0px",
                    height: "32px",
                  }}
                  value={dueText}
                />
              </Stack>

              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                padding="8px"
                marginLeft={"12px"}
                borderRadius={"4px"}
                sx={{
                  flexShrink: 0,

                  backgroundColor: theme.palette.primary.light,
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <StudyTypographySmallText style={{ height: "100%" }}>
                  {`${
                    moment(due).isValid()
                      ? moment(due).format("M/D hh:mma")
                      : "none"
                  }`}
                </StudyTypographySmallText>
              </Stack>
            </Stack>
          </div>
        ) : null}
        <div
          style={{
            width: "100%",
            height: "32px",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            marginTop: "24px",
          }}
        >
          <Button
            variant="text"
            color="secondary"
            sx={{
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.error.dark,
              marginRight: "12px",
              height: "100%",
              "&:hover": {
                backgroundColor: theme.palette.secondary.light,
              },
            }}
            onClick={() => {
              DeleteTodo(todo, todos, setTodos);
              setOpenEditTodo(false);
            }}
          >
            delete
          </Button>
          <Stack flexDirection={"row"} alignItems={"center"}>
            <Button
              variant="text"
              color="secondary"
              sx={{
                backgroundColor: theme.palette.primary.light,
                height: "100%",
                marginRight: "12px",
                "&:hover": {
                  backgroundColor: theme.palette.secondary.light,
                },
              }}
              onClick={() => {
                nullTodoStart(todo);
              }}
            >
              remove
            </Button>
            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                backgroundColor: theme.palette.primary.light,
                borderRadius: "4px",
                marginRight: "12px",
              }}
            >
              <Badge
                color={"primary"}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                overlap="circular"
              >
                <Tooltip title={"copy time to share"} placement={"top"}>
                  <IosShare
                    sx={{
                      padding: "8px",
                      borderRadius: "4px",
                      width: "16px",
                      height: "16px",
                      // backgroundColor:
                      //   typeCal === "timeGridDay"
                      //     ? theme.palette.primary.main
                      //     : theme.palette.primary.light,
                      "&:hover": {
                        backgroundColor: theme.palette.secondary.light,
                        cursor: "pointer",
                      },
                    }}
                    onClick={async () => {
                      toast.promise(
                        copyScheduleToClipboard(),
                        {
                          loading: "copying times..",
                          success: <b>times copied to clipboard!</b>,
                          error: <b>Could not copy.</b>,
                        },
                        {
                          duration: 4000,
                          style: {
                            fontSize: "12px",
                            backgroundColor: theme.palette.primary.light,
                            color: theme.palette.text.primary,
                          },
                        }
                      );
                    }}
                    // onMouseEnter={handleShareOpen}
                    // onMouseLeave={() => {
                    //   setAnchorEl(null);
                    // }}
                  />
                </Tooltip>
              </Badge>
            </div>

            <Button
              variant="text"
              color="secondary"
              sx={{
                backgroundColor: theme.palette.primary.light,
                height: "100%",
                "&:hover": {
                  backgroundColor: theme.palette.secondary.light,
                },
              }}
              onClick={() => {
                if (isReoccurring) {
                  updateReoccurringTodo(todo);
                } else {
                  updateTodo(todo);
                }
                // updateTodo(todo);
                // editModalUpdateTodo(
                //   todo,
                //   todos,
                //   setTodos,
                //   selectedTitle,
                //   due,
                //   start,
                //   duration
                // );
                setOpenEditTodo(false);
              }}
            >
              save
            </Button>
          </Stack>
        </div>
      </StyledBox>
    </StyledDialog>
  );
};
