import { alpha, styled } from "@mui/material";
import { isMobile } from "react-device-detect";
import { STUDYO_GRADIENT } from "../../../utils/constants";
import { StudyTypographyMediumText } from "../../glovebox/StudyTypography";

export const SettingsToggle = styled("div")<{ isOn: any }>(
  ({ theme, isOn }) => ({
    width: "100%",
    height: "45px",
    background: isOn ? STUDYO_GRADIENT : "rgba(0,0,0,0)",
    backgroundColor: isOn ? "white" : theme.palette.primary.light,
    display: "flex",
    flex: 1,
    justifyContent: isOn ? "flex-end" : "flex-start",
    borderRadius: "7.5px",
    padding: "10px",
    cursor: "pointer",
    alignItems: "center",
    boxSizing: "border-box",
  })
);

export const SettingsToggleContainer = styled("div")<{ isOn: any }>(
  ({ theme, isOn }) => ({
    padding: "16px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "200px",
    display: "flex",
    outline: `3px dashed ${theme.palette.primary.light}`,
    borderRadius: "4px",
    background: isOn ? STUDYO_GRADIENT : "rgba(0,0,0,0)",

    backgroundColor: isOn
      ? "rgba(255,255,255,0.2)"
      : theme.palette.primary.main,
  })
);

export const SettingsInformationContainer = styled("div")(({ theme }) => ({
  padding: "16px",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "200px",
  display: "flex",
  borderRadius: "4px",
  backgroundColor: theme.palette.primary.light,
  overflow: "scroll",
}));

export const SettingsInformationContainerBugs = styled("div")(({ theme }) => ({
  padding: "16px",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  height: "200px",
  display: "flex",
  borderRadius: "4px",
  backgroundColor: theme.palette.primary.light,
  overflow: "scroll",
}));

export const SettingsIconAndTitleContainer = styled("div")(({ theme }) => ({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  height: "100%",
}));

export const SettingsStatsTextBox = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  alignSelf: "center",
  justifyContent: isMobile ? "space-between" : "center",
  textAlign: "center",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    cursor: "pointer",
  },
}));

export const SettingsUserBox = styled("div")(({ theme }) => ({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  height: "100%",
}));
export const SettingsStatsBox = styled("div")(({ theme }) => ({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  height: "100%",
  width: "100%",
}));

export const SettingsText = styled(StudyTypographyMediumText)(({ theme }) => ({
  width: "100%",
  height: "100%",
  textAlign: "center",
  verticalAlign: "center",
  alignSelf: "center",
  background: STUDYO_GRADIENT,
  backgroundColor: "rgba(255,255,255,.3)",
  borderRadius: "12px",
  lineHeight: "150px",
  opacity: 0.5,
  "&:hover": {
    cursor: "pointer",
    opacity: 1,
  },
}));
