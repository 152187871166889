import { ClickAwayListener, Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { RANDOM_COLOR } from "../../utils/constants";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import { Add } from "@mui/icons-material";
import { v4 as uuid } from "uuid";
import { client } from "../../utils/apollo";
import { ADD_USER_CATEGORY } from "../../graphql/mutations/user";

export const AddToolbarCategory = ({
  handleAddCategory,
}: {
  handleAddCategory: any;
}) => {
  const [categoryName, setCategoryName] = useState("");
  const [isPressed, setIsPressed] = useState(false);
  const [categoryLabel, setCategoryLabel] = useState(RANDOM_COLOR);

  const theme = useTheme();
  return (
    <Stack flexDirection={"row"} alignItems={"center"} width={"100%"}>
      <div
        style={{
          width: "6px",
          height: "20px",
          borderRadius: "4px",
          backgroundColor: categoryLabel,
          boxSizing: "border-box",
          flexShrink: 0,
        }}
      ></div>
      <ClickAwayListener
        onClickAway={() => {
          setIsPressed(false);
        }}
      >
        <input
          suppressContentEditableWarning={true}
          contentEditable={true}
          onFocus={() => {
            setIsPressed(true);
          }}
          style={{
            border: "0px",
            backgroundColor: "rgba(0,0,0,0)",
            width: "100%",
            height: "24px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "16px",
            outlineWidth: 0,
            borderRadius: "4px",
            color: theme.palette.text.primary,
          }}
          placeholder={"add new category.."}
          type={"text"}
          name="title"
          value={categoryName}
          onChange={(text: any) => {
            setCategoryName(text.target.value);
          }}
        ></input>
      </ClickAwayListener>

      {isPressed || isMobile ? (
        <motion.div
          animate={{ x: [100, 0] }}
          transition={{
            type: "spring",
            stiffness: 300,
            mass: 0.3,
          }}
          style={{ display: "flex" }}
        >
          <Add
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.primary.light,
              borderRadius: "4px",
              padding: "4px",
              "&:hover": {
                cursor: "pointer",
                // backgroundColor: theme.palette.primary.main,
              },
            }}
            onClick={() => {
              setCategoryName("");
              setCategoryLabel(RANDOM_COLOR);
              handleAddCategory(categoryName, categoryLabel);
            }}
          />
        </motion.div>
      ) : null}
    </Stack>
  );
};
