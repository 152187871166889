import { Box, Fab, Typography, useTheme, Zoom } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  FRIEND_TODOS,
  GET_USERS,
  GET_USER_FRIEND_REQUESTS,
} from "../../../graphql/queries/user";
import { useLazyQuery, useQuery } from "@apollo/client";
import { client } from "../../../utils/apollo";
import { SearchBar } from "./SearchBar";
import { FriendRequest } from "./FriendRequest";
import { DraggingContext, PageContext } from "../../../utils/contexts";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { isMobile } from "react-device-detect";
import {
  CalendarTodayOutlined,
  CalendarViewMonthOutlined,
  FormatListBulletedOutlined,
} from "@mui/icons-material";
import { FABS } from "../../../utils/constants";
import {
  ACCEPT_FRIEND_REQUEST,
  REJECT_FRIEND_REQUEST,
  sendRequest,
  unsendRequest,
  SEND_FRIEND_REQUEST,
  UNSEND_FRIEND_REQUEST,
  rejectRequest,
  acceptRequest,
} from "../../../graphql/mutations/friends";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-hot-toast";

interface MyProps {
  showIsDragging: () => void;
}

export const AddFriendsMain = () => {
  const [todos, setTodos, user, setUser, categories, setCategories] =
    useOutletContext<any>();
  // const [user, setUser] = useState(
  //   JSON.parse(localStorage.getItem("user")!) || []
  // );
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [getUsers, { data: usersData }] = useLazyQuery(GET_USERS, {
    onCompleted: (data) => {
      setAllUsers(data);
    },
  });
  const [friends, setFriends] = useState(
    user.friends.map((request: any) => {
      return request.id;
    })
  );
  const [recieved, setRecieved] = useState(
    user.friendRequestsRecieved.map((request: any) => {
      return request.id;
    })
  );
  const [sent, setSent] = useState(
    user.friendRequestsSent.map((request: any) => {
      return request.id;
    })
  );
  const navigate = useNavigate();
  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    const getData = async () => {
      await client
        .query({
          query: GET_USER_FRIEND_REQUESTS,
          variables: {
            getUserId: user.id,
          },
          fetchPolicy: "network-only",
        })
        .then(({ data: queryData }) => {
          let newUser = { ...user };
          newUser.friendRequestsRecieved =
            queryData.getUser.friendRequestsRecieved;
          setUser(newUser);
        })
        .catch((queryError) => {
          console.log(queryError.code + ": " + queryError.message);
        });
    };
    getData();
  }, []);

  // useEffect(() => {
  //   localStorage.setItem("user", JSON.stringify(user));
  // }, [user]);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
    setFriends(
      user.friends.map((request: any) => {
        return request.id;
      })
    );
    setRecieved(
      user.friendRequestsRecieved.map((request: any) => {
        return request.id;
      })
    );
    setSent(
      user.friendRequestsSent.map((request: any) => {
        return request.id;
      })
    );
  }, [user]);

  const sendRequestHelper = (friend: any) => {
    sendRequest(friend, user, setUser);
  };
  const unsendRequestHelper = (friendId: any) => {
    unsendRequest(friendId, user, setUser);
  };

  const acceptRequestHelper = (friend: any) => {
    setLoading(true);
    setRecieved(user.friendRequestsRecieved);
    toast.promise(
      acceptRequest(friend, user, setUser).then(() => setLoading(false)),
      {
        loading: "adding friend..",
        success: <b>friend added!</b>,
        error: <b>Could not save.</b>,
      },
      {
        duration: 4000,
        style: {
          fontSize: "12px",
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.primary,
        },
      }
    );
  };
  const rejectRequestHelper = (friendId: any) => {
    rejectRequest(friendId, setRecieved, user, setUser);
  };
  const theme = useTheme();

  const [isComponentDragging, setIsDragging] = useState(false);

  const { page, showMainPage, setShowMainPage } = useContext(PageContext);

  const fab = FABS.findIndex((fab) => fab.label === "Add Friends");

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      }}
    >
      <DraggingContext.Provider value={{ isComponentDragging, setIsDragging }}>
        <DndProvider backend={HTML5Backend}>
          {/* <ContentWrapper> */}
          {!showMainPage && isMobile ? null : (
            <FriendRequest
              user={user}
              setUser={setUser}
              recieved={user.friendRequestsRecieved}
              sent={user.friendRequestsSent}
              acceptRequest={acceptRequestHelper}
              rejectRequest={rejectRequestHelper}
              unsendRequest={unsendRequestHelper}
              loading={loading}
            />
          )}
          {!isMobile || isComponentDragging || !showMainPage ? (
            <SearchBar
              user={user}
              setUser={setUser}
              friends={friends}
              recieved={recieved}
              sent={sent}
              sendRequest={sendRequestHelper}
              unsendRequest={unsendRequestHelper}
            />
          ) : null}
          {/* </ContentWrapper> */}
          {isMobile ? (
            <Zoom
              key={FABS[fab].color}
              in={page === FABS[fab].label}
              timeout={transitionDuration}
              style={{
                transitionDelay: `${
                  page === FABS[fab].label ? transitionDuration.exit : 0
                }ms`,
              }}
              unmountOnExit
            >
              <Fab
                sx={FABS[fab].sx}
                aria-label={FABS[fab].label}
                color={FABS[fab].color}
                onClick={() => {
                  setShowMainPage(!showMainPage);
                }}
              >
                {showMainPage ? FABS[fab].iconTool : FABS[fab].iconMain}
              </Fab>
            </Zoom>
          ) : null}
        </DndProvider>
      </DraggingContext.Provider>
    </div>
  );
  // return (
  //   <div>
  //     Friends
  //     <SearchBar />
  //     <FriendRequest />
  //   </div>
  // );
};
