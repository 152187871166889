import {
  AccessTimeRounded,
  AddHomeOutlined,
  CalendarTodayOutlined,
  CalendarViewMonthOutlined,
  CheckCircleOutlineOutlined,
  DeleteOutlineOutlined,
  EditOutlined,
  FormatListBulletedOutlined,
  PeopleAltOutlined,
  RemoveCircleOutlineOutlined,
  SearchRounded,
} from "@mui/icons-material";
import { alpha, useTheme } from "@mui/material";

export function random_rgba() {
  var o = Math.round,
    r = Math.random,
    s = 80,
    a = 175;
  return (
    "rgba(" +
    o(r() * s + a) +
    "," +
    o(r() * s + a) +
    "," +
    o(r() * s + a) +
    "," +
    r().toFixed(1) +
    ")"
  );
}

export enum LightModeInput {
  LIGHT = "LIGHT",
  GREY = "GREY",
  DARK = "DARK",
}

export enum PriorityInput {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  URGENT = "URGENT",
}

export enum StatusInput {
  TODO = "LOW",
  PROGRESS = "IN PROGRESS",
  COMPLETED = "COMPLETED",
}

//FF5ACD
//FBDA61
//5C68DA
//92EBFF
//8664E5
export const STUDYO_PRIMARY_GRADIENT = "#6384F7";
export const STUDYO_SECONDARY_GRADIENT = "#6384F7";
export const STUDYO_GRADIENT = `linear-gradient(135deg, ${alpha(
  "#6384F7",
  1
)}, ${alpha("#6384F7", 1)}`;

// export const STUDYO_PRIMARY_GRADIENT = "#7Fc6F1";
// export const STUDYO_SECONDARY_GRADIENT = "#515cd8";
// export const STUDYO_GRADIENT = `linear-gradient(135deg, ${alpha(
//   "#7Fc6F1",
//   1
// )}, ${alpha("#515cd8", 1)}`;

// linear-gradient(
//   270deg,
//   #ccbbff -5.62%,
//   #7427ff 45.92%,
//   #00baff 103.12%
// )

export const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const MONTHS_OF_YEAR = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const timeDuration = [5, 10, 15, 30, 45, 60, 90, 120];

export function LightenDarkenColor(col: any, amt: any) {
  var num = parseInt(col, 16);
  var r = (num >> 16) + amt;
  var b = ((num >> 8) & 0x00ff) + amt;
  var g = (num & 0x0000ff) + amt;
  var newColor = g | (b << 8) | (r << 16);

  return newColor.toString(16);
}

export function hexToRgbA(hex: any) {
  var c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",.4)"
    );
  } else {
    hexToRgbA("#6384F7");
  }
}

export const STAGGER_CONTAINER = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0, // this will set a delay before the children start animating
      staggerChildren: 0.03, // this will set the time inbetween children animation
    },
  },
};
export const STAGGER_CHILDREN = {
  hidden: {
    y: "100vh",
  },
  visible: {
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      mass: 0.3,
    },
  },
};

export const STAGGER_DRAG_CHILDREN = {
  hidden: {
    // y: "100vh",
  },
  visible: {
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      mass: 0.3,
    },
  },
};

export const EDIT_TODO = "edit todo";
export const DELETE_TODO = "delete todo";
export const COMPLETE_TODO = "complete todo";
export const ADD_START = "add start time";
export const ADD_DUE = "add due date";
export const REMOVE_START = "remove start";
export const ARRAY_OF_COLORS = [
  // Cyan-Blue
  "#A3EFFF",
  "#7cb9f2",
  "#4a9fc6",
  "#267f9d",
  "#14687d",

  // Orange-Red
  "#FFBB64",
  "#FF8E48",
  "#ff682c",
  "#df5110",
  "#be0c0c",

  // Light Green-Dark Green
  "#A3FFA3",
  "#7CF27C",
  "#4ABE4A",
  "#269C26",
  "#147D14",

  // Pink-Purple
  "#FFA3E3",
  "#F27CD6",
  "#C64ABE",
  "#9D269C",
  "#7A147D",

  // Purple-Blue (Updated with more blue)
  "#D0A3FF",
  "#B27CF2",
  "#6068ff",
  "#3960c1",
  "#1d407d",
];

export const RANDOM_COLOR =
  ARRAY_OF_COLORS[Math.round(Math.random() * (ARRAY_OF_COLORS.length - 1))];

export const TOOLBAR_RIGHT_CLICK = [
  {
    name: EDIT_TODO,
    icon: <EditOutlined style={{ width: "20px" }} />,
    index: 0,
  },
  {
    name: ADD_START,
    icon: <AccessTimeRounded style={{ width: "20px" }} />,
    index: 1,
  },
  // {
  //   name: ADD_DUE,
  //   icon: <AddAlarmOutlined style={{ width: "20px" }} />,
  //   index: 2,
  // },
  {
    name: COMPLETE_TODO,
    icon: <CheckCircleOutlineOutlined style={{ width: "20px" }} />,
    index: 1,
  },
  {
    name: DELETE_TODO,
    icon: <DeleteOutlineOutlined style={{ width: "20px" }} />,
    index: 2,
  },
];

export const MAINBAR_RIGHT_CLICK = [
  {
    name: EDIT_TODO,
    icon: <EditOutlined style={{ width: "20px" }} />,
    index: 0,
  },
  // {
  //   name: ADD_START,
  //   icon: <AccessTimeRounded style={{ width: "20px" }} />,
  //   index: 1,
  // },
  // {
  //   name: ADD_DUE,
  //   icon: <AddAlarmOutlined style={{ width: "20px" }} />,
  //   index: 2,
  // },

  {
    name: COMPLETE_TODO,
    icon: <CheckCircleOutlineOutlined style={{ width: "20px" }} />,
    index: 4,
  },
  {
    name: REMOVE_START,
    icon: <RemoveCircleOutlineOutlined style={{ width: "20px" }} />,
    index: 3,
  },
  {
    name: DELETE_TODO,
    icon: <DeleteOutlineOutlined style={{ width: "20px" }} />,
    index: 5,
  },
];
const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
};

export const FABS = [
  {
    color: "primary" as "primary",
    sx: fabStyle,
    iconTool: <CalendarViewMonthOutlined />,
    iconMain: <CalendarViewMonthOutlined />,
    label: "Calendar",
  },
  {
    color: "primary" as "primary",
    sx: fabStyle,
    iconTool: <FormatListBulletedOutlined />,
    iconMain: <CalendarTodayOutlined />,
    label: "Schedule",
  },
  {
    color: "primary" as "primary",
    sx: fabStyle,
    iconTool: <CalendarViewMonthOutlined />,
    iconMain: <CalendarViewMonthOutlined />,
    label: "Cowork Space",
  },
  {
    color: "primary" as "primary",
    sx: fabStyle,
    iconTool: <PeopleAltOutlined />,
    iconMain: <AddHomeOutlined />,
    label: "Friends",
  },
  {
    color: "primary" as "primary",
    sx: fabStyle,
    iconTool: <SearchRounded />,
    iconMain: <PeopleAltOutlined />,
    label: "Add Friends",
  },
];
