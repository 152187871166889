import { Typography } from "@mui/material";
import { Navigate, useRoutes } from "react-router-dom";
import { AddFriendsMain } from "./components/mainbar/addfriend/AddFriendMain";
import CalendarMain from "./components/mainbar/calendar/CalendarMain";
import { CoworkGroups } from "./components/mainbar/cowork/CoworkGroups";
import { CoworkLayout } from "./components/mainbar/cowork/CoworkLayout";
import { FriendsLayout } from "./components/mainbar/friends/friendsLayout";
import { FriendsMain } from "./components/mainbar/friends/friendsMain";
import { ScheduleLayout } from "./components/mainbar/schedule/ScheduleLayout";
import { SettingMain } from "./components/mainbar/settings/SettingMain";
import { TodosMain } from "./components/mainbar/todos/TodosMain";
import { HomePage } from "./components/mainpage/HomePage";
import { MainPage } from "./components/mainpage/MainPage";
import { DashboardLayout } from "./layouts/DashboardLayout";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import Login from "./screens/Login";
import NotesMain from "./components/mainbar/notes/NotesMain";
import WorkSeshMain from "./components/mainbar/worksesh/SessionMain";
import SessionMain from "./components/mainbar/worksesh/SessionMain";

// ----------------------------------------------------------------------
// element: uid ? <DashboardLayout /> : <Navigate to="/login" />,
export default function Router({
  setThemeValueToLight,
  setThemeValueToGray,
  setThemeValueToDark,
}: {
  setThemeValueToLight: any;
  setThemeValueToGray: any;
  setThemeValueToDark: any;
}) {
  return useRoutes([
    {
      path: "/app",

      element:
        localStorage.getItem("userId") !== null ? (
          <DashboardLayout
            setThemeValueToLight={setThemeValueToLight}
            setThemeValueToGray={setThemeValueToGray}
            setThemeValueToDark={setThemeValueToDark}
          />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { element: <Navigate to="/" replace /> },
        { path: "schedule", element: <ScheduleLayout /> },
        { path: "todos/*", element: <TodosMain /> },
        { path: "cowork/:uuid", element: <CoworkLayout /> },
        { path: "cowork", element: <CoworkGroups /> },
        { path: "notes", element: <NotesMain /> },
        { path: "session", element: <SessionMain /> },
        { path: "calendar", element: <CalendarMain /> },
        { path: "friends", element: <FriendsLayout /> },
        { path: "addfriend", element: <AddFriendsMain /> },
        { path: "settings", element: <SettingMain /> },
      ],
    },

    {
      path: "/",
      element:
        localStorage.getItem("user") !== null ? (
          <Navigate to="/app/schedule" />
        ) : (
          <LogoOnlyLayout />
        ),
      children: [
        {
          path: "/",
          element: (
            <HomePage
              setThemeValueToLight={setThemeValueToLight}
              setThemeValueToGray={setThemeValueToGray}
              setThemeValueToDark={setThemeValueToDark}
            />
          ),
        },

        { path: "login", element: <Login /> },
        { path: "register", element: <Login /> },
        {
          path: "features",
          element: (
            <MainPage
              setThemeValueToLight={setThemeValueToLight}
              setThemeValueToGray={setThemeValueToGray}
              setThemeValueToDark={setThemeValueToDark}
            />
          ),
        },

        { path: "*", element: <Typography>IN TROUBLE</Typography> },
      ],
    },
    { path: "*", element: <FriendsLayout /> },
  ]);
}
