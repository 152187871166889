import { useTheme } from "@mui/material";
import {
  STUDYO_PRIMARY_GRADIENT,
  STUDYO_SECONDARY_GRADIENT,
} from "../../utils/constants";
import { HoverPointerStyle } from "./HoverPointerStyle";

export function AddFriendsIcon(props: {
  active: boolean;
  clicked: Function;
  width?: string;
  height?: string;
}) {
  const theme = useTheme();
  return (
    <HoverPointerStyle
      width={props?.width || "30"}
      height={props?.height || "30"}
      viewBox={`0 0 32 32`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        props.clicked();
      }}
    >
      {props.active ? (
        <rect
          width={props?.width || "32"}
          height={props?.height || "32"}
          rx="4.125"
          fill="url(#paint0_linear_59_2302)"
        />
      ) : (
        <rect
          width={props?.width || "32"}
          height={props?.height || "32"}
          rx="4.125"
          fill={theme.palette.primary.light}
        />
      )}

      <path
        d="M19.6081 24.1408V22.3332C19.6081 21.3744 19.2272 20.4549 18.5492 19.7769C17.8713 19.099 16.9518 18.7181 15.993 18.7181H9.66649C8.7077 18.7181 7.78818 19.099 7.11021 19.7769C6.43224 20.4549 6.05136 21.3744 6.05136 22.3332V24.1408"
        stroke={props.active ? "white" : "#777777"}
        strokeLinecap="round"
      />
      <path
        d="M12.8234 15.0982C14.82 15.0982 16.4385 13.4797 16.4385 11.4831C16.4385 9.48651 14.82 7.86797 12.8234 7.86797C10.8268 7.86797 9.20828 9.48651 9.20828 11.4831C9.20828 13.4797 10.8268 15.0982 12.8234 15.0982Z"
        stroke={props.active ? "white" : "#777777"}
        strokeLinecap="round"
      />
      <path
        d="M23.2295 12.3829V17.8056"
        stroke={props.active ? "white" : "#777777"}
        strokeLinecap="round"
      />
      <path
        d="M25.9361 15.099H20.5135"
        stroke={props.active ? "white" : "#777777"}
        strokeLinecap="round"
      />

      <defs>
        <linearGradient
          id="paint0_linear_59_2302"
          x1="0"
          y1="0"
          x2="32"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={STUDYO_PRIMARY_GRADIENT} />
          <stop offset="1" stopColor={STUDYO_SECONDARY_GRADIENT} />
        </linearGradient>
      </defs>
    </HoverPointerStyle>
  );
}
