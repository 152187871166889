import { InputUnstyled } from "@mui/base";
import { Autocomplete, Avatar, Box, Popper, styled } from "@mui/material";
import React from "react";
import DateTimePicker from "react-datetime-picker";
import { isMobile } from "react-device-detect";
import StyleGuide from "../StyleGuide";
import { StudyTypographyMediumSubtitle } from "../glovebox/StudyTypography";

export const ProfileBlockContainer = styled("div")({
  width: "100%",
  justifyContent: "space-between",
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
});

export const ProfileBlockLeftSide = styled("div")({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
});

export const ProfileBlockAvatar = styled(Avatar)(({ theme }) => ({
  width: "32px",
  height: "32px",
  borderRadius: "4px",
  fontWeight: 500,
  fontSize: 14,
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.text.primary,
  cursor: "pointer",
}));

//   (props) => (
//   <Avatar
//     variant="rounded"
//     style={{
//       width: "40px",
//       height: "40px",
//       borderRadius: "4px",
//       // padding: "12px",
//       fontWeight: 900,
//       backgroundColor: ,
//       color: StyleGuide.Colors.darkGray,
//     }}
//   >
//     PM
//   </Avatar>
// ))``;

export const ProfileBlockPopper = styled(Popper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  // padding: "12px",
  borderRadius: "4px",
  color: theme.palette.text.primary,
}));

export const ToolbarContainer = styled("div")({
  display: "block",
  paddingLeft: "16px",
  paddingRight: "16px",
  height: "90vh",
  width: isMobile ? "100vw" : "30vw",
  justifyContent: "space-between",
  flexDirection: "column",
  alignContent: "center",
  overflowY: "scroll",
  scrollbarWidth: "none",
  marginBottom: isMobile ? "75px" : "0px",

  "&::-webkit-scrollbar": {
    display: "none",
  },
});

export const StyledInputElement = styled("input")(
  ({ theme }) => `
  width: 100%;
    background-color: ${theme.palette.primary.light};
    font-family: Space Grotesk;
    font-size: 14px;
    font-weight: 500;
    color: ${theme.palette.text.primary};
    border:none;
    &:focus {
        outline: none;
    }
  `
);

export const CustomDateTimePicker = styled(DateTimePicker)(({ theme }) => ({
  "& .MuiInputBase-root": {
    border: "none",
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
  },
  "& .MuiInputBase-input": {
    color: theme.palette.text.primary,
    fontWeight: 500,
    padding: theme.spacing(1),
  },
  "& .MuiFormLabel-root": {
    color: theme.palette.text.primary,
    fontWeight: 500,
    marginBottom: theme.spacing(0.5),
  },
  "& .MuiIconButton-root": {
    color: theme.palette.text.primary,
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1.2rem",
  },
  "& .Mui-focused": {
    "& .MuiIconButton-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
    },
  },
}));

export const CustomInput = React.forwardRef(function CustomInput(
  props: React.InputHTMLAttributes<HTMLInputElement>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <InputUnstyled
      // components={{ Input: StyledInputElement }}
      // component={StyledInputElement}
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      slots={{ input: StyledInputElement }}
      {...props}
      ref={ref}
      style={{ width: "100%" }}
    />
  );
});

export const ToolbarToDoCreateInput = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: "5px",
  padding: "10px",
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flex: "0 0 30vw",
}));

export const ToolbarBackground = styled("div")(({ theme }) => ({
  // backgroundColor: theme.palette.primary.main,
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export const ToolbarTodoContainer = styled("div")({
  flex: "0 0 30vw",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

export const SwitchCategoryContainer = styled("div")(({ theme }) => ({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  padding: "8px 12px 8px 12px",
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    borderRadius: "4px",
    cursor: "pointer",
  },
}));

export const ToolbarToDoBox = styled("div")(({ theme }) => ({
  // background: `linear-gradient(135deg, ${alpha(
  //   theme.palette.action.active,
  //   0.5
  // )} 0%, ${theme.palette.primary.light} 10%)`,
  backgroundColor: theme.palette.primary.light,
  display: "flex",

  flexDirection: "row",
  justifyContent: "space-between",
  borderRadius: "4px",
  alignItems: "center",
  // zIndex: 10,
}));

export const ToolbarToDoLeftSide = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  width: "50%",
});

export const ToolbarToDoTitle = styled(Box)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "300",
  fontStyle: "Space Grotesk",
  width: "100%",
  color: theme.palette.text.primary,
  // display: "-webkit-box",
  // overflow: "hidden",
  // WebkitBoxOrient: "vertical",
  // WebkitLineClamp: 1,
}));

export const ToolbarToDoSubtitle = styled(Box)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "400",
  color: theme.palette.action.active,
  width: "100%",
}));

export const ToolbarToDoContainer = styled(Box)`
  &::-webkit-scrollbar {
    display: block;
    position: absolute;
    z-index: 999;
    width: 0px;
    background: transparent;
    border-radius: 0 4px 4px 0;
    outline: none;
  }
  &::-webkit-scrollbar-track {
    background: ${StyleGuide.Colors.lightestGray};
    background-clip: padding-box;
    border: 8px solid rgba(0, 0, 0, 0);
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    border: 8px solid rgba(0, 0, 0, 0);
    background: #c4c4c4;
    background-clip: padding-box;
  }
`;

export const ToolbarDurationBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.text.secondary,
  borderRadius: "4px",
  height: "24px",
  alignItems: "center",
  flexDirection: "row",
  display: "flex",
  cursor: "pointer",
  marginLeft: "4px",
}));

export const StyledAutocomplete = styled(Autocomplete)`
  display: flex;
  align-items: center;
  background: #0f0f0f;
  border-radius: 6px;
  min-height: 40px;
  color: ${StyleGuide.Colors.white};
  position: relative;
  padding-right: 0;

  .MuiInputBase-adornedStart {
    padding-right: 10px !important;
  }
  color: ${StyleGuide.Colors.white};

  input {
    color: #c4c4c4;
    top: -10px;
    left: 8px;
    font-size: 14px;
    height: 30px;

    &::placeholder {
      color: #c4c4c4;
      opacity: 1;
    }
  }

  .search-icon {
    right: 10px;
    height: 11px;
    position: absolute;
  }

  svg {
    color: #c4c4c4;
  }

  .MuiAutocomplete-popper {
    background: #0f0f0f;
  }

  .MuiAutocomplete-endAdornment {
    top: auto;
  }
`;

export const ToolbarToDoDurationText = styled(StudyTypographyMediumSubtitle)(
  ({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      padding: "4px",
      borderRadius: "4px",
      cursor: "pointer",
    },
  })
);

export const ToolbarUncheckedBox = styled(Box)`
  background-color: ${StyleGuide.Colors.lightGray};
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 8px;
  :hover {
    cursor: pointer;
    background-color: ${StyleGuide.Colors.gray};
  }
`;

export const ToolbarTasksBox = styled(Box)`
  overflow-y: auto;
  scrollbar-width: none;
  height: "100%";
  ::-webkit-scrollbar {
    display: none;
  }
`;
