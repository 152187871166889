import { UPDATE_CATEGORY_TODOS } from "../graphql/mutations/categories";
import { UPDATE_USER_BULK_TODOS } from "../graphql/mutations/user";
import { client } from "./apollo";
import { v4 as uuid } from 'uuid';

export const addBulkData = (newTodos: any, userId: any, categoryId: any) => {
    const todosMutatiion = client.mutate({
        mutation: UPDATE_CATEGORY_TODOS,
        variables: {
            uuid: categoryId,
            todos: newTodos,
            userId: userId,
        }
    });
    return Promise.all([todosMutatiion]).then(() => {
        let newTodoIds = newTodos.map((todo: any) => { return todo.uuid});
        client.mutate({
            mutation: UPDATE_USER_BULK_TODOS,
            variables: {
                userId: userId,
                addTodoIds: newTodoIds,
            },
        })
        return 'Success';
    });
}

// const firstDate = new Date();
const secondDate = new Date();
// firstDate.setHours(14);
// firstDate.setMinutes(0);
// firstDate.setSeconds(0);
secondDate.setHours(15);
secondDate.setMinutes(0);
secondDate.setSeconds(0);


export const tutorialTodos = [
    {
        title: "Welcome to Studyo! The todo list X calendar collab ",
        startTime: null,
        duration: 30,
        uuid: uuid(),
    },
    {
        title: "Drag and drop todos into the schedule",
        startTime: null,
        duration: 60,
        uuid: uuid(),
    },
    {
        title: "Right click on any task or category to modify (double tap on mobile)",
        startTime: secondDate,
        duration: 120,
        uuid: uuid(),
    },
    {
        title: "Go to settings to import google calendar events!",
        startTime: null,
        duration: 30,
        uuid: uuid(),
    },
    {
        title: "Share your schedule w/ friends in friends tab (COMING SOON)",
        startTime: null,
        duration: 30,
        uuid: uuid(),
    }
]

export const categoryTutorialTodos = [
    {
        title: "ENJOY!!!",
        startTime: null,
        duration: 30,
        uuid: uuid(),
    }
]

export const categories = (baseCategoryId: string, tutorialId: string, rightId: string) => {
    return [
        {
            name: 'Todos',
            uuid: baseCategoryId,
            label: '#FFFFFF'
        },
        {
            name: 'Tutorial',
            uuid: tutorialId,
            label: '#A653F5'
        },
        {
            name: 'Right Click Me!',
            uuid: rightId,
            label: '#3AC4E8'
        },
    ]
}

export const baseUser = (baseCategoryId: string, email: string, firstName: string, lastName: string, userId: string) => {
    return {
        active: false,
        baseCategoryId: baseCategoryId,
        displayTodoName: false,
        email: email,
        firstName: firstName,
        friendRequestsRecieved: [],
        friendRequestsSent: [],
        friends: [],
        groups: [],
        id: userId,
        lastName: lastName,
        lightSetting: "DARK",
        privateTodos: false,
        __typename: "User"
    }
}

export const baseCategories = (baseCategoryId: string, tutorialId: string, rightId: string) => {
    return [
        {
            id: null,
            label: "#FFFFFF",
            name: "Todos",
            uuid: baseCategoryId,
            __typename: "Category",
        },
        {
            id: null,
            name: 'Tutorial',
            uuid: tutorialId,
            label: '#A653F5',
            __typename: "Category",
        },
        {
            id: null,
            name: 'Right Click Me!',
            uuid: rightId,
            label: '#3AC4E8',
            __typename: "Category",
        },
    ]
};

export const baseTodo = (todo: any, categoryId: any) => {
    return  {
        categoryId: categoryId,
        completedAt: null,
        createdAt: null,
        deletedAt: null,
        dueAt: null,
        duration: todo.duration,
        id: null,
        reoccurring: false,
        event: false,
        todoRepicas: [],
        startTime: todo.startTime,
        title: todo.title,
        uuid: todo.uuid,
        __typename: "Todo"
    }
    
}