import React, { useState } from "react";
import {
  StyledBox,
  StyledCloseButton,
  StyledDialog,
  StyledDialogTopBar,
  StyledHeader,
} from "../mainbar/calendar/styles";
import { XIcon } from "../sidebar/Icons";

function Modal({
  title,
  open,
  handleOpen,
  children,
}: {
  title: string;
  open: boolean;
  handleOpen: Function;
  children: any;
}) {
  return (
    <StyledDialog
      open={open}
      onClose={() => {
        handleOpen();
      }}
      aria-labelledby="archive-task-modal"
      aria-describedby="modal-modal-description"
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          width: "100%",
        },
      }}
    >
      <StyledBox>
        <StyledDialogTopBar>
          <StyledHeader
            style={{
              marginLeft: 0,
            }}
          >
            {title}
          </StyledHeader>
          <StyledCloseButton
            onClick={() => {
              handleOpen();
            }}
          >
            <XIcon />
          </StyledCloseButton>
        </StyledDialogTopBar>
        {children}
      </StyledBox>
    </StyledDialog>
  );
}

export default Modal;
