import styled from "styled-components";

export const IPhoneButton = styled.div`
  backdrop-filter: blur(16px);
  color: #fff;
  background-color: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 4px;
  align-items: center;
  padding: 10px 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  transition: border-color 0.2s;
  display: flex;
  overflow: hidden;
`;

export const IPhoneBlock = styled.div<{
  backgroundColor: any;
  backgroundColor2: any;
}>`
  width: 240px;
  height: 512px;
  perspective-origin: 0 0;
  transform-origin: 0 0;
  background-image: linear-gradient(
    172deg,
    ${(props) => props.backgroundColor},
    ${(props) => props.backgroundColor} 62%
  );
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;

  display: block;
  flex-direction: column;
  position: absolute;
  top: 24px;
  overflow: hidden;
  box-shadow: inset 0 1px 2px ${(props) => props.backgroundColor2},
    0 0 16px -4px rgba(0, 0, 0, 0.5);
`;

export const IPhoneBox = styled.div<{
  backgroundColor: any;
  backgroundColor2: any;
}>`
  width: 64px;
  height: 64px;
  perspective-origin: 0 0;
  transform-origin: 0 0;
  background-color: ${(props) => props.backgroundColor};
  border: 1.5px solid ${(props) => props.backgroundColor2};
  border-radius: 8px;
  flex-shrink: 0;
`;

export const IPhoneTitle = styled.div<{
  backgroundColor: any;
  backgroundColor2: any;
}>`
  height: 18px;
  background-color: ${(props) => props.backgroundColor};
  border: 1.5px solid ${(props) => props.backgroundColor2};
  border-radius: 8px;
  margin-bottom: 10px;
`;
export const IPhoneDescription = styled.div<{
  backgroundColor: any;
}>`
  /* width: 64px; */
  height: 6px;
  width: 80%;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 8px;
  margin-bottom: 6px;
`;
