import { Stack, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import moment from "moment";
import React, { useState } from "react";
import { CheckIcon } from "../../Icons/CheckIcon";
import FilterTodos from "../../glovebox/FilterTodos";
import {
  StudyTypographyMediumTitle,
  StudyTypographySmallText,
} from "../../glovebox/StudyTypography";
import { AIToDoBlock } from "../../mainpage/AIToDoBlock";

function SelectTodosForAI({
  todos,
  selectedTodos,
  setSelectedTodos,
  selectedLockedTodos,
}: {
  todos: any;
  selectedTodos: any;
  setSelectedTodos: any;
  selectedLockedTodos: any;
}) {
  const [showEvents, setShowEvents] = useState(false);
  const [hideWeekOld, setHideWeekOld] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(filterAnchorEl ? null : event.currentTarget);
  };
  const filterOpen = Boolean(filterAnchorEl);
  const filteredTodos = todos.filter(
    (todo: any) =>
      todo?.completedAt === null &&
      !selectedLockedTodos.includes(todo) &&
      (showEvents ? true : todo?.event === false) &&
      (hideWeekOld
        ? moment(todo?.createdAt).toISOString() >=
          moment(new Date()).subtract(1, "week").toISOString()
        : true)
  );
  const theme = useTheme();

  const scheduledFilteredTodos = filteredTodos.filter(
    (todo: any) => todo?.startTime !== null
  );
  const unscheduledFilteredTodos = filteredTodos.filter(
    (todo: any) => todo?.startTime === null
  );
  return (
    <div style={{ height: "500px" }}>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        marginBottom={"12px"}
        justifyContent={"space-between"}
      >
        <Stack flexDirection={"row"} alignItems={"center"}>
          <FilterTodos
            setHideOldTodos={setHideWeekOld}
            hideOldTodos={hideWeekOld}
            handleFilterClick={handleFilterClick}
            filteredCategories={filteredCategories}
            setFilteredCategories={setFilteredCategories}
          />
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            padding="8px"
            borderRadius={"4px"}
            height={"32px"}
            marginLeft={"12px"}
            style={{ backgroundColor: theme.palette.primary.light }}
          >
            <motion.div
              whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
              whileHover={{ rotate: 10 }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 8,
              }}
              style={{
                // marginLeft: "12px",
                alignSelf: "center",
                display: "flex",
              }}
            >
              <CheckIcon
                height="18px"
                width="18px"
                active={showEvents}
                clicked={() => {
                  setShowEvents(!showEvents);
                }}
              />
            </motion.div>
            <StudyTypographySmallText
              style={{
                marginLeft: "12px",
              }}
            >
              show events
            </StudyTypographySmallText>
          </Stack>
        </Stack>
        <StudyTypographyMediumTitle
          style={{
            border: `2px solid ${theme.palette.primary.light}`,
            borderRadius: "2px",
            padding: "8px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {`${selectedTodos.length}`}
          <StudyTypographySmallText>selected</StudyTypographySmallText>
        </StudyTypographyMediumTitle>
      </Stack>
      <div
        style={{
          height: "450px", // Set your desired height here
          overflow: "auto", // Enable scrolling for the container
        }}
      >
        <Stack>
          <StudyTypographyMediumTitle style={{ marginBottom: "12px" }}>
            Scheduled todos
          </StudyTypographyMediumTitle>
          {scheduledFilteredTodos.map((todo: any) => (
            <div
              key={todo.id}
              onClick={() => {
                selectedTodos.includes(todo)
                  ? setSelectedTodos(
                      selectedTodos.filter(
                        (selectedTodo: any) => selectedTodo !== todo
                      )
                    )
                  : setSelectedTodos([todo, ...selectedTodos]);
              }}
            >
              <AIToDoBlock
                data={todo}
                selected={selectedTodos.includes(todo)}
              />
            </div>
          ))}
        </Stack>
        <Stack>
          <StudyTypographyMediumTitle
            style={{ marginBottom: "12px", marginTop: "24px" }}
          >
            Unscheduled todos
          </StudyTypographyMediumTitle>
          {unscheduledFilteredTodos.map((todo: any) => (
            <div
              key={todo.id}
              onClick={() => {
                selectedTodos.includes(todo)
                  ? setSelectedTodos(
                      selectedTodos.filter(
                        (selectedTodo: any) => selectedTodo !== todo
                      )
                    )
                  : setSelectedTodos([todo, ...selectedTodos]);
              }}
            >
              <AIToDoBlock
                data={todo}
                selected={selectedTodos.includes(todo)}
              />
            </div>
          ))}
        </Stack>
      </div>
    </div>
  );
}

export default SelectTodosForAI;
