import {
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  MoreHorizOutlined,
} from "@mui/icons-material";
import { alpha, Box, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { LoaderIcon } from "react-hot-toast";
import {
  ACCEPT_FRIEND_REQUEST,
  REJECT_FRIEND_REQUEST,
  UNSEND_FRIEND_REQUEST,
} from "../../../graphql/mutations/friends";
import { FRIEND_TODOS } from "../../../graphql/queries/user";
import { client } from "../../../utils/apollo";
import { random_rgba } from "../../../utils/constants";
import {
  StudyTypographyMediumTitle,
  StudyTypographySmallTitle,
} from "../../glovebox/StudyTypography";
import {
  ProfileBlockPopper,
  ToolbarContainer,
  ToolbarToDoCreateInput,
} from "../../toolbar/styles";

export const FriendRequest = ({
  user,
  setUser,
  sent,
  recieved,
  acceptRequest,
  rejectRequest,
  unsendRequest,
  loading,
}: {
  user: any;
  setUser: any;
  sent: any;
  recieved: any;
  acceptRequest: any;
  rejectRequest: any;
  unsendRequest: any;
  loading: any;
}) => {
  // const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')!) || {});
  // const [recieved, setRecieved] = useState(user?.friendRequestsRecieved || []);
  // const [sent, setSent] = useState(user?.friendRequestsSent || []);
  // console.log(sent)
  // const [loading, setLoading] = useState(false);
  const [showPinnedRequests, setShowPinnedRequests] = useState(
    isMobile ? false : true
  );
  const [showPinnedSent, setShowPinnedSent] = useState(isMobile ? false : true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [recievedAnchorEl, setRecievedAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const recievedOpen = Boolean(recievedAnchorEl);

  const handleRecievedClick = (event: React.MouseEvent<HTMLElement>) => {
    setRecievedAnchorEl(recievedAnchorEl ? null : event.currentTarget);
  };
  const theme = useTheme();
  // useEffect(() => {
  //   localStorage.setItem("user", JSON.stringify(user));
  //   setRecieved(user.friendRequestsRecieved);
  //   setSent(user.friendRequestsSent);
  //   // console.log(recieved)
  // }, [user]);

  return (
    <ToolbarContainer>
      <ToolbarToDoCreateInput
        style={{
          marginBottom: "10px",
        }}
        onClick={() => {
          setShowPinnedRequests(!showPinnedRequests);
        }}
        // onContextMenu={handleRightClick}
      >
        <>
          <StudyTypographySmallTitle>
            Requests Received
          </StudyTypographySmallTitle>

          {showPinnedRequests ? (
            <ArrowDropDownRounded />
          ) : (
            <ArrowDropUpRounded />
          )}
        </>
      </ToolbarToDoCreateInput>

      {/* <CustomAddFriendsBox> */}
      {recieved && showPinnedRequests ? (
        recieved.map((request: any, index: number) => {
          return (
            <div
              style={{
                backgroundColor: theme.palette.primary.main,
                border: `3px solid ${theme.palette.primary.light}`,
                display: "flex",
                alignItems: "center",
                padding: "16px",
                borderRadius: "4px",
                flexDirection: "column",
                marginBottom: "8px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <MoreHorizOutlined
                  onClick={(event: any) => {
                    handleRecievedClick(event);
                  }}
                  sx={{
                    padding: "4px",

                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: "4px",
                      cursor: "pointer",
                    },
                  }}
                />
              </div>
              <ProfileBlockPopper
                open={recievedOpen}
                anchorEl={recievedAnchorEl}
                placement={"bottom"}
                modifiers={[{ name: "arrow", enabled: true }]}
                style={{
                  backgroundColor: "rgba(0,0,0,0)",
                  margin: 0,
                  paddingRight: 0,
                  zIndex: 100,
                  paddingTop: "8px",
                }}
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
              >
                <Box
                  sx={{
                    alignItems: "center",
                    display: "inline-grid",
                    justifyContent: "center",
                    backgroundColor: theme.palette.primary.main,
                    border: `1px solid ${theme.palette.secondary.light}`,
                    // padding: "8px",
                    borderRadius: "4px",
                    margin: "4px",
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "inline-grid",
                      justifyContent: "center",
                      padding: "8px 16px 8px 16px",
                      backgroundColor: theme.palette.primary.main,

                      borderRadius: "4px",
                      margin: "4px 4px 0px 4px",
                      "&:hover": {
                        backgroundColor: theme.palette.secondary.light,

                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      acceptRequest(request);
                      setRecievedAnchorEl(null);
                    }}
                  >
                    Accept
                  </Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "inline-grid",
                      justifyContent: "center",
                      backgroundColor: theme.palette.primary.main,
                      padding: "8px 16px 8px 16px",
                      borderRadius: "4px",
                      margin: "4px",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.error.main,
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      rejectRequest(request.id);
                      setRecievedAnchorEl(null);
                    }}
                  >
                    Reject
                  </Box>
                </Box>
              </ProfileBlockPopper>
              {loading ? (
                <LoaderIcon
                  style={{
                    width: "32px",
                    height: "32px",
                    margin: "16px",
                  }}
                />
              ) : null}
              {request.profileImageURL ? (
                <img
                  src={`https://${process.env.REACT_APP_PUBLIC_AWS_S3_BUCKET_NAME}.s3.amazonaws.com/${request.id}`}
                  // {...register('image', { required: true })}
                  style={{
                    borderRadius: "12px",
                    height: "50px",
                    width: "50px",
                    cursor: "pointer",
                    marginBottom: "16px",
                  }}
                />
              ) : (
                <div
                  style={{
                    borderRadius: "12px",
                    height: "50px",
                    width: "50px",
                    cursor: "pointer",
                    marginBottom: "16px",
                    background: `linear-gradient(135deg, ${alpha(
                      random_rgba(),
                      1
                    )} 0%, ${alpha(random_rgba(), 1)} 100%)`,
                    backgroundColor: "white",
                  }}
                />
              )}
              <StudyTypographyMediumTitle style={{ marginBottom: "16px" }}>
                {request.email}
              </StudyTypographyMediumTitle>
            </div>
          );
        })
      ) : (
        <div />
      )}
      {/* </CustomAddFriendsBox> */}

      <ToolbarToDoCreateInput
        style={{
          marginBottom: "10px",
        }}
        onClick={() => {
          setShowPinnedSent(!showPinnedSent);
        }}
        // onContextMenu={handleRightClick}
      >
        <>
          <StudyTypographySmallTitle>Requests Sent</StudyTypographySmallTitle>

          {showPinnedSent ? <ArrowDropDownRounded /> : <ArrowDropUpRounded />}
        </>
      </ToolbarToDoCreateInput>
      {/* <CustomAddFriendsBox> */}
      {sent && showPinnedSent ? (
        sent.map((request: any, index: number) => {
          return (
            <div
              style={{
                backgroundColor: theme.palette.primary.main,
                border: `3px solid ${theme.palette.primary.light}`,
                borderRadius: "4px",
                padding: "16px",
                marginBottom: "8px",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <MoreHorizOutlined
                  onClick={(event: any) => {
                    handleClick(event);
                  }}
                  sx={{
                    padding: "4px",

                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: "4px",
                      cursor: "pointer",
                    },
                  }}
                />
              </div>
              <ProfileBlockPopper
                open={open}
                anchorEl={anchorEl}
                placement={"bottom"}
                modifiers={[{ name: "arrow", enabled: true }]}
                style={{
                  backgroundColor: "rgba(0,0,0,0)",
                  margin: 0,
                  paddingRight: 0,
                  zIndex: 100,
                  paddingTop: "8px",
                }}
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
              >
                <Box
                  sx={{
                    alignItems: "center",
                    display: "inline-grid",
                    justifyContent: "center",
                    backgroundColor: theme.palette.primary.main,
                    border: `1px solid ${theme.palette.secondary.light}`, // padding: "8px",
                    borderRadius: "4px",
                    margin: "4px",
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "inline-grid",
                      justifyContent: "center",
                      backgroundColor: theme.palette.primary.main,
                      padding: "8px 16px 8px 16px",
                      borderRadius: "4px",
                      margin: "4px",
                      "&:hover": {
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.error.main,
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      unsendRequest(request?.id);
                    }}
                  >
                    Unsend
                  </Box>
                </Box>
              </ProfileBlockPopper>
              {request.profileImageURL ? (
                <img
                  src={`https://${process.env.REACT_APP_PUBLIC_AWS_S3_BUCKET_NAME}.s3.amazonaws.com/${request.id}`}
                  // {...register('image', { required: true })}
                  style={{
                    borderRadius: "12px",
                    height: "50px",
                    width: "50px",
                    cursor: "pointer",
                    marginBottom: "16px",
                  }}
                />
              ) : (
                <div
                  style={{
                    borderRadius: "12px",
                    height: "50px",
                    width: "50px",
                    cursor: "pointer",
                    marginBottom: "16px",
                    background: `linear-gradient(135deg, ${alpha(
                      random_rgba(),
                      1
                    )} 0%, ${alpha(random_rgba(), 1)} 100%)`,
                    backgroundColor: "white",
                  }}
                />
              )}
              <StudyTypographyMediumTitle style={{ marginBottom: "16px" }}>
                {request.email}
              </StudyTypographyMediumTitle>
            </div>
          );
        })
      ) : (
        <div />
      )}
      {/* </CustomAddFriendsBox> */}
    </ToolbarContainer>
  );
};
