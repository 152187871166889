import { useMutation } from "@apollo/client";
import {
  LockOutlined,
  MusicNoteOutlined,
  VpnLockOutlined,
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import { SetStateAction, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  UPDATE_USER,
  UPDATE_USER_PERMISSIONS,
  UPDATE_USER_TOKEN,
} from "../../../graphql/mutations/user";
import {
  StudyTypographyMediumSubtitle,
  StudyTypographyMediumTitle,
  StudyTypographyMegaTitle,
} from "../../glovebox/StudyTypography";
import { ClockIcon } from "../../Icons/ClockIcon";
import { FriendsIcon } from "../../Icons/FriendsIcon";
import { RoomIcon } from "../../Icons/RoomIcon";
import { ToDoListIcon } from "../../Icons/ToDoListIcon";
import {
  SettingsIconAndTitleContainer,
  SettingsInformationContainer,
  SettingsInformationContainerBugs,
  SettingsStatsBox,
  SettingsStatsTextBox,
  SettingsToggle,
  SettingsToggleContainer,
  SettingsUserBox,
} from "./styles";
// import DateTimePicker from "react-datetime-picker";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import toast from "react-hot-toast";
import { GET_USER_BUGS } from "../../../graphql/queries/bugs";
import { GET_USER_GOOGLE_CATEGORIES } from "../../../graphql/queries/category";
import {
  addGoogleCalendarEvents,
  getGoogleCalendarEvents,
  getGoogleCalendars,
} from "../../../utils/addGoogleCalTodos";
import { client } from "../../../utils/apollo";
import { STUDYO_GRADIENT } from "../../../utils/constants";
import { getToken } from "../../../utils/tokens";
import { GCalendarIcon } from "../../Icons/GCalendarIcon";
import { XIcon } from "../../sidebar/Icons";
import {
  StyledBox,
  StyledCloseButton,
  StyledDialog,
  StyledDialogTopBar,
  StyledHeader,
} from "../calendar/styles";

const spring = {
  type: "spring",
  stiffness: 300,
  damping: 30,
};
const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      {/* <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg> */}
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

export const SettingMain = () => {
  const [todos, setTodos, user, setUser, categories, setCategories] =
    useOutletContext<any>();
  // const [todos, setTodos] = useState(
  //   JSON.parse(localStorage.getItem("todos")!) || []
  // );
  const [bugs, setBugs] = useState<any>([]);
  const [publicTodos, setPublicTodos] = useState(false);
  const [publicAccount, setPublicAccount] = useState(false);
  const [sound, setSound] = useState(true);
  //   const [user, setUser] = useState<any>();
  // const [user, setUser] = useState(
  //   JSON.parse(localStorage.getItem("user")!) || {}
  // );
  // // console.log(user);
  // const [categories, setCategories] = useState(
  //   JSON.parse(localStorage.getItem("categories")!) || []
  // );
  const [calendars, setCalendars] = useState<any[]>([]);

  const [selcetedCalendars, setSelectedCalendars] = useState<any>([]);
  const [fetching, setFetching] = useState(false);
  // const [privacy, setPrrivacy] = useState(user.privateTodos)
  // const [titles, setTitleVisability] = useState(user.displayTodoName)
  const [refreshing, setRefreshing] = useState(false);
  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 90 },
    {
      field: "description",
      headerName: "description",
      width: window.innerWidth / 5,
      editable: false,
    },
    {
      field: "priority",
      headerName: "priority",
      width: window.innerWidth / 10,
      editable: false,
      renderCell: (params) => (
        <strong>
          {params.value === "LOW" ? (
            <StudyTypographyMediumSubtitle
              style={{
                backgroundColor: theme.palette.warning.light,
                padding: "4px",
                borderRadius: "4px",
                textAlign: "center",
              }}
            >
              LOW
            </StudyTypographyMediumSubtitle>
          ) : params.value === "MEDIUM" ? (
            <StudyTypographyMediumSubtitle
              style={{
                backgroundColor: theme.palette.warning.main,
                padding: "4px",
                borderRadius: "4px",
                textAlign: "center",
              }}
            >
              MEDIUM
            </StudyTypographyMediumSubtitle>
          ) : params.value === "HIGH" ? (
            <StudyTypographyMediumSubtitle
              style={{
                backgroundColor: theme.palette.error.main,
                padding: "4px",
                borderRadius: "4px",
                textAlign: "center",
              }}
            >
              HIGH
            </StudyTypographyMediumSubtitle>
          ) : (
            <StudyTypographyMediumSubtitle
              style={{
                backgroundColor: theme.palette.error.dark,
                padding: "4px",
                borderRadius: "4px",
                textAlign: "center",
              }}
            >
              URGENT
            </StudyTypographyMediumSubtitle>
          )}
        </strong>
      ),
    },
    {
      field: "status",
      headerName: "status",
      width: window.innerWidth / 10,
      editable: false,
    },
  ];
  console.log(user?.id);
  useEffect(() => {
    const getData = async () => {
      await client
        .query({
          query: GET_USER_BUGS,
          variables: {
            userId: user?.id,
          },
          fetchPolicy: "network-only",
        })
        .then(({ data: queryData }) => {
          // console.log(queryData);
          setBugs(queryData.getUserSubmittedBugs);
        })
        .catch((queryError) => {
          console.log(queryError.code + ": " + queryError.message);
        });
    };
    getData();
  }, [user]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  // console.log(user.id);
  const imageSrc = user.profileImageURL
    ? `https://${process.env.REACT_APP_PUBLIC_AWS_S3_BUCKET_NAME}.s3.amazonaws.com/${user.id}`
    : require("../../Icons/ProfileMemoji.png");

  useEffect(() => {
    handleTokenFromQueryParams();
  }, []);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    localStorage.setItem("todos", JSON.stringify(todos));
  }, [todos]);

  useEffect(() => {
    localStorage.setItem("categories", JSON.stringify(categories));
  }, [categories]);

  const createGoogleAuthLink = async () => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_GOOGLE_URL}/api/authlink`,
        {
          // mode: 'no-cors',
          method: "POST",
        }
      );
      const response = await request.json();
      window.location.href = response.url;
    } catch (error: any) {
      throw new Error("Issue with Login", error.message);
    }
  };

  const handleTokenFromQueryParams = async () => {
    if (!user.rtoken) {
      setStatus("Connecting with Google...");
      const query = new URLSearchParams(window.location.search);
      const accessToken = query.get("accessToken");
      const refreshToken = query.get("refreshToken");
      const expirationDate = newExpirationDate();
      // console.log("App.js 30 | expiration Date", expirationDate);
      if (accessToken && refreshToken) {
        // if (accessToken && refreshToken) {

        storeTokenData(accessToken, expirationDate);
        // const calendarCategoryId = uuid();
        let newuser = { ...user };
        newuser.rtoken = refreshToken;
        setUser(newuser);
        // localStorage.setItem('googleCalendarCategoryId', calendarCategoryId);
        localStorage.setItem("accessToken", accessToken);
        if (user?.calendar === true) {
          setRefreshing(true);
          await client
            .mutate({
              mutation: UPDATE_USER_TOKEN,
              variables: {
                userId: user.id,
                rtoken: refreshToken,
              },
            })
            .then(() => {
              let newUser = { ...user };
              newUser.rtoken = refreshToken;
              setUser(newUser);
              handleRefresh();
            });
        } else {
          const googleCalendars = await getGoogleCalendars(accessToken);
          setCalendars(googleCalendars.items);
          setSelectedCalendars(
            new Array(googleCalendars.items.length).fill(false)
          );
          setLoading(true);
        }
      }
    }
  };

  const handleRefresh = async () => {
    if (user.rtoken) {
      setRefreshing(true);
      client
        .query({
          query: GET_USER_GOOGLE_CATEGORIES,
          variables: { email: user.email },
          fetchPolicy: "network-only",
        })
        .then(async ({ data: gc }) => {
          const userCalendars = gc.getUserGoogleCategories;
          const token = await getToken(user.rtoken);
          await getGoogleCalendarEvents(
            token,
            false,
            user.id,
            userCalendars?.map((cal: any) => {
              return cal.uuid;
            })
          )
            .then(async (newGoogleTodos: any) => {
              let lsnewTodos = newGoogleTodos.map(
                (newTodos: any, index: any) => {
                  return newTodos?.map((todo: any) => {
                    return {
                      gCalId: todo.gcalId,
                      categoryId: userCalendars[index].uuid,
                      completedAt: null,
                      createdAt: null,
                      dueAt: null,
                      duration: todo.duration,
                      id: null,
                      startTime: todo.startTime,
                      title: todo.title,
                      uuid: todo.uuid,
                      __typename: "Todo",
                    };
                  });
                }
              );
              setTodos([...lsnewTodos.flat(), ...todos]);
              await newGoogleTodos.map(async (newTodos: any, index: any) => {
                if (newTodos?.length > 0) {
                  await addGoogleCalendarEvents(
                    user.id,
                    userCalendars[index].uuid,
                    newTodos
                  );
                }
              });
              setRefreshing(false);
            })
            .catch((error: any) => {
              setRefreshing(false);
            });
        })
        .catch((error: any) => {
          setRefreshing(false);
        });
    }
  };

  const handleSubmitGoogleCalenders = async () => {
    const userCalendars = calendars?.filter((cal, index) => {
      return selcetedCalendars[index];
    });
    // const userCalendarsUUID = calendars.map((cal: any) => {return uuid()})
    const newCalenders = userCalendars.map((cal: any, index: any) => {
      return {
        name: cal.summary,
        label: "Google Calendar",
        googleCal: true,
        uuid: cal.id,
      };
    });
    let newCategories = [...categories];
    // newUser.categories = [...newCalenders, ...categories]
    newCategories = [...newCalenders, ...categories];
    setCategories(newCategories);
    const accessToken = localStorage.getItem("accessToken")!;
    setFetching(true);
    // setLoading(false);
    setStatus("Fetching Todos from Google Calendar...");
    const newTodoPromises = getGoogleCalendarEvents(
      accessToken,
      true,
      user.id,
      newCalenders?.map((cal) => {
        return cal?.uuid;
      })
    );
    const updatePermisionsPromise = client.mutate({
      mutation: UPDATE_USER_PERMISSIONS,
      variables: {
        userId: user.id,
        rtoken: user.rtoken,
        calendars: newCalenders,
        calendar: true,
        calendarIds: newCalenders?.map((cal) => {
          return cal?.uuid;
        }),
      },
    });
    await Promise.all([newTodoPromises, updatePermisionsPromise]).then(
      async ([newGoogleTodos, oldGoogleTodos]) => {
        let lsnewTodos = newGoogleTodos.map((newTodos: any, index: any) => {
          return newTodos?.map((todo: any) => {
            return {
              gCalId: todo.gcalId,
              categoryId: newCalenders[index]?.uuid,
              completedAt: null,
              createdAt: null,
              dueAt: null,
              duration: todo.duration,
              id: null,
              startTime: todo.startTime,
              title: todo.title,
              uuid: todo?.uuid,
              __typename: "Todo",
            };
          });
        });
        setTodos([...lsnewTodos.flat(), ...todos]);
        await newGoogleTodos.map(async (newTodos: any, index: any) => {
          if (newTodos.length > 0) {
            // setStatus('Updating your profile...');
            setStatus("Updating your profile...");
            await addGoogleCalendarEvents(
              user.id,
              newCalenders[index]?.uuid,
              newTodos
            );
          }
        });
        setFetching(false);
        setIsLoggedIn(true);
        setStatus("");
        setLoading(false);
      }
    );
  };

  const newExpirationDate = () => {
    var expiration = new Date();
    expiration.setHours(expiration.getHours() + 1);
    return expiration;
  };

  const storeTokenData = async (token: any, expirationDate: any) => {
    sessionStorage.setItem("accessToken", token);
    // sessionStorage.setItem("refreshToken", refreshToken);
    sessionStorage.setItem("expirationDate", expirationDate);
  };

  const USER_INFORMATION = [
    {
      title: "User Info",
    },
  ];

  const handleAccountPrivacy = () => {
    let newUser = { ...user };
    let prev = newUser.publicProfile;
    newUser.publicProfile = !newUser.publicProfile;
    setUser(newUser);
    // updateUserAccountPrivacy();
    client.mutate({
      mutation: UPDATE_USER,
      variables: {
        updateUserId: user.id,
        publicProfile: prev,
      },
    });
  };
  const handleTodoPrivacy = () => {
    let newUser = { ...user };
    let prev = newUser.privateTodos;
    newUser.privateTodos = !newUser.privateTodos;
    setUser(newUser);
    // updateUserTodoPrivacy();
    client.mutate({
      mutation: UPDATE_USER,
      variables: {
        updateUserId: user.id,
        privateTodos: prev,
      },
    });
  };
  const handleVisability = () => {
    let newUser = { ...user };
    let prev = newUser.displayTodoName;
    newUser.displayTodoName = !newUser.displayTodoName;
    setUser(newUser);
    // updateUserVisability();
    client.mutate({
      mutation: UPDATE_USER,
      variables: {
        updateUserId: user.id,
        displayTodoName: prev,
      },
    });
  };
  const handleSound = () => {
    let newUser = { ...user };
    let prev = newUser.soundEffects;
    newUser.soundEffects = !newUser.soundEffects;
    setUser(newUser);
    // updateUserSound();
    client.mutate({
      mutation: UPDATE_USER,
      variables: {
        updateUserId: user.id,
        soundEffects: prev,
      },
    });
  };
  const handleOnChange = (position: any) => {
    const updatedCheckedState = selcetedCalendars.map((item: any, index: any) =>
      index === position ? !item : item
    );
    setSelectedCalendars(updatedCheckedState);
  };
  const handleGoogleAccess = async () => {
    await createGoogleAuthLink();
  };

  const uploadPhoto = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length <= 0) return;
    const file = e.target.files[0];
    // const filename = encodeURIComponent(file.name);
    let newUser = { ...user };

    newUser.profileImageURL = `https://${process.env.REACT_APP_PUBLIC_AWS_S3_BUCKET_NAME}.s3.amazonaws.com/${user.id}`;

    await fetch(
      `${process.env.REACT_APP_BACKEND_GOOGLE_URL}/api/uploadprofileimage?file=${user.id}`
    ).then(async (res) => {
      const data = await res.json();
      const formData = new FormData();
      setUser(newUser);
      await client.mutate({
        mutation: UPDATE_USER,
        variables: {
          updateUserId: user.id,
          imageURL: `https://${process.env.REACT_APP_PUBLIC_AWS_S3_BUCKET_NAME}.s3.amazonaws.com/${user.id}`,
        },
      });
      Object.entries({ ...data.fields, file }).forEach(([key, value]) => {
        // @ts-ignore
        formData.append(key, value);
      });

      toast.promise(
        fetch(data.url, {
          method: "POST",
          body: formData,
        }).then(() => {
          setUser(newUser);
          window.location.reload();
        }),
        {
          loading: "uploading...",
          success: "image saved!🎉",
          error: `could not save 😥`,
        },
        {
          duration: 4000,
          style: {
            fontSize: "12px",
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.text.primary,
          },
        }
      );
    });
  };

  const SETTINGS_INFORMATION = [
    {
      title: "Make todos public",
      description:
        "Enabling this setting will allow you to share your daily schedule with others",
      switch: user.privateTodos,
      setSwitch: handleVisability,
      icon: <LockOutlined style={{ width: "30px", height: "30px" }} />,
    },
    {
      title: "Make profile public",
      description:
        "When other users search in friends tab enabling this will allow them to see you if they input your name",
      switch: user.publicProfile,
      setSwitch: handleAccountPrivacy,
      icon: <VpnLockOutlined style={{ width: "30px", height: "30px" }} />,
    },
    {
      title: "Enable sound effects",
      description:
        "We have sound effects to make the app feel alive. Use this setting to turn on or off",
      switch: user.soundEffects,
      setSwitch: handleSound,
      icon: <MusicNoteOutlined style={{ width: "30px", height: "30px" }} />,
    },
  ];

  const toggleSwitch = (
    hello: boolean,
    setSwitch: { (value: SetStateAction<boolean>): void; (arg0: boolean): void }
  ) => {
    setSwitch(!hello);
  };

  const [
    updateUserTodoPrivacy,
    { data: userPrivacy, loading: loadingPrivacy },
  ] = useMutation(UPDATE_USER, {
    variables: {
      updateUserId: user.id,
      privateTodos: user.privateTodos,
    },
  });
  const [updateUserAccountPrivacy, { data: userAccountPrivacy }] = useMutation(
    UPDATE_USER,
    {
      variables: {
        updateUserId: user.id,
        publicProfile: user.publicProfile,
      },
    }
  );
  const [
    updateUserVisability,
    { data: userVisability, loading: loadingVisability },
  ] = useMutation(UPDATE_USER, {
    variables: {
      updateUserId: user.id,
      displayTodoName: user.displayTodoName,
    },
  });
  const [updateUserSound, { data: updatateSound }] = useMutation(UPDATE_USER, {
    variables: {
      updateUserId: user.id,
      soundEffects: user.soundEffects,
    },
  });

  const theme = useTheme();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        // flexDirection: "row",
        overflowY: "auto",
        height: "90vh",

        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        flexDirection: "column",
      }}
    >
      <StyledDialog
        open={loading}
        onClose={() => {}}
        aria-labelledby="archive-task-modal"
        aria-describedby="modal-modal-description"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <StyledBox>
          <StyledDialogTopBar>
            <StyledHeader
              style={{
                marginLeft: 0,
              }}
            >
              Pick Your Calendar
            </StyledHeader>
            <StyledCloseButton onClick={() => setLoading(false)}>
              <XIcon />
            </StyledCloseButton>
          </StyledDialogTopBar>
          {/* <Box sx={style}> */}
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={fetching}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            {status}
          </Typography> */}
          {calendars ? (
            <div>
              <StudyTypographyMediumTitle
                style={{ fontWeight: "300", marginBottom: "12px" }}
              >
                Select which of your Google Calendars you would like to pull
                from
              </StudyTypographyMediumTitle>
              <form onSubmit={handleSubmitGoogleCalenders}>
                {/* <select multiple={true} value={selcetedCalendars} onChange={(e)=> {console.log(e.target.options)}}> */}
                {calendars?.map((cal: any, index: any) => {
                  return (
                    <div style={{ alignItems: "center", display: "flex" }}>
                      <input
                        type="checkbox"
                        key={index}
                        id={`custom-checkbox-${index}`}
                        name={cal.summary}
                        value={cal.summary}
                        checked={selcetedCalendars[index]}
                        onChange={() => handleOnChange(index)}
                        style={{ marginRight: "12px" }}
                      />
                      <label htmlFor={`custom-checkbox-${index}`}>
                        {cal.summary}
                      </label>
                    </div>
                  );
                })}
                {/* </select> */}
                <Button
                  onClick={handleSubmitGoogleCalenders}
                  style={{
                    marginTop: "12px",
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.text.primary,
                  }}
                  color="primary"
                >
                  Submit
                </Button>
              </form>
            </div>
          ) : (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              You do not have any calendars connected to your Google Account.
            </Typography>
          )}
          {/* </Box> */}
        </StyledBox>
      </StyledDialog>
      {/* </Modal> */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={refreshing}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* <div
        style={{
          margin: "16px",
          marginBottom: "0px",

          border: `16px solid ${theme.palette.primary.light}`,
          backgroundColor: theme.palette.primary.light,

          borderRadius: "4px",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          height: "150px",
          flexDirection: "column",
        }}
      > */}

      {isMobile ? (
        <div style={{ padding: "16px", flexDirection: "column" }}>
          <Grid item xs={2} marginBottom={"16px"}>
            <SettingsInformationContainer>
              <input
                onChange={uploadPhoto}
                id="imgupload"
                type="file"
                accept="image/png, image/jpeg"
                name="image"
                style={{ display: "none" }}
              />
              <label
                style={{ width: "150px", height: "150px" }}
                htmlFor="imgupload"
              >
                <img
                  // {...register('image', { required: true })}
                  src={imageSrc}
                  key={Date.now()}
                  style={{
                    borderRadius: "12px",
                    height: "150px",
                    marginRight: "12px",
                    cursor: "pointer",
                  }}
                />
              </label>
            </SettingsInformationContainer>
          </Grid>
          <Grid item xs={5} marginBottom={"16px"}>
            <SettingsInformationContainer>
              <SettingsUserBox>
                <StudyTypographyMediumSubtitle>
                  Name:{" "}
                </StudyTypographyMediumSubtitle>
                <StudyTypographyMediumTitle
                  style={{ marginLeft: "6px" }}
                >{`${user?.firstName} ${user?.lastName}`}</StudyTypographyMediumTitle>
              </SettingsUserBox>
              <SettingsUserBox>
                <StudyTypographyMediumSubtitle>
                  Email:{" "}
                </StudyTypographyMediumSubtitle>

                <StudyTypographyMediumTitle
                  style={{ marginLeft: "6px" }}
                >{`${user?.email}`}</StudyTypographyMediumTitle>
              </SettingsUserBox>
            </SettingsInformationContainer>
          </Grid>
          <Grid item xs={5}>
            <SettingsInformationContainer>
              <StudyTypographyMediumTitle>
                Bugs submitted :)
              </StudyTypographyMediumTitle>
            </SettingsInformationContainer>
          </Grid>
        </div>
      ) : (
        <Grid
          container
          spacing={2}
          padding={"16px"}
          direction={isMobile ? "column" : "row"}
        >
          <Grid item xs={2}>
            <SettingsInformationContainer>
              <input
                onChange={uploadPhoto}
                id="imgupload"
                type="file"
                accept="image/png, image/jpeg"
                name="image"
                style={{ display: "none" }}
              />
              <label
                style={{
                  width: "100%",
                }}
                htmlFor="imgupload"
              >
                <img
                  // {...register('image', { required: true })}
                  src={imageSrc}
                  key={Date.now()}
                  style={{
                    borderRadius: "12px",
                    // minWidth: "150px",
                    // minHeight: "150px",
                    width: "100%",

                    marginRight: "12px",
                    cursor: "pointer",
                  }}
                />
              </label>
            </SettingsInformationContainer>
          </Grid>
          <Grid item xs={5}>
            <SettingsInformationContainer>
              <SettingsUserBox>
                <StudyTypographyMediumSubtitle>
                  Name:{" "}
                </StudyTypographyMediumSubtitle>
                <StudyTypographyMediumTitle
                  style={{ marginLeft: "6px" }}
                >{`${user?.firstName} ${user?.lastName}`}</StudyTypographyMediumTitle>
              </SettingsUserBox>
              <SettingsUserBox>
                <StudyTypographyMediumSubtitle>
                  Email:{" "}
                </StudyTypographyMediumSubtitle>

                <StudyTypographyMediumTitle
                  style={{ marginLeft: "6px" }}
                >{`${user?.email}`}</StudyTypographyMediumTitle>
              </SettingsUserBox>
            </SettingsInformationContainer>
          </Grid>
          <Grid item xs={5}>
            <SettingsInformationContainerBugs>
              <StudyTypographyMediumTitle style={{ marginBottom: "12px" }}>
                Bugs submitted :)
              </StudyTypographyMediumTitle>

              <DataGrid
                rows={bugs}
                columns={columns}
                hideFooter
                hideFooterPagination
                slots={{ toolbar: null, noRowsOverlay: CustomNoRowsOverlay }}
                columnHeaderHeight={32}
                style={{
                  width: "100%",
                  borderColor: theme.palette.primary.light,
                  backgroundColor: theme.palette.primary.light,
                  color: theme.palette.text.primary,
                  justifyContent: "space-between",
                }}
              />

              {/* {bugs?.map((bug: any) => (
                <Stack
                  flexDirection={"row"}
                  alignItems="center"
                  display="flex"
                  width={"100%"}
                  justifyContent="space-between"
                  textAlign={"left"}
                >
                  <StudyTypographyMediumSubtitle
                    style={{ textAlign: "left", width: "100%" }}
                  >
                    {bug?.description}
                  </StudyTypographyMediumSubtitle>
                  <StudyTypographyMediumSubtitle
                    style={{ textAlign: "center", width: "100%" }}
                  >
                    {bug?.priority}
                  </StudyTypographyMediumSubtitle>

                  <StudyTypographyMediumSubtitle
                    style={{ textAlign: "right", width: "100%" }}
                  >
                    {bug?.status}
                  </StudyTypographyMediumSubtitle>
                </Stack>
              ))} */}
            </SettingsInformationContainerBugs>
          </Grid>
        </Grid>
      )}
      {isMobile ? (
        <div style={{ padding: "16px", flexDirection: "column" }}>
          <Grid item xs={6} marginBottom={"16px"}>
            <SettingsInformationContainer>
              <SettingsStatsBox>
                <SettingsStatsTextBox
                  onClick={() => {
                    navigate("/app/schedule");
                  }}
                >
                  <StudyTypographyMediumTitle style={{ marginTop: "16px" }}>
                    current todos
                  </StudyTypographyMediumTitle>
                  <div style={{ margin: "8px" }}>
                    <ClockIcon active={false} clicked={() => {}} />
                  </div>
                  <StudyTypographyMegaTitle style={{ marginBottom: "16px" }}>
                    {
                      todos?.filter((todo: any) => todo?.completedAt === null)
                        .length
                    }
                  </StudyTypographyMegaTitle>
                </SettingsStatsTextBox>
                <SettingsStatsTextBox
                  onClick={() => {
                    navigate("/app/todos");
                  }}
                >
                  <StudyTypographyMediumTitle style={{ marginTop: "16px" }}>
                    completed todos
                  </StudyTypographyMediumTitle>
                  <div style={{ margin: "8px" }}>
                    <ToDoListIcon active={false} clicked={() => {}} />
                  </div>

                  <StudyTypographyMegaTitle style={{ marginBottom: "16px" }}>
                    {
                      todos?.filter((todo: any) => todo?.completedAt !== null)
                        .length
                    }
                  </StudyTypographyMegaTitle>
                </SettingsStatsTextBox>
                <SettingsStatsTextBox
                  onClick={() => {
                    navigate("/app/friends");
                  }}
                >
                  <StudyTypographyMediumTitle style={{ marginTop: "16px" }}>
                    friends
                  </StudyTypographyMediumTitle>
                  <div style={{ margin: "8px" }}>
                    <FriendsIcon active={false} clicked={() => {}} />
                  </div>

                  <StudyTypographyMegaTitle style={{ marginBottom: "16px" }}>
                    {user?.friends.length}
                  </StudyTypographyMegaTitle>
                </SettingsStatsTextBox>
                <SettingsStatsTextBox
                  onClick={() => {
                    navigate("/app/cowork");
                  }}
                >
                  <StudyTypographyMediumTitle style={{ marginTop: "16px" }}>
                    rooms
                  </StudyTypographyMediumTitle>
                  <div style={{ margin: "8px" }}>
                    <RoomIcon active={false} clicked={() => {}} />
                  </div>

                  <StudyTypographyMegaTitle style={{ marginBottom: "16px" }}>
                    {user?.groups?.length}
                  </StudyTypographyMegaTitle>
                </SettingsStatsTextBox>
              </SettingsStatsBox>
            </SettingsInformationContainer>
          </Grid>
          <Grid item xs={3}>
            <SettingsInformationContainer>
              <SettingsUserBox>
                <GCalendarIcon active={false} clicked={() => {}} />
              </SettingsUserBox>
              <SettingsUserBox>
                <StudyTypographyMediumSubtitle>
                  Sync Google Calendar
                </StudyTypographyMediumSubtitle>
              </SettingsUserBox>
              <SettingsUserBox style={{ width: "100%" }}>
                {user.rtoken ? (
                  <Button
                    fullWidth
                    style={{
                      textTransform: "none",
                      background: STUDYO_GRADIENT,
                      backgroundColor: "white",
                      marginTop: "16px",
                      color: theme.palette.text.primary,
                    }}
                    onClick={handleRefresh}
                  >
                    refresh
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    style={{
                      textTransform: "none",
                      background: STUDYO_GRADIENT,
                      backgroundColor: "white",
                      marginTop: "16px",
                    }}
                    onClick={handleGoogleAccess}
                  >
                    sync!
                  </Button>
                )}
              </SettingsUserBox>
            </SettingsInformationContainer>
          </Grid>
          <Grid item xs={3}>
            <SettingsInformationContainer>
              <SettingsUserBox>
                <GCalendarIcon active={false} clicked={() => {}} />
              </SettingsUserBox>
              <SettingsUserBox>
                <StudyTypographyMediumSubtitle>
                  Sync Apple Calendar
                </StudyTypographyMediumSubtitle>
              </SettingsUserBox>
              <SettingsUserBox style={{ width: "100%" }}>
                <Button
                  fullWidth
                  style={{
                    textTransform: "none",
                    background: STUDYO_GRADIENT,
                    backgroundColor: "white",
                    marginTop: "16px",
                  }}
                  onClick={handleRefresh}
                  disabled
                >
                  coming soon!
                </Button>
              </SettingsUserBox>
            </SettingsInformationContainer>
          </Grid>
        </div>
      ) : (
        <Grid
          container
          spacing={2}
          padding={"16px"}
          direction={isMobile ? "column" : "row"}
        >
          <Grid item xs={6}>
            <SettingsInformationContainer>
              <SettingsStatsBox>
                <SettingsStatsTextBox
                  onClick={() => {
                    navigate("/app/schedule");
                  }}
                >
                  <StudyTypographyMediumTitle>
                    current todos
                  </StudyTypographyMediumTitle>
                  <div style={{ margin: "8px" }}>
                    <ClockIcon active={false} clicked={() => {}} />
                  </div>
                  <StudyTypographyMegaTitle>
                    {
                      todos?.filter((todo: any) => todo?.completedAt === null)
                        .length
                    }
                  </StudyTypographyMegaTitle>
                </SettingsStatsTextBox>
                <SettingsStatsTextBox
                  onClick={() => {
                    navigate("/app/todos/completed");
                  }}
                >
                  <StudyTypographyMediumTitle>
                    completed todos
                  </StudyTypographyMediumTitle>
                  <div style={{ margin: "8px" }}>
                    <ToDoListIcon active={false} clicked={() => {}} />
                  </div>

                  <StudyTypographyMegaTitle>
                    {
                      todos?.filter((todo: any) => todo?.completedAt !== null)
                        .length
                    }
                  </StudyTypographyMegaTitle>
                </SettingsStatsTextBox>
                <SettingsStatsTextBox
                  onClick={() => {
                    navigate("/app/friends");
                  }}
                >
                  <StudyTypographyMediumTitle>
                    friends
                  </StudyTypographyMediumTitle>
                  <div style={{ margin: "8px" }}>
                    <FriendsIcon active={false} clicked={() => {}} />
                  </div>

                  <StudyTypographyMegaTitle>
                    {user?.friends.length}
                  </StudyTypographyMegaTitle>
                </SettingsStatsTextBox>
                <SettingsStatsTextBox
                  onClick={() => {
                    navigate("/app/cowork");
                  }}
                >
                  <StudyTypographyMediumTitle>rooms</StudyTypographyMediumTitle>
                  <div style={{ margin: "8px" }}>
                    <RoomIcon active={false} clicked={() => {}} />
                  </div>
                  <StudyTypographyMegaTitle>
                    {user?.groups?.length}
                  </StudyTypographyMegaTitle>
                </SettingsStatsTextBox>
              </SettingsStatsBox>
            </SettingsInformationContainer>
          </Grid>
          <Grid item xs={3}>
            <SettingsInformationContainer>
              <SettingsUserBox>
                <GCalendarIcon active={false} clicked={() => {}} />
              </SettingsUserBox>
              <SettingsUserBox>
                <StudyTypographyMediumSubtitle>
                  Sync Google Calendar
                </StudyTypographyMediumSubtitle>
              </SettingsUserBox>
              <SettingsUserBox style={{ width: "100%" }}>
                {user.rtoken ? (
                  <Button
                    fullWidth
                    style={{
                      textTransform: "none",
                      background: STUDYO_GRADIENT,
                      backgroundColor: "white",
                      marginTop: "16px",
                      color: theme.palette.text.primary,
                    }}
                    onClick={handleRefresh}
                  >
                    refresh
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    style={{
                      textTransform: "none",
                      background: STUDYO_GRADIENT,
                      backgroundColor: "white",
                      marginTop: "16px",
                    }}
                    onClick={handleGoogleAccess}
                  >
                    sync!
                  </Button>
                )}
              </SettingsUserBox>
            </SettingsInformationContainer>
          </Grid>
          <Grid item xs={3}>
            <SettingsInformationContainer>
              <SettingsUserBox>
                <GCalendarIcon active={false} clicked={() => {}} />
              </SettingsUserBox>
              <SettingsUserBox>
                <StudyTypographyMediumSubtitle>
                  Sync Apple Calendar
                </StudyTypographyMediumSubtitle>
              </SettingsUserBox>
              <SettingsUserBox style={{ width: "100%" }}>
                <Button
                  fullWidth
                  style={{
                    textTransform: "none",
                    backgroundColor: theme.palette.grey[600],
                    marginTop: "16px",
                  }}
                  onClick={handleRefresh}
                  disabled
                >
                  coming soon!
                </Button>
              </SettingsUserBox>
            </SettingsInformationContainer>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        spacing={2}
        padding={"16px"}
        direction={isMobile ? "column" : "row"}
        style={{ opacity: "0.2" }}
      >
        {SETTINGS_INFORMATION.map((setting) => (
          <Grid item xs={4}>
            <SettingsToggleContainer isOn={setting.switch}>
              <SettingsIconAndTitleContainer>
                <div style={{ marginRight: "12px" }}>{setting.icon}</div>
                <div>
                  <StudyTypographyMediumTitle>
                    Settings coming soon
                  </StudyTypographyMediumTitle>
                  {/* <StudyTypographyMediumTitle style={{ marginBottom: "4px" }}>
                    {setting.title}
                  </StudyTypographyMediumTitle> */}
                  <StudyTypographyMediumSubtitle
                    style={{ marginBottom: "16px" }}
                  >
                    {setting.description}
                  </StudyTypographyMediumSubtitle>
                </div>
              </SettingsIconAndTitleContainer>

              <SettingsToggle
                isOn={setting.switch}
                onClick={() => {
                  toggleSwitch(setting.switch, setting.setSwitch);
                }}
              >
                <motion.div
                  className="handle"
                  layout
                  transition={spring}
                  style={{
                    width: "75px",
                    height: "30px",
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "7.5px",
                  }}
                />
              </SettingsToggle>
            </SettingsToggleContainer>
          </Grid>
        ))}
      </Grid>
      {isMobile ? <div style={{ height: "80px", width: "100%" }}></div> : null}
    </div>
  );
};
