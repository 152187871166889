import { alpha, Box, Button, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useRef, useState } from "react";
import {
  STAGGER_CHILDREN,
  STAGGER_CONTAINER,
  STUDYO_GRADIENT,
} from "../../../utils/constants";
import {
  StudyTypographyMediumText,
  StudyTypographyMegaSubtitle,
} from "../../glovebox/StudyTypography";
import { AddIcon } from "../../Icons/AddIcon";
import { CustomInput, ToolbarToDoCreateInput } from "../../toolbar/styles";
import { CoworkChatContainer } from "./styles";

export const CoworkChat = ({
  modal,
  setModal,
  loading,
}: {
  modal: any;
  setModal: any;
  loading: any;
}) => {
  // console.log(loading);
  const [messages, setMessages] = useState<any>([
    { title: "hello there!", id: "1" },
    { title: "this feature is coming very soon", id: "1" },
    { title: "hope you are as excited as we are", id: "1" },
    { title: "AND AWAYYYYY WE GO", id: "2" },
  ]);
  const [message, setMessage] = useState("");
  const inputRef = useRef<any>(null);
  // const { id: userId } = useUserContext();
  const userId = localStorage.getItem("userId");

  const theme = useTheme();

  return (
    <div>
      <CoworkChatContainer>
        <StudyTypographyMegaSubtitle></StudyTypographyMegaSubtitle>
        <motion.div
          variants={STAGGER_CONTAINER}
          initial="hidden"
          animate="visible"
          style={{ height: "100%" }}
        >
          {/* <StudyTypographyMega>PLATONS CHAT</StudyTypographyMega> */}
          <Box
            style={{
              height: "100%",
              justifyContent: "flex-end",
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
            }}
          >
            {messages.map((item: any, index: any) => (
              <motion.div
                key={index}
                variants={STAGGER_CHILDREN}
                style={{
                  padding: "8px",
                  backgroundImage:
                    item.userId === userId
                      ? STUDYO_GRADIENT
                      : theme.palette.primary.light,
                  backgroundColor:
                    item.userId === userId
                      ? "#F4F6F8"
                      : theme.palette.primary.light,
                  borderRadius: "8px",

                  maxWidth: "80%",
                  alignSelf: item.userId === userId ? "flex-end" : "flex-start",
                  marginBottom: "12px",
                  hyphens: "auto",
                }}
              >
                <StudyTypographyMediumText
                  style={{
                    fontSize: 14,
                    color:
                      item.userId === userId
                        ? "black"
                        : theme.palette.text.secondary,
                  }}
                >
                  {item.title}
                </StudyTypographyMediumText>
              </motion.div>
            ))}
          </Box>
        </motion.div>

        <form
          onSubmit={(event) => {
            event.preventDefault();
            var newMessages = [
              ...messages,
              {
                title: message,
                userId: userId,
              },
            ];
            setMessages(newMessages);
            setMessage("");
          }}
        >
          <ToolbarToDoCreateInput style={{ marginBottom: "10px" }}>
            <CustomInput
              ref={inputRef}
              aria-label="Demo input"
              placeholder="Type something…"
              onChange={(text) => {
                setMessage(text.currentTarget.value);
              }}
              value={message}
            />
            <AddIcon
              clicked={() => {
                var newMessages = [
                  ...messages,
                  {
                    title: message,
                    userId: userId,
                  },
                ];
                setMessages(newMessages);
                setMessage("");
              }}
            />
          </ToolbarToDoCreateInput>
        </form>
        <Box
          sx={{
            alignItems: "center",
            width: "100%",
            display: "inline-grid",
            justifyContent: "center",
            backgroundColor: theme.palette.secondary.light,
            // padding: "8px",
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: theme.palette.primary.light,

              cursor: "pointer",
            },
          }}
        >
          <Button
            onClick={(event: any) => {
              event.stopPropagation();
              // modal = true;
              setModal(true);
              // mutation(data.uuid)
              // setRecievedAnchorEl(null);
            }}
            disabled={loading}
          >
            Add Users
          </Button>
        </Box>
      </CoworkChatContainer>
    </div>
  );
};
