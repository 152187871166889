import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query GetUsers {
    getUsers {
      id
      email
      lastName
      firstName
      profileImageURL
    }
  }
`;

export const GET_USER_SIGNIN = gql`
  query GetUserEmail($email: String!) {
    getUserEmail(email: $email) {
      id
      firstName
      lastName
      email
      active
      rtoken
      syncToken
      googleCals
    }
  }
`;

// export const GET_USER_INFO = gql`
//   query GetUser($getUserId: String!) {
//     getUser(id: $getUserId) {
//       lightSetting
//       todos {
//         Category {
//           id
//           name
//           uuid
//         }
//         completedAt
//         createdAt
//         deletedAt
//         dueAt
//         duration
//         id
//         startTime
//         title
//       }
//       email
//       firstName
//       active
//       lastName
//       groups {
//         id
//         name
//         todos {
//           Category {
//             name
//             uuid
//           }
//           categoryId
//           completedAt
//           createdAt
//           deletedAt
//           dueAt
//           duration
//           id
//           startTime
//           title
//         }
//       }
//       id
//       friends {
//         active
//         firstName
//         lastName
//         id
//         privateTodos
//         displayTodoName
//         todos {
//           id
//           title
//           startTime
//           duration
//         }
//       }
//     }
//   }
// `;

export const GET_USER_FRIEND_REQUESTS = gql`
query GetUser($getUserId: String!) {
  getUser(id: $getUserId) {
    friendRequestsRecieved {
      id
      email
      firstName
      lastName
      profileImageURL
    }
  }
}
`

export const GET_USER_FRIENDS = gql`
query GetUser(
  $getUserId: String!
  $userId: String!
  ) {
  getUser(id: $getUserId) {
    friends {
      id
      email
      lastName
      firstName
      profileImageURL
    }
  }
  getUserGroupInvites(userId: $userId) {
    id
    name
    uuid
    users {
      id
    }
  }
}
`;

export const GET_USER_INFO_REFRESH = gql`
  query GetUser(
    $userId: String!
    $getUserTodosUserId2: String!
    $getUserTodoInvitesUserId2: String!
    $getUserGroupInvitesUserId3: String!
  ) {
    getUserCategories(userId: $userId) {
      id
      name
      uuid
      label
    }
    getUserTodos(userId: $getUserTodosUserId2) {
      id
      title
      duration
      startTime
      completedAt
      deletedAt
      createdAt
      dueAt
      categoryId
      uuid
      gCalId
      event
      reoccurring
      todoReplicas {
        id
        duration
        startTime
        completedAt
        todoId
        event
        uuid
      }
    }
    getUserTodoInvites(userId: $getUserTodoInvitesUserId2) {
      id
      title
      uuid
      duration
      startTime
    }
    getUserGroupInvites(userId: $getUserGroupInvitesUserId3) {
      id
      name
      uuid
      users {
        id
      }
    }
  }
`;

export const GET_USER_INFO = gql`
  query GetUser(
    $getUserId: String!
    $userId: String!
    $getUserTodosUserId2: String!
    $getUserGroupInvitesUserId3: String!
  ) {
    getUser(id: $getUserId) {
      id
      email
      displayTodoName
      privateTodos
      publicProfile
      soundEffects
      lightSetting
      baseCategoryId
      active
      firstName
      profileImageURL
      calendar
      lastName
      groups {
        id
        name
        users {
          id
        }
        uuid
      }
      friends {
        id
        email
        lastName
        firstName
        profileImageURL
      }
      friendRequestsRecieved {
        id
        email
        firstName
        lastName
        profileImageURL
      }
      friendRequestsSent {
        email
        id
        firstName
        lastName
        profileImageURL
      }
      rtoken
    }
    getUserCategories(userId: $userId) {
      id
      name
      uuid
      googleCal
      label
    }
    getUserTodos(userId: $getUserTodosUserId2) {
      id
      title
      duration
      startTime
      completedAt
      deletedAt
      createdAt
      dueAt
      categoryId
      uuid
      gCalId
      event
      reoccurring
      todoReplicas {
        id
        duration
        startTime
        completedAt
        todoId
        event
        uuid
      }
    }
    getUserGroupInvites(userId: $getUserGroupInvitesUserId3) {
      id
      name
      uuid
      users {
        id
      }
    }
  }
`;

export const USER_GROUP_INVITES = gql`
  query GetUserGroupInvites($userId: String!) {
    getUserGroupInvites(userId: $userId) {
      id
      name
      uuid
      users {
        id
      }
    }
  }
`;

export const FRIEND_TODOS = gql`
  query GetUser($getUserId: String!) {
    getUser(id: $getUserId) {
      id
      email
      lastName
      firstName
      profileImageURL
      displayTodoName
      privateTodos
    }
  }
`;

// todos {
//   uuid
//   title
//   startTime
//   id
//   duration
//   dueAt
//   createdAt
//   completedAt
// }
// todos {
//   uuid
//   id
//   startTime
//   title
//   duration
//   dueAt
//   createdAt
//   completedAt
//   categoryId
// }

// eventInvites {
//   id
//   title
//   uuid
//   duration
//   startTime
// }
// friends {
//   id
//   email
//   lastName
//   firstName
//   todos {
//     id
//     startTime
//     title
//     duration
//   }
//   displayTodoName
//   privateTodos
// }
