export const getToken = async (rtoken:any) => {
    // if (tokenExpired()) {
    const refreshtoken = rtoken;
    const token = await getValidTokenFromServer(refreshtoken);
    sessionStorage.setItem("accessToken", token.accessToken);
    sessionStorage.setItem("expirationDate", newExpirationDate().toISOString());
    return token.accessToken;
    // } else {
    //   console.log("tokens.js 11 | token not expired");
    //   return sessionStorage.getItem("accessToken");
    // }
};
  
const newExpirationDate = () => {
    var expiration = new Date();
    expiration.setHours(expiration.getHours() + 1);
    return expiration;
};
  
const tokenExpired = () => {
    const now = Date.now();
  
    const expirationDate = sessionStorage.getItem("expirationDate");
    const expDate = new Date(expirationDate!);
  
    if (now > expDate.getTime()) {
        return true; // token expired
    }
  
    return false; // valid token
};
  
const getValidTokenFromServer = async (refreshToken: any) => {
    // get new token from server with refresh token
    try {
        const request = await fetch(`${process.env.REACT_APP_BACKEND_GOOGLE_URL}/api/token`, {
            method: "POST",
            mode: 'cors',
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify({
            refreshToken: refreshToken,
            }),
        });
      const token = await request.json();
      return token;
    } catch (error: any) {
      throw new Error("Issue getting new token", error.message);
    }
};