import { gql } from "@apollo/client";
import { client } from "../../utils/apollo";

export const SEND_FRIEND_REQUEST = gql`
  mutation SendFriendRequest($userId: String!, $friendId: String!) {
    sendFriendRequest(userId: $userId, friendId: $friendId) {
      id
      email
    }
  }
`;

export const UNSEND_FRIEND_REQUEST = gql`
  mutation UnsendFriendRequest($userId: String!, $friendId: String!) {
    unsendFriendRequest(userId: $userId, friendId: $friendId) {
      id
      email
    }
  }
`;

export const REJECT_FRIEND_REQUEST = gql`
  mutation RejectFriendRequest($userId: String!, $friendId: String!) {
    rejectFriendRequest(userId: $userId, friendId: $friendId) {
      id
      email
    }
  }
`;

export const ACCEPT_FRIEND_REQUEST = gql`
  mutation AddFriend($userId: String!, $friendId: String!) {
    addFriend(userId: $userId, friendId: $friendId) {
      id
      email
      friends {
        id
        email
        friends {
          id
          email
        }
        friendRequestsRecieved {
          id
          email
        }
        friendRequestsSent {
          id
          email
        }
      }
    }
  }
`;

export const DELETE_FRIEND = gql`
  mutation DeleteFriend($userId: String!, $friendId: String!) {
    deleteFriend(userId: $userId, friendId: $friendId) {
      id
      email
    }
  }
`;

export const sendRequest = async (friend: any, user: any, setUser: any) => {
  // console.log(friendId)
  let updatedUser = { ...user };
  updatedUser.friendRequestsSent = [friend, ...updatedUser.friendRequestsSent];
  setUser(updatedUser);
  // setSent(updatedUser.friendRequestsSent.map((request: any) => {return request.id}))
  // localStorage.setItem('user', JSON.stringify(updatedUser));
  return client.mutate({
    mutation: SEND_FRIEND_REQUEST,
    variables: {
      userId: user.id,
      friendId: friend.id,
    },
  });
};

export const unsendRequest = async (
  friendId: string,
  user: any,
  setUser: any
) => {
  let updatedUser = { ...user };
  updatedUser.friendRequestsSent = updatedUser.friendRequestsSent.filter(
    function (u: any) {
      return u.id !== friendId;
    }
  );
  setUser(updatedUser);
  // setSent(updatedUser.friendRequestsSent.map((request: any) => {return request.id}))
  // localStorage.setItem('user', JSON.stringify(updatedUser));
  return client.mutate({
    mutation: UNSEND_FRIEND_REQUEST,
    variables: {
      userId: user.id,
      friendId: friendId,
    },
  });
};

export const rejectRequest = async (
  friendId: string,
  setRecieved: any,
  user: any,
  setUser: any
) => {
  let updatedUser = { ...user };
  updatedUser.friendRequestsRecieved =
    updatedUser.friendRequestsRecieved.filter(function (u: any) {
      return u.id !== friendId;
    });
  setUser(updatedUser);
  setRecieved(user.friendRequestsRecieved);
  return client.mutate({
    mutation: REJECT_FRIEND_REQUEST,
    variables: {
      userId: user.id,
      friendId: friendId,
    },
  });
};

export const acceptRequest = async (friend: any, user: any, setUser: any) => {
  let updatedUser = { ...user };
  updatedUser.friendRequestsRecieved =
    updatedUser.friendRequestsRecieved.filter(function (u: any) {
      return u.id !== friend.id;
    });
  updatedUser.friends = [{ ...friend }, ...updatedUser.friends];
  setUser(updatedUser);
  return client.mutate({
    mutation: ACCEPT_FRIEND_REQUEST,
    variables: {
      userId: user.id,
      friendId: friend.id,
    },
  });
  //I NEED A LOADING OVERLAY WHILE IT MUTATES AND FETCHES NEW
  //INFO -- LEFT IT TO YOU BECAUSE IDK IF YOU HAVE A PACKAGE
};

export const DeleteFriend = async (
  friendId: string,
  user: any,
  setUser: any
) => {
  let updatedUser = { ...user };
  updatedUser.friends = updatedUser.friends.filter(function (u: any) {
    return u.id !== friendId;
  });
  setUser(updatedUser);
  // setFriends(updatedUser.friends);
  return client.mutate({
    mutation: DELETE_FRIEND,
    variables: {
      userId: user.id,
      friendId: friendId,
    },
  });
};
