import { styled } from "@mui/material/styles";
import { Box, Card, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { isMobile } from "react-device-detect";
import {
  StudyTypographyMediumTitle,
  StudyTypographyMegaTitle,
} from "../glovebox/StudyTypography";
import { select } from "d3-selection";
import PowerGridLines from "./PowerGridLines";

const RootStyle = styled(Card)(({ theme, background }) => ({
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  height: isMobile || window.innerWidth < 425 ? null : 500,
  justifyContent: "space-between",
  boxShadow: "none",
  textAlign: "center",
  alignItems: "center",
  alignContent: "center",
  paddingTop: "24px",
  backgroundColor: theme.palette.primary.light,
  borderRadius: "12px",
}));

function RandomLine({ hovered }) {
  const theme = useTheme();
  const ref = useRef();
  const [points, setPoints] = useState([]);

  useEffect(() => {
    if (ref.current) {
      const width = ref.current.clientWidth;
      const height = ref.current.clientHeight;
      const numPoints = 5;

      let lastX = 0;
      let counter = 0;

      const generatePoint = () => {
        if (counter === 0) {
          counter++;
          return [0, height];
        }
        if (counter === numPoints - 1) {
          counter = 0;
          return [width, height];
        }

        const widthPerQuadrant = width / numPoints;
        const minX = counter * widthPerQuadrant;
        const maxX = (counter + 1) * widthPerQuadrant;
        const newX = minX + Math.random() * (maxX - minX);
        const y = counter % 2 === 0 ? height : Math.random() * height;
        counter++;
        return [newX, y];
      };

      // Generate points only once
      if (points.length === 0) {
        setPoints(Array.from({ length: numPoints }).map(() => generatePoint()));
      }
    }
  }, [ref.current]);

  useEffect(() => {
    if (points.length > 0) {
      const svg = d3.select(ref.current);
      const lineGenerator = d3.line().curve(d3.curveCardinal);
      let path = svg.select("path");
      if (path.empty()) {
        path = svg.append("path");
      }

      path
        .datum(points)
        .attr("d", lineGenerator)
        .attr("stroke", hovered ? theme.palette.action.active : "rgba(0,0,0,0)")
        .attr("stroke-width", 5)
        .attr("stroke-dasharray", "100, 2")
        .attr(
          "fill",
          hovered ? "rgba(99,132,247, 0.2)" : "rgba(128,128,128, 0.2)"
        );

      if (hovered) {
        const totalLength = path.node().getTotalLength();
        path
          .attr("stroke-dasharray", totalLength + " " + totalLength)
          .attr("stroke-dashoffset", totalLength)
          .transition()
          .duration(1000)
          .ease(d3.easeLinear)
          .attr("stroke-dashoffset", 0);
      } else {
        path.interrupt();
      }
    }
  }, [hovered, points]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        bottom: 0,
        left: 0,
        zIndex: 0,
      }}
    >
      <svg ref={ref} width="100%" height="100%"></svg>
    </div>
  );
}

export default function DemoCard({ title, children }) {
  const [isHovered, setHovered] = useState(false);
  const theme = useTheme();
  return (
    <motion.div whileHover={{ scale: 1.02 }} style={{ cursor: "pointer" }}>
      <RootStyle
        onMouseLeave={() => {
          setHovered(false);
        }}
        onMouseEnter={() => {
          setHovered(true);
        }}
      >
        <StudyTypographyMegaTitle sx={{ marginBottom: "12px", zIndex: 1 }}>
          {title}
        </StudyTypographyMegaTitle>
        <div style={{ width: "100%", height: "100%", zIndex: 1 }}>
          {children}
        </div>
        <PowerGridLines />
        {/* <RandomLine hovered={isHovered} /> */}
      </RootStyle>
    </motion.div>
  );
}
