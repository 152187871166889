import { styled } from "@mui/material";
import { StudyTypographyMediumSubtitle } from "./glovebox/StudyTypography";

export const HoverTypography = styled("div")(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    paddingLeft: "8px",
    cursor: "pointer",
  },
}));
