import React from "react";
import { StudyTypographyMediumTitle } from "../../glovebox/StudyTypography";

function NotesMain() {
  return (
    <div>
      <StudyTypographyMediumTitle>Notes Page</StudyTypographyMediumTitle>
    </div>
  );
}

export default NotesMain;
