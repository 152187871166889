import FullCalendar from "@fullcalendar/react";
import { StudyTypographyMegaSubtitle } from "../../glovebox/StudyTypography";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";
import { ScheduleToDoBar } from "../schedule/ScheduleToDoBar";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import "./UserCalendar.css";
export const UserCalendar = ({ user }: { user: any }) => {
  const [todos, setTodos] = useState(user.todos || []);
  const [events, setEvents] = useState<any>();
  const theme = useTheme();
  const [me, setMe] = useState(JSON.parse(localStorage.getItem("user")!) || []);

  useEffect(() => {
    const newSchedule = user?.todos?.filter(
      (todo: any) => moment(todo.startTime).date() === moment().date()
    );
    const restructureSchedule = newSchedule
      ?.filter((item: any) => item.deletedAt === null)
      .map((schedule: any) => ({
        title: schedule?.title,
        id: schedule?.uuid,
        start: moment(schedule?.startTime).toISOString(),
        end: moment(schedule?.startTime)
          .add("minutes", schedule?.duration)
          .toISOString(),
      }));
    setEvents(restructureSchedule);
  }, [user]);
  function renderEventContent(eventInfo: any) {
    var task = user?.todos?.find(
      (todo: any) => todo?.uuid === eventInfo.event.id
    );
    return (
      <div
        style={{
          alignItems: "center",
          width: "100%",
          height: "100%",
          flex: 1,
          display: "inline-grid",
          border: "0px solid black",
          overflow: "scroll",
        }}
      >
        <ScheduleToDoBar
          data={
            !task
              ? {
                  title: eventInfo.event.title,
                  duration: 30,
                  completedAt: null,
                  dueAt: null,
                  startTime: eventInfo.timeText,
                  creating: true,
                }
              : user?.id !== me?.id
              ? { ...task, title: "busy" }
              : task
          }
          key={1}
          index={1}
          toolbar={false}
          handleDeleteToDo={() => {}}
          schedule={true}
          todos={user?.todos}
          setTodos={setTodos}
          tapped={false}
          typeCal={"timeGridDay"}
          slotTime={"00:30:00"}
          duration={false}
          cowork={true}
        />
      </div>
    );
  }

  return (
    <div
      key={user?.uuid}
      className="user-calendar"
      style={{
        width: "100%",
        marginRight: "16px",
        height: "100%",
      }}
    >
      <StudyTypographyMegaSubtitle
        style={{ textAlign: "center", marginBottom: "16px" }}
      >
        {`${user?.firstName} ${user?.lastName}`}
      </StudyTypographyMegaSubtitle>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={false}
        initialView="timeGridDay"
        scrollTime={"08:00:00"}
        dayHeaders={false}
        editable={false}
        allDaySlot={false}
        nowIndicator={true}
        selectable={false}
        droppable={false}
        eventStartEditable={false}
        eventDragStart={(bFlag) => {
          // this would not allow event to be dragged
          if (bFlag) {
            return false;
          }
        }}
        selectMirror={true}
        eventContent={renderEventContent} // custom render function
        dayMaxEvents={true}
        eventMinHeight={15}
        eventShortHeight={15}
        eventBorderColor={theme.palette.primary.light}
        eventBackgroundColor={theme.palette.primary.light}
        weekends={true}
        events={events}
        height={"1800px"}
        contentHeight={"1800px"}
        viewHeight={"1800px"}
      />
      {/* <CoworkSchedule groupUser={item} /> */}
    </div>
  );
};
