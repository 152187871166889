// @ts-nocheck
import { CalendarTodayOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  ClickAwayListener,
  Stack,
  Tooltip,
  useTheme,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { motion } from "framer-motion";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-hot-toast";
import { useHotkeys } from "react-hotkeys-hook";
import useSound from "use-sound";
import { v4 as uuid } from "uuid";
import {
  CREATE_BULK_REOCCURING_TODOS,
  DeleteTodo,
  createTodo,
} from "../../graphql/mutations/todo";
import { ADD_USER_CATEGORY } from "../../graphql/mutations/user";
import { client } from "../../utils/apollo";
import { DAYS_OF_WEEK } from "../../utils/constants";
import { HotkeyContext } from "../../utils/contexts";
import { HOTKEYS } from "../../utils/hotkeyHelper";
import { AddIcon } from "../Icons/AddIcon";
import { CheckIcon } from "../Icons/CheckIcon";
import { HoverPointerStyle } from "../Icons/HoverPointerStyle";
import { AddToolbarCategory } from "../glovebox/AddToolbarCategory";
import BoxedText from "../glovebox/BoxedText";
import {
  StudyTypographyMediumSubtitle,
  StudyTypographySmallText,
} from "../glovebox/StudyTypography";
import {
  StyledBox,
  StyledCloseButton,
  StyledDialog,
  StyledHeader,
} from "../mainbar/calendar/styles";
import { TodoLineAndBoxContainer } from "../mainbar/todos/styles";
import { XIcon } from "../sidebar/Icons";
import { ToolbarToDoContainer } from "./ToolbarToDoContainer";
import {
  CustomInput,
  ProfileBlockPopper,
  SwitchCategoryContainer,
  ToolbarContainer,
  ToolbarTasksBox,
  ToolbarToDoCreateInput,
} from "./styles";
import { StudyDivider } from "../Divider";
import FilterTodos from "../glovebox/FilterTodos";
import { LongPressButton } from "../glovebox/LongPressButton";
var Sherlock = require("sherlockjs");
// var timezone = require("dayjs/plugin/timezone");
// dayjs.extend(timezone);

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { x: 100, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
};

export const Toolbar = ({
  todos,
  setTodos,
  categories,
  setCategories,
}: // updateTodoList,
{
  todos: any;
  setTodos: any;
  categories: any;
  setCategories: any;
  // updateTodoList: any;
}) => {
  dayjs.extend(timezone);
  // dayjs.extend(window.dayjs_plugin_utc);
  // dayjs.extend(window.dayjs_plugin_timezone);
  dayjs.tz.guess();
  // console.log(todos);
  const [due, setDue] = useState<any>();
  const [title, setTitle] = useState("");
  const [typeInput, setTypeInput] = useState("todo");
  const [selectedDate, setSelectedDate] = useState<any>();
  const [sherlockTitle, setSherlockTitle] = useState("");
  const [daysReoccuringSelected, setDaysReoccuringSelected] = useState<any>([]);
  const [duration, setDuration] = useState("30");
  const [isPressed, setIsPressed] = useState<any>(false);
  const [playSwitchPageSound] = useSound("/p-static/sounds/rising-pops.mp3");
  const [endDate, setEndDate] = useState(new Date());
  const [reoccurringModal, setReoccurringModal] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [startText, setStartText] = useState("");
  const [endText, setEndText] = useState("");
  const [frequency, setFrequency] = useState();
  const [useTime, setUseTime] = useState(false);
  const [end, setEnd] = useState<Dayjs | null>(dayjs(new Date()));
  const [start, setStart] = useState<Dayjs | null>(dayjs(new Date()));
  // const [categories, setCategories] = useState<any>(
  //   JSON.parse(localStorage.getItem("categories")!) || []
  // );
  const [event, setEvent] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState<any>([]);

  const [hideOldTodos, setHideOldTodos] = useState<any>();

  const [categoryAnchorEl, setCategoryAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const handleCategoryClick = (event: React.MouseEvent<HTMLElement>) => {
    setCategoryAnchorEl(categoryAnchorEl ? null : event.currentTarget);
  };
  const categoryOpen = Boolean(categoryAnchorEl);

  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(filterAnchorEl ? null : event.currentTarget);
  };
  const filterOpen = Boolean(filterAnchorEl);
  const [openOnMobile, setOpenOnMobile] = useState(false);

  const reoccuringTodos = () => {
    let parentId = uuid();
    let newTodos = [];
    let localTodos = [];
    let startDate = start!.toDate();
    startDate.setSeconds(0);
    let endDate;
    if (useTime) {
      let endDate = end!.toDate();
      endDate.setSeconds(0);
    }
    let currentDate = new Date(startDate);
    // console.log(useTime, currentDate, endDate);
    // console.log(useTime && currentDate.getTime() <= endDate.getTime());
    while (
      (!useTime && newTodos.length < parseInt(frequency)) ||
      (useTime && currentDate.getTime() <= endDate.getTime())
    ) {
      if (
        daysReoccuringSelected.includes(DAYS_OF_WEEK[currentDate.getDay() % 7])
      ) {
        let childUUID = uuid();
        newTodos.push({
          uuid: childUUID,
          duration: parseInt(duration),
          startTime: currentDate.toISOString(),
          event: typeInput === "todo" ? false : true,
          categoryId: pickedCategory?.uuid,
        });
        localTodos.push({
          id: null,
          startTime: currentDate.toISOString(),
          duration: parseInt(duration),
          todoId: parentId,
          completedAt: null,
          uuid: childUUID,
          event: typeInput === "todo" ? false : true,
          categoryId: pickedCategory?.uuid,
          __typeName: "TodoReplica",
        });
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    let newTodo = {
      categoryId: pickedCategory?.uuid,
      completedAt: null,
      createdAt: null,
      dueAt: null,
      todoReplicas: localTodos,
      duration: null,
      id: null,
      startTime: null,
      reoccurring: true,
      title: selectedTitle,
      uuid: parentId,
      event: typeInput === "todo" ? false : true,
      __typename: "Todo",
    };
    setTodos([newTodo, ...todos]);
    toast.promise(
      client
        .mutate({
          mutation: CREATE_BULK_REOCCURING_TODOS,
          variables: {
            title: selectedTitle,
            userId: user?.id,
            uuid: parentId,
            categoryId: pickedCategory?.uuid,
            event: typeInput === "todo" ? false : true,
            todos: newTodos,
          },
        })
        .then(() => {
          setStart(dayjs(new Date()));
          setStartText("");
          setEnd(dayjs(new Date()));
          setEndText("");
          setDaysReoccuringSelected([]);
          setDuration("30");
          setFrequency("0");
          setTitle("");
        }),
      {
        loading: "adding todo..",
        success: <b>todos saved!</b>,
        error: <b>Could not save.</b>,
      },
      {
        duration: 4000,
        style: {
          fontSize: "12px",
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.primary,
        },
      }
    );
  };

  const handleCreateReoccurring = () => {
    console.log("hello");
  };

  const handleAddCategory = (categoryName: any, categoryLabel: any) => {
    let newCategoryUUID = uuid();
    let updatedCategories = [
      ...categories,
      {
        id: null,
        name: categoryName,
        uuid: newCategoryUUID,
        label: categoryLabel,
        __typename: "Category",
      },
    ];
    setCategories(updatedCategories);
    client.mutate({
      mutation: ADD_USER_CATEGORY,
      variables: {
        userId: user.id,
        category: categoryName,
        uuid: newCategoryUUID,
        categoryLabel: categoryLabel,
      },
    });
  };

  useEffect(() => {
    localStorage.setItem("categories", JSON.stringify(categories));
  }, [categories]);

  useEffect(() => {
    if (openOnMobile) setOpenOnMobile(false);
    playSwitchPageSound();
  }, [todos]);

  // const [todos, setTodos] = useState<any>([]);
  // const [todos, setTodos] = useState(
  //   JSON.parse(localStorage.getItem("todos")!) || []
  // );
  // console.log(todos);
  // const { id: userId } = useUserContext();
  const userId = localStorage.getItem("userId");
  const user = JSON.parse(localStorage.getItem("user")!);
  const [pickedCategory, setPickedCategory] = useState(
    categories[
      categories.findIndex(
        (category: any) => category?.uuid === user?.baseCategoryId
      )
    ]
  );

  const [showPinned, setShowPinned] = useState(false);
  // const [showTodos, setShowTodos] = useState(true);

  const inputRef = useRef<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  // console.log(todos);
  // useEffect(() => {
  //   localStorage.setItem("todos", JSON.stringify(todos));
  // }, [todos]);

  const theme = useTheme();
  // const getDuration = (startTime: Date, endTime: Date) => {
  //   const timeDiffHours = endTime.getHours() - startTime.getHours();
  //   const timeDiffMinutes = endTime.getMinutes() - startTime.getMinutes();

  //   if (timeDiffHours > 0) {
  //     return timeDiffHours * 60 + timeDiffMinutes;
  //   } else return timeDiffMinutes;
  // };

  const arraymove = (arr: any, fromIndex: any, toIndex: any) => {
    let newarr = [...arr];
    var element = arr[fromIndex];
    newarr.splice(fromIndex, 1);
    newarr.splice(toIndex, 0, element);
    return newarr;
  };

  const changeCategoryOrder = () => {
    const defaultIndex = categories.findIndex(
      (category: any) => category?.uuid === user?.baseCategoryId
    );
    const newArray = arraymove(categories, defaultIndex, 0);
    return newArray;
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(todos, result.source.index, result.destination.index);
    setTodos(items);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const { showBadge } = useContext(HotkeyContext);

  const handleDeleteToDo = async (todo: any) => {
    await DeleteTodo(todo, todos, setTodos);
  };

  const createTodoHelper = () => {
    let todoEvent = event;
    setEvent(false);
    toast.promise(
      createTodo(
        pickedCategory?.uuid,
        title,
        30,
        due,
        null,
        todoEvent,
        userId,
        todos,
        setTodos
      ),
      {
        loading: "adding todo..",
        success: <b>todo saved!</b>,
        error: <b>Could not save.</b>,
      },
      {
        duration: 4000,
        style: {
          fontSize: "12px",
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.primary,
        },
      }
    );
    setTitle("");
    setDue(null);
  };

  useHotkeys(HOTKEYS.ENTER_TODO, (event: any) => {
    setIsPressed(true);
    inputRef.current.focus();
  });

  return (
    <ToolbarContainer>
      <ClickAwayListener
        onClickAway={() => {
          setIsPressed(false);
        }}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault();
            if (title !== "") {
              console.log("YO YO YO YO YO");
              createTodoHelper();
            }
          }}
        >
          <ToolbarToDoCreateInput style={{ marginBottom: "10px" }}>
            {true ? (
              <motion.div>
                <Tooltip title={pickedCategory?.name} placement={"top"}>
                  <ClickAwayListener
                    onClickAway={() => {
                      setCategoryAnchorEl(null);
                    }}
                  >
                    {pickedCategory?.label === "Google Calendar" ? (
                      <div onClick={handleCategoryClick}>
                        <CalendarTodayOutlined
                          style={{
                            padding: 0,
                            margin: 0,
                            width: "18px",
                            height: "18px",
                            marginRight: "12px",
                          }}
                        />
                      </div>
                    ) : (
                      <Box
                        sx={{
                          // whiteSpace: "nowrap",
                          width: "14px",
                          height: "14px",
                          border: `2px solid ${pickedCategory?.label}`,
                          borderRadius: "4px",
                          marginRight: "12px",
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                        onClick={handleCategoryClick}
                      />
                    )}
                  </ClickAwayListener>
                </Tooltip>

                <ProfileBlockPopper
                  open={categoryOpen}
                  anchorEl={categoryAnchorEl}
                  placement={"bottom-start"}
                  modifiers={[{ name: "arrow", enabled: true }]}
                  style={{
                    backgroundColor: "rgba(0,0,0,0)",
                    margin: 0,
                    paddingRight: 0,
                    paddingTop: "8px",
                    zIndex: 100,
                  }}
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                >
                  <motion.div
                    style={{
                      alignItems: "center",
                      display: "inline-grid",
                      justifyContent: "center",
                      backgroundColor: theme.palette.primary.main,
                      border: `1px solid ${theme.palette.secondary.light}`,
                      padding: "4px",
                      borderRadius: "4px",
                    }}
                    variants={container}
                  >
                    {categories.map((category: any) => (
                      <Stack
                        flexDirection={"row"}
                        alignItems="center"
                        sx={{
                          padding: "8px 12px 8px 12px",
                          "&:hover": {
                            backgroundColor: theme.palette.primary.light,
                            borderRadius: "4px",
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => {
                          setCategoryAnchorEl(null);
                          setPickedCategory(category);
                        }}
                      >
                        {category?.label === "Google Calendar" ? (
                          <div
                            onClick={handleCategoryClick}
                            style={{
                              alignSelf: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <CalendarTodayOutlined
                              style={{
                                padding: 0,
                                margin: 0,
                                width: "18px",
                                height: "18px",
                                marginRight: "12px",
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "14px",
                              height: "14px",
                              border: `2px solid ${category?.label}`,
                              borderRadius: "4px",
                              marginRight: "12px",
                            }}
                          ></div>
                        )}
                        <motion.div variants={item} key={category?.uuid}>
                          <StudyTypographyMediumSubtitle
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {category?.name}
                          </StudyTypographyMediumSubtitle>
                        </motion.div>
                      </Stack>
                    ))}
                  </motion.div>
                </ProfileBlockPopper>
              </motion.div>
            ) : null}

            <input
              ref={inputRef}
              onFocus={() => {
                setIsPressed(true);
              }}
              aria-label="Demo input"
              placeholder={
                isMobile ? "Add a todo... " : "Add a todo... (shift+enter)"
              }
              onChange={(text) => {
                setTitle(text.currentTarget.value);
                var sherlocked = Sherlock.parse(text.currentTarget.value);
                setDue(sherlocked.startDate);
                setSherlockTitle(sherlocked?.eventTitle);
              }}
              style={{
                backgroundColor: theme.palette.primary.light,
                height: "32px",
                border: "0px",
                width: "100%",
                color: theme.palette.text.primary,
                outlineWidth: 0,
                outline: "none",
              }}
              value={title}
            />

            {isPressed || isMobile ? (
              <motion.div
                animate={{ x: [100, 0] }}
                transition={{
                  type: "spring",
                  stiffness: 300,
                  mass: 0.3,
                }}
                style={{ display: "flex" }}
              >
                <AddIcon
                  clicked={() => {
                    createTodoHelper();
                  }}
                />
              </motion.div>
            ) : null}
          </ToolbarToDoCreateInput>
        </form>
      </ClickAwayListener>
      {/* <LongPressButton todos={todos} setTodos={setTodos} /> */}

      <ProfileBlockPopper
        open={filterOpen}
        anchorEl={filterAnchorEl}
        placement={"bottom-start"}
        modifiers={[{ name: "arrow", enabled: true }]}
        style={{
          backgroundColor: "rgba(0,0,0,0)",
          margin: 0,
          paddingRight: 0,
          paddingTop: "8px",
          zIndex: 100,
        }}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        <div
          style={{
            alignItems: "center",
            display: "inline-grid",
            justifyContent: "center",
            backgroundColor: theme.palette.primary.main,
            border: `1px solid ${theme.palette.secondary.light}`,
            padding: "4px",
            borderRadius: "4px",
          }}
        >
          {categories.map((category: any) => {
            return (
              <SwitchCategoryContainer
                onClick={() => {
                  const filteredIndex = filteredCategories?.findIndex(
                    (filteredCategory: any) =>
                      //@ts-ignore
                      category.uuid === filteredCategory?.uuid
                  );
                  if (filteredIndex !== -1) {
                    const holdCategory = filteredCategories.filter(
                      (filteredCategory: any) =>
                        filteredCategory.uuid !== category.uuid
                    );
                    setFilteredCategories(holdCategory);
                  } else {
                    const holdCategory = filteredCategories.concat(category);

                    setFilteredCategories(holdCategory);
                  }
                }}
                style={{
                  backgroundColor:
                    filteredCategories &&
                    filteredCategories?.findIndex(
                      (filteredCategory: any) =>
                        //@ts-ignore
                        category.uuid === filteredCategory?.uuid
                    ) !== -1
                      ? theme.palette.primary.light
                      : theme.palette.primary.main,
                  borderRadius: "4px",
                  marginBottom: "4px",
                }}
              >
                {category.label === "Google Calendar" ? (
                  <div onClick={handleCategoryClick}>
                    <CalendarTodayOutlined
                      style={{
                        padding: 0,
                        margin: 0,
                        width: "18px",
                        height: "18px",
                        marginRight: "12px",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      width: "14px",
                      height: "14px",
                      border: `2px solid ${category?.label}`,
                      borderRadius: "4px",
                      marginRight: "12px",
                    }}
                  ></div>
                )}
                <StudyTypographyMediumSubtitle
                  style={{
                    textAlign: "left",
                  }}
                  onClick={() => {
                    // updateTodo({
                    //   variables: { duration: time, uuid: data?.uuid },
                    // });
                    // updateTodoDuration(data?.uuid, time);
                  }}
                >
                  {category?.name}
                </StudyTypographyMediumSubtitle>
              </SwitchCategoryContainer>
            );
          })}
        </div>
      </ProfileBlockPopper>
      {/* </ClickAwayListener> */}

      <ToolbarTasksBox style={{ marginTop: "12px" }}>
        {due ? (
          <BoxedText text={`DUE: ${moment(due).format("M/D hh:mma")}`} />
        ) : null}

        {changeCategoryOrder()
          ?.filter((mainCategory: any) =>
            filteredCategories.length === 0
              ? true
              : filteredCategories.some(
                  (filteredCategory: any) => filteredCategory === mainCategory
                )
          )
          .map((category: any, index: number) => {
            return (
              // console.log(categories)
              // console.log(Object.getOwnPropertyNames(category))
              <TodoLineAndBoxContainer id={`external-events-${index}`}>
                <ToolbarToDoContainer
                  handleDeleteToDo={handleDeleteToDo}
                  category={category}
                  categoryIndex={index}
                  categories={categories}
                  todos={todos}
                  setTodos={setTodos}
                  setCategories={setCategories}
                  showInput={false}
                  hideOldTodos={hideOldTodos}
                />
              </TodoLineAndBoxContainer>
            );
          })}
      </ToolbarTasksBox>
      <AddToolbarCategory handleAddCategory={handleAddCategory} />
      {/* {isMobile ? <div style={{ height: "100px", width: "100%" }}></div> : null} */}
      <StyledDialog
        open={reoccurringModal}
        onClose={() => {
          setReoccurringModal(false);
        }}
        aria-labelledby="archive-task-modal"
        aria-describedby="modal-modal-description"
        style={{
          zIndex: 1,
          backgroundColor: "transparent",
          borderRadius: "12px",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <StyledBox
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <Stack
            alignItems={"row"}
            flexDirection="row"
            justifyContent="space-between"
            style={{
              paddingBottom: "12px",
              borderBottom: `0.5px dashed ${theme.palette.primary.dark}`,
            }}
          >
            <StyledHeader
              style={{
                marginLeft: 0,
              }}
            >
              Create Reoccurring Todo
            </StyledHeader>

            <StyledCloseButton
              onClick={() => {
                setReoccurringModal(false);
                setStart(null);
                setStartText("");
                setEnd(null);
                setEndText("");
                setDaysReoccuringSelected([]);
                setDuration("30");
                setFrequency("0");
                setTitle("");
                // setOpenEditTodo(false);
              }}
            >
              <XIcon />
            </StyledCloseButton>
          </Stack>
          {/* Need to add category */}
          <Stack
            paddingTop={"12px"}
            flexDirection={"row"}
            alignItems={"center"}
            borderRadius={"4px"}
            marginBottom={"12px"}
            width={"100%"}
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            <StudyTypographySmallText
              marginRight={"12px"}
              width={"100px"}
              flexShrink={0}
            >{`TITLE: `}</StudyTypographySmallText>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              padding="6px"
              borderRadius={"4px"}
              width={"100%"}
              style={{ backgroundColor: theme.palette.primary.light }}
            >
              <CustomInput
                ref={inputRef}
                aria-label="Demo input"
                placeholder={`Title`}
                onChange={(text) => {
                  setSelectedTitle(text.currentTarget.value);
                }}
                autoFocus
                style={{
                  fontSize: "10px",
                  padding: "0px",
                  height: "32px",
                  flex: 1,
                }}
                value={selectedTitle}
              />
            </Stack>
          </Stack>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            borderRadius={"4px"}
            marginBottom={"12px"}
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            <StudyTypographySmallText
              marginRight={"12px"}
              width={"100px"}
              flexShrink={0}
            >{`CATEGORY: `}</StudyTypographySmallText>

            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              padding="8px"
              borderRadius={"4px"}
              sx={{
                backgroundColor: theme.palette.primary.light,
                "&:hover": { cursor: "pointer" },
              }}
              onClick={handleCategoryClick}
            >
              <Box
                sx={{
                  // whiteSpace: "nowrap",
                  width: "14px",
                  height: "14px",
                  border: `2px solid ${pickedCategory?.label}`,
                  borderRadius: "4px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={handleCategoryClick}
              />
              <StudyTypographySmallText
                style={{
                  marginLeft: "12px",
                }}
              >
                {pickedCategory?.name}
              </StudyTypographySmallText>
            </Stack>
          </Stack>

          <ProfileBlockPopper
            open={categoryOpen}
            anchorEl={categoryAnchorEl}
            placement={"bottom-start"}
            modifiers={[{ name: "arrow", enabled: true }]}
            style={{
              backgroundColor: "rgba(0,0,0,0)",
              margin: 0,
              paddingRight: 0,
              paddingTop: "8px",
              zIndex: 100,
            }}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          >
            <motion.div
              style={{
                alignItems: "center",
                display: "inline-grid",
                justifyContent: "center",
                backgroundColor: theme.palette.primary.main,
                border: `1px solid ${theme.palette.secondary.light}`,
                padding: "4px",
                borderRadius: "4px",
              }}
              variants={container}
            >
              {categories.map((category: any) => (
                <Stack
                  flexDirection={"row"}
                  alignItems="center"
                  sx={{
                    padding: "8px 12px 8px 12px",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.light,
                      borderRadius: "4px",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setCategoryAnchorEl(null);
                    setPickedCategory(category);
                  }}
                >
                  {category?.label === "Google Calendar" ? (
                    <div
                      onClick={handleCategoryClick}
                      style={{
                        alignSelf: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <CalendarTodayOutlined
                        style={{
                          padding: 0,
                          margin: 0,
                          width: "18px",
                          height: "18px",
                          marginRight: "12px",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "14px",
                        height: "14px",
                        border: `2px solid ${category?.label}`,
                        borderRadius: "4px",
                        marginRight: "12px",
                      }}
                    ></div>
                  )}
                  <motion.div variants={item} key={category?.uuid}>
                    <StudyTypographyMediumSubtitle
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {category?.name}
                    </StudyTypographyMediumSubtitle>
                  </motion.div>
                </Stack>
              ))}
            </motion.div>
          </ProfileBlockPopper>

          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            borderRadius={"4px"}
            marginBottom={"12px"}
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            <StudyTypographySmallText
              marginRight={"12px"}
              width={"100px"}
              flexShrink={0}
            >{`TYPE: `}</StudyTypographySmallText>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              padding="8px"
              borderRadius={"4px"}
              style={{ backgroundColor: theme.palette.primary.light }}
            >
              <motion.div
                whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
                whileHover={{ rotate: 10 }}
                transition={{
                  type: "spring",
                  stiffness: 200,
                  damping: 8,
                }}
                style={{
                  // marginLeft: "12px",
                  alignSelf: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <HoverPointerStyle
                  width={"18px"}
                  height={"18px"}
                  viewBox={`0 0 24 24`}
                  fill={
                    typeInput === "todo"
                      ? theme.palette.success.main
                      : theme.palette.secondary.light
                  }
                  onClick={() => {
                    setTypeInput("todo");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" rx="4" />
                  <path
                    d="M9.32199 15.2378L6.42256 12.3384C6.09669 12.0125 5.57028 12.0125 5.2444 12.3384C4.91853 12.6643 4.91853 13.1907 5.2444 13.5166L8.73709 17.0092C9.06297 17.3351 9.58938 17.3351 9.91525 17.0092L18.7556 8.1689C19.0815 7.84303 19.0815 7.31662 18.7556 6.99074C18.4297 6.66487 17.9033 6.66487 17.5774 6.99074L9.32199 15.2378Z"
                    fill={theme.palette.secondary.light}
                  />
                </HoverPointerStyle>
              </motion.div>
              <StudyTypographySmallText
                style={{
                  marginLeft: "12px",
                }}
              >
                create todo
              </StudyTypographySmallText>
            </Stack>

            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              padding="8px"
              marginLeft={"12px"}
              borderRadius={"4px"}
              sx={{
                backgroundColor: theme.palette.primary.light,
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <motion.div
                whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
                whileHover={{ rotate: 10 }}
                transition={{
                  type: "spring",
                  stiffness: 200,
                  damping: 8,
                }}
                style={{
                  // marginLeft: "12px",
                  alignSelf: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <HoverPointerStyle
                  width={"18px"}
                  height={"18px"}
                  viewBox={`0 0 24 24`}
                  fill={
                    typeInput === "event"
                      ? theme.palette.success.main
                      : theme.palette.secondary.light
                  }
                  onClick={() => {
                    setTypeInput("event");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" rx="4" />
                  <path
                    d="M9.32199 15.2378L6.42256 12.3384C6.09669 12.0125 5.57028 12.0125 5.2444 12.3384C4.91853 12.6643 4.91853 13.1907 5.2444 13.5166L8.73709 17.0092C9.06297 17.3351 9.58938 17.3351 9.91525 17.0092L18.7556 8.1689C19.0815 7.84303 19.0815 7.31662 18.7556 6.99074C18.4297 6.66487 17.9033 6.66487 17.5774 6.99074L9.32199 15.2378Z"
                    fill={theme.palette.secondary.light}
                  />
                </HoverPointerStyle>
              </motion.div>

              <StudyTypographySmallText
                style={{
                  marginLeft: "12px",
                }}
              >
                create event
              </StudyTypographySmallText>
            </Stack>
          </Stack>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            borderRadius={"4px"}
            marginBottom={"12px"}
            width={"100%"}
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            <StudyTypographySmallText
              marginRight={"12px"}
              width={"100px"}
              flexShrink={0}
            >{`DAYS REPEATED: `}</StudyTypographySmallText>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              // padding="6px"
              borderRadius={"4px"}
              justifyContent={"space-between"}
              width={"100%"}
              // style={{ backgroundColor: theme.palette.primary.light }}
            >
              {DAYS_OF_WEEK.map((day) => (
                <StudyTypographyMediumSubtitle
                  sx={{
                    padding: "4px",
                    borderRadius: "4px",
                    border: `1px solid ${theme.palette.primary.light}`,
                    width: "30px",
                    textAlign: "center",
                    backgroundColor: daysReoccuringSelected.includes(day)
                      ? theme.palette.primary.dark
                      : theme.palette.primary.main,

                    "&:hover": {
                      backgroundColor: theme.palette.secondary.light,
                      cursor: "pointer",
                    },
                    // marginRight: "8px",
                  }}
                  onClick={() => {
                    var holdDays = daysReoccuringSelected;
                    if (daysReoccuringSelected.includes(day)) {
                      holdDays = daysReoccuringSelected.filter(
                        (holdDay: any) => day !== holdDay
                      );
                    } else {
                      holdDays = [...holdDays, day];
                    }
                    setDaysReoccuringSelected(holdDays);
                  }}
                >
                  {day}
                </StudyTypographyMediumSubtitle>
              ))}
            </Stack>
          </Stack>

          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            borderRadius={"4px"}
            // marginBottom={"12px"}
            width={"100%"}
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            <StudyTypographySmallText
              marginRight={"12px"}
              width={"100px"}
              flexShrink={0}
            >{`DURATION: `}</StudyTypographySmallText>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              padding="6px"
              borderRadius={"4px"}
              width={"100%"}
              style={{ backgroundColor: theme.palette.primary.light }}
            >
              <CustomInput
                ref={inputRef}
                aria-label="Demo input"
                placeholder={"0"}
                onChange={(text) => {
                  setDuration(text.currentTarget.value);
                }}
                autoFocus
                style={{
                  fontSize: "10px",
                  padding: "0px",
                  height: "32px",
                  flex: 1,
                }}
                value={duration}
              />
            </Stack>
          </Stack>

          <Stack
            paddingTop={"16px"}
            flexDirection={"row"}
            alignItems={"center"}
            borderRadius={"4px"}
            marginBottom={"12px"}
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            <StudyTypographySmallText
              marginRight={"12px"}
              width={"100px"}
              flexShrink={0}
            >{`START: `}</StudyTypographySmallText>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              padding="6px"
              borderRadius={"4px"}
              width={"100%"}
              style={{ backgroundColor: theme.palette.primary.light }}
            >
              <CustomInput
                ref={inputRef}
                aria-label="Demo input"
                placeholder={`ex. tomorrow 3PM`}
                onChange={(text) => {
                  setStartText(text.currentTarget.value);
                  var sherlocked = Sherlock.parse(text.currentTarget.value);
                  setStart(dayjs(sherlocked.startDate));
                  // if (end )
                }}
                autoFocus
                style={{
                  fontSize: "10px",
                  padding: "0px",
                  height: "32px",
                }}
                value={startText}
              />
            </Stack>

            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              padding="8px"
              marginLeft={"12px"}
              borderRadius={"4px"}
              sx={{
                backgroundColor: theme.palette.primary.light,
                flexShrink: 0,
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <StudyTypographySmallText>
                {`${
                  moment(start?.toDate()).isValid()
                    ? moment(start?.toDate()).format("M/D hh:mma")
                    : "none"
                }`}
              </StudyTypographySmallText>
            </Stack>
            {/* <Stack
              flexDirection={"row"}
              alignItems={"center"}
              padding="8px"
              marginLeft={"12px"}
              borderRadius={"4px"}
              sx={{
                backgroundColor: theme.palette.primary.light,
                flexShrink: 0,
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <AccessTimeRounded sx={{ mr: "0.5rem" }} />
                <CustomDateTimePicker
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      label="Time and Date"
                      style={{
                        border: "0px solid rgba(0,0,0,0)",
                        color: theme.palette.text.primary,
                      }}
                    />
                  )}
                  value={selectedDate}
                  onChange={(newValue) => {
                    setSelectedDate(newValue);
                  }}
                />
                <EventRounded sx={{ ml: "0.5rem" }} />
              </Box>
            </LocalizationProvider>
          </Stack> */}
          </Stack>
          <div>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              borderRadius={"4px"}
              marginBottom={"12px"}
              style={{ backgroundColor: theme.palette.primary.main }}
            >
              <StudyTypographySmallText
                marginRight={"12px"}
                width={"100px"}
                flexShrink={0}
              >{`END: `}</StudyTypographySmallText>
              <motion.div
                whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
                whileHover={{ rotate: 10 }}
                transition={{
                  type: "spring",
                  stiffness: 200,
                  damping: 8,
                }}
                style={{
                  marginRight: "12px",
                  alignSelf: "center",
                  display: "flex",
                }}
              >
                <CheckIcon
                  height="18px"
                  width="18px"
                  active={useTime}
                  clicked={() => {
                    setUseTime(!useTime);
                    // createTodoHelper();
                  }}
                />
              </motion.div>
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                padding="6px"
                borderRadius={"4px"}
                width={"100%"}
                style={{
                  backgroundColor: theme.palette.primary.light,
                  color: !useTime
                    ? theme.palette.primary.main
                    : theme.palette.primary.light,
                }}
              >
                <input
                  ref={inputRef}
                  // disabled={!useTime}
                  aria-label="Demo input"
                  placeholder={`ex. next friday 3PM`}
                  onChange={(text) => {
                    setEndText(text.currentTarget.value);
                    var sherlocked = Sherlock.parse(text.currentTarget.value);
                    // console.log(sherlocked);
                    setEnd(dayjs(sherlocked.startDate));
                  }}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    setUseTime(true);
                  }}
                  autoFocus
                  style={{
                    // padding: "0px",
                    flexShrink: "0",
                    backgroundColor: theme.palette.primary.light,
                    fontFamily: "Space Grotesk",
                    fontWeight: "500",
                    fontSize: "14px",
                    height: "20px",
                    width: "100%",
                    flex: 1,
                    border: 0,
                    outlineWidth: 0,
                    boxSizing: "border-box",
                    outline: "none",
                    borderRadius: "4px",
                    color: useTime
                      ? theme.palette.text.primary
                      : theme.palette.primary.light,
                  }}
                  value={endText}
                />
              </Stack>
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                padding="8px"
                marginLeft={"12px"}
                borderRadius={"4px"}
                sx={{
                  backgroundColor: theme.palette.primary.light,
                  flexShrink: 0,
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <StudyTypographySmallText>
                  {`${
                    moment(end?.toDate()).isValid()
                      ? moment(end?.toDate()).format("M/D hh:mma")
                      : "none"
                  }`}
                </StudyTypographySmallText>
              </Stack>
            </Stack>
          </div>
          <div>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              borderRadius={"4px"}
              marginBottom={"12px"}
              style={{ backgroundColor: theme.palette.primary.main }}
            >
              <StudyTypographySmallText
                marginRight={"12px"}
                width={"100px"}
                flexShrink={0}
              >{`OCCURRENCES: `}</StudyTypographySmallText>
              <motion.div
                whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
                whileHover={{ rotate: 10 }}
                transition={{
                  type: "spring",
                  stiffness: 200,
                  damping: 8,
                }}
                style={{
                  marginRight: "12px",
                  alignSelf: "center",
                  display: "flex",
                }}
              >
                <CheckIcon
                  height="18px"
                  width="18px"
                  active={!useTime}
                  clicked={() => {
                    setUseTime(!useTime);
                  }}
                />
              </motion.div>
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                padding="6px"
                borderRadius={"4px"}
                width={"100%"}
                style={{
                  backgroundColor: theme.palette.primary.light,
                  color: useTime
                    ? theme.palette.primary.main
                    : theme.palette.primary.light,
                }}
              >
                <input
                  ref={inputRef}
                  // disabled={useTime}
                  aria-label="Demo input"
                  placeholder={`number of sessions`}
                  onChange={(text) => {
                    setFrequency(text.currentTarget.value);
                    // setEndText(text.currentTarget.value);
                    // var sherlocked = Sherlock.parse(text.currentTarget.value);
                    // console.log(sherlocked);
                    // setDue(sherlocked.startDate);
                  }}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    setUseTime(false);
                  }}
                  autoFocus
                  style={{
                    // padding: "0px",
                    flexShrink: "0",
                    backgroundColor: theme.palette.primary.light,
                    fontFamily: "Space Grotesk",
                    fontWeight: "500",
                    fontSize: "14px",
                    height: "20px",
                    width: "100%",
                    flex: 1,
                    border: 0,
                    outlineWidth: 0,
                    boxSizing: "border-box",
                    outline: "none",
                    borderRadius: "4px",
                    color: !useTime
                      ? theme.palette.text.primary
                      : theme.palette.primary.light,
                  }}
                  value={frequency}
                />
              </Stack>
            </Stack>
          </div>
          <div
            style={{
              width: "100%",
              height: "32px",
              justifyContent: "flex-end",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              marginTop: "24px",
            }}
          >
            <Stack flexDirection={"row"} alignItems={"center"}>
              <Button
                variant="text"
                color="secondary"
                sx={{
                  backgroundColor: theme.palette.primary.light,
                  height: "100%",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.light,
                  },
                }}
                onClick={() => {
                  setReoccurringModal(false);
                  reoccuringTodos();
                  // updateTodo(todo);
                  // setOpenEditTodo(false);
                }}
                // disabled={
                //   start === null ||
                //   (end === null && parseInt(frequency) === 0) ||
                //   selectedTitle === "" ||
                //   parseInt(duration) < 15 ||
                //   daysReoccuringSelected.length !== 0
                // }
              >
                save
              </Button>
            </Stack>
          </div>
        </StyledBox>
      </StyledDialog>
    </ToolbarContainer>
  );
};
