import { ClickAwayListener, Stack, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  createTodo,
  CREATE_TO_DO,
  DELETE_TODO_UUID,
  NULL_TODO_COMPLETEDAT,
  updateTodoState,
  UPDATE_TODO_UUID,
} from "../../../graphql/mutations/todo";
import { client } from "../../../utils/apollo";
import { AddIcon } from "../../Icons/AddIcon";
import { CheckIcon } from "../../Icons/CheckIcon";
import { XIcon } from "../../sidebar/Icons";
import { CustomInput, ToolbarToDoCreateInput } from "../../toolbar/styles";

import { toast } from "react-hot-toast";
import {
  CalendarViewLabel,
  CalendarViewTaskContainer,
  CalendarViewTaskLabel,
  CalendarViewWarningIcon,
  StyledBox,
  StyledCloseButton,
  StyledDialog,
  StyledDialogTopBar,
  StyledHeader,
} from "./styles";
import { StudyTypographySmallText } from "../../glovebox/StudyTypography";
import { HoverPointerStyle } from "../../Icons/HoverPointerStyle";
var Sherlock = require("sherlockjs");

const monthsOfYear = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const CalendarViewAddEventModal = ({
  open,
  onClose,
  todos,
  setTodos,
  day,
}: {
  open: any;
  onClose: any;
  todos: any;
  setTodos: any;
  day: any;
}) => {
  const theme = useTheme();
  // console.log(day);
  // const { id: userId } = useUserContext();
  const userId = localStorage.getItem("userId");
  // const [todos, setTodos] = useState(JSON.parse(localStorage.getItem('todos')!) || [])
  // console.log(todos)
  const [startText, setStartText] = useState("");
  const [dueText, setDueText] = useState("");

  const [start, setStart] = useState(null);
  const [due, setDue] = useState(day?.day);

  useEffect(() => {
    setDue(day?.day);
  }, [day]);

  const [editTodo, setEditTodo] = useState(false);
  const [editTodoText, setEditTodoText] = useState("");
  const [typeInput, setTypeInput] = useState("todo");
  const [title, setTitle] = useState("");
  const user = JSON.parse(localStorage.getItem("user")!);
  const inputRef = useRef<any>(null);
  const [rightClickTask, setRightClickTask] = useState<any>();
  const [clickAnchorEl, setClickAnchorEl] = useState<null | HTMLElement>(null);
  const clickOpen = Boolean(clickAnchorEl);
  const handleRightClick = (event: any, taskId: any) => {
    event.preventDefault();
    setRightClickTask(rightClickTask === taskId ? null : taskId);
    setClickAnchorEl(
      clickAnchorEl === event.currentTarget ? null : event.currentTarget
    );
    // Trigger your custom action here
  };

  const createTodoHelper = () => {
    setTitle("");

    toast.promise(
      createTodo(
        user.baseCategoryId,
        title,
        30,
        moment(due),
        moment(start),
        typeInput === "todo" ? false : true,
        user.id,
        todos,
        setTodos
      ),
      {
        loading: "adding todo..",
        success: <b>todo saved!</b>,
        error: <b>Could not save.</b>,
      },
      {
        duration: 4000,
        style: {
          fontSize: "12px",
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.primary,
        },
      }
    );
  };
  const updateTodoStateHelper = (
    oldTodo: any,
    completed: boolean,
    deleted: boolean
  ) => {
    toast.promise(
      updateTodoState(oldTodo, completed, deleted, todos, setTodos),
      {
        loading: "updating todo..",
        success: <b>todo saved!</b>,
        error: <b>Could not save.</b>,
      },
      {
        duration: 4000,
        style: {
          fontSize: "12px",
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.primary,
        },
      }
    );
  };

  // console.log(day);
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="archive-task-modal"
      aria-describedby="modal-modal-description"
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <StyledBox>
        <StyledDialogTopBar>
          <StyledHeader
            style={{
              marginLeft: 0,
            }}
          >
            {`Create new ${typeInput}`}
          </StyledHeader>
          <StyledCloseButton onClick={onClose}>
            <XIcon />
          </StyledCloseButton>
        </StyledDialogTopBar>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          borderRadius={"4px"}
          marginBottom={"12px"}
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            padding="8px"
            borderRadius={"4px"}
            style={{ backgroundColor: theme.palette.primary.light }}
          >
            <motion.div
              whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
              whileHover={{ rotate: 10 }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 8,
              }}
              style={{
                // marginLeft: "12px",
                alignSelf: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <HoverPointerStyle
                width={"18px"}
                height={"18px"}
                viewBox={`0 0 24 24`}
                fill={
                  typeInput === "todo"
                    ? theme.palette.success.main
                    : theme.palette.secondary.light
                }
                onClick={() => {
                  setTypeInput("todo");
                }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="4" />
                <path
                  d="M9.32199 15.2378L6.42256 12.3384C6.09669 12.0125 5.57028 12.0125 5.2444 12.3384C4.91853 12.6643 4.91853 13.1907 5.2444 13.5166L8.73709 17.0092C9.06297 17.3351 9.58938 17.3351 9.91525 17.0092L18.7556 8.1689C19.0815 7.84303 19.0815 7.31662 18.7556 6.99074C18.4297 6.66487 17.9033 6.66487 17.5774 6.99074L9.32199 15.2378Z"
                  fill={theme.palette.secondary.light}
                />
              </HoverPointerStyle>
            </motion.div>
            <StudyTypographySmallText
              style={{
                marginLeft: "12px",
              }}
            >
              create todo
            </StudyTypographySmallText>
          </Stack>

          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            padding="8px"
            marginLeft={"12px"}
            borderRadius={"4px"}
            sx={{
              backgroundColor: theme.palette.primary.light,
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <motion.div
              whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
              whileHover={{ rotate: 10 }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 8,
              }}
              style={{
                // marginLeft: "12px",
                alignSelf: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <HoverPointerStyle
                width={"18px"}
                height={"18px"}
                viewBox={`0 0 24 24`}
                fill={
                  typeInput === "event"
                    ? theme.palette.success.main
                    : theme.palette.secondary.light
                }
                onClick={() => {
                  setTypeInput("event");
                }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="4" />
                <path
                  d="M9.32199 15.2378L6.42256 12.3384C6.09669 12.0125 5.57028 12.0125 5.2444 12.3384C4.91853 12.6643 4.91853 13.1907 5.2444 13.5166L8.73709 17.0092C9.06297 17.3351 9.58938 17.3351 9.91525 17.0092L18.7556 8.1689C19.0815 7.84303 19.0815 7.31662 18.7556 6.99074C18.4297 6.66487 17.9033 6.66487 17.5774 6.99074L9.32199 15.2378Z"
                  fill={theme.palette.secondary.light}
                />
              </HoverPointerStyle>
            </motion.div>

            <StudyTypographySmallText
              style={{
                marginLeft: "12px",
              }}
            >
              create event
            </StudyTypographySmallText>
          </Stack>
        </Stack>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          borderRadius={"4px"}
          marginBottom={"12px"}
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          <StudyTypographySmallText
            marginRight={"12px"}
          >{`START: `}</StudyTypographySmallText>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            padding="6px"
            borderRadius={"4px"}
            style={{ backgroundColor: theme.palette.primary.light }}
          >
            <CustomInput
              ref={inputRef}
              aria-label="Demo input"
              placeholder={`ex. tomorrow 3PM`}
              onChange={(text) => {
                setStartText(text.currentTarget.value);
                var sherlocked = Sherlock.parse(text.currentTarget.value);
                console.log(sherlocked);
                setStart(sherlocked.startDate);
              }}
              autoFocus
              style={{ fontSize: "10px", padding: "0px", height: "32px" }}
              value={startText}
            />
          </Stack>

          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            padding="8px"
            marginLeft={"12px"}
            borderRadius={"4px"}
            sx={{
              backgroundColor: theme.palette.primary.light,
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <StudyTypographySmallText>
              {`${
                moment(start).isValid()
                  ? moment(start).format("M/D hh:mma")
                  : "null"
              }`}
            </StudyTypographySmallText>
          </Stack>
        </Stack>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          borderRadius={"4px"}
          marginBottom={"12px"}
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          <StudyTypographySmallText
            marginRight={"24px"}
          >{`DUE: `}</StudyTypographySmallText>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            padding="6px"
            borderRadius={"4px"}
            style={{ backgroundColor: theme.palette.primary.light }}
          >
            <CustomInput
              ref={inputRef}
              aria-label="Demo input"
              placeholder={`ex. tomorrow 3PM`}
              onChange={(text) => {
                setDueText(text.currentTarget.value);
                var sherlocked = Sherlock.parse(text.currentTarget.value);
                console.log(sherlocked);
                setDue(sherlocked.startDate);
              }}
              autoFocus
              style={{ fontSize: "10px", padding: "0px", height: "32px" }}
              value={dueText}
            />
          </Stack>

          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            padding="8px"
            marginLeft={"12px"}
            borderRadius={"4px"}
            sx={{
              backgroundColor: theme.palette.primary.light,
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <StudyTypographySmallText>
              {`${
                moment(due).isValid() ? moment(due).format("M/D hh:mma") : ""
              }`}
            </StudyTypographySmallText>
          </Stack>
        </Stack>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            createTodoHelper();
          }}
        >
          <ToolbarToDoCreateInput style={{ marginBottom: "10px" }}>
            <CustomInput
              ref={inputRef}
              aria-label="Demo input"
              placeholder={`add ${typeInput}..`}
              onChange={(text) => {
                setTitle(text.currentTarget.value);
              }}
              autoFocus
              value={title}
            />
            <AddIcon
              clicked={() => {
                onClose();
                createTodoHelper();
              }}
            />
          </ToolbarToDoCreateInput>
        </form>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          borderRadius={"4px"}
          marginTop={"12px"}
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          <CalendarViewWarningIcon
            style={{
              width: "18px",
              height: "18px",
              // color: theme.palette.success.main,
            }}
          />
          <CalendarViewLabel>
            Only todos will show up on todo list!
          </CalendarViewLabel>
        </Stack>
      </StyledBox>
    </StyledDialog>
  );
};

export default CalendarViewAddEventModal;
