import { useTheme } from "@mui/material";
import React from "react";

function PageIndicator({
  currentPage,
  totalPages,
  onPageChange,
}: {
  currentPage: any;
  totalPages: any;
  onPageChange: any;
}) {
  const theme = useTheme();
  return (
    <div
      style={{
        alignItems: "center",
        justifyItems: "space-between",
        display: "flex",
        flexDirection: "row",
        // flex: 1,
      }}
    >
      {Array.from({ length: totalPages }).map((num, index) => (
        <div
          style={{
            width: "10px",
            height: "10px",
            backgroundColor:
              currentPage === index
                ? theme.palette.action.active
                : theme.palette.primary.light,
            borderRadius: "5px",
            margin: "4px",
          }}
        ></div>
      ))}
    </div>
  );
}

export default PageIndicator;
