import { useQuery } from "@apollo/client";
import {
  Backdrop,
  CircularProgress,
  Fab,
  Typography,
  useTheme,
  Zoom,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useOutletContext, useParams } from "react-router-dom";
import { updateGroup, UPDATE_GROUP } from "../../../graphql/mutations/group";
import { GROUP_INFO } from "../../../graphql/queries/group";
import { client } from "../../../utils/apollo";
import { FABS } from "../../../utils/constants";
import { DraggingContext, PageContext } from "../../../utils/contexts";
import { XIcon } from "../../sidebar/Icons";
import {
  StyledBox,
  StyledCloseButton,
  StyledDialog,
  StyledDialogTopBar,
  StyledHeader,
} from "../calendar/styles";
import { CoworkChat } from "./CoworkChat";
import { CoworkFriendContainer } from "./CoworkFriendContainer";
import { CoworkMain } from "./CoworkMain";

interface MyProps {
  showIsDragging: () => void;
}
const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
};

export const CoworkLayout = () => {
  const [todos, setTodos, user, setUser, categories, setCategories] =
    useOutletContext<any>();
  const [isComponentDragging, setIsDragging] = useState(false);

  const { page, showMainPage, setShowMainPage } = useContext(PageContext);
  const theme = useTheme();
  const [modal, setModal] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [friendsNotInGroup, setFriendsNotInGroup] = useState<any[]>([]);
  const [newGroupUsers, setNewGroupUsers] = useState<any[]>([]);

  // const [user, setUser] = useState(
  //   JSON.parse(localStorage.getItem("user")!) || {}
  // );
  const { uuid } = useParams();
  const [group, setGroup] = useState(
    JSON.parse(localStorage.getItem("group")!) || {}
  );
  const combinedUsers = group?.users?.concat(group?.addedUsers);

  useEffect(() => {
    localStorage.setItem("group", JSON.stringify(group));
    const combinedUsers = group?.users?.concat(group?.addedUsers);
    setFriendsNotInGroup(
      user?.friends?.filter(function (friend: any) {
        return combinedUsers?.every(
          (groupUser: any) => groupUser?.id !== friend?.id
        );
      })
    );
  }, [group]);

  const { loading, error, data } = useQuery(GROUP_INFO, {
    variables: {
      uuid: uuid,
    },
    onError: () => {},
    onCompleted: (data) => {
      setGroup(data.getGroup);
      // console.log(data.getGroup);
      // const combinedUsers = data.getGroup?.users?.concat(
      //   data.getGroup?.addedUsers
      // );
      // // console.log(combinedUsers);
      // setFriendsNotInGroup(
      //   user?.friends?.filter(function (friend: any) {
      //     // console.log("friend: ", friend);
      //     return combinedUsers?.every(
      //       (groupUser: any) => groupUser.id !== friend.id
      //     );
      //   })
      // );
    },
  });

  const addToGroupHelper = async (friendId: any) => {
    await updateGroup(friendId, group, setGroup);
  };
  // console.log("rest: ", friendsNotInGroup);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  const fab = FABS.findIndex((fab) => fab.label === page);
  // console.log("rest of friends: ", friendsNotInGroup);
  return (
    <div
      style={{
        height: "90vh",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        // backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      }}
    >
      <DraggingContext.Provider value={{ isComponentDragging, setIsDragging }}>
        <DndProvider backend={HTML5Backend}>
          <StyledDialog
            open={modal}
            onClose={() => {}}
            aria-labelledby="archive-task-modal"
            aria-describedby="modal-modal-description"
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }}
          >
            <StyledBox>
              <StyledDialogTopBar>
                <StyledHeader
                  style={{
                    marginLeft: 0,
                  }}
                >
                  Select friends to add to the room!
                </StyledHeader>
                <StyledCloseButton onClick={() => setModal(false)}>
                  <XIcon />
                </StyledCloseButton>
              </StyledDialogTopBar>
              {/* <Box sx={style}> */}
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={refreshing}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            {status}
          </Typography> */}
              {friendsNotInGroup?.length !== 0 ? (
                friendsNotInGroup?.map((friend: any) => {
                  return (
                    <CoworkFriendContainer
                      data={friend}
                      addToGroup={addToGroupHelper}
                    />
                  );
                })
              ) : (
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  No Friends to add
                </Typography>
              )}
              {/* </Box> */}
            </StyledBox>
          </StyledDialog>
          {/* </Modal> */}
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={refreshing}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {/* <ContentWrapper> */}
          {(isComponentDragging && isMobile) || showMainPage ? null : (
            <CoworkChat modal={modal} setModal={setModal} loading={loading} />
          )}
          {!isMobile || isComponentDragging || showMainPage ? (
            <CoworkMain group={data?.getGroup} loading={loading} />
          ) : null}
          {/* </ContentWrapper> */}

          {isMobile ? (
            <Zoom
              key={FABS[fab].color}
              in={page === FABS[fab].label}
              timeout={transitionDuration}
              style={{
                transitionDelay: `${
                  page === FABS[fab].label ? transitionDuration.exit : 0
                }ms`,
              }}
              unmountOnExit
            >
              <Fab
                sx={FABS[fab].sx}
                aria-label={FABS[fab].label}
                color={FABS[fab].color}
                onClick={() => {
                  setShowMainPage(!showMainPage);
                }}
              >
                {showMainPage ? FABS[fab].iconTool : FABS[fab].iconMain}
              </Fab>
            </Zoom>
          ) : null}
        </DndProvider>
      </DraggingContext.Provider>
    </div>
  );
};

// const ContentWrapper = styled.div`
//   margin-left: 4rem;
//   height: 100vh;
//   width: 100%;
//   display: flex;
//   box-sizing: border-box;
//   font-size: 1rem;
//   a {
//     &:hover {
//       text-decoration: underline;
//     }
//   }

//   @media (max-width: 850px) {
//     margin-left: 0px;
//   }
// `;
