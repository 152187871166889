/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import Dropzone from "react-dropzone";
import {
  Add,
  AutoAwesomeOutlined,
  CalendarTodayOutlined,
  FileCopyOutlined,
  FileCopyRounded,
  IosShare,
  Stars,
  ViewDayRounded,
  ViewWeekRounded,
} from "@mui/icons-material";
import {
  Badge,
  Button,
  Chip,
  Divider,
  Stack,
  Tooltip,
  alpha,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { motion } from "framer-motion";
import moment from "moment";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-hot-toast";
import { useHotkeys } from "react-hotkeys-hook";
import { v4 as uuid } from "uuid";
import {
  CREATE_CHILD_TODO,
  DELETE_CHILD_TODO,
  NULL_TODO_STARTTIME,
  UPDATE_CHILD_TODO,
  UPDATE_REOCCURRING_STARTTIMES,
  UPDATE_REOCCURRING_TODOS,
  UPDATE_TODO_UUID,
  createTodo,
  updateTodoState,
} from "../../../graphql/mutations/todo";
import { client } from "../../../utils/apollo";
import { DAYS_OF_WEEK, hexToRgbA } from "../../../utils/constants";
import {
  DraggingContext,
  HotkeyContext,
  ThemeContext,
  useUserContext,
} from "../../../utils/contexts";
import { ARROW_KEYS, HOTKEYS, NUMS } from "../../../utils/hotkeyHelper";
import { AddIcon } from "../../Icons/AddIcon";
import { HoverPointerStyle } from "../../Icons/HoverPointerStyle";
import BoxedText from "../../glovebox/BoxedText";
import DateCollector from "../../glovebox/DateCollector";
import { EditToDoModal } from "../../glovebox/EditToDoModal";
import Modal from "../../glovebox/Modal";
import {
  StudyTypographyMediumSubtitle,
  StudyTypographyMediumTitle,
  StudyTypographySmallSubtitle,
  StudyTypographySmallText,
  StudyTypographySmallTitle,
} from "../../glovebox/StudyTypography";
import { XIcon } from "../../sidebar/Icons";
import {
  CustomInput,
  ProfileBlockPopper,
  ToolbarToDoCreateInput,
} from "../../toolbar/styles";
import {
  CalendarLeftArrowIcon,
  CalendarRightArrowIcon,
  CalendarViewLabel,
  CalendarViewWarningIcon,
  StyledBox,
  StyledCloseButton,
  StyledDialog,
  StyledDialogTopBar,
  StyledHeader,
} from "../calendar/styles";
import { MainbarContainer } from "../styles";
import { ScheduleToDoBar } from "./ScheduleToDoBar";
import ShareScheduleFreeTimes from "./ShareScheduleFreeTimes";
import "./styles.css";
import { AddFriendsToGroupBox } from "../friends/styles";
import { PeopleIcon } from "../../Icons/PeopleIcon";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../../../utils/types";
import DropZone from "../../glovebox/DropZone";
import AIScheduleSystem from "./AIScheduleSystem";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};
const item = {
  hidden: { x: 100, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
};

export const ScheduleMain = ({
  todos,
  setTodos,
  allTodos,
  setAllTodos,
  categories,
  setCategories,
}: // updateTodoList,
{
  todos: any;
  setTodos: any;
  allTodos: any;
  setAllTodos: any;
  categories: any;
  setCategories: any;
  // updateTodoList: any;
}) => {
  const [eventPicked, setEventPicked] = useState();
  const [typeCal, setTypeCal] = useState("timeGridDay");
  const [editTodo, setEditTodo] = useState(false);
  const [editTodoText, setEditTodoText] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [startBarrier, setStartBarrier] = useState(moment().set("hour", 8));
  const [endBarrier, setEndBarrier] = useState(moment().set("hour", 22));
  const [daysSelected, setDaysSelected] = useState<any>([
    DAYS_OF_WEEK[moment().day()],
  ]);
  const user = JSON.parse(localStorage.getItem("user")!);
  const [pickedCategory, setPickedCategory] = useState<any>(
    categories.find((category: any) => category.uuid === user.baseCategoryId)
  );
  const [due, setDue] = useState();
  const [temporaryDue, setTemporaryDue] = useState();
  const [temporaryDueText, setTemporaryDueText] = useState();
  const [times, setTimes] = useState();
  const [temporaryCategory, setTemporaryCategory] = useState();
  const [hoveredTask, setHoveredTask] = useState();
  const [calTitle, setCalTitle] = useState(null);
  const [selectedTask, setSelectedTask] = useState();
  const [currentTime, setCurrentTime] = useState(moment().format("HH:mm:ss"));
  const [events, setEvents] = useState<any>();
  const [open, setOpen] = useState(false);
  const [typeInput, setTypeInput] = useState("todo");
  const [title, setTitle] = useState("");
  const [reoccurringAll, setReoccurringAll] = useState(true);
  const [editReoccurringModal, setEditReoccurringModal] = useState(false);
  const [sendSelected, setSendSelected] = useState();
  const shareInputRef = useRef(null);
  const [aiModalTitle, setAiModalTitle] = useState("AI Scheduler");
  const handleAiModalTitle = (name) => {
    setAiModalTitle(name);
  };
  const [reoccurringInfo, setReoccurringInfo] = useState();
  const onClose = () => {
    setOpen(false);
    setTitle("");
    setPickedCategory(
      categories.find((category: any) => category.uuid === user.baseCategoryId)
    );
    setTemporaryCategory(null);

    setDue(null);
  };
  const [slotTime, setSlotTime] = useState("00:30:00");
  const { isComponentDragging } = useContext(DraggingContext);

  // const [daysSelected, setDaysSelected] = useState<any>(DAYS_OF_WEEK);
  // var timePicked = "8:30";
  // const [todos, setTodos] = useState(JSON.parse(localStorage.getItem('todos')!) || []);
  const { googleUser } = useUserContext();
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().format("HH:mm:ss"));
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const returnHours = (time: any) => {
    var hours = time.split(":")[0];
    return hours;
  };

  const handleSetDue = (date: any) => {
    setTemporaryDue(date);
  };

  const handleSetCategory = (category: any) => {
    setTemporaryCategory(category);
  };

  const handleSetDueText = (text: any) => {
    setTemporaryDueText(text);
  };

  const handleSetTitle = (title: any) => {
    setTitle(title);
  };

  const handleRightClick = (event: any) => {
    event.preventDefault();
    setEditTodo(false);
    setSelectedTask(task);
    // handleTaskClick(event);
    // Trigger your custom action here
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openShare = Boolean(anchorEl);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const handleCategoryClick = (event: React.MouseEvent<HTMLElement>) => {
    setCategoryAnchorEl(categoryAnchorEl ? null : event.currentTarget);
  };
  const categoryOpen = Boolean(categoryAnchorEl);
  const [shareAnchorEl, setShareAnchorEl] = useState(false);

  const handleShareOpen = () => {
    setShareAnchorEl(!shareAnchorEl);
  };

  const [pdfOpen, setPdfOpen] = useState(false);
  const handlePdfOpen = () => {
    setPdfOpen(!pdfOpen);
  };

  const [aiSchedulerOpen, setAiSchedulerOpen] = useState(false);
  const handleAiSchedulerOpen = () => {
    setAiSchedulerOpen(!aiSchedulerOpen);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useEffect(() => {
    localStorage.setItem("todos", JSON.stringify(todos));
    const weekStartDate = moment().startOf("isoWeek").date() - 2;
    const weekEndDate = moment().endOf("isoWeek").date() - 2;

    const newSchedule = allTodos;
    // ?.filter(
    //   (todo: any) =>
    //     moment(todo.startTime).date() > weekStartDate &&
    //     moment(todo.startTime).date() < weekEndDate
    // );
    const restructureSchedule = newSchedule.map((schedule: any) => ({
      title: schedule?.title,
      id: schedule?.uuid,
      start: moment(schedule?.startTime).toISOString(),
      end: moment(schedule?.startTime)
        .add("minutes", schedule?.duration)
        .toISOString(),
      reoccurring: schedule?.todoId
        ? todos.find((todo) => todo.uuid === schedule.todoId)?.reoccurring
        : schedule?.reoccurring,
    }));

    setEvents(restructureSchedule);
  }, [allTodos, todos]);

  const handleDeleteTodoHelper = async (todo: any) => {
    await DeleteTodo(todo, todos, setTodos);
  };

  const constructAvailableTimes = async () => {
    let minHours = 8;
    let maxHours = 22;
    let now = new Date();
    if (now.getHours() >= maxHours) {
      now.setDate(now.getDate() + 1);
      now.setHours(minHours, 0, 0, 0);
    }
    if (now.getHours() < minHours) {
      now.setHours(minHours, 0, 0, 0);
    }
    const shareEndDate = new Date(now);
    shareEndDate.setDate(shareEndDate.getDate() + 6);
    shareEndDate.setHours(maxHours, 0, 0, 0);
    const allTodos = todos
      ?.map((parent: any) => {
        if (parent.todoReplicas) {
          let children = parent.todoReplicas.map((child: any) => ({
            ...child,
            title: parent.title,
          }));
          return [parent, ...children];
        }
        return [parent];
      }) // Merge parent and children into an array
      ?.reduce((prev: any, curr: any) => prev.concat(curr));
    let yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);
    let validTodos = allTodos?.map((todo: any) => {
      let start = new Date(todo.startTime);
      if (start > yesterday && start < shareEndDate) {
        let event = { ...todo };
        event.startTime = new Date(todo.startTime);
        return event;
      }
      // return None;
      // return start > now && start < endOfWeek;
    });
    validTodos = validTodos.filter(function (todo: any) {
      return todo !== undefined;
    });
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    function formatTimeSlot(date, duration) {
      function diff_minutes(dt2, dt1) {
        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));
      }
      const start = new Date(date);
      const end = new Date(start.getTime() + duration);
      if (diff_minutes(start, end) < 5) return "";
      return formatTime(start) + " - " + formatTime(end);
    }

    // function to add x mintues to dateTime
    function addMinutes(date, minutes) {
      return new Date(date.getTime() + minutes * 60000);
    }

    // Define a function to format a time in the format "hh:mm[am/pm]"
    function formatTime(date) {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "pm" : "am";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
      return formattedHours + ":" + formattedMinutes + ampm;
    }
    function findAvailableTimeSlots(events) {
      let currentTime = new Date(now);
      currentTime.setDate(currentTime.getDate());
      let coeff = 1000 * 60 * 5;
      let rounded = new Date(Math.round(currentTime.getTime() / coeff) * coeff);
      if (currentTime.getTime() > rounded.getTime()) {
        let timeChange = currentTime.getTime() - rounded.getTime();
        currentTime.setTime(currentTime.getTime() - timeChange);
        currentTime = addMinutes(currentTime, 5);
      } else {
        currentTime = rounded;
      }
      let eventsByDay = {};
      let availableTimeSlotsByDay = {};
      for (const tevent of events) {
        let startDay = tevent.startTime.getDay();
        let endDay = addMinutes(tevent.startTime, tevent.duration).getDay();
        if (endDay !== startDay) {
          if (!(endDay in eventsByDay)) {
            eventsByDay[endDay] = [];
          }
          eventsByDay[endDay].push(tevent);
        }
        if (!(startDay in eventsByDay)) {
          eventsByDay[startDay] = [];
        }
        if (tevent.startTime.getTime() > yesterday.getTime()) {
          eventsByDay[startDay].push(tevent);
        }
      }
      while (currentTime < shareEndDate) {
        let currentDay = currentTime.getDay();
        let dayEvents = eventsByDay[currentDay];
        availableTimeSlotsByDay[currentDay] = [];
        dayEvents?.sort((a, b) => a.startTime - b.startTime);
        if (dayEvents && dayEvents?.length !== 0) {
          for (const devent of dayEvents) {
            let endTime = new Date(
              devent.startTime.getTime() + devent.duration * 60000
            );
            if (
              endTime.getDay() === currentTime.getDay() &&
              endTime.getHours() <= minHours
            ) {
              continue;
            }
            if (
              currentTime < devent.startTime &&
              devent.startTime.getHours() < maxHours
            ) {
              availableTimeSlotsByDay[currentTime.getDay()].push(
                formatTimeSlot(currentTime, devent.startTime - currentTime)
              );
            }
            if (endTime.getDay() !== currentTime.getDay()) {
              if (endTime.getHours() < minHours) {
                currentTime.setDate(endTime.getDate());
                currentTime.setHours(minHours, 0, 0, 0);
                break;
              }
              if (endTime.getHours() >= maxHours) {
                currentTime.setDate(endTime.getDate() + 1);
                currentTime.setHours(minHours, 0, 0, 0);
                break;
              }
              currentTime.setDate(endTime.getDate());
              currentTime.setHours(endTime.getHours());
              break;
            }
            if (
              devent.startTime.getHours() >= maxHours ||
              endTime.getHours() >= maxHours
            ) {
              currentTime.setDate(currentTime.getDate() + 1);
              currentTime.setHours(minHours, 0, 0, 0);
              continue;
            }
            let possibleNewTime = new Date(
              devent.startTime.getTime() + devent.duration * 60000
            );
            if (currentTime < possibleNewTime) {
              currentTime = possibleNewTime;
            }
            // i++;
          }
        } else {
          let endOfDay = new Date(currentTime);
          endOfDay.setHours(maxHours, 0, 0, 0);
          availableTimeSlotsByDay[currentTime.getDay()].push(
            formatTimeSlot(currentTime, endOfDay - currentTime)
          );
          currentTime.setDate(currentTime.getDate() + 1);
          currentTime.setHours(minHours, 0, 0, 0);
        }
        if (currentDay === currentTime.getDay()) {
          if (currentTime.getHours() < maxHours) {
            let endOfDay = new Date(currentTime);
            endOfDay.setHours(maxHours, 0, 0, 0);
            availableTimeSlotsByDay[currentTime.getDay()].push(
              formatTimeSlot(currentTime, endOfDay - currentTime)
            );
          }
          currentTime.setDate(currentTime.getDate() + 1);
          currentTime.setHours(minHours, 0, 0, 0);
        }
        if (currentTime.getDate() === shareEndDate.getDate()) {
          break;
        }
      }
      if (currentTime < shareEndDate) {
        if (!(shareEndDate.getDay() in availableTimeSlotsByDay)) {
          availableTimeSlotsByDay[shareEndDate.getDay()] = [];
        }
        availableTimeSlotsByDay[shareEndDate.getDay()].push(
          formatTimeSlot(currentTime, shareEndDate - currentTime)
        );
      }
      return availableTimeSlotsByDay;
    }
    let res = findAvailableTimeSlots(validTodos);
    let formatedRes = [];
    let currentDay = now.getDay();
    let cycles = 0;
    while (cycles === 0) {
      if (
        res[currentDay % 7] &&
        daysSelected.includes(DAYS_OF_WEEK[currentDay % 7])
      ) {
        formatedRes.push(
          days[currentDay % 7] + ": " + res[currentDay % 7]?.join(", ")
        );
      }
      currentDay = (currentDay + 1) % 7;
      if (currentDay === now.getDay()) {
        cycles += 1;
      }
    }
    let availableTimeSlots =
      "Hey! This is what my availability looks like for the rest of the week! \n";
    availableTimeSlots =
      availableTimeSlots +
      "\n" +
      formatedRes.join("\n") +
      "\n" +
      "\npowered by mystudyo.com";
    // setAvailableSchedule(availableTimeSlots);
    return availableTimeSlots;
  };

  const { showBadge } = useContext(HotkeyContext);

  const createTodoHelper = (title: string, selectInfo: any) => {
    if (title) {
      toast.promise(
        createTodo(
          pickedCategory.uuid || user.baseCategoryId,
          title,
          diff_minutes(selectInfo.end, selectInfo.start),
          due,
          new Date(selectInfo.startStr),
          typeInput === "todo" ? false : true,
          user.id,
          todos,
          setTodos
        ),

        {
          loading: "adding todo..",
          success: <b>todo saved!</b>,
          error: <b>Could not save.</b>,
        },
        {
          duration: 4000,
          style: {
            fontSize: "12px",
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.text.primary,
          },
        }
      );
    }
  };

  const updateTodo = (dropInfo: any) => {
    let replica = false;
    let todoReplicaUUID = "";
    let parentTodoDuation = 0;
    let newTodos = [...todos];
    newTodos = newTodos?.map((todo) => {
      let newTodo = { ...todo };
      if (todo.uuid === dropInfo.draggedEl.id) {
        if (todo.startTime != null) {
          replica = true;
          todoReplicaUUID = uuid();
          let newTodoReplica = {
            id: null,
            startTime: dropInfo.event.startStr,
            duration: todo.duration,
            todoId: dropInfo.draggedEl.id,
            completedAt: null,
            uuid: todoReplicaUUID,
            event: false,
            __typeName: "TodoReplica",
          };
          parentTodoDuation = todo.duration;
          newTodo.todoReplicas = [newTodoReplica, ...newTodo.todoReplicas];
        } else {
          newTodo.startTime = dropInfo.event.startStr;
          newTodo.duration = todo.duration;
        }
      }

      return newTodo;
    });
    setTodos(newTodos);
    if (replica) {
      toast.promise(
        client.mutate({
          mutation: CREATE_CHILD_TODO,
          variables: {
            startTime: moment(dropInfo.event.startStr),
            duration: dropInfo.draggedEl.duration
              ? dropInfo.draggedEl.duration
              : parentTodoDuation,
            parentId: dropInfo.draggedEl.id,
            uuid: todoReplicaUUID,
            event: false,
          },
        }),
        {
          loading: "updating todo..",
          success: <b>todo created!</b>,
          error: <b>Could not save.</b>,
        },
        {
          duration: 4000,
          style: {
            fontSize: "12px",
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.text.primary,
          },
        }
      );
    } else {
      if (hoveredTask) {
        toast.promise(
          client
            .mutate({
              mutation: UPDATE_TODO_UUID,
              variables: {
                startTime: moment(dropInfo.event.startStr),
                duration: hoveredTask?.duration
                  ? hoveredTask?.duration
                  : parentTodoDuation,
                uuid: hoveredTask?.uuid,
              },
            })
            .then((data) => {}),
          {
            loading: "updating todo..",
            success: <b>todo saved!</b>,
            error: <b>Could not save.</b>,
          },
          {
            duration: 4000,
            style: {
              fontSize: "12px",
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.text.primary,
            },
          }
        );
      } else {
        toast.promise(
          client
            .mutate({
              mutation: UPDATE_TODO_UUID,
              variables: {
                startTime: moment(dropInfo.event.startStr),
                duration: dropInfo.draggedEl.duration
                  ? dropInfo.draggedEl.duration
                  : parentTodoDuation,
                uuid: dropInfo.draggedEl.id,
              },
            })
            .catch((error) => {
              console.log(dropInfo);
              console.log(error);
            }),
          {
            loading: "updating todo..",
            success: <b>todo saved!</b>,
            error: <b>Could not save.</b>,
          },
          {
            duration: 4000,
            style: {
              fontSize: "12px",
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.text.primary,
            },
          }
        );
      }
    }
  };

  const updateTodoStartTime = (changeInfo: any, duration: any) => {
    console.log("in update start time");
    let replica = false;
    let reoccurring = false;
    let newStart = false;
    let timeChange = 0;
    let parentId = "";
    let newTodos = [...todos];
    let uuids: any[] = [];
    let newStartTimes: any[] = [];
    console.log(duration);
    newTodos = newTodos?.map((todo: any) => {
      let newTodo = { ...todo };
      if (!replica) {
        if (todo.uuid === changeInfo.event.id) {
          newTodo.startTime = moment(changeInfo.event.start).toISOString();
          // newTodo.duration = duration ? ;
          if (duration) {
            newTodo.duration = duration;
          } else {
            duration = newTodo.duration;
          }
          return newTodo;
        } else {
          newTodo.todoReplicas = newTodo.todoReplicas?.map((child: any) => {
            let newChild = { ...child };
            if (child.uuid === changeInfo.event.id) {
              replica = true;
              if (todo?.reoccurring && reoccurringAll) {
                reoccurring = true;
                parentId = todo.uuid;
                let currTime = new Date(child.startTime);
                let newTime = moment(changeInfo.event.start).toDate();
                if (currTime.getTime() !== newTime.getTime()) {
                  timeChange = newTime.getTime() - currTime.getTime();
                  newStart = true;
                }
              } else {
                newChild.startTime = moment(
                  changeInfo.event.start
                ).toISOString();
                if (duration) {
                  newChild.duration = duration;
                } else {
                  duration = newChild.duration;
                }
              }
            }
            return newChild;
          });
          if (reoccurring) {
            newTodo.todoReplicas = newTodo.todoReplicas?.map((child: any) => {
              let newChild = { ...child };
              if (newStart) {
                let childStartTime = new Date(newChild.startTime);
                childStartTime.setTime(childStartTime.getTime() + timeChange);
                newChild.startTime = childStartTime.toISOString();
                uuids.push(newChild.uuid);
                newStartTimes.push(newChild.startTime);
              }
              if (duration) {
                newChild.duration = duration;
              } else {
                duration = newChild.duration;
              }
              return newChild;
            });
          }
          if (replica) {
            return newTodo;
          }
        }
        return newTodo;
      } else {
        return newTodo;
      }
    });
    setTodos(newTodos);
    setReoccurringAll(true);
    if (reoccurring) {
      toast.promise(
        client
          .mutate({
            mutation: UPDATE_REOCCURRING_TODOS,
            variables: {
              duration: duration,
              todoId: parentId,
            },
          })
          .then(() => {
            if (newStart) {
              client.mutate({
                mutation: UPDATE_REOCCURRING_STARTTIMES,
                variables: {
                  uuids: uuids,
                  startTimes: newStartTimes,
                },
              });
            }
          }),

        {
          loading: "updating todo..",
          success: <b>todo updated!</b>,
          error: <b>Could not save.</b>,
        },
        {
          duration: 4000,
          style: {
            fontSize: "12px",
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.text.primary,
          },
        }
      );
    } else if (replica) {
      toast.promise(
        client.mutate({
          mutation: UPDATE_CHILD_TODO,
          variables: {
            startTime: moment(changeInfo.event.start).toISOString(),
            duration: duration,
            todoId: changeInfo.event.id,
          },
        }),

        {
          loading: "updating todo..",
          success: <b>todo updated!</b>,
          error: <b>Could not save.</b>,
        },
        {
          duration: 4000,
          style: {
            fontSize: "12px",
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.text.primary,
          },
        }
      );
    } else {
      toast.promise(
        client.mutate({
          mutation: UPDATE_TODO_UUID,
          variables: {
            startTime: moment(changeInfo.event.start).toISOString(),
            duration: duration,
            uuid: changeInfo.event.id,
          },
        }),

        {
          loading: "updating todo..",
          success: <b>todo updated!</b>,
          error: <b>Could not save.</b>,
        },
        {
          duration: 4000,
          style: {
            fontSize: "12px",
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.text.primary,
          },
        }
      );
    }
  };

  const removeTodoStart = (todo: any) => {
    let newTodos = [...todos];
    if (todo.todoId) {
      newTodos = newTodos.map((oldTodo: any) => {
        let newTodo = { ...oldTodo };
        if (oldTodo.uuid === todo.todoId) {
          newTodo.todoReplicas = oldTodo?.todoReplicas?.filter(function (
            child: any
          ) {
            return child.uuid !== todo.uuid;
          });
        }
        return newTodo;
      });
      setTodos(newTodos);
      toast.promise(
        client.mutate({
          mutation: DELETE_CHILD_TODO,
          variables: {
            uuid: todo.uuid,
          },
        }),
        {
          loading: "adding todo..",
          success: <b>todo saved!</b>,
          error: <b>Could not save.</b>,
        },
        {
          duration: 4000,
          style: {
            fontSize: "12px",
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.text.primary,
          },
        }
      );
    } else {
      newTodos = newTodos?.map((oldTodo: any) => {
        if (oldTodo.uuid === todo.uuid) {
          oldTodo.startTime = null;
        }
        return oldTodo;
      });
      setTodos(newTodos);
      toast.promise(
        client.mutate({
          mutation: NULL_TODO_STARTTIME,
          variables: {
            uuid: todo.uuid,
          },
        }),
        {
          loading: "adding todo..",
          success: <b>todo saved!</b>,
          error: <b>Could not save.</b>,
        },
        {
          duration: 4000,
          style: {
            fontSize: "12px",
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.text.primary,
          },
        }
      );
    }
  };

  const updateTodoTitle = (oldTodo: any) => {
    let todoUUID = oldTodo.todoId ? oldTodo.todoId : oldTodo.uuid;
    let newTodos = [...todos];
    newTodos = newTodos?.map((todo) => {
      let newTodo = { ...todo };
      if (todo.uuid === todoUUID) {
        newTodo.title = editTodoText;
      }
      return newTodo;
    });
    setTodos(newTodos);
    client.mutate({
      mutation: UPDATE_TODO_UUID,
      variables: {
        uuid: todoUUID,
        title: editTodoText,
      },
    });
  };
  // const updateTodoStartTimeHelper = (changeInfo: any, duration: any) => {
  //   toast.promise(
  //     updateTodoStartTime(changeInfo, duration, todos, setTodos),
  //     {
  //       loading: "updating todo..",
  //       success: <b>start time update saved!</b>,
  //       error: <b>Could not save.</b>,
  //     },
  //     {
  //       duration: 4000,
  //       style: {
  //         fontSize: "12px",
  //         backgroundColor: theme.palette.primary.light,
  //         color: theme.palette.text.primary,
  //       },
  //     }
  //   );
  // };

  const todayWeek = (view: any) => {
    const API = getApi();
    API && API.today();
  };
  useHotkeys(
    HOTKEYS.OPEN_TODAY,
    () => {
      todayWeek();
    },
    [todayWeek]
  );

  useHotkeys(
    NUMS.ONE,
    () => {
      setSlotTime("00:05:00");
    },
    [slotTime]
  );
  useHotkeys(
    NUMS.TWO,
    () => {
      setSlotTime("00:15:00");
    },
    [slotTime]
  );
  useHotkeys(
    NUMS.THREE,
    () => {
      setSlotTime("00:30:00");
    },
    [slotTime]
  );
  useHotkeys(
    NUMS.FOUR,
    () => {
      setSlotTime("00:60:00");
    },
    [slotTime]
  );
  useHotkeys(
    HOTKEYS.DAY_VIEW,
    () => {
      setTypeCal("timeGridDay");
    },
    [typeCal]
  );
  useHotkeys(
    HOTKEYS.WEEK_VIEW,
    () => {
      setTypeCal("timeGridWeek");
    },
    [typeCal]
  );
  useHotkeys(
    ARROW_KEYS.ARROW_LEFT,
    () => {
      minusWeek();
    },
    [typeCal]
  );
  useHotkeys(
    ARROW_KEYS.ARROW_RIGHT,
    () => {
      addWeek();
    },
    [typeCal]
  );

  const copyScheduleToClipboard = (message: any) => {
    toast.promise(
      navigator.clipboard.writeText(message),

      {
        loading: "copying..",
        success: <b>copied to clipboard!</b>,
        error: <b>could not save.</b>,
      },
      {
        duration: 4000,
        style: {
          fontSize: "12px",
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.primary,
        },
      }
    );
    setTimes("");
  };

  const updateTodoStateHelper = (
    oldTodo: any,
    completed: boolean,
    deleted: boolean
  ) => {
    toast.promise(
      updateTodoState(oldTodo, completed, deleted, todos, setTodos),
      {
        loading: "updating todo..",
        success: <b>todo saved!</b>,
        error: <b>Could not save.</b>,
      },
      {
        duration: 4000,
        style: {
          fontSize: "12px",
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.primary,
        },
      }
    );
  };
  const theme = useTheme();
  const { themeValue } = useContext(ThemeContext);
  function renderEventContent(eventInfo: any) {
    let task = {};
    // let parent = {};
    let found = false;
    // console.log(todos);
    for (const todo of todos) {
      if (todo.uuid === eventInfo.event.id) {
        task = todo;
        break;
      }
      if (todo.todoReplicas) {
        for (const child of todo.todoReplicas) {
          if (child.uuid === eventInfo.event.id) {
            task = {
              categoryId: todo.categoryId,
              title: todo.title,
              duration: child.duration,
              completedAt: child.completedAt,
              dueAt: todo.dueAt,
              startTime: child.startTime,
              creating: true,
              uuid: child.uuid,
              todoId: child.todoId,
              reoccurring: todo.reoccurring,
            };
            // parent = todo;
            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
    }
    // console.log(selectedTask);
    // console.log(subtask, parent);
    // const categoryId = parent ? parent.categoryId : task.categoryId;
    // const taskTitle = parent ? parent.title : eventInfo.event.title;
    // const task = todos?.find((todo: any) => todo?.uuid === eventInfo.event.id);
    // console.log(task);
    const taskCategory =
      categories[
        categories.findIndex(
          (category: any) => category.uuid === task?.categoryId
        )
      ];
    const backgroundColor =
      categories[
        categories.findIndex(
          (category: any) => category.uuid === task?.categoryId
        )
      ]?.label;
    const selectedBackgroundColor =
      categories[
        categories.findIndex(
          (category: any) => category.uuid === selectedTask?.categoryId
        )
      ]?.label;

    const rgbaBackground = backgroundColor
      ? hexToRgbA(backgroundColor, 0.3)
      : theme.palette.primary.light;

    return (
      <div
        style={{
          alignItems: typeCal === "timeGridDay" ? "center" : "flex-start",
          width: "100%",
          height: "100%",
          margin: "0px",
          flex: 1,
          display: "inline-grid",
          borderRadius: "4px",
          overflow: "clip",
          paddingTop:
            typeCal === "timeGridDay" || !user?.compact ? "0px" : "8px",
          // backgroundColor: task?.completedAt
          //   ? theme.palette.success.light
          //   : theme.palette.primary.light,
          border: task?.completedAt ? null : `2px solid ${backgroundColor}`,
          backgroundColor: !task?.completedAt
            ? rgbaBackground
            : // `linear-gradient(${
            //     typeCal === "timeGridDay" ? "90deg" : "180deg"
            //   }, ${alpha("#FF5ACD", 0.0)} 70%, ${alpha(
            //     `${backgroundColor}`,
            //     0.7
            //   )}140%)`
            //alpha(`${backgroundColor}`, 0.7)
            typeCal === "timeGridDay" && task?.completedAt
            ? alpha(theme.palette.success.light, 0.3)
            : typeCal === "timeGridWeek" && task?.completedAt
            ? alpha(theme.palette.success.light, 0.3)
            : theme.palette.primary.light,
        }}
        onMouseEnter={() => {
          setHoveredTask(task);
        }}
        id="internal-event"
        onContextMenu={handleRightClick}
        onClick={(event: any) => {
          event.stopPropagation();

          // Check if the Shift key is pressed
          if (event.shiftKey) {
            console.log(task);
            updateTodoStateHelper(
              task,
              task?.completedAt ? false : true,
              false
            );
          } else {
            // Perform the default action when Shift key is not pressed
            setEditTodo(!editTodo);
            setSelectedTask(task);
            // handleTaskClick(event);
          }
        }}
        onDoubleClick={isMobile ? handleRightClick : null}
      >
        <ScheduleToDoBar
          data={
            task
              ? task
              : {
                  title: eventInfo.event.title,
                  duration: 30,
                  completedAt: null,
                  dueAt: null,
                  startTime: eventInfo.timeText,
                  creating: true,
                }
          }
          key={1}
          index={1}
          toolbar={false}
          handleDeleteToDo={handleDeleteTodoHelper}
          schedule={true}
          todos={todos}
          setTodos={setTodos}
          tapped={eventInfo.event.id === eventPicked}
          typeCal={typeCal}
          slotTime={slotTime}
        />
        {selectedTask ? (
          <EditToDoModal
            todo={selectedTask}
            todos={todos}
            setTodos={setTodos}
            openEdit={editTodo && selectedTask?.uuid === task?.uuid}
            setOpenEditTodo={setEditTodo}
            title={selectedTask?.title}
          />
        ) : null}
      </div>
    );
  }
  function diff_minutes(dt2: any, dt1: any) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  const handleDateSelect = (selectInfo: any) => {
    setSendSelected(selectInfo);
    setOpen(true);
    // clear date selection
  };

  const handleDrop = (info: any) => {
    console.log("dropping", info);
    // console.log(info);
    if (info.event.extendedProps?.reoccurring) {
      setReoccurringInfo(info);
      setEditReoccurringModal(true);
    } else if (info.draggedEl.id) {
      updateTodo(info);
    } else {
      updateTodoStartTime(info);
    }
  };

  const handleEventClick = (clickInfo: any) => {
    console.log("clicking");
    setEventPicked(
      clickInfo.event.id !== eventPicked ? clickInfo.event.id : null
    );
  };

  const handleEventChange = (changeInfo: any) => {
    console.log("change", changeInfo);
    if (changeInfo.event.extendedProps?.reoccurring) {
      setReoccurringInfo(changeInfo);
      setEditReoccurringModal(true);
    } else {
      const duration = diff_minutes(
        changeInfo.event.end,
        changeInfo.event.start
      );
      updateTodoStartTime(changeInfo, duration);
      changeInfo.revert();
    }
  };
  const handleEventChangeDrag = (changeInfo: any) => {
    console.log("change drag");
    const duration = diff_minutes(changeInfo.event.end, changeInfo.event.start);
    updateTodoStartTimeHelper(changeInfo, duration);
    changeInfo.revert();
  };
  const calendarRef = useRef();

  useEffect(() => {
    localStorage.setItem("scheduleType", typeCal);
    changeView(typeCal);
  }, [typeCal]);

  const changeView = (view: any) => {
    const API = getApi();

    API && API.changeView(view);
  };

  const minusWeek = (view: any) => {
    const API = getApi();
    API && API.prev();
  };

  const addWeek = (view: any) => {
    const API = getApi();
    API && API.next();
  };

  const getApi = () => {
    const { current: calendarDom } = calendarRef;
    //@ts-ignore
    setCalTitle(calendarDom.calendar?.currentData?.viewTitle);
    //@ts-ignore
    return calendarDom ? calendarDom.getApi() : null;
  };

  useEffect(() => {
    constructAvailableTimes().then((data) => {
      setTimes(data);
      // console.log(times);
    });
  }, [daysSelected]);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.BOX,
    drop: () => ({ name: "Dustbin" }),
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <MainbarContainer>
      <Modal
        title={aiModalTitle}
        open={aiSchedulerOpen}
        handleOpen={handleAiSchedulerOpen}
      >
        <AIScheduleSystem
          todos={todos}
          setTodos={setTodos}
          aiModalTitle={aiModalTitle}
          handleAiModalTitle={handleAiModalTitle}
          handleOpen={handleAiSchedulerOpen}
        />
      </Modal>
      <Modal
        title="Drag in files to autocreate tasks and events"
        open={pdfOpen}
        handleOpen={handlePdfOpen}
      >
        <DropZone />
      </Modal>
      <Modal
        title="Share times you are free!"
        open={shareAnchorEl}
        handleOpen={handleShareOpen}
      >
        <ShareScheduleFreeTimes
          daysSelected={daysSelected}
          setDaysSelected={setDaysSelected}
        />
        <ToolbarToDoCreateInput style={{ marginTop: "16px" }}>
          <div
            style={{
              fontSize: "10px",
              padding: "0px",
              // height: "100px",
              marginTop: "0px",
              whiteSpace: "pre-line",
            }}
            multiple
            ref={shareInputRef}
            aria-label="Demo input"
            placeholder={"tap on days to share schedule times"}
            onChange={(text) => {
              setTimes(text.currentTarget.value);
            }}
            autoFocus
          >
            {times}
          </div>
        </ToolbarToDoCreateInput>
        <div
          style={{
            width: "100%",
            justifyContent: "flex-end",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            marginTop: "24px",
          }}
        >
          <Button
            variant="text"
            color="secondary"
            sx={{
              backgroundColor: theme.palette.primary.light,
              height: "100%",
              "&:hover": {
                backgroundColor: theme.palette.secondary.light,
              },
            }}
            onClick={() => {
              copyScheduleToClipboard(times);
            }}
          >
            copy
          </Button>
        </div>
      </Modal>
      {editReoccurringModal ? (
        <StyledDialog
          open={editReoccurringModal}
          onClose={() => {
            setEditReoccurringModal(false);
          }}
          aria-labelledby="archive-task-modal"
          aria-describedby="modal-modal-description"
          style={{
            zIndex: 1,
            backgroundColor: "transparent",
            borderRadius: "12px",
          }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          <StyledBox
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          >
            <StyledDialogTopBar>
              <StyledHeader
                style={{
                  marginLeft: 0,
                }}
              >
                Warning!
              </StyledHeader>
              <StyledCloseButton
                onClick={() => {
                  setReoccurringInfo();
                  setReoccurringAll(true);
                  setEditReoccurringModal(false);
                  // console.log(reoccurringInfo);
                  // reoccurringInfo.revert();
                }}
              >
                <XIcon />
              </StyledCloseButton>
            </StyledDialogTopBar>
            <Stack flexDirection={"column"} display={"flex"}>
              <CalendarViewLabel>
                You are about to edit a reoccurring event! Do you want to edit
                all occurrences?
              </CalendarViewLabel>
              <Chip
                label="All"
                variant={reoccurringAll ? "outlined" : "filled"}
                onClick={() => setReoccurringAll(true)}
              />
              <Chip
                label="Only this one"
                variant={!reoccurringAll ? "outlined" : "filled"}
                onClick={() => setReoccurringAll(false)}
              />
            </Stack>

            <div
              style={{
                width: "100%",
                height: "32px",
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                marginTop: "24px",
              }}
            >
              <Stack flexDirection={"row"} alignItems={"center"}>
                <Button
                  variant="text"
                  color="secondary"
                  sx={{
                    backgroundColor: theme.palette.primary.light,
                    height: "100%",
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.light,
                    },
                  }}
                  onClick={() => {
                    let oldTime = new Date(reoccurringInfo.event.start);
                    let newTime = new Date(reoccurringInfo?.oldEvent?.start);

                    const duration =
                      oldTime.getTime() === newTime.getTime()
                        ? diff_minutes(
                            reoccurringInfo.event.end,
                            reoccurringInfo.event.start
                          )
                        : null;
                    console.log(duration);
                    updateTodoStartTime(reoccurringInfo, duration);
                    setReoccurringInfo();
                    setEditReoccurringModal(false);
                  }}
                >
                  save
                </Button>
              </Stack>
            </div>
          </StyledBox>
        </StyledDialog>
      ) : null}
      <Stack
        flexDirection={"row"}
        width="100%"
        alignItems={"center"}
        justifyContent="space-between"
        marginBottom={"12px"}
      >
        <StudyTypographyMediumTitle style={{ width: "100%" }}>
          {typeCal === "timeGridWeek" &&
          calendarRef?.current?.calendar?.currentData?.viewTitle
            ? calendarRef?.current?.calendar?.currentData?.viewTitle
            : `${moment(
                calendarRef?.current?.calendar?.currentData?.viewTitle
              ).format("ddd, MMMM Do")} ${currentTime}`}
        </StudyTypographyMediumTitle>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <div
            style={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
              width: "100%",
              justifyContent: "center",
              backgroundColor: theme.palette.primary.light,
              borderRadius: "4px",
              marginRight: "12px",
            }}
          >
            <Badge
              color={"primary"}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              overlap="circular"
            >
              <Tooltip title={"share free time slots"} placement={"top"}>
                <AutoAwesomeOutlined
                  sx={{
                    padding: "8px",
                    borderRadius: "4px",
                    width: "16px",
                    margin: "4px",
                    height: "16px",
                    // backgroundColor:
                    //   typeCal === "timeGridDay"
                    //     ? theme.palette.primary.main
                    //     : theme.palette.primary.light,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      cursor: "pointer",
                    },
                  }}
                  onClick={handleAiSchedulerOpen}
                />
              </Tooltip>
            </Badge>
          </div>
          <div
            style={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
              width: "100%",
              justifyContent: "center",
              backgroundColor: theme.palette.primary.light,
              borderRadius: "4px",
              marginRight: "12px",
            }}
          >
            <Badge
              color={"primary"}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              overlap="circular"
            >
              <Tooltip title={"share free time slots"} placement={"top"}>
                <Add
                  sx={{
                    padding: "8px",
                    borderRadius: "4px",
                    width: "16px",
                    margin: "4px",
                    height: "16px",
                    // backgroundColor:
                    //   typeCal === "timeGridDay"
                    //     ? theme.palette.primary.main
                    //     : theme.palette.primary.light,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      cursor: "pointer",
                    },
                  }}
                  onClick={handlePdfOpen}
                />
              </Tooltip>
            </Badge>
          </div>
          <div
            style={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
              width: "100%",
              justifyContent: "center",
              backgroundColor: theme.palette.primary.light,
              borderRadius: "4px",
              marginRight: "12px",
            }}
          >
            <Badge
              color={"primary"}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              overlap="circular"
            >
              <Tooltip title={"share free time slots"} placement={"top"}>
                <IosShare
                  sx={{
                    padding: "8px",
                    borderRadius: "4px",
                    width: "16px",
                    margin: "4px",
                    height: "16px",
                    // backgroundColor:
                    //   typeCal === "timeGridDay"
                    //     ? theme.palette.primary.main
                    //     : theme.palette.primary.light,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      cursor: "pointer",
                    },
                  }}
                  onClick={handleShareOpen}
                />
              </Tooltip>
            </Badge>
          </div>
          {!isMobile ? (
            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                backgroundColor: theme.palette.primary.light,
                borderRadius: "4px",
                marginRight: "12px",
              }}
            >
              <Badge
                badgeContent={NUMS.ONE}
                color={"primary"}
                invisible={!showBadge}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                overlap="circular"
              >
                <Box
                  sx={{
                    // padding: "8px",
                    borderRadius: "4px",
                    width: "32px",
                    margin: "4px",
                    height: "32px",
                    alignSelf: "center",
                    justifyContent: "center",
                    flex: 1,
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",

                    backgroundColor:
                      slotTime === "00:05:00"
                        ? theme.palette.primary.main
                        : theme.palette.primary.light,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setSlotTime("00:05:00");
                  }}
                >
                  <StudyTypographySmallTitle
                    style={{
                      display: "flex",
                      alignItems: "center",

                      alignSelf: "center",
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    5
                  </StudyTypographySmallTitle>
                </Box>
              </Badge>

              <Divider
                style={{
                  width: "2px",
                  backgroundColor: theme.palette.primary.light,
                  height: "20px",
                  borderRadius: "4px",
                  alignSelf: "center",
                }}
                orientation="vertical"
                flexItem
              />
              <Badge
                badgeContent={NUMS.TWO}
                color={"primary"}
                invisible={!showBadge}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                overlap="circular"
              >
                <Box
                  sx={{
                    // padding: "8px",
                    borderRadius: "4px",
                    width: "32px",
                    margin: "4px",
                    height: "32px",
                    alignSelf: "center",
                    justifyContent: "center",
                    flex: 1,
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",

                    backgroundColor:
                      slotTime === "00:15:00"
                        ? theme.palette.primary.main
                        : theme.palette.primary.light,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setSlotTime("00:15:00");
                  }}
                >
                  <StudyTypographySmallTitle
                    style={{
                      display: "flex",
                      alignItems: "center",

                      alignSelf: "center",
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    15
                  </StudyTypographySmallTitle>
                </Box>
              </Badge>

              <Divider
                style={{
                  width: "2px",
                  backgroundColor: theme.palette.primary.light,
                  height: "20px",
                  borderRadius: "4px",
                  alignSelf: "center",
                }}
                orientation="vertical"
                flexItem
              />
              <Badge
                badgeContent={NUMS.THREE}
                color={"primary"}
                invisible={!showBadge}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                overlap="circular"
              >
                <Box
                  sx={{
                    // padding: "8px",
                    borderRadius: "4px",
                    width: "32px",
                    margin: "4px",
                    height: "32px",
                    alignSelf: "center",
                    justifyContent: "center",
                    flex: 1,
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",

                    backgroundColor:
                      slotTime === "00:30:00"
                        ? theme.palette.primary.main
                        : theme.palette.primary.light,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setSlotTime("00:30:00");
                  }}
                >
                  <StudyTypographySmallTitle
                    style={{
                      display: "flex",
                      alignItems: "center",

                      alignSelf: "center",
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    30
                  </StudyTypographySmallTitle>
                </Box>
              </Badge>

              <Divider
                style={{
                  width: "2px",
                  backgroundColor: theme.palette.primary.light,
                  height: "20px",
                  borderRadius: "4px",
                  alignSelf: "center",
                }}
                orientation="vertical"
                flexItem
              />
              {/* <Badge
              badgeContent={NUMS.FOUR}
              color={"primary"}
              invisible={!showBadge}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              overlap="circular"
            >
              <Box
                sx={{
                  // padding: "8px",
                  borderRadius: "4px",
                  width: "32px",
                  margin: "4px",
                  height: "32px",
                  alignSelf: "center",
                  justifyContent: "center",
                  flex: 1,
                  display: "flex",
                  textAlign: "center",
                  alignItems: "center",

                  backgroundColor:
                    slotTime === "00:60:00"
                      ? theme.palette.primary.main
                      : theme.palette.primary.light,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  setSlotTime("00:60:00");
                }}
              >
                <StudyTypographyMediumTitle
                  style={{
                    display: "flex",
                    alignItems: "center",

                    alignSelf: "center",
                    height: "100%",
                    justifyContent: "center",
                  }}
                >
                  60
                </StudyTypographyMediumTitle>
              </Box>
            </Badge> */}
            </div>
          ) : null}
          {isMobile ? null : (
            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                backgroundColor: theme.palette.primary.light,
                borderRadius: "4px",
                marginRight: "12px",
              }}
            >
              <Badge
                badgeContent={HOTKEYS.DAY_VIEW}
                color={"primary"}
                invisible={!showBadge}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                overlap="circular"
              >
                <ViewDayRounded
                  sx={{
                    padding: "8px",
                    borderRadius: "4px",
                    width: "16px",
                    margin: "4px",
                    height: "16px",
                    backgroundColor:
                      typeCal === "timeGridDay"
                        ? theme.palette.primary.main
                        : theme.palette.primary.light,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setTypeCal("timeGridDay");
                  }}
                />
              </Badge>

              <Divider
                style={{
                  width: "2px",
                  backgroundColor: theme.palette.primary.light,
                  height: "20px",
                  borderRadius: "4px",
                  alignSelf: "center",
                }}
                orientation="vertical"
                flexItem
              />
              <Badge
                badgeContent={HOTKEYS.WEEK_VIEW}
                color={"primary"}
                invisible={!showBadge}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                overlap="circular"
              >
                <ViewWeekRounded
                  sx={{
                    margin: "4px",
                    padding: "8px",
                    width: "16px",
                    height: "16px",
                    backgroundColor:
                      typeCal === "timeGridWeek"
                        ? theme.palette.primary.main
                        : theme.palette.primary.light,

                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setTypeCal("timeGridWeek");
                  }}
                />
              </Badge>
            </div>
          )}
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              backgroundColor: theme.palette.primary.light,
              borderRadius: "4px",
              height: "32px",
              padding: "4px",
              marginRight: "12px",
            }}
          >
            <Badge
              badgeContent={HOTKEYS.OPEN_TODAY}
              invisible={!showBadge}
              color={"primary"}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              overlap="circular"
            >
              <StudyTypographySmallSubtitle
                sx={{
                  backgroundColor: theme.palette.primary.light,
                  // padding: "6px",
                  margin: "4px 2px 4px 2px",
                  padding: "8px",
                  // width: "16px",
                  // height: "8px",
                  borderRadius: "4px",
                  textTransform: "none",
                  alignSelf: "center",
                  textAlign: "center",
                  alignItems: "center",
                  display: "flex",
                  "&:hover": {
                    cursor: "pointer",
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
                onClick={() => {
                  todayWeek();
                }}
              >
                Today
              </StudyTypographySmallSubtitle>
            </Badge>
          </div>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{
              backgroundColor: theme.palette.primary.light,
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            {/* <Badge
              badgeContent={"⭠"}
              color={"primary"}
              invisible={!showBadge}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              overlap="circular"
            > */}
            <CalendarLeftArrowIcon
              style={{
                marginRight: "4px",
                backgroundColor: theme.palette.primary.main,
              }}
              onClick={() => {
                minusWeek();
              }}
            />
            {/* </Badge>
            <Badge
              badgeContent={"⭢"}
              color={"primary"}
              invisible={!showBadge}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              overlap="circular"
            > */}
            <CalendarRightArrowIcon
              style={{ backgroundColor: theme.palette.primary.main }}
              onClick={() => {
                addWeek();
              }}
            />
            {/* </Badge> */}
          </Stack>
        </Stack>
      </Stack>

      {/* <StudyTypographyMegaSubtitle style={{ marginBottom: "12px" }}>
        Click or drag to add todos to schedule
      </StudyTypographyMegaSubtitle> */}

      <div
        style={{
          backgroundColor: theme.palette.primary.main,
          height: "100px",
          width: "100%",
          // position: "absolute",

          // flex: 1,
        }}
      >
        <FullCalendar
          //@ts-ignore
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={false}
          initialView={typeCal}
          eventLongPressDelay={500}
          scrollTime={"08:00:00"}
          dayHeaders={typeCal === "timeGridDay" ? false : true}
          dayHeaderClassNames={"calHeader"}
          editable={true}
          allDaySlot={false}
          nowIndicator={true}
          selectable={true}
          droppable={true}
          selectMirror={false}
          dayMaxEvents={true}
          eventMinHeight={15}
          eventShortHeight={15}
          // drop={handleDrop}
          eventReceive={handleDrop}
          eventChange={handleEventChange}
          // eventDrop={handleEventChange}
          slotDuration={slotTime}
          snapDuration={"00:15:00"}
          // eventOverlap={false}
          eventBorderColor={theme.palette.primary.light}
          // eventTextColor={theme.palette.text.primary}
          eventBackgroundColor={theme.palette.primary.light}
          weekends={true}
          fixedMirrorParent={document.body}
          select={handleDateSelect}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          events={events}
          weekText={"W"}
        />
      </div>
      <StyledDialog
        open={open}
        onClose={onClose}
        aria-labelledby="archive-task-modal"
        aria-describedby="modal-modal-description"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <StyledBox>
          <StyledDialogTopBar>
            <StyledHeader
              style={{
                marginLeft: 0,
              }}
            >
              {`Enter new ${typeInput}`}
            </StyledHeader>
            <StyledCloseButton onClick={onClose}>
              <XIcon />
            </StyledCloseButton>
          </StyledDialogTopBar>
          {/* <CalendarViewStatusNotifier style={{ marginBottom: "12px" }}> */}

          {/* </CalendarViewStatusNotifier> */}
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            borderRadius={"4px"}
            marginBottom={"12px"}
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              padding="8px"
              borderRadius={"4px"}
              sx={{
                backgroundColor: theme.palette.primary.light,
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                setTypeInput("todo");
              }}
            >
              <motion.div
                whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
                whileHover={{ rotate: 10 }}
                transition={{
                  type: "spring",
                  stiffness: 200,
                  damping: 8,
                }}
                style={{
                  // marginLeft: "12px",
                  alignSelf: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <HoverPointerStyle
                  width={"18px"}
                  height={"18px"}
                  viewBox={`0 0 24 24`}
                  fill={
                    typeInput === "todo"
                      ? theme.palette.success.main
                      : theme.palette.secondary.light
                  }
                  onClick={() => {
                    setTypeInput("todo");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" rx="4" />
                  <path
                    d="M9.32199 15.2378L6.42256 12.3384C6.09669 12.0125 5.57028 12.0125 5.2444 12.3384C4.91853 12.6643 4.91853 13.1907 5.2444 13.5166L8.73709 17.0092C9.06297 17.3351 9.58938 17.3351 9.91525 17.0092L18.7556 8.1689C19.0815 7.84303 19.0815 7.31662 18.7556 6.99074C18.4297 6.66487 17.9033 6.66487 17.5774 6.99074L9.32199 15.2378Z"
                    fill={theme.palette.secondary.light}
                  />
                </HoverPointerStyle>
              </motion.div>
              <StudyTypographySmallText
                style={{
                  marginLeft: "12px",
                }}
              >
                create todo
              </StudyTypographySmallText>
            </Stack>

            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              padding="8px"
              marginLeft={"12px"}
              borderRadius={"4px"}
              sx={{
                backgroundColor: theme.palette.primary.light,
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                setTypeInput("event");
              }}
            >
              <motion.div
                whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
                whileHover={{ rotate: 10 }}
                transition={{
                  type: "spring",
                  stiffness: 200,
                  damping: 8,
                }}
                style={{
                  // marginLeft: "12px",
                  alignSelf: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <HoverPointerStyle
                  width={"18px"}
                  height={"18px"}
                  viewBox={`0 0 24 24`}
                  fill={
                    typeInput === "event"
                      ? theme.palette.success.main
                      : theme.palette.secondary.light
                  }
                  onClick={() => {
                    setTypeInput("event");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" rx="4" />
                  <path
                    d="M9.32199 15.2378L6.42256 12.3384C6.09669 12.0125 5.57028 12.0125 5.2444 12.3384C4.91853 12.6643 4.91853 13.1907 5.2444 13.5166L8.73709 17.0092C9.06297 17.3351 9.58938 17.3351 9.91525 17.0092L18.7556 8.1689C19.0815 7.84303 19.0815 7.31662 18.7556 6.99074C18.4297 6.66487 17.9033 6.66487 17.5774 6.99074L9.32199 15.2378Z"
                    fill={theme.palette.secondary.light}
                  />
                </HoverPointerStyle>
              </motion.div>

              <StudyTypographySmallText
                style={{
                  marginLeft: "12px",
                }}
              >
                create event
              </StudyTypographySmallText>
            </Stack>
          </Stack>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            borderRadius={"4px"}
            marginBottom={"12px"}
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              padding="8px"
              borderRadius={"4px"}
              sx={{
                backgroundColor: theme.palette.primary.light,
                "&:hover": { cursor: "pointer" },
              }}
              onClick={handleCategoryClick}
            >
              <Box
                sx={{
                  // whiteSpace: "nowrap",
                  width: "14px",
                  height: "14px",
                  border: `2px solid ${pickedCategory?.label}`,
                  borderRadius: "4px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={handleCategoryClick}
              />
              <StudyTypographySmallText
                style={{
                  marginLeft: "12px",
                }}
              >
                {pickedCategory?.name}
              </StudyTypographySmallText>
            </Stack>

            <ProfileBlockPopper
              open={categoryOpen}
              anchorEl={categoryAnchorEl}
              placement={"bottom-start"}
              modifiers={[{ name: "arrow", enabled: true }]}
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                margin: 0,
                paddingRight: 0,
                paddingTop: "8px",
                zIndex: 10000,
              }}
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
            >
              <motion.div
                style={{
                  alignItems: "center",
                  display: "inline-grid",
                  justifyContent: "center",
                  backgroundColor: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.secondary.light}`,
                  padding: "4px",
                  borderRadius: "4px",
                }}
                variants={container}
              >
                {categories.map((category: any) => (
                  <Stack
                    flexDirection={"row"}
                    alignItems="center"
                    sx={{
                      padding: "8px 12px 8px 12px",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.light,
                        borderRadius: "4px",
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      setCategoryAnchorEl(null);
                      setPickedCategory(category);
                    }}
                  >
                    {category?.label === "Google Calendar" ? (
                      <div
                        onClick={handleCategoryClick}
                        style={{
                          alignSelf: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <CalendarTodayOutlined
                          style={{
                            padding: 0,
                            margin: 0,
                            width: "18px",
                            height: "18px",
                            marginRight: "12px",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "14px",
                          height: "14px",
                          border: `2px solid ${category?.label}`,
                          borderRadius: "4px",
                          marginRight: "12px",
                        }}
                      ></div>
                    )}
                    <motion.div variants={item} key={category?.uuid}>
                      <StudyTypographyMediumSubtitle
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {category?.name}
                      </StudyTypographyMediumSubtitle>
                    </motion.div>
                  </Stack>
                ))}
              </motion.div>
            </ProfileBlockPopper>
          </Stack>
          {due ? (
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              borderRadius={"4px"}
              marginBottom={"12px"}
              style={{ backgroundColor: theme.palette.primary.main }}
            >
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                padding="8px"
                borderRadius={"4px"}
                sx={{
                  backgroundColor: theme.palette.primary.light,
                  "&:hover": { cursor: "pointer" },
                }}
              >
                <div
                  style={{
                    alignSelf: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <CalendarTodayOutlined
                    style={{
                      padding: 0,
                      margin: 0,
                      width: "18px",
                      height: "18px",
                      marginRight: "12px",
                      color: theme.palette.error.dark,
                    }}
                  />
                </div>
                <StudyTypographySmallText>
                  {moment(due).format("M/D h:mma")}
                </StudyTypographySmallText>
              </Stack>
            </Stack>
          ) : null}

          <ToolbarToDoCreateInput style={{ marginBottom: "10px" }}>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                setOpen(false);
                let calendarApi = sendSelected.view.calendar;
                calendarApi.unselect();
                createTodoHelper(title, sendSelected);
                onClose();
              }}
              style={{ width: "100%", flex: 1, marginRight: "12px" }}
            >
              <DateCollector
                handleSetDue={handleSetDue}
                handleSetCategory={handleSetCategory}
                handleSetTitle={handleSetTitle}
                handleSetDueText={handleSetDueText}
                value={title}
                placeholder={`Add a ${typeInput}...`}
              />
            </form>

            <AddIcon
              clicked={() => {
                setOpen(false);
                let calendarApi = sendSelected.view.calendar;
                // calendarApi.unselect();
                createTodoHelper(title, sendSelected);
                onClose();
              }}
            />
          </ToolbarToDoCreateInput>
          {temporaryDue || temporaryCategory ? (
            <StudyTypographyMediumSubtitle>
              Suggestions
            </StudyTypographyMediumSubtitle>
          ) : null}
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginTop: temporaryCategory || temporaryDue ? "12px" : "0px",
            }}
          >
            {temporaryDue ? (
              <BoxedText
                text={`${moment(temporaryDue).format("M/D hh:mma")}`}
                compact={true}
                makeChange={true}
                color={theme.palette.error.dark}
                handleChange={(text: string) => {
                  const newText = title.replace(temporaryDueText, "").trim();
                  console.log(title);
                  console.log(temporaryDueText);
                  console.log(newText);
                  setTemporaryDue(null);
                  setTitle(newText);
                  setDue(temporaryDue);
                }}
              />
            ) : null}
            {temporaryDue && temporaryCategory ? (
              <div style={{ width: "0px", marginRight: "12px" }} />
            ) : null}
            {temporaryCategory ? (
              <BoxedText
                text={temporaryCategory?.name}
                compact={true}
                makeChange={true}
                color={temporaryCategory?.label}
                handleChange={(text: string) => {
                  const newText = title.replace(text, "").trim();
                  setTitle(newText);
                  setTemporaryCategory(null);
                  setPickedCategory(temporaryCategory);
                }}
              />
            ) : null}
          </div>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            borderRadius={"4px"}
            marginTop={"12px"}
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            <CalendarViewWarningIcon
              style={{
                width: "18px",
                height: "18px",
                // color: theme.palette.success.main,
              }}
            />
            <CalendarViewLabel>
              Only todos will show up on todo list!
            </CalendarViewLabel>
          </Stack>
        </StyledBox>
      </StyledDialog>
      {isMobile ? <div style={{ height: "80px", width: "100%" }}></div> : null}
    </MainbarContainer>
  );
};
