import { Badge, useTheme } from "@mui/material";
import { HoverPointerStyle } from "./HoverPointerStyle";
import { HotkeyContext } from "../../utils/contexts";
import { useContext } from "react";
import { HOTKEYS } from "../../utils/hotkeyHelper";
import {
  STUDYO_PRIMARY_GRADIENT,
  STUDYO_SECONDARY_GRADIENT,
} from "../../utils/constants";

export function SearchIcon(props: {
  active: boolean;
  clicked: Function;
  width?: string;
  height?: string;
}) {
  const theme = useTheme();

  return (
    <HoverPointerStyle
      width={props?.width || "32"}
      height={props?.height || "32"}
      viewBox={`0 0 32 32`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        props.clicked();
      }}
    >
      {props.active ? (
        <rect
          width={props?.width || "32"}
          height={props?.height || "32"}
          rx="4.125"
          fill="url(#paint0_linear_59_2302)"
        />
      ) : (
        <rect
          width={props?.width || "32"}
          height={props?.height || "32"}
          rx="4.125"
          fill={theme.palette.primary.light}
        />
      )}

      {/* <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      {/* <rect width="32" height="32" rx="4" fill="#F5F5F7"/> */}
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.64124 14.6665C9.64124 11.6289 12.1037 9.1665 15.1412 9.1665C18.1788 9.1665 20.6412 11.6289 20.6412 14.6665C20.6412 17.7041 18.1788 20.1665 15.1412 20.1665C12.1037 20.1665 9.64124 17.7041 9.64124 14.6665ZM15.1412 8.1665C11.5514 8.1665 8.64124 11.0767 8.64124 14.6665C8.64124 18.2564 11.5514 21.1665 15.1412 21.1665C16.7612 21.1665 18.2428 20.5739 19.381 19.5936C19.4012 19.6267 19.4257 19.658 19.4543 19.6867L23.4543 23.6867C23.6496 23.882 23.9662 23.882 24.1615 23.6867C24.3567 23.4915 24.3567 23.1749 24.1615 22.9796L20.1615 18.9796C20.1328 18.9509 20.1015 18.9265 20.0683 18.9062C21.0486 17.768 21.6412 16.2865 21.6412 14.6665C21.6412 11.0767 18.7311 8.1665 15.1412 8.1665Z"
        fill={props.active ? "white" : "#777777"}
      />
      {/* </svg> */}

      <defs>
        <linearGradient
          id="paint0_linear_59_2302"
          x1="0"
          y1="0"
          x2="32"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={STUDYO_PRIMARY_GRADIENT} />
          <stop offset="1" stopColor={STUDYO_SECONDARY_GRADIENT} />
        </linearGradient>
      </defs>
    </HoverPointerStyle>
  );
}
