import { useTheme } from "@mui/material";
import {
  STUDYO_PRIMARY_GRADIENT,
  STUDYO_SECONDARY_GRADIENT,
} from "../../utils/constants";
import { HoverPointerStyle } from "./HoverPointerStyle";

export function ToDoListIcon(props: {
  active: boolean;
  clicked: Function;
  width?: string;
  height?: string;
}) {
  const theme = useTheme();

  return (
    <HoverPointerStyle
      width={props?.width || "30"}
      height={props?.height || "30"}
      viewBox={`0 0 32 32`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        props.clicked();
      }}
    >
      {props.active ? (
        <rect
          width={props?.width || "32"}
          height={props?.height || "32"}
          rx="4.125"
          fill="url(#paint0_linear_59_2302)"
        />
      ) : (
        <rect
          width={props?.width || "32"}
          height={props?.height || "32"}
          rx="4.125"
          fill={theme.palette.primary.light}
        />
      )}

      <path
        d="M17.9716 7.75214H11.5716C11.1472 7.75214 10.7403 7.92071 10.4402 8.22077C10.1402 8.52082 9.97159 8.92779 9.97159 9.35214V22.1521C9.97159 22.5765 10.1402 22.9834 10.4402 23.2835C10.7403 23.5836 11.1472 23.7521 11.5716 23.7521H21.1716C21.5959 23.7521 22.0029 23.5836 22.303 23.2835C22.603 22.9834 22.7716 22.5765 22.7716 22.1521V12.5521L17.9716 7.75214Z"
        stroke={props.active ? "white" : "#777777"}
        strokeLinecap="round"
      />
      <path
        d="M17.9716 7.75214V12.5521H22.7716"
        stroke={props.active ? "white" : "#777777"}
        strokeLinecap="round"
      />
      <path
        d="M19.5747 16.5529H13.1747"
        stroke={props.active ? "white" : "#777777"}
        strokeLinecap="round"
      />
      <path
        d="M19.5747 19.7521H13.1747"
        stroke={props.active ? "white" : "#777777"}
        strokeLinecap="round"
      />
      <path
        d="M14.7747 13.3537H13.9747H13.1747"
        stroke={props.active ? "white" : "#777777"}
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_59_2302"
          x1="0"
          y1="0"
          x2="32"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={STUDYO_PRIMARY_GRADIENT} />
          <stop offset="1" stopColor={STUDYO_SECONDARY_GRADIENT} />
        </linearGradient>
      </defs>
    </HoverPointerStyle>
  );
}
