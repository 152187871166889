import { gql } from "@apollo/client";
import { client } from "../../utils/apollo";

// export const CREATE_USER = gql`
//     mutation CreateUser($email: String!, $firstName: String!, $lastName: String!) {
//         createUser(email: $email, firstName: $firstName, lastName: $lastName) {
//             id
//         }
//     }
// `;

// export const CREATE_USER = gql`
//   mutation CreateUser(
//     $email: String!
//     $firstName: String!
//     $lastName: String!
//     $category: String!
//     $categoryId: String!,
//     $categoryLabel: String!,
//   ) {
//     createUser(
//       email: $email
//       firstName: $firstName
//       lastName: $lastName
//       category: $category
//       categoryId: $categoryId
//       categoryLabel: $categoryLabel
//     ) {
//       id
//     }
//   }
// `;

export const CREATE_USER = gql`
mutation CreateUser($email: String!, $firstName: String!, $lastName: String!, $categoryId: String!, $categories: [CategoryInput!]!) {
  createUser(email: $email, firstName: $firstName, lastName: $lastName, categoryId: $categoryId, categories: $categories) {
    id
  }
}
`

// mutation CreateUser($email: String!, $firstName: String!, $lastName: String!) {
//     createUser(email: $email, firstName: $firstName, lastName: $lastName) {
//         id
//     }
// }

export const UPDATE_USER = gql`
mutation UpdateUser($updateUserId: String!, $firstName: String, $lastName: String, $email: String, $displayTodoName: Boolean, $privateTodos: Boolean, $publicProfile: Boolean, $soundEffects: Boolean, $syncToken: String, $imageURL: String) {
  updateUser(id: $updateUserId, firstName: $firstName, lastName: $lastName, email: $email, displayTodoName: $displayTodoName, privateTodos: $privateTodos, publicProfile: $publicProfile, soundEffects: $soundEffects, syncToken: $syncToken, imageURL: $imageURL) {
    id
  }
}
`

// export const UPDATE_USER_FIRENDS = gql`
//     mutation UpdateUserFriends($updateUserFriendsId: String!, $addFriendIds: [String], $deleteFriendIds: [String]) {
//         updateUserFriends(id: $updateUserFriendsId, addFriendIds: $addFriendIds, deleteFriendIds: $deleteFriendIds) {
//             id
//         }
//     }
// `
export const UPDATE_USER_BULK_TODOS = gql`
mutation UpdateUserTodosBulk($userId: String!, $addTodoIds: [String]) {
  updateUserTodosBulk(userId: $userId, addTodoIds: $addTodoIds) {
    id
  }
}
`

export const UPDATE_USER_TOKEN= gql`
mutation UpdateUserToken($userId: String!, $rtoken: String!) {
  updateUserToken(userId: $userId, rtoken: $rtoken) {
    id
  }
}
`


export const UPDATE_USER_THEME = gql`
  mutation UpdateUserTheme(
    $updateUserThemeId: String!
    $lightSetting: LightModeInput!
  ) {
    updateUserTheme(id: $updateUserThemeId, lightSetting: $lightSetting) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($deleteUserId: String!) {
    deleteUser(id: $deleteUserId) {
      id
    }
  }
`;

export const ADD_USER_CATEGORY = gql`
  mutation AddCategory($userId: String!, $category: String!, $uuid: String!, $categoryLabel: String!) {
    addCategory(userId: $userId, category: $category, uuid: $uuid, categoryLabel: $categoryLabel) {
      id
    }
  }
`;

export const UPDATE_USER_PERMISSIONS = gql`
mutation UpdateUserPermissions($userId: String!, $rtoken: String!, $calendar: Boolean, $gmail: Boolean, $calendars: [GoogleCategoryInput!], $calendarIds: [String!]) {
  updateUserPermissions(userId: $userId, rtoken: $rtoken, calendar: $calendar, gmail: $gmail, calendars: $calendars, calendarIds: $calendarIds) {
    id
  }
}
`

export const UPDATE_USER_SYNC = gql`
mutation UpdateUser($updateUserId: String!, $syncToken: String) {
    updateUser(id: $updateUserId, syncToken: $syncToken) {
        id
    }
}
`

export const UPDATE_USER_GOOGLE_CALENDARS = gql`
mutation UpdateUserGoogleCalenders($userId: String!, $calendars: [String!]) {
    updateUserGoogleCalenders(userId: $userId, calendars: $calendars) {
        id
    }
} 
`

export const USER_EVENT_INVITE = gql`
mutation UpdateUserTodoInvites($userId: String!, $todoId: String!, $accept: Boolean!) {
  updateUserTodoInvites(userId: $userId, todoId: $todoId, accept: $accept) {
    id
  }
}
`

export const USER_GROUP_INVITE = gql`
mutation UpdateUserGroupInvites($userId: String!, $groupId: String!, $accept: Boolean!) {
  updateUserGroupInvites(userId: $userId, groupId: $groupId, accept: $accept) {
    id
  }
}
`;

export const LEAVE_GROUP = gql`
mutation LeaveGroup($userId: String!, $groupId: String!) {
  leaveGroup(userId: $userId, groupId: $groupId) {
    id
  }
}
`;

export const LEAVE_EVENT = gql`
mutation LeaveTodo($userId: String!, $todoId: String!) {
  leaveTodo(userId: $userId, todoId: $todoId) {
    id
  }
}
`;

export const groupRequest = async (group: any, accept: boolean, user: any, setUser: any, groupInvites: any, setGroupInvites: any) => {
  let groupUsers = group.users ? [...group.users] : [];
  let newGroup = {
    id: group.id,
    name: group.name,
    uuid: group.uuid,
    users: [{ __typeName: "User", id: user.id }, ...groupUsers],
    __typename: "Group",
  };
  // console.log(newGroup)
  let newGroupInvites = [...groupInvites];
  setGroupInvites(
    newGroupInvites?.filter(function (item) {
      return item.uuid !== group.uuid;
    })
  );
  if (accept) {
    let newUser = { ...user };
    let newGroups = [newGroup, ...newUser.groups];
    newUser.groups = newGroups;
    setUser(newUser);
  }
  client.mutate({
    mutation: USER_GROUP_INVITE,
    variables: {
      userId: user.id,
      groupId: group.uuid,
      accept: accept,
    },
  });
}
