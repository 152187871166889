import { styled } from "@mui/material";
import { isMobile } from "react-device-detect";

export const MainContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  width: "100%",
  height: "100vh",
  flexDirection: "row",
  display: "flex",
  flex: 1,
  // position: "fixed",
  padding: "8px",
  boxSizing: "border-box",
}));

export const LeftMainContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  width: isMobile ? "100%" : "18%",
  flexDirection: "column",
  display: "flex",
  paddingTop: "16px",
  paddingBottom: "16px",
  justifyContent: "space-between",
  alignItems: "center",
  marginRight: isMobile ? "0px" : "12px",
}));

export const RightMainContainer = styled("div")(({ theme }) => ({
  width: isMobile ? "100%" : "82%",
  flexDirection: "column",
  display: "flex",
  //   margin: "6px",
  backgroundColor: theme.palette.primary.main,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
}));

export const LeftMainToolBox = styled("div")(({ theme }) => ({
  flexDirection: "row",
  display: "flex",
  //   width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "12px",
  padding: "12px 16px 12px 16px",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    cursor: "pointer",
  },
}));

export const RightMainDivider = styled("div")`
  width: 650px;
  height: 0px;

  /* LightActionColor */

  border-bottom: 3px dashed #3e4249;
`;
