import { CalendarTodayOutlined, Lock, LockOutlined } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  ClickAwayListener,
  Tooltip,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  ADD_DUE,
  ADD_START,
  COMPLETE_TODO,
  DELETE_TODO,
  EDIT_TODO,
  MAINBAR_RIGHT_CLICK,
  REMOVE_START,
  hexToRgbA,
  timeDuration,
} from "../../utils/constants";
import { HoverTypography } from "../HoverTypography";
import { CheckIcon } from "../Icons/CheckIcon";
import DurationBox from "../glovebox/DurationBox";
import { StudyTypographyMediumSubtitle } from "../glovebox/StudyTypography";
import {
  ProfileBlockPopper,
  SwitchCategoryContainer,
  ToolbarBackground,
  ToolbarToDoBox,
  ToolbarToDoDurationText,
  ToolbarToDoLeftSide,
  ToolbarToDoSubtitle,
  ToolbarToDoTitle,
} from "../toolbar/styles";

export const AIToDoBlock = ({
  data,
  selected,
  lock,
}: // updateTodoList,
{
  data: any;
  selected: any;
  lock?: boolean;
}) => {
  const [editTodo, setEditTodo] = useState(false);
  const [editTodoText, setEditTodoText] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // const { user, setUser } = useContext(UserContext);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")!) || []
  );
  const [categories, setCategories] = useState<any>(
    JSON.parse(localStorage.getItem("categories")!) || []
  );
  useEffect(() => {
    localStorage.setItem("categories", JSON.stringify(categories));
  }, [categories]);
  // const [todos, setTodos] = useState(JSON.parse(localStorage.getItem('todos')!) || [])
  // // const { id: userId } = useUserContext();
  const [value, setValue] = useState<any>(moment().format("HH:MM"));
  const [checked, setChecked] = useState(false);
  const userId = localStorage.getItem("userId");
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [dateAnchorEl, setDateAnchorEl] = useState<null | HTMLElement>(null);
  const handleClickDate = (event: React.MouseEvent<HTMLElement>) => {
    setDateAnchorEl(dateAnchorEl ? null : event.currentTarget);
  };

  const [categoryAnchorEl, setCategoryAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const handleCategoryClick = (event: React.MouseEvent<HTMLElement>) => {
    setCategoryAnchorEl(categoryAnchorEl ? null : event.currentTarget);
  };
  const categoryOpen = Boolean(categoryAnchorEl);
  const theme = useTheme();

  const category =
    categories[
      categories.findIndex(
        (category: any) => data?.categoryId === category?.uuid
      )
    ];
  const handleFocus = (event: any) => event.target.select();

  const rgbaBackground = selected
    ? hexToRgbA(lock ? theme.palette.error.light : theme.palette.success.main)
    : theme.palette.primary.light;
  const rgbaBorder = selected
    ? `2px solid ${
        lock
          ? hexToRgbA(theme.palette.error.light)
          : hexToRgbA(theme.palette.success.main)
      }`
    : "0px solid white";

  return (
    <ToolbarBackground style={{ backgroundColor: "rgba(0,0,0,0)" }}>
      <ToolbarToDoBox
        key={data?.uuid}
        style={{
          height: "32px",
          marginBottom: "4px",
          border: rgbaBorder,
          borderRadius: "4px",
          alignItems: "center",
          padding: user?.compact ? "2px 8px 2px 8px" : "8px",
          backgroundColor: rgbaBackground,
          cursor: "pointer",
        }}
      >
        <ToolbarToDoLeftSide>
          {editTodo ? (
            <ClickAwayListener
              onClickAway={() => {
                setEditTodo(false);
              }}
            >
              <input
                autoFocus
                suppressContentEditableWarning={true}
                contentEditable={true}
                onFocus={handleFocus}
                style={{
                  border: "0px",
                  backgroundColor: theme.palette.primary.light,
                  width: "100%",
                  height: "24px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "16px",
                  outlineWidth: 0,
                  borderRadius: "4px",
                  color: theme.palette.text.primary,
                }}
                placeholder={"text new todo.."}
                type={"text"}
                name="title"
                value={editTodoText}
                onChange={(text) => {
                  setEditTodoText(text.target.value);
                }}
              ></input>
            </ClickAwayListener>
          ) : (
            <div
              style={{
                marginLeft: "8px",
                textAlign: "left",
                flexShrink: "0",
                overflow: "hidden",
              }}
            >
              <ToolbarToDoTitle
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  textAlign: "flex-start",
                }}
                onDoubleClick={() => {
                  setEditTodo(true);
                  setEditTodoText(data?.title);
                }}
              >
                {data?.title}
              </ToolbarToDoTitle>
              <Breadcrumbs>
                {moment(data?.startTime).date() === new Date().getDate() ? (
                  <ToolbarToDoSubtitle>
                    {`${moment(data?.startTime).format("h:mma")} - ${moment(
                      data?.startTime
                    )
                      .add(data?.duration, "minutes")
                      .format("h:mma")}`}
                  </ToolbarToDoSubtitle>
                ) : moment(data?.startTime) &&
                  moment(data?.startTime).isValid() ? (
                  <ToolbarToDoSubtitle>
                    {moment(data?.startTime).format("M/DD")}
                  </ToolbarToDoSubtitle>
                ) : null}
                {moment(data?.dueAt).date() === new Date().getDate() ? (
                  <ToolbarToDoSubtitle
                    style={{ color: theme.palette.error.light }}
                  >
                    {`${moment(data?.startTime).format("h:mma")} - ${moment(
                      data?.startTime
                    )
                      .add(data?.duration, "minutes")
                      .format("h:mma")}`}
                  </ToolbarToDoSubtitle>
                ) : (data?.dueAt && moment(data?.dueAt).isValid()) ||
                  (moment(data?.dueAt) &&
                    !data?.completedAt &&
                    moment(data?.dueAt).isValid()) ? (
                  <ToolbarToDoSubtitle
                    style={{ color: theme.palette.error.light }}
                  >
                    {moment(data?.dueAt).format("M/DD")}
                  </ToolbarToDoSubtitle>
                ) : null}
              </Breadcrumbs>
            </div>
          )}
        </ToolbarToDoLeftSide>
        {editTodo ? (
          <form onSubmit={() => {}}>
            <Button
              variant="text"
              color="secondary"
              sx={{ textTransform: "none" }}
              onClick={() => {
                // updateTodo({
                //   variables: { title: editTodoText, uuid: data?.uuid },
                // });
                setEditTodo(false);
              }}
            >
              save
            </Button>
          </form>
        ) : (
          <ClickAwayListener
            onClickAway={() => {
              setAnchorEl(null);
            }}
          >
            <DurationBox
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              num={data?.duration}
              user={user}
            />
          </ClickAwayListener>
        )}

        <ProfileBlockPopper
          open={open}
          anchorEl={anchorEl}
          placement={"bottom-start"}
          modifiers={[{ name: "arrow", enabled: true }]}
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            margin: 0,
            paddingRight: 0,
            zIndex: 100,
            paddingTop: "8px",
          }}
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          <div
            style={{
              alignItems: "center",
              display: "inline-grid",
              justifyContent: "center",
              backgroundColor: theme.palette.primary.main,
              border: `1px solid ${theme.palette.secondary.light}`,
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            {timeDuration.map((time) => (
              <ToolbarToDoDurationText
                style={{
                  padding: "8px",
                  textAlign: "center",
                  color: theme.palette.text.primary,
                }}
                onClick={(event: any) => {
                  // updateTodo({
                  //   variables: { duration: time, uuid: data?.uuid },
                  // });
                  event?.stopPropagation();
                  setAnchorEl(null);
                }}
              >
                {time} min
              </ToolbarToDoDurationText>
            ))}
          </div>
        </ProfileBlockPopper>
        {lock && selected ? (
          <LockOutlined
            style={{
              whiteSpace: "nowrap",
              width: "16px",
              height: "16px",
              marginLeft: "12px",
            }}
          />
        ) : (
          <Tooltip
            title={
              categories.includes(category) ? category?.name : "no category"
            }
            placement={"top"}
          >
            <div
              onClick={handleCategoryClick}
              style={{
                whiteSpace: "nowrap",
                width: "14px",
                height: "14px",
                border: categories.includes(category)
                  ? `2px solid ${category?.label}`
                  : `2px solid ${theme.palette.text.primary}`,
                borderRadius: "4px",
                marginLeft: "12px",
              }}
            ></div>
          </Tooltip>
        )}
      </ToolbarToDoBox>
    </ToolbarBackground>
  );
};
