import { Box, useTheme } from "@mui/material";
import React from "react";
import { StudyTypographySmallSubtitle } from "./StudyTypography";
import { AddRounded } from "@mui/icons-material";

function BoxedText({
  text,
  compact,
  color,
  makeChange,
  handleChange,
}: {
  text: string;
  compact?: boolean;
  color?: string;
  makeChange?: boolean;
  handleChange?: any;
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.light,
        border: `1px solid ${color || theme.palette.primary.main}`,
        padding: "8px",
        borderRadius: "4px",
        textAlign: "center",
        maxLines: 1,
        WebkitLineClamp: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: compact ? "space-between" : "center",
        lineClamp: 1,
        // width: compact ? "120px" : "",
        "&:hover": {
          cursor: makeChange ? "pointer" : "default",
          backgroundColor: makeChange ? theme.palette.primary.main : "",
        },
      }}
      onClick={() => {
        if (makeChange) {
          handleChange(text);
        }
      }}
    >
      <StudyTypographySmallSubtitle>{text}</StudyTypographySmallSubtitle>
      {compact ? (
        <AddRounded sx={{ width: "16px", marginLeft: "12px" }} />
      ) : null}
    </Box>
  );
}

export default BoxedText;
