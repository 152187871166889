import { ArrowDropDownRounded } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  ClickAwayListener,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  NULL_REOCCURING_COMPLETEDAT,
  NULL_TODO_COMPLETEDAT,
  UPDATE_CHILD_TODO,
  UPDATE_REOCCURRING_TODOS,
  UPDATE_TODO_UUID,
} from "../../../graphql/mutations/todo";
import { client } from "../../../utils/apollo";
import {
  MAINBAR_RIGHT_CLICK,
  TOOLBAR_RIGHT_CLICK,
  timeDuration,
} from "../../../utils/constants";
import { HoverTypography } from "../../HoverTypography";
import { CheckIcon } from "../../Icons/CheckIcon";
import { StudyTypographyMediumSubtitle } from "../../glovebox/StudyTypography";
import {
  ProfileBlockPopper,
  ToolbarBackground,
  ToolbarDurationBox,
  ToolbarToDoBox,
  ToolbarToDoDurationText,
  ToolbarToDoLeftSide,
  ToolbarToDoSubtitle,
  ToolbarToDoTitle,
} from "../../toolbar/styles";
import DurationBox from "../../glovebox/DurationBox";

export const TodoBar2 = ({
  data,
  index,
  toolbar,
  handleDeleteToDo,
  todos,
  setTodos,
}: // updateTodoList,
{
  data: any;
  index: any;
  toolbar: boolean;
  handleDeleteToDo: any;
  todos: any;
  setTodos: any;
  // updateTodoList: any;
}) => {
  const [editTodo, setEditTodo] = useState(false);
  const [editTodoText, setEditTodoText] = useState("");
  // const [todos, setTodos] = useState(JSON.parse(localStorage.getItem('todos')!) || [])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const { id: userId } = useUserContext();
  const userId = localStorage.getItem("userId");
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")!) || []
  );
  // const { user, setUser } = useContext(UserContext);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [clickAnchorEl, setClickAnchorEl] = useState<null | HTMLElement>(null);
  const clickOpen = Boolean(clickAnchorEl);

  useEffect(() => {
    localStorage.setItem("todos", JSON.stringify(todos));
  }, [todos]);

  const handleRightClick = (event: any) => {
    event.preventDefault();
    setClickAnchorEl(anchorEl ? null : event.currentTarget);
    // Trigger your custom action here
  };

  const updateTodoStateHelper = async (
    oldTodo: any,
    completed: boolean,
    deleted: boolean
  ) => {
    // console.log("here");
    if (completed) {
      if (oldTodo.todoId) {
        let newTodos = [...todos];
        newTodos = newTodos?.map((todo) => {
          let newTodo = { ...todo };
          if (todo.uuid === oldTodo.todoId) {
            newTodo.todoReplicas = newTodo.todoReplicas?.map((child: any) => {
              let newChild = { ...child };
              if (child.uuid === oldTodo.uuid) {
                newChild.completedAt = completed
                  ? new Date().toISOString()
                  : todo.completedAt;
              }
              return newChild;
            });
          }
          return newTodo;
        });
        setTodos(newTodos);
        client.mutate({
          mutation: UPDATE_CHILD_TODO,
          variables: {
            todoId: oldTodo.uuid,
            completed: completed,
          },
        });
      } else {
        let newTodos = [...todos];
        newTodos = newTodos?.map((todo) => {
          let newTodo = { ...todo };
          if (todo.uuid === oldTodo.uuid) {
            newTodo.completedAt = new Date().toISOString();
          }
          return newTodo;
        });
        setTodos(newTodos);
        client.mutate({
          mutation: UPDATE_TODO_UUID,
          variables: {
            uuid: oldTodo.uuid,
            completed: completed,
          },
        });
      }
    } else {
      if (oldTodo.todoId) {
        let newTodos = [...todos];
        newTodos = newTodos?.map((todo) => {
          let newTodo = { ...todo };
          if (todo.uuid === oldTodo.todoId) {
            newTodo.todoReplicas = newTodo.todoReplicas?.map((child: any) => {
              let newChild = { ...child };
              if (child.uuid === oldTodo.uuid) {
                newChild.completedAt = null;
              }
              return newChild;
            });
          }
          return newTodo;
        });
        setTodos(newTodos);
        client.mutate({
          mutation: NULL_REOCCURING_COMPLETEDAT,
          variables: {
            uuid: oldTodo.uuid,
          },
        });
      } else {
        let newTodos = [...todos];
        newTodos = newTodos?.map((todo) => {
          let newTodo = { ...todo };
          if (todo.uuid === oldTodo.uuid) {
            newTodo.completedAt = null;
          }
          return newTodo;
        });
        setTodos(newTodos);
        client.mutate({
          mutation: NULL_TODO_COMPLETEDAT,
          variables: {
            uuid: oldTodo.uuid,
          },
        });
      }
    }
  };

  const updateTodoTitle = (oldTodo: any) => {
    let todoUUID = oldTodo.todoId ? oldTodo.todoId : oldTodo.uuid;
    let newTodos = [...todos];
    newTodos = newTodos?.map((todo) => {
      let newTodo = { ...todo };
      if (todo.uuid === todoUUID) {
        newTodo.title = editTodoText;
      }
      return newTodo;
    });
    // console.log(newTodos)
    setTodos(newTodos);
    client.mutate({
      mutation: UPDATE_TODO_UUID,
      variables: {
        uuid: todoUUID,
        title: editTodoText,
      },
    });
  };

  const updateTodoDuration = (todo: any, duration: number) => {
    console.log("here!");
    let newTodos = [...todos];
    if (todo.reoccurring) {
      let newTodo = { ...todo };
      newTodo.todoReplicas = newTodo.todoReplicas?.map((child: any) => {
        let newChild = { ...child };
        newChild.duration = duration;
        return newChild;
      });
      newTodos = newTodos?.map((oldTodo: any) => {
        return oldTodo.uuid === newTodo.uuid ? newTodo : oldTodo;
      });
      setTodos(newTodos);
      client.mutate({
        mutation: UPDATE_REOCCURRING_TODOS,
        variables: {
          todoId: todo.uuid,
          duration: duration,
        },
      });
    } else if (todo.todoId) {
      newTodos = newTodos?.map((oldTodo: any) => {
        let newTodo = { ...oldTodo };
        if (newTodo.uuid === todo.todoId) {
          newTodo.todoReplicas = newTodo.todoReplicas?.map((child: any) => {
            let newChild = { ...child };
            if (child.uuid === todo.uuid) {
              newChild.duration = duration;
            }
            return newChild;
          });
        }
        return newTodo;
      });
      setTodos(newTodos);
      client.mutate({
        mutation: UPDATE_CHILD_TODO,
        variables: {
          todoId: todo.uuid,
          duration: duration,
        },
      });
    } else {
      newTodos = newTodos?.map((task) => {
        let newTodo = { ...task };
        if (todo.uuid === newTodo.uuid) {
          newTodo.duration = duration;
        }
        return newTodo;
      });
      setTodos(newTodos);
      client.mutate({
        mutation: UPDATE_TODO_UUID,
        variables: {
          uuid: todo.uuid,
          duration: duration,
        },
      });
    }
    // await updateTodoState(oldTodo, completed, deleted, todos, setTodos);
  };

  // const updateTodoTitleHelper = async (todo: any) => {
  //   await updateTodoTitle(todo, editTodoText, todos, setTodos);
  // };

  const theme = useTheme();

  return (
    <ToolbarBackground onContextMenu={handleRightClick}>
      <ToolbarToDoBox
        key={data?.uuid}
        style={{
          // height: toolbar ? "40px" : `${data?.duration * 1.5}px`,
          marginBottom: toolbar ? "4px" : "0px",
          border: clickOpen ? "1px solid white" : "0px",
          borderRadius: "4px",
          alignItems: "center",
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          padding: user?.compact ? "4px 8px 4px 8px" : "8px",
        }}
      >
        <ToolbarToDoLeftSide>
          <motion.div
            whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
            whileHover={{ rotate: 10 }}
            transition={{
              type: "spring",
              stiffness: 200,
              damping: 8,
            }}
            style={{
              alignItems: "center",
              alignSelf: "center",
              display: "flex",
            }}
          >
            <CheckIcon
              height={"24px"}
              width={"24px"}
              active={data?.completedAt !== null}
              clicked={() => {
                // updateTodo({
                //   variables: {
                //     uuid: data?.uuid,
                //     completed: data?.completedAt ? false : true,
                //   },
                // });
                updateTodoStateHelper(
                  data,
                  data?.completedAt ? false : true,
                  false
                );
              }}
            />
          </motion.div>
          {editTodo ? (
            <ClickAwayListener
              onClickAway={() => {
                setEditTodo(false);
              }}
            >
              <input
                autoFocus
                style={{
                  border: "0px",
                  backgroundColor: theme.palette.primary.light,
                  width: "100%",
                  height: "30px",
                  paddingTop: "10px",
                  paddingBottom: "10px",

                  paddingLeft: "16px",
                  outlineWidth: 0,
                  borderRadius: "4px",
                  color: theme.palette.text.primary,
                }}
                placeholder={"text new todo.."}
                type={"text"}
                name="title"
                value={editTodoText}
                onChange={(text) => {
                  setEditTodoText(text.target.value);
                }}
              ></input>
            </ClickAwayListener>
          ) : (
            <div
              style={{
                marginLeft: "12px",
                marginRight: "12px",
                // width: "100%",
              }}
            >
              <ToolbarToDoTitle>{data?.title}</ToolbarToDoTitle>
              <Breadcrumbs>
                {moment(data?.startTime).date() === new Date().getDate() ? (
                  <ToolbarToDoSubtitle>
                    {moment(data?.startTime).format("h:mma")}
                  </ToolbarToDoSubtitle>
                ) : moment(data?.startTime) &&
                  moment(data?.startTime).isValid() ? (
                  <ToolbarToDoSubtitle>
                    {moment(data?.startTime).format("M/DD")}
                  </ToolbarToDoSubtitle>
                ) : null}
                {moment(data?.dueAt).date() === new Date().getDate() ? (
                  <ToolbarToDoSubtitle
                    style={{ color: theme.palette.error.light }}
                  >
                    {moment(data?.startTime).format("h:mma")}
                  </ToolbarToDoSubtitle>
                ) : (data?.dueAt && moment(data?.dueAt).isValid()) ||
                  (moment(data?.dueAt) &&
                    !data?.completedAt &&
                    moment(data?.dueAt).isValid()) ? (
                  <ToolbarToDoSubtitle
                    style={{ color: theme.palette.error.light }}
                  >
                    {moment(data?.dueAt).format("M/DD")}
                  </ToolbarToDoSubtitle>
                ) : null}
              </Breadcrumbs>
            </div>
          )}
        </ToolbarToDoLeftSide>
        {editTodo ? (
          <Button
            variant="text"
            color="secondary"
            sx={{
              textTransform: "none",
              marginLeft: "12px",
              padding: "4px",
              backgroundColor: theme.palette.secondary.light,
            }}
            onClick={() => {
              // updateTodo({
              //   variables: { title: editTodoText, uuid: data?.uuid },
              // });
              updateTodoTitle(data);
              // updateTodoTitleHelper(data);
            }}
          >
            save
          </Button>
        ) : (
          <ClickAwayListener
            onClickAway={() => {
              setAnchorEl(null);
            }}
          >
            <DurationBox
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              num={
                data.reoccurring
                  ? data?.todoReplicas[0]?.duration
                  : data?.duration
              }
              user={user}
            />
          </ClickAwayListener>
        )}

        <ProfileBlockPopper
          open={open}
          anchorEl={anchorEl}
          placement={"bottom-start"}
          modifiers={[{ name: "arrow", enabled: true }]}
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            margin: 0,
            paddingTop: "8px",

            paddingRight: 0,
          }}
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          <div
            style={{
              alignItems: "center",
              display: "inline-grid",
              justifyContent: "center",
              backgroundColor: theme.palette.primary.main,
              border: `1px solid ${theme.palette.secondary.light}`,
              padding: "8px",
              borderRadius: "4px",
            }}
          >
            {timeDuration.map((time) => (
              <ToolbarToDoDurationText
                style={{
                  padding: "4px",
                  textAlign: "center",
                }}
                onClick={(event: any) => {
                  event.preventDefault();
                  event.stopPropagation();
                  // updateTodo({
                  //   variables: { duration: time, uuid: data?.uuid },
                  // });
                  updateTodoDuration(data, time);
                  setAnchorEl(null);
                  // updateTodoDuration(data, time, todos, setTodos);
                }}
              >
                {time} min
              </ToolbarToDoDurationText>
            ))}
          </div>
        </ProfileBlockPopper>
        <ClickAwayListener
          onClickAway={() => {
            setClickAnchorEl(null);
          }}
        >
          <ProfileBlockPopper
            open={clickOpen}
            anchorEl={clickAnchorEl}
            placement={toolbar ? "bottom-start" : "bottom"}
            modifiers={[{ name: "arrow", enabled: true }]}
            style={{
              paddingTop: "8px",
              backgroundColor: "rgba(0,0,0,0)",
            }}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          >
            <div
              style={{
                alignItems: "center",
                display: "inline-grid",
                justifyContent: "center",
                padding: "8px",
                borderRadius: "4px",
                backgroundColor: theme.palette.primary.main,
                border: `1px solid ${theme.palette.secondary.light}`,
              }}
            >
              {toolbar
                ? TOOLBAR_RIGHT_CLICK.map((item) => {
                    return (
                      <HoverTypography
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "8px",
                          paddingRight: "8px",
                          marginBottom: "4px",
                          borderRadius: "4px",
                          color:
                            item.name === "delete todo"
                              ? theme.palette.error.dark
                              : theme.palette.text.primary,
                        }}
                        onClick={() => {
                          setClickAnchorEl(null);
                          if (item.index === 0) {
                            setEditTodo(true);
                            setEditTodoText(data?.title);
                          } else if (item.index === 1) {
                            // updateTodo({
                            //   variables: {
                            //     uuid: data?.uuid,
                            //     completed: true,
                            //   },
                            // });
                            updateTodoStateHelper(
                              data,
                              data?.completedAt ? false : true,
                              false
                            );
                          } else if (item.index === 2) {
                            // updateTodo({
                            //   variables: {
                            //     uuid: data?.uuid,
                            //     deleted: true,
                            //   },
                            // });
                            // updateTodoState(data?.uuid, false, true);
                            console.log("selected task: ", data);
                            handleDeleteToDo(data);
                          }
                        }}
                      >
                        {item.icon}
                        <StudyTypographyMediumSubtitle
                          style={{
                            paddingTop: "6px",
                            paddingBottom: "6px",
                            borderRadius: "4px",
                            paddingLeft: "8px",
                            color:
                              item.name === "delete todo"
                                ? theme.palette.error.dark
                                : theme.palette.text.primary,
                          }}
                        >
                          {item.name}
                        </StudyTypographyMediumSubtitle>
                      </HoverTypography>
                    );
                  })
                : MAINBAR_RIGHT_CLICK.map((item) => {
                    return (
                      <HoverTypography
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "8px",
                          paddingRight: "8px",
                          marginBottom: "4px",
                          borderRadius: "4px",
                          color:
                            item.name === "delete todo"
                              ? theme.palette.error.dark
                              : theme.palette.text.primary,
                        }}
                        onClick={() => {
                          setClickAnchorEl(null);
                          if (item.index === 0) {
                            setEditTodo(true);
                            setEditTodoText(data?.title);
                          } else if (item.index === 1) {
                            // updateTodo({
                            //   variables: {
                            //     uuid: data?.uuid,
                            //     startTime: null,
                            //   },
                            // });
                          } else if (item.index === 2) {
                            // updateTodo({
                            //   variables: {
                            //     uuid: data?.uuid,
                            //     completed: true,
                            //   },
                            // });
                            updateTodoStateHelper(
                              data,
                              data?.completedAt ? false : true,
                              false
                            );
                          } else if (item.index === 3) {
                            // updateTodo({
                            //   variables: {
                            //     uuid: data?.uuid,
                            //     deleted: true,
                            //   },
                            // });
                            // updateTodoState(data, false, true);
                            handleDeleteToDo(data);
                          }
                        }}
                      >
                        {item.icon}
                        <StudyTypographyMediumSubtitle
                          style={{
                            paddingTop: "6px",
                            paddingBottom: "6px",
                            borderRadius: "4px",
                            paddingLeft: "8px",
                            color:
                              item.name === "delete todo"
                                ? theme.palette.error.dark
                                : theme.palette.text.primary,
                          }}
                        >
                          {item.name}
                        </StudyTypographyMediumSubtitle>
                      </HoverTypography>
                    );
                  })}
            </div>
          </ProfileBlockPopper>
        </ClickAwayListener>
      </ToolbarToDoBox>
    </ToolbarBackground>
  );
};
