import { queries } from "@testing-library/react";
import { getToken } from "./tokens";

export const getMyGoogleCalendars = async (token: string) => {
  try {
    // console.log(rtoken)
    // const token = await getToken(rtoken);
    const params = {
      showDeleted: 'false',
      fields: 'items(id,summary)'
    }
    const response = await fetch(
      // `https://www.googleapis.com/calendar/v3/calendars/primary/events?` + new URLSearchParams(params).toString(),
      `https://www.googleapis.com/calendar/v3/users/me/calendarList?` + new URLSearchParams(params).toString(),
      {
        headers: {
            Authorization: `Bearer ${token}`,
        }
      }
    );

    const data = await response.json();
    // return {data: data, token: token};
    return data
  } catch (error: any) {
    return {error: error.message};
  }
}

// export const getMyGoogleCalendarsList = async (token: string, first: boolean, syncToken: string, data: any) => {
export const getMyGoogleCalendarsList = async (token: string, first: boolean, data: any) => {
  const requests = data?.map((calendar:any) => {
    try {
      var response;
      var maxDate = new Date();
      var minDate = new Date();
      minDate.setDate(minDate.getDate() - 7);
      maxDate.setDate(maxDate.getDate() + 14);
      // if(first !== true && syncToken) {
      //     const params = {
      //         syncToken: syncToken,
      //     }
      //     response = await fetch(
      //         `https://www.googleapis.com/calendar/v3/calendars/primary/events` + new URLSearchParams(params).toString(),
      //         {
      //             headers: {
      //                 Authorization: `Bearer ${token}`,
      //             }
      //         }
      //     );
      // } else {
          const params = {
              timeMin: minDate.toISOString(),
              timeMax: maxDate.toISOString(),
              showDeleted: 'false',
              singleEvents: 'true',
              fields: 'summary,timeZone,nextPageToken,items(id,status,htmlLink,summary,start,end/dateTime),nextSyncToken'
          }
          response = fetch(
              `https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(calendar)}/events?` + new URLSearchParams(params).toString(),
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  },
              }
          );
      return response;
    } catch (error: any) {
      return error.message;
    }
  });

  return Promise.all(requests).then(async responses => {
    const jsons = await Promise.all(responses.map(async (response) => {
      return await response.json();
    }));
    // console.log(jsons)
    return jsons.map((json) => json.items);
  })
};