import { FolderOffOutlined } from "@mui/icons-material";
import { Stack, useTheme } from "@mui/material";
import moment from "moment";
import {
  StudyTypographyMediumTitle,
  StudyTypographySmallText,
} from "../../glovebox/StudyTypography";
import TimePicker from "../../glovebox/TimePicker";
import { AIToDoBlock } from "../../mainpage/AIToDoBlock";

function SelectSettingsForAI({
  todos,
  startTime,
  endTime,
  setSelectedStartTime,
  setSelectedEndTime,
  selectedLockedTodos,
  setSelectedLockedTodos,
}: {
  todos: any;
  startTime: string;
  endTime: string;
  setSelectedStartTime: any;
  setSelectedEndTime: any;
  selectedLockedTodos: any;
  setSelectedLockedTodos: any;
}) {
  const filteredTodos = todos.filter(
    (todo: any) =>
      todo?.completedAt === null &&
      moment(todo?.startTime).isSame(new Date(), "day")
  );

  const theme = useTheme();
  return (
    <Stack height={"500px"}>
      <StudyTypographyMediumTitle style={{ marginBottom: "12px" }}>
        Lock in events that should not change
      </StudyTypographyMediumTitle>
      {filteredTodos?.length > 0 ? (
        filteredTodos.map((todo: any) => (
          <div
            key={todo.id}
            onClick={() => {
              selectedLockedTodos.includes(todo)
                ? setSelectedLockedTodos(
                    selectedLockedTodos.filter(
                      (selectedTodo: any) => selectedTodo !== todo
                    )
                  )
                : setSelectedLockedTodos([todo, ...selectedLockedTodos]);
            }}
          >
            <AIToDoBlock
              data={todo}
              selected={selectedLockedTodos.includes(todo)}
              lock={true}
            />
          </div>
        ))
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            height: "200px",
            border: `3px dashed ${theme.palette.primary.light}`,
            borderRadius: "4px",
            boxSizing: "border-box",
          }}
        >
          <FolderOffOutlined
            style={{
              backgroundColor: theme.palette.primary.light,
              height: "64px",
              width: "48px",
              cursor: "pointer",
              borderRadius: "4px",
              padding: "12px",
              marginBottom: "12px",
              color: theme.palette.primary.dark,
            }}
          />
          <StudyTypographySmallText>
            No todos scheduled for today, you can continue!
          </StudyTypographySmallText>
        </div>
      )}
      <StudyTypographyMediumTitle
        style={{ marginBottom: "12px", marginTop: "24px" }}
      >
        Time slot to schedule
      </StudyTypographyMediumTitle>
      <Stack flexDirection={"row"} alignItems={"center"} gap={"12px"}>
        <TimePicker
          time={startTime}
          setTime={setSelectedStartTime}
          title={"START: "}
        />
        <TimePicker
          time={endTime}
          setTime={setSelectedEndTime}
          title={"END: "}
        />
      </Stack>
    </Stack>
  );
}

export default SelectSettingsForAI;
