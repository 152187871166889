export const HOTKEYS = {
  GLOBAL_SEARCH: "⌘F",
  ENTER_TODO: "shift+enter",
  SHOW_SHORTCUTS: "shift+S",
  CHANGE_THEME: "command+T",
  CREATE_TASK: "shift+T",
  OPEN_CALENDAR: "Q",
  OPEN_TODO: "W",
  OPEN_SCHEDULE: "E",
  OPEN_ROOM: "R",
  OPEN_FRIENDS: "T",
  OPEN_NOTIFICATION: "N",
  OPEN_SETTINGS: "S",
  OPEN_POD: "L",
  OPEN_PROFILE: "P",
  OPEN_ADDFRIENDS: "Y",
  OPEN_TODAY: "H",
  DAY_VIEW: "F",
  WEEK_VIEW: "G",
  DELETE_TODOS: "shift+D",
  ALL_KEYS: "*",
};

export const ARROW_KEYS = {
  ARROW_DOWN: "ArrowDown",
  ARROW_UP: "ArrowUp",
  ARROW_RIGHT: "right",
  ARROW_LEFT: "left",
};

export const NUMS = {
  ONE: "1",
  TWO: "2",
  THREE: "3",
  FOUR: "4",
  FIVE: "5",
  SIX: "6",
  SEVEN: "7",
  EIGHT: "8",
  NINE: "9",
};
