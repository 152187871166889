import PropTypes from "prop-types";
// material
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

EmptyLogo.propTypes = {
  sx: PropTypes.object,
};
export default function EmptyLogo(props: { sx: any }) {
  const { sx } = props;
  return (
    <Box
      component="img"
      src={require("./StudyoLogo.png")}
      sx={{
        width: 30,
        height: 30,
        ...sx,
      }}
    />
  );
}
