import { gql } from "@apollo/client";
import { client } from "../../utils/apollo";

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($name: String!, $uuid: String!, $label: String!) {
    createCategory(name: $name, uuid: $uuid, label: $label) {
      id
    }
  }
`;
export const CREATE_GROUP_CATEGORY = gql`
  mutation CreateGroupCategory($name: String!, $uuid: String!) {
    createGroupCategory(name: $name, uuid: $uuid) {
      id
    }
  }
`;

export const CONNECT_BASE_BULK = gql`
mutation ConnectToCategoryBulk($uuid: String!, $todoIds: [String!]) {
  connectToCategoryBulk(uuid: $uuid, todoIds: $todoIds) {
    id
  }
}
`

export const UPDATE_CATEGORY = gql`
mutation UpdateCategory($updateCategoryId: String!, $todoId: String, $name: String, $label: String) {
  updateCategory(id: $updateCategoryId, todoId: $todoId, name: $name, label: $label) {
    id
  }
}
`;

export const UPDATE_CATEGORY_UUID = gql`
mutation UpdateCategoryUUID($uuid: String!, $todoId: String, $name: String, $label: String) {
  updateCategoryUUID(uuid: $uuid, todoId: $todoId, name: $name, label: $label) {
    id
  }
}
`

export const UPDATE_GROUP_CATEGORY = gql`
  mutation UpdateGroupCategory(
    $updateGroupCategoryId: String!
    $todoIds: [String]
    $name: String
  ) {
    updateGroupCategory(
      id: $updateGroupCategoryId
      todoIds: $todoIds
      name: $name
    ) {
      id
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($deleteCategoryId: String!) {
    deleteCategory(id: $deleteCategoryId) {
      id
    }
  }
`;

export const DELETE_CATEGORY_UUID = gql`
mutation DeleteCategoryUUID($uuid: String!) {
  deleteCategoryUUID(uuid: $uuid) {
    id
  }
}
`

export const DELETE_GROUP_CATEGORY = gql`
  mutation DeleteGroupCategory($deleteGroupCategoryId: String!) {
    deleteGroupCategory(id: $deleteGroupCategoryId) {
      id
    }
  }
`;

export const UPDATE_CATEGORY_TODOS = gql`
mutation updateCategoryWithGoogleCalendar($uuid: String!, $todos: [TodoInput!]!) {
    updateCategoryWithGoogleCalendar(uuid: $uuid, todos: $todos) {
        id
    }
}
`

export const UPDATE_CATEGORY_TODOS_BULK = gql`
mutation UpdateCategoryWithBulkData($uuid: String!, $todos: [BulkTodoInput!]!) {
  updateCategoryWithBulkData(uuid: $uuid, todos: $todos) {
    id
  }
}
`;

export const deleteTodoCategory = async (categoryId: string, userCategoryId: string, categories: any, setCategories: any, todos: any, setTodos: any) => {
  let updatedCategories = [...categories];
      updatedCategories = updatedCategories.filter(function (category: any) {
        return category.uuid !== categoryId;
      });
      let newTodos = [...todos];
      let deleteCategoryTodos: any[] = [];
      newTodos = newTodos.map((todo) => {
        if (todo?.categoryId === categoryId) {
          todo.categoryId = userCategoryId;
          deleteCategoryTodos.push(todo.uuid);
        }
        return todo;
      });
      setTodos(newTodos);
      setCategories(updatedCategories);
      return client
        .mutate({
          mutation: DELETE_CATEGORY_UUID,
          variables: {
            uuid: categoryId,
          },
        })
        .then(() => {
          client.mutate({
            mutation: CONNECT_BASE_BULK,
            variables: {
              uuid: userCategoryId,
              todoIds: deleteCategoryTodos,
            },
          });
        });
}
