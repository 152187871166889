import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useOutletContext } from "react-router-dom";
import { DELETE_GROUP, leaveGroup } from "../../../graphql/mutations/group";
import { LEAVE_GROUP } from "../../../graphql/mutations/user";
import { client } from "../../../utils/apollo";
import { CoworkGroup } from "./CoworkGroup";

export const CoworkGroups = () => {
  // const [user, setUser] = useState(
  //   JSON.parse(localStorage.getItem("user")!) || {}
  // );
  const [todos, setTodos, user, setUser, categories, setCategories] =
    useOutletContext<any>();

  const leaveGroupHelper = async (groupUUID: any) => {
    await leaveGroup(groupUUID, user, setUser);
  };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  //   console.log(user);

  return (
    <div
      style={{
        // width: "100%",
        height: "90vh",
        overflowY: "scroll",
        padding: isMobile ? "16px" : "0px",
      }}
    >
      <Grid
        container
        rowSpacing={2}
        columnSpacing={isMobile ? 0 : 2}
        direction={isMobile ? "column" : "row"}
        width={"100%"}
        style={{ marginBottom: isMobile ? "50px" : "0px" }}
      >
        {user.groups.length > 0 ? (
          user.groups.map((group: any, index: number) => {
            return (
              <CoworkGroup
                data={group}
                leaveGroup={leaveGroupHelper}
                handleSetNewGroup={() => {}}
                height={"200px"}
              />
            );
          })
        ) : (
          <CoworkGroup
            data={{ name: "Click to create cowork space" }}
            leaveGroup={leaveGroupHelper}
            handleSetNewGroup={() => {}}
            height={"200px"}
          />
        )}
      </Grid>
    </div>
  );
};
