import PropTypes from "prop-types";
// material
import { Popover } from "@mui/material";
import { alpha, styled, useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------

const ArrowStyle = styled("span")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 12,
    height: 12,
    content: "''",
    position: "absolute",
    borderRadius: "0 0 4px 0",
    transform: "rotate(-135deg)",
    background: theme.palette.primary.main,
    borderRight: `solid 1px ${theme.palette.primary.light}`,
    borderBottom: `solid 1px ${theme.palette.primary.light}`,
  },
}));

// ----------------------------------------------------------------------

MenuPopover.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

export default function MenuPopover({
  children,
  sx,
  open,
  onClose,
  anchorEl,
}: {
  children: any;
  sx: any;
  open: any;
  onClose: any;
  anchorEl: any;
}) {
  const theme = useTheme();
  return (
    <Popover
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      PaperProps={{
        sx: {
          mt: "12px",
          ml: 0.5,
          overflow: "inherit",
          // boxShadow: (theme) => theme.customShadows.z20,
          // border: (theme) => `solid 1px ${theme.palette.grey[500_8]}`,
          // width: 360,
          backgroundColor: theme.palette.primary.light,
          borderRadius: "8px",
        },
      }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
    >
      {/* <ArrowStyle className="arrow" /> */}
      {children}
    </Popover>
  );
}
