import { useTheme } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import StyleGuide from "../StyleGuide";
import { HoverPointerStyle } from "./HoverPointerStyle";

const EllipsesRect = styled.rect`
  fill: "black";
  .icon:hover {
    fill: "url(#paint0_linear_59_2302)";
    cursor: pointer;
  }
`;

export function CheckIcon(props: {
  width?: string;
  height?: string;
  active: boolean;
  clicked: Function;
}) {
  // console.log(props.active);
  const [isActive, setIsActive] = useState(props.active !== null);
  const theme = useTheme();
  return (
    <HoverPointerStyle
      width={props?.width || "30"}
      height={props?.height || "30"}
      viewBox={`0 0 24 24`}
      fill={
        props.active
          ? theme.palette.success.main
          : theme.palette.secondary.light
      }
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        // setIsActive(!isActive);
        props.clicked();
      }}
    >
      <rect width="24" height="24" rx="4" />
      <path
        d="M9.32199 15.2378L6.42256 12.3384C6.09669 12.0125 5.57028 12.0125 5.2444 12.3384C4.91853 12.6643 4.91853 13.1907 5.2444 13.5166L8.73709 17.0092C9.06297 17.3351 9.58938 17.3351 9.91525 17.0092L18.7556 8.1689C19.0815 7.84303 19.0815 7.31662 18.7556 6.99074C18.4297 6.66487 17.9033 6.66487 17.5774 6.99074L9.32199 15.2378Z"
        fill={theme.palette.secondary.light}
      />
    </HoverPointerStyle>
  );
}
