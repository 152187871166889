import { useTheme } from "@mui/material";
import {
  STUDYO_PRIMARY_GRADIENT,
  STUDYO_SECONDARY_GRADIENT,
} from "../../utils/constants";
import { HoverPointerStyle } from "./HoverPointerStyle";

export function ClockIcon(props: {
  active: boolean;
  clicked: Function;
  width?: string;
  height?: string;
}) {
  const theme = useTheme();

  return (
    <HoverPointerStyle
      width={props?.width || "30"}
      height={props?.height || "30"}
      viewBox={`0 0 32 32`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        props.clicked();
      }}
    >
      {props.active ? (
        <rect
          width={props?.width || "32"}
          height={props?.height || "32"}
          rx="4.125"
          fill="url(#paint0_linear_59_2302)"
        />
      ) : (
        <rect
          width={props?.width || "32"}
          height={props?.height || "32"}
          rx="4.125"
          fill={theme.palette.primary.light}
        />
      )}
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="4" />
        <path
          d="M19.2465 19.4187L16.4194 17.6662V13.6968C16.4194 13.3463 16.151 13.0659 15.8154 13.0659H15.7651C15.4296 13.0659 15.1611 13.3463 15.1611 13.6968V17.8327C15.1611 18.1394 15.3121 18.4285 15.5722 18.5863L18.6341 20.5052C18.9193 20.6805 19.2885 20.5929 19.4562 20.2949C19.6324 19.9882 19.5401 19.5939 19.2465 19.4187ZM23.701 10.4459L21.1172 8.20275C20.7649 7.89607 20.2364 7.93988 19.9344 8.31667C19.6408 8.68469 19.6911 9.23672 20.0435 9.55217L22.6189 11.7954C22.9712 12.102 23.4997 12.0582 23.8017 11.6814C24.1037 11.3134 24.0534 10.7614 23.701 10.4459ZM9.37275 11.7954L11.9481 9.55217C12.3089 9.23672 12.3592 8.68469 12.0572 8.31667C11.7636 7.93988 11.2351 7.89607 10.8828 8.20275L8.29897 10.4459C7.94663 10.7614 7.8963 11.3134 8.1983 11.6814C8.49191 12.0582 9.02041 12.102 9.37275 11.7954ZM16 9.56093C11.8307 9.56093 8.44997 13.0922 8.44997 17.4471C8.44997 21.8021 11.8307 25.3333 16 25.3333C20.1693 25.3333 23.55 21.8021 23.55 17.4471C23.55 13.0922 20.1693 9.56093 16 9.56093ZM16 23.5808C12.7619 23.5808 10.1278 20.8294 10.1278 17.4471C10.1278 14.0648 12.7619 11.3134 16 11.3134C19.2381 11.3134 21.8722 14.0648 21.8722 17.4471C21.8722 20.8294 19.2381 23.5808 16 23.5808Z"
          fill={props.active ? "white" : "#777777"}
        />
      </svg>

      <defs>
        <linearGradient
          id="paint0_linear_59_2302"
          x1="0"
          y1="0"
          x2="32"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={STUDYO_PRIMARY_GRADIENT} />
          <stop offset="1" stopColor={STUDYO_SECONDARY_GRADIENT} />
        </linearGradient>
      </defs>
    </HoverPointerStyle>
  );
}
