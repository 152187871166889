import { MoreHorizOutlined } from "@mui/icons-material";
import { alpha, Button, Grid, Stack, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { useNavigate } from "react-router-dom";
import { random_rgba } from "../../../utils/constants";
import { DraggingContext } from "../../../utils/contexts";
import { ItemTypes } from "../../../utils/types";
import {
  StudyTypographyMediumTitle,
  StudyTypographySmallSubtitle,
} from "../../glovebox/StudyTypography";

export const CoworkFriendContainer = ({
  data,
  addToGroup,
}: {
  data: any;
  addToGroup: any;
}) => {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={4}
      width={"100%"}
      // ref={drag}
      key={data?.id}
      data-testid={`box`}
    >
      <div
        onClick={() => {
          addToGroup(data.id);

          //   if (friend?.uuid) {
          //     // navigate to user profile
          //   } else {
          //     navigate("/app/addfriend");
          //   }
          // handleSelectFriend(data.id);
        }}
        style={{
          backgroundColor: theme.palette.primary.main,
          border: `3px solid ${theme.palette.primary.light}`,
          cursor: "pointer",
          // width: "100%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          padding: "16px",
          borderRadius: "4px",
          // opacity: opacity,
        }}
      >
        <div
          // src={require("../../Icons/ProfileMemoji.png")}
          style={{
            borderRadius: "12px",
            height: "50px",
            width: "50px",
            cursor: "pointer",
            marginBottom: "16px",
            marginTop: "16px",
            background: `linear-gradient(135deg, ${alpha(
              random_rgba(),
              1
            )} 0%, ${alpha(random_rgba(), 1)} 100%)`,
            backgroundColor: "white",
          }}
        />
        <StudyTypographyMediumTitle>
          {data?.firstName} {data?.lastName}
        </StudyTypographyMediumTitle>
        <StudyTypographySmallSubtitle style={{ marginBottom: "16px" }}>
          {data?.email}
        </StudyTypographySmallSubtitle>
        {data?.uuid ? (
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Button
              size="small"
              style={{
                textTransform: "none",
                backgroundColor: theme.palette.secondary.light,

                color: theme.palette.text.primary,

                marginRight: "8px",
                height: "24px",
              }}
              fullWidth
              onClick={() => {
                addToGroup(data.id);
              }}
            >
              + Group
            </Button>
            <Button
              size="small"
              style={{
                textTransform: "none",
                backgroundColor: theme.palette.secondary.light,
                marginRight: "8px",
                height: "24px",

                color: theme.palette.text.primary,
              }}
              fullWidth
            >
              message
            </Button>
            <MoreHorizOutlined
              style={{
                backgroundColor: theme.palette.secondary.light,

                color: theme.palette.text.primary,

                height: "24px",
                width: "24px",
                borderRadius: "4px",
              }}
            />
          </Stack>
        ) : null}
      </div>
    </Grid>
  );
};
