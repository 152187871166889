import { Avatar, styled } from "@mui/material";
import { isMobile } from "react-device-detect";

export const CoworkContainer = styled("div")({
  alignItems: "flex-start",
  width: "100%",
  // overflowX: "clip",
  overflowY: "scroll",

  // position: "absolute",
  boxSizing: "border-box",
  flex: 1,
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "row",
  // height: "90vh",
  height: "1000px",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

export const ThemedAvatar = styled(Avatar)(({ theme }) => ({
  width: "24px",
  height: "24px",
  fontSize: "14px",
  boxShadow: "none",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.light,
}));

export const CoworkScheduleContainer = styled("div")({
  width: isMobile ? "70%" : "100%",
  //   marginLeft: "8px",
  //   marginRight: "8px",
  overflowY: "scroll",
  scrollbarWidth: "none",
  height: "90vh",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

export const CoworkChatContainer = styled("div")({
  display: "flex",
  overflow: "hidden",
  paddingLeft: "16px",
  paddingRight: "16px",
  height: "90vh",
  width: isMobile ? "100vw" : "25vw",
  justifyContent: "space-between",
  flexDirection: "column",
  alignContent: "center",
  // overflowY: "scroll",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});
