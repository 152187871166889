import { Box, Button, Modal, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useHotkeys } from "react-hotkeys-hook";
import { HorizontalContainer } from "../glovebox/HorizontalContainer";
import {
  StudyTypographyMediumText,
  StudyTypographySmallSubtitle,
} from "../glovebox/StudyTypography";
import StudyoCalendar from "../../assets/StudyoSchedule.mp4";
import "./Line.css";

function StudyoVideo() {
  const [fullScreen, setFullScreen] = useState(false);

  var elem = document.getElementById("schedule-video");

  return (
    <motion.div
      style={{
        backgroundColor: "transparent",
        // width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <video
        controls={false}
        autoPlay={true}
        playsInline
        muted
        loop
        id={"schedule-video"}
        className={fullScreen ? "" : "video"}
        onClick={() => {
          setFullScreen(true);
          elem?.requestFullscreen();
        }}
        style={{
          borderRadius: "12px", // Set the border radius as needed
          overflow: "hidden", // Prevent the video from overflowing the border radius              margin: "24px",
          objectFit: "cover",
          width: "95%",
          height: "95%",
        }}
      >
        <source
          src={StudyoCalendar}
          type="video/mp4"
          style={{ borderRadius: "1000px" }}
        />
        <source
          src="myVideo.mp4"
          type="video/mp4"
          style={{ borderRadius: "1000px" }}
        />
        <p>
          Your browser doesn't support HTML video. Here is a
          <a href="myVideo.mp4">link to the video</a> instead.
        </p>
      </video>
    </motion.div>
  );
}

export default StudyoVideo;
