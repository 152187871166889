import { gql } from "@apollo/client";

export const GET_USER_BUGS = gql`
  query GetUserSubmittedBugs($userId: String!) {
    getUserSubmittedBugs(userId: $userId) {
      description
      id
      status
      priority
      createdAt
    }
  }
`;
