import styled from "styled-components";

export const ContentWrapper = styled.div`
  /* margin-left: 15rem; */
  /* padding: 2rem; */
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
  a {
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 850px) {
    margin-left: 0px;
    /* padding-top: 65px; */
  }
`;
