import { CalendarTodayOutlined } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  ClickAwayListener,
  Tooltip,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  ADD_DUE,
  ADD_START,
  COMPLETE_TODO,
  DELETE_TODO,
  EDIT_TODO,
  MAINBAR_RIGHT_CLICK,
  RANDOM_COLOR,
  REMOVE_START,
  timeDuration,
} from "../../utils/constants";
import { HoverTypography } from "../HoverTypography";
import { CheckIcon } from "../Icons/CheckIcon";
import DurationBox from "../glovebox/DurationBox";
import { StudyTypographyMediumSubtitle } from "../glovebox/StudyTypography";
import {
  ProfileBlockPopper,
  SwitchCategoryContainer,
  ToolbarBackground,
  ToolbarToDoBox,
  ToolbarToDoDurationText,
  ToolbarToDoLeftSide,
  ToolbarToDoSubtitle,
  ToolbarToDoTitle,
} from "../toolbar/styles";

export const SimpleToDo = ({
  data,
}: // updateTodoList,
{
  data: any;
}) => {
  const [editTodo, setEditTodo] = useState(false);
  const [editTodoText, setEditTodoText] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const { user, setUser } = useContext(UserContext);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")!) || []
  );
  const [categories, setCategories] = useState<any>(
    JSON.parse(localStorage.getItem("categories")!) || []
  );
  useEffect(() => {
    localStorage.setItem("categories", JSON.stringify(categories));
  }, [categories]);
  // const [todos, setTodos] = useState(JSON.parse(localStorage.getItem('todos')!) || [])
  // // const { id: userId } = useUserContext();
  const [value, setValue] = useState<any>(moment().format("HH:MM"));
  const [timeOpen, setTimeOpen] = useState(false);
  const [remove, setRemove] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleChange = (newValue: any) => {
    setValue(newValue);
  };
  const userId = localStorage.getItem("userId");
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [dateAnchorEl, setDateAnchorEl] = useState<null | HTMLElement>(null);
  const handleClickDate = (event: React.MouseEvent<HTMLElement>) => {
    setDateAnchorEl(dateAnchorEl ? null : event.currentTarget);
  };
  const dateOpen = Boolean(dateAnchorEl);

  const [categoryAnchorEl, setCategoryAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const handleCategoryClick = (event: React.MouseEvent<HTMLElement>) => {
    setCategoryAnchorEl(categoryAnchorEl ? null : event.currentTarget);
  };
  const categoryOpen = Boolean(categoryAnchorEl);
  const theme = useTheme();
  const [clickAnchorEl, setClickAnchorEl] = useState<null | HTMLElement>();
  const clickOpen = Boolean(clickAnchorEl);

  const handleRightClick = (event: any) => {
    event.preventDefault();
    setClickAnchorEl(clickAnchorEl ? null : event.currentTarget);
    // Trigger your custom action here
  };

  const handleFocus = (event: any) => event.target.select();

  // const StyledTimePicker = styled(TimePicker)`
  //   .popupStyles {
  //     background-color: ${theme.palette.primary.main};
  //     color: ${theme.palette.text.primary};
  //   }
  //   .ant-picker-content {
  //     background-color: red;
  //   }
  // `;
  return (
    <ToolbarBackground
      style={{ backgroundColor: "rgba(0,0,0,0)" }}
      onContextMenu={handleRightClick}
      // onClick={(e: any) => {
      //   e.stopPropagation();
      //   e.preventDefault();

      //   const holdClickAnchor = clickAnchorEl;
      //   setClickAnchorEl(holdClickAnchor ? null : e.currentTarget);
      // }}
      onDoubleClick={isMobile ? handleRightClick : setValue}
    >
      <ToolbarToDoBox
        key={data?.uuid}
        style={{
          height: "32px",
          marginBottom: "4px",
          border: clickOpen ? `2px solid ${theme.palette.text.primary}` : "0px",
          borderRadius: "4px",
          alignItems: "center",
          padding: user?.compact ? "2px 8px 2px 8px" : "8px",
          backgroundColor: data.backgroundColor,
        }}
      >
        <ToolbarToDoLeftSide>
          <motion.div
            whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
            whileHover={{ rotate: 10 }}
            transition={{
              type: "spring",
              stiffness: 200,
              damping: 8,
            }}
            style={{
              alignItems: "center",
              alignSelf: "center",
              display: "flex",
            }}
          >
            <CheckIcon
              height={user?.compact ? "18px" : "24px"}
              width={user?.compact ? "18px" : "24px"}
              active={checked}
              clicked={(event: any) => {
                event?.stopPropagation();
                setChecked(!checked);
              }}
            />
          </motion.div>
          {editTodo ? (
            <ClickAwayListener
              onClickAway={() => {
                setEditTodo(false);
              }}
            >
              <input
                autoFocus
                suppressContentEditableWarning={true}
                contentEditable={true}
                onFocus={handleFocus}
                style={{
                  border: "0px",
                  backgroundColor: theme.palette.primary.light,
                  width: "100%",
                  height: "24px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "16px",
                  outlineWidth: 0,
                  borderRadius: "4px",
                  color: theme.palette.text.primary,
                }}
                placeholder={"text new todo.."}
                type={"text"}
                name="title"
                value={editTodoText}
                onChange={(text) => {
                  setEditTodoText(text.target.value);
                }}
              ></input>
            </ClickAwayListener>
          ) : (
            <div
              style={{
                marginLeft: "12px",
                textAlign: "left",
                flexShrink: "0",
                overflow: "hidden",
              }}
            >
              <ToolbarToDoTitle
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  textAlign: "flex-start",
                }}
                onDoubleClick={() => {
                  setEditTodo(true);
                  setEditTodoText(data?.title);
                }}
              >
                {data?.title}
              </ToolbarToDoTitle>
              <Breadcrumbs>
                {moment(data?.startTime).date() === new Date().getDate() ? (
                  <ToolbarToDoSubtitle>
                    {moment(data?.startTime).format("h:mma")}
                  </ToolbarToDoSubtitle>
                ) : moment(data?.startTime) &&
                  moment(data?.startTime).isValid() ? (
                  <ToolbarToDoSubtitle>
                    {moment(data?.startTime).format("M/DD")}
                  </ToolbarToDoSubtitle>
                ) : null}
                {moment(data?.dueAt).date() === new Date().getDate() ? (
                  <ToolbarToDoSubtitle
                    style={{ color: theme.palette.error.light }}
                  >
                    {moment(data?.startTime).format("h:mma")}
                  </ToolbarToDoSubtitle>
                ) : (data?.dueAt && moment(data?.dueAt).isValid()) ||
                  (moment(data?.dueAt) &&
                    !data?.completedAt &&
                    moment(data?.dueAt).isValid()) ? (
                  <ToolbarToDoSubtitle
                    style={{ color: theme.palette.error.light }}
                  >
                    {moment(data?.dueAt).format("M/DD")}
                  </ToolbarToDoSubtitle>
                ) : null}
              </Breadcrumbs>
            </div>
          )}
        </ToolbarToDoLeftSide>
        {editTodo ? (
          <form onSubmit={() => {}}>
            <Button
              variant="text"
              color="secondary"
              sx={{ textTransform: "none" }}
              onClick={() => {
                // updateTodo({
                //   variables: { title: editTodoText, uuid: data?.uuid },
                // });
                setEditTodo(false);
              }}
            >
              save
            </Button>
          </form>
        ) : (
          <ClickAwayListener
            onClickAway={() => {
              setAnchorEl(null);
            }}
          >
            <DurationBox
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              num={data?.duration}
              user={user}
            />
          </ClickAwayListener>
        )}

        <ProfileBlockPopper
          open={open}
          anchorEl={anchorEl}
          placement={"bottom-start"}
          modifiers={[{ name: "arrow", enabled: true }]}
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            margin: 0,
            paddingRight: 0,
            zIndex: 10000,
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          <div
            style={{
              alignItems: "center",
              display: "inline-grid",
              justifyContent: "center",
              backgroundColor: theme.palette.primary.main,
              border: `1px solid ${theme.palette.secondary.light}`,
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            {timeDuration.map((time) => (
              <ToolbarToDoDurationText
                style={{
                  padding: "8px",
                  textAlign: "center",
                  color: theme.palette.text.primary,
                }}
                onClick={(event: any) => {
                  // updateTodo({
                  //   variables: { duration: time, uuid: data?.uuid },
                  // });
                  event?.stopPropagation();
                  setAnchorEl(null);
                }}
              >
                {time} min
              </ToolbarToDoDurationText>
            ))}
          </div>
        </ProfileBlockPopper>
        <ClickAwayListener
          onClickAway={() => {
            setCategoryAnchorEl(null);
          }}
        >
          <Tooltip title={"category :)"} placement={"top"}>
            <div
              onClick={handleCategoryClick}
              style={{
                whiteSpace: "nowrap",
                width: "14px",
                height: "14px",
                border: `2px solid ${RANDOM_COLOR}`,
                borderRadius: "4px",
                marginLeft: "12px",
              }}
            ></div>
          </Tooltip>
        </ClickAwayListener>

        <ProfileBlockPopper
          open={categoryOpen}
          anchorEl={categoryAnchorEl}
          placement={"bottom-start"}
          modifiers={[{ name: "arrow", enabled: true }]}
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            margin: 0,
            paddingRight: 0,
            paddingTop: "8px",
            zIndex: 100,
          }}
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          <div
            style={{
              alignItems: "center",
              display: "inline-grid",
              justifyContent: "center",
              backgroundColor: theme.palette.primary.main,
              border: `1px solid ${theme.palette.secondary.light}`,
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            {categories.map((category: any) => (
              <SwitchCategoryContainer
                onClick={() => {
                  setCategoryAnchorEl(null);
                }}
              >
                {category.label === "Google Calendar" ? (
                  <div onClick={handleCategoryClick}>
                    <CalendarTodayOutlined
                      style={{
                        padding: 0,
                        margin: 0,
                        width: "18px",
                        height: "18px",
                        marginRight: "12px",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      width: "14px",
                      height: "14px",
                      border: `2px solid ${category?.label}`,
                      borderRadius: "4px",
                      marginRight: "12px",
                    }}
                  ></div>
                )}
                <StudyTypographyMediumSubtitle
                  style={{
                    textAlign: "left",
                  }}
                  onClick={() => {
                    // updateTodo({
                    //   variables: { duration: time, uuid: data?.uuid },
                    // });
                    // updateTodoDuration(data?.uuid, time);
                  }}
                >
                  {category?.name}
                </StudyTypographyMediumSubtitle>
              </SwitchCategoryContainer>
            ))}
          </div>
        </ProfileBlockPopper>

        <ClickAwayListener
          onClickAway={() => {
            setClickAnchorEl(null);
          }}
        >
          <ProfileBlockPopper
            open={clickOpen}
            anchorEl={clickAnchorEl}
            placement={"bottom-end"}
            modifiers={[{ name: "arrow", enabled: true }]}
            style={{
              backgroundColor: "rgba(0,0,0,0)",
              paddingTop: "8px",
            }}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          >
            <div
              style={{
                alignItems: "center",
                display: "inline-grid",
                justifyContent: "center",
                backgroundColor: theme.palette.primary.main,
                border: `1px solid ${theme.palette.secondary.light}`,
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              {MAINBAR_RIGHT_CLICK.map((item) => {
                return (
                  <HoverTypography
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      paddingBottom: "4px",
                      paddingTop: "4px",

                      borderRadius: "4px",
                      color:
                        item.name === "delete todo"
                          ? theme.palette.error.dark
                          : theme.palette.text.primary,
                    }}
                    onClick={(event: any) => {
                      // setClickAnchorEl(null);
                      if (item.name === EDIT_TODO) {
                        setEditTodo(true);
                        setEditTodoText(data?.title);
                      } else if (item.name === REMOVE_START) {
                      } else if (item.name === COMPLETE_TODO) {
                        // updateTodo({
                        //   variables: {
                        //     uuid: data?.uuid,
                        //     completed: true,
                        //   },
                        // });
                      } else if (item.name === DELETE_TODO) {
                        // updateTodo({
                        //   variables: {
                        //     uuid: data?.uuid,
                        //     deleted: true,
                        //   },
                        // });
                        // updateTodoState(data?.uuid, true, false)
                      } else if (item.name === ADD_START) {
                        handleClickDate(event);
                      } else if (item.name === ADD_DUE) {
                        handleClickDate(event);
                      }
                    }}
                  >
                    {item.icon}
                    <StudyTypographyMediumSubtitle
                      style={{
                        paddingTop: "6px",
                        paddingBottom: "6px",
                        borderRadius: "4px",
                        paddingLeft: "8px",
                        color:
                          item.name === "delete todo"
                            ? theme.palette.error.dark
                            : theme.palette.text.primary,
                      }}
                    >
                      {item.name}
                    </StudyTypographyMediumSubtitle>
                  </HoverTypography>
                );
              })}
            </div>
          </ProfileBlockPopper>
        </ClickAwayListener>
        {/* <ClickAwayListener
              onClickAway={() => {
                setClickAnchorEl(null);
              }}
            >
              <ProfileBlockPopper
                open={dateOpen}
                anchorEl={clickAnchorEl}
                placement={toolbar ? "bottom-end" : "bottom"}
                modifiers={[{ name: "arrow", enabled: true }]}
                style={{
                  backgroundColor: theme.palette.secondary.light,
                  padding: "4px",
                  color: theme.palette.text.primary,
                  alignItems: "center",
                  display: "flex",
                }}
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
              >
                <StyledTimePicker
                  use12Hours
                  format="h:mm a"
                  onChange={(date) => {
                    setValue(date);
                  }}
                  open={timeOpen}
                  placeholder={""}
                  showNow={false}
                  suffixIcon={null}
                  onOk={(date) => {
                    updateTodoStartTime(dayjs(date).toDate(), data?.uuid);
                    setClickAnchorEl(null);
                    setDateAnchorEl(null);
                    setTimeOpen(false);
                  }}
                  style={{
                    backgroundColor: theme.palette.secondary.light,
                    color: "white",
                    border: "none",
                    opacity: 0,
                    width: "0px",
                    padding: "0px",
                  }}
                  popupStyle={{
                    backgroundColor: "rgba(0,0,0,0)",
                    color: theme.palette.text.primary,
                  }}
                />
                <StudyTypographyMediumSubtitle
                  sx={{
                    padding: "4px",
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setTimeOpen(!timeOpen);
                  }}
                >
                  Select time
                </StudyTypographyMediumSubtitle>
                <CloseRounded
                  sx={{
                    width: "16px",
                    height: "16px",
                    marginLeft: "12px",
                    borderRadius: "2px",
                    padding: "4px",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      cursor: "pointer",
                    },
                  }}
                  onClick={(event: any) => {
                    event.preventDefault();
                    setDateAnchorEl(null);
                  }}
                />
              </ProfileBlockPopper>
            </ClickAwayListener> */}
      </ToolbarToDoBox>
    </ToolbarBackground>
  );
};
