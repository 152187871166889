import { Fab, useTheme, Zoom } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useOutletContext } from "react-router-dom";
import { GET_USER_FRIENDS } from "../../../graphql/queries/user";
import { client } from "../../../utils/apollo";
import { FABS } from "../../../utils/constants";
import { DraggingContext, PageContext } from "../../../utils/contexts";
import { FriendsMain } from "./friendsMain";
import { FriendsToolbar } from "./friendsToolBar";

// import { Friends}

interface MyProps {
  showIsDragging: () => void;
}

export const FriendsLayout = () => {
  const [todos, setTodos, user, setUser, categories, setCategories] =
    useOutletContext<any>();
  const [isComponentDragging, setIsDragging] = useState(false);
  // const [user, setUser] = useState(
  //   JSON.parse(localStorage.getItem("user")!) || {}
  // );
  const [groupInvites, setGroupInvites] = useState(
    JSON.parse(localStorage.getItem("groupInvites")!) || []
  );
  const [newGroup, setNewGroup] = useState<any>([]);
  let holdNewGroup: any = [];

  const fab = FABS.findIndex((fab) => fab.label === "Friends");

  const handleSetNewGroup = (friend: any) => {
    if (!holdNewGroup.some((person: any) => person.id === friend.id)) {
      holdNewGroup = newGroup;
      holdNewGroup.push(friend);
    } else {
      alert("already in group!");
    }
    setNewGroup(holdNewGroup);
  };
  const removeFriend = (group: any) => {
    setNewGroup(group);
  };

  useEffect(() => {
    localStorage.setItem("groupInvites", JSON.stringify(groupInvites));
  }, [groupInvites]);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    const getData = async () => {
      await client
        .query({
          query: GET_USER_FRIENDS,
          variables: {
            getUserId: user.id,
            userId: user.id,
          },
          fetchPolicy: "network-only",
        })
        .then(({ data: queryData }) => {
          let newUser = { ...user };
          newUser.friends = queryData.getUser.friends;
          setUser(newUser);
          setGroupInvites(queryData.getUserGroupInvites);
        })
        .catch((queryError) => {
          console.log(queryError.code + ": " + queryError.message);
        });
    };
    getData();
  }, []);

  const { page, showMainPage, setShowMainPage } = useContext(PageContext);
  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <div
      style={{
        height: "90vh",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      }}
    >
      <DraggingContext.Provider value={{ isComponentDragging, setIsDragging }}>
        <DndProvider backend={HTML5Backend}>
          {/* <ContentWrapper> */}

          {!isMobile || isComponentDragging || showMainPage ? null : (
            <FriendsToolbar
              user={user}
              setUser={setUser}
              handleSetNewGroup={handleSetNewGroup}
            />
          )}
          {!isMobile || isComponentDragging || showMainPage ? (
            <FriendsMain
              user={user}
              setUser={setUser}
              newGroup={newGroup}
              groupInvites={groupInvites}
              setGroupInvites={setGroupInvites}
              removeFriend={removeFriend}
            />
          ) : null}
          {isMobile ? null : (
            <FriendsToolbar
              user={user}
              setUser={setUser}
              handleSetNewGroup={handleSetNewGroup}
            />
          )}
          {/* </ContentWrapper> */}
          {isMobile ? (
            <Zoom
              key={FABS[fab].color}
              in={page === FABS[fab].label}
              timeout={transitionDuration}
              style={{
                transitionDelay: `${
                  page === FABS[fab].label ? transitionDuration.exit : 0
                }ms`,
              }}
              unmountOnExit
            >
              <Fab
                sx={FABS[fab].sx}
                aria-label={FABS[fab].label}
                color={FABS[fab].color}
                onClick={() => {
                  setShowMainPage(!showMainPage);
                }}
              >
                {showMainPage ? FABS[fab].iconTool : FABS[fab].iconMain}
              </Fab>
            </Zoom>
          ) : null}
        </DndProvider>
      </DraggingContext.Provider>
    </div>
  );
};
