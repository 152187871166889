import { gql } from "@apollo/client";

export const GET_CATEGORIES = gql`
query GetCategories {
    getCategories {
        id
        name
        todos {
            id
            uuid
            title
        }
        uuid
    }
}
`;

export const GET_USER_GOOGLE_CATEGORIES = gql`
query GetUserGoogleCategories($email: String!) {
    getUserGoogleCategories(email: $email) {
        id
        name
        uuid
    }
}
`

export const GET_USER_CATEGORIES = gql`
query GetUserCategories($userId: String!) {
    getUserCategories(userId: $userId) {
        id
        name
        uuid
        todos {
            id
            startTime
            title
            dueAt
            duration
            completedAt
            createdAt
            uuid
        }
    }
}
`;

// query GetUser($getUserId: String!) {
//     getUser(id: $getUserId) {
//         todos {
//             id
//             uuid
//             title
//             startTime
//             duration
//             dueAt
//             createdAt
//             completedAt
//             Category {
//                 id
//                 name
//                 uuid
//             }
//         }
//     }
// }

