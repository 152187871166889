import { Button, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import PageIndicator from "../../glovebox/PageIndicator";
import SelectSettingsForAI from "./SelectSettingsForAI";
import SelectTodosForAI from "./SelectTodosForAI";
import AIFinishScreen from "./AIFinishScreen";
import { sendMessageToGPT } from "../../../utils/sendMessageToOpenAI";
import moment from "moment";
import { client } from "../../../utils/apollo";
import { UPDATE_TODO_UUID } from "../../../graphql/mutations/todo";

function AIScheduleSystem({
  todos,
  setTodos,
  aiModalTitle,
  handleAiModalTitle,
  handleOpen,
}: {
  todos: any;
  setTodos: any;
  aiModalTitle: string;
  handleAiModalTitle: any;
  handleOpen: any;
}) {
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(3);
  const [selectedLockedTodos, setSelectedLockedTodos] = useState<any>([]);
  const [selectedTodos, setSelectedTodos] = useState([]);
  const [selectedStartTime, setSelectedStartTime] = useState("7am");
  const [selectedEndTime, setSelectedEndTime] = useState("8pm");

  const [updateTodosObject, setUpdateTodosObject] = useState<any>(null);
  const [updateTodosMessage, setUpdateTodosMessage] = useState<any>(null);

  function getCurrentPage() {
    switch (currentPage) {
      case 0:
        return (
          <SelectSettingsForAI
            todos={todos}
            startTime={selectedStartTime}
            endTime={selectedEndTime}
            setSelectedStartTime={setSelectedStartTime}
            setSelectedEndTime={setSelectedEndTime}
            selectedLockedTodos={selectedLockedTodos}
            setSelectedLockedTodos={setSelectedLockedTodos}
          />
        );
      case 1:
        return (
          <SelectTodosForAI
            todos={todos}
            selectedTodos={selectedTodos}
            setSelectedTodos={setSelectedTodos}
            selectedLockedTodos={selectedLockedTodos}
          />
        );
      case 2:
        return (
          <AIFinishScreen
            selectedTodos={selectedTodos}
            updateTodosObject={updateTodosObject}
            updateTodosMessage={updateTodosMessage}
          />
        );
    }
  }

  function getCurrentButton() {
    switch (currentPage) {
      case 0:
        return "NEXT";
      case 1:
        return "RUN AI";
      case 2:
        return "SAVE";
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        height: "100%",
      }}
    >
      {getCurrentPage()}
      <div
        style={{
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          marginTop: "24px",
        }}
      >
        <Button
          variant="text"
          color="secondary"
          sx={{
            backgroundColor: theme.palette.primary.light,
            height: "100%",
            "&:hover": {
              backgroundColor: theme.palette.secondary.light,
            },
            opacity: currentPage === 0 ? 0 : 1,
          }}
          disabled={currentPage === 0 ? true : false}
          onClick={() => {
            setCurrentPage(currentPage === 0 ? currentPage : currentPage - 1);
          }}
        >
          Back
        </Button>
        <PageIndicator
          currentPage={currentPage}
          totalPages={3}
          onPageChange={() => {}}
        />
        <Button
          variant="text"
          color="secondary"
          sx={{
            backgroundColor: theme.palette.primary.light,
            height: "100%",
            "&:hover": {
              backgroundColor: theme.palette.secondary.light,
            },
          }}
          onClick={() => {
            if (currentPage !== 2) {
              if (currentPage === 0) {
                handleAiModalTitle("Select todos you want to complete today!");
              } else if (currentPage === 1) {
                handleAiModalTitle("Finalize changes to the schedule");
                sendMessageToGPT(
                  `Plan an optimized schedule for today. Return the output structured as follows:

                  1. A JSON array enclosed with triple backticks and labeled as 'json'. The array should contain task objects with properties: 'id', 'title', 'startTime'. The id and title should correlate to its original self, but the startTime should planned by you and given in the form of a date variable that can be wrapped in a moment object. Each object represents a time block allocated for a task.
                     Format: \\\`\\\`\\\`json\n{the returned json}\\\`\\\`\\\`
                    
                  2. A concluding message explaining the rationale behind the schedule, positioned immediately after the JSON array.
                  
                  Incorporate the following productivity hacks for optimal efficiency:
                  
                  1. Eisenhower Matrix: Prioritize tasks based on their urgency and importance.
                  2. Task Batching: Group similar tasks together to reduce cognitive load.
                  3. Deep Work: Allocate uninterrupted blocks of time for tasks that require high concentration.
                  
                  Time Window: ${selectedStartTime} to ${selectedEndTime}. Date today: ${moment(
                    Date.now()
                  ).toISOString()}
                  
                  Fixed Appointments (Non-Movable):
                  ${selectedLockedTodos
                    .map(
                      (todo: any) =>
                        `- ID: ${todo.uuid}, Title: ${todo.title}, Duration: ${
                          todo.duration
                        } minutes, StartTime: ${moment(todo.startTime).format(
                          "hh:mm A"
                        )}`
                    )
                    .join("\n")}
                  
                  Flexible Tasks:
                  ${selectedTodos
                    .map(
                      (todo: any) =>
                        `- ID: ${todo.uuid}, Title: ${todo.title}, Duration: ${todo.duration} minutes`
                    )
                    .join("\n")}
                  
                  Constraints:
                  1. No overlapping tasks.
                  2. Adhere to the specified durations for each task.
                  3. Fixed appointments cannot be moved.
                  
                  Thank you!`
                ).then((data) => {
                  console.log(data);
                  setUpdateTodosObject(data?.object);
                  setUpdateTodosMessage(data?.message);
                });
              }

              setCurrentPage(currentPage + 1);
            } else {
              updateTodosObject?.forEach((todo: any) => {
                client.mutate({
                  mutation: UPDATE_TODO_UUID,
                  variables: {
                    uuid: todo.id,
                    startTime: moment(todo.startTime).toISOString(),
                  },
                });
              });
              handleOpen();
            }
          }}
        >
          {`${getCurrentButton()}`}
        </Button>
      </div>
    </div>
  );
}

export default AIScheduleSystem;
