import { alpha, Paper, styled } from "@mui/material";
// import EmojiPicker from "emoji-picker-react";
import { isMobile } from "react-device-detect";

export const TodosBoxContainer = styled("div")(({ theme }) => ({
  display: isMobile ? "inline-block" : "flex",
  position: "absolute",
  // overflowX: "scroll",
  width: "100%",
  boxSizing: "border-box",
  padding: "16px",
  flex: "0 0 600px",
  height: "90vh",
  justifyContent: "flex-start",
  flexDirection: "row",
  overflowY: "scroll",
  scrollbarWidth: "none",
  // marginRight: isMobile ? "0px" : "100px",
  "&::-webkit-scrollbar": {
    height: "5px",
    // width: "5px",
    background: theme.palette.primary.main,
    "-webkit-border-radius": "1ex",
  },

  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.secondary.light,
    "-webkit-border-radius": "1ex",
  },

  "&::-webkit-scrollbar-corner": {
    background: theme.palette.primary.main,
  },
}));

export const CustomBox = styled("div")`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(5, 1fr);
  padding: 8px;
  grid-template-rows: "repeat(6, 1fr)";
`;

export const CustomColor = styled("div")(({ theme }) => ({
  padding: "4px",
  margin: "1px",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "4px",
  "&:hover": {
    outline: `2px solid ${theme.palette.primary.dark}`,
    // backgroundColor: theme.palette.primary.main,
    cursor: "pointer",
  },
}));

export const PreviewTodosBoxContainer = styled("div")({
  display: isMobile ? "inline-block" : "flex",
  // position: "absolute",
  // overflowX: "scroll",
  width: "100%",
  boxSizing: "border-box",
  padding: "16px",
  height: "100vh",
  justifyContent: "center",
  flexDirection: "row",
  overflowY: "scroll",
  scrollbarWidth: "none",
  // marginRight: isMobile ? "0px" : "100px",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

export const TodoContainer = styled("div")({
  flex: "0 0 310px",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

export const TodoAddContainerLine = styled("div")<{ second: any }>(
  ({ theme, second }) => ({
    height: "40%",
    width: "2px",
    padding: "1px",

    cursor: "pointer",
    background: second
      ? `linear-gradient(135deg, ${alpha("#FBDA61", 0.5)} 0%, ${alpha(
          "#FF5ACD",
          0.5
        )} 100%)`
      : `linear-gradient(135deg, ${alpha("#FF5ACD", 0.5)} 0%, ${alpha(
          "#FBDA61",
          0.5
        )} 100%)`,

    backgroundColor: "white",
    borderRadius: "4px",
    marginBottom: "8px",
    marginTop: "8px",
  })
);

export const HoldTodoLines = styled("div")(({ theme }) => ({
  opacity: 0.1,
  justifyContent: "center",
  display: "flex",
  // marginLeft: "8px",
  // marginRight: "8px",
  flexDirection: "column",
  alignItems: "center",
  border: `2px solid ${theme.palette.primary.main}`,
  "&:hover": {
    opacity: 1,
  },
}));

export const TodoLineAndBoxContainer = styled("div")({
  // flexDirection: "row",
  width: isMobile ? "100%" : "30vw",
  display: isMobile ? "grid" : "flex",
  flex: "0 0 330px",
  overflowX: "scroll",
  overflowY: "clip",
});
