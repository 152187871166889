import { useTheme } from "@mui/material";
import React, { useState } from "react";
import BoxedText from "./BoxedText";
import moment from "moment";
import { StudyTypographyMediumSubtitle } from "./StudyTypography";
var Sherlock = require("sherlockjs");

function DateCollector({
  handleSetDue,
  handleSetCategory,
  handleSetTitle,
  handleSetDueText,
  value,
  placeholder,
}: any) {
  const [categories, setCategories] = useState<any>(
    JSON.parse(localStorage.getItem("categories")!) || []
  );
  const [pickedCategory, setPickedCategory] = useState<any>();
  const [title, setTitle] = useState("");
  const [due, setDue] = useState<any>();
  const [sherlockTitle, setSherlockTitle] = useState("");
  const [dateIndex, setDateIndex] = useState<number>(-1);

  function removeCommonWords(title: any, eventTitle: any) {
    title += " ";
    eventTitle += " ";
    const titleWords = title.split(" ");
    const eventTitleWords = eventTitle.split(" ");

    const filteredTitleWords = titleWords.filter(
      (word: any) => !eventTitleWords.includes(word) && word !== "undefined"
    );

    return filteredTitleWords.join(" ");
  }

  // function renderInputValue() {
  //   if (!title || !sherlockTitle) {
  //     return <span>{title}</span>;
  //   }

  //   const parts = title.split(sherlockTitle);
  //   return parts.map((part, i) => {
  //     if (i === parts.length - 1) {
  //       return <span key={i}>{part}</span>;
  //     }

  //     const start = part.length;
  //     const end = start + sherlockTitle.length;
  //     return (
  //       <React.Fragment key={i}>
  //         <span>{part}</span>
  //         <span
  //           style={{
  //             display: "inline-block",
  //             backgroundColor: "red",
  //             padding: "2px",
  //           }}
  //         >
  //           {sherlockTitle}
  //         </span>
  //       </React.Fragment>
  //     );
  //   });
  // }

  const findMatchingCategory = (text: string) => {
    for (const category of categories) {
      // Create a regular expression to search for the category name
      const regex = new RegExp("\\b" + category.name + "\\b", "i");

      // Check if the input text contains the category name
      if (regex.test(text)) {
        setPickedCategory(category);
        return category;
      }
    }

    setPickedCategory(null);
    return null;
  };

  const theme = useTheme();

  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <input
        autoFocus
        placeholder={placeholder}
        value={value}
        onChange={(text) => {
          setTitle(text.currentTarget.value);
          const sherlocked = Sherlock.parse(text.currentTarget.value);
          setDue(sherlocked.startDate);
          setSherlockTitle(sherlocked?.eventTitle);
          setDateIndex(sherlocked.index || -1);
          handleSetTitle(text.currentTarget.value);
          handleSetDue(sherlocked.startDate);
          handleSetDueText(
            removeCommonWords(text.currentTarget.value, sherlocked?.eventTitle)
          );
          handleSetCategory(
            findMatchingCategory(text.currentTarget.value) || null
          );
        }}
        style={{
          backgroundColor: theme.palette.primary.light,
          height: "32px",
          border: "0px",
          width: "100%",
          color: theme.palette.text.primary,
          outlineWidth: 0,
          outline: "none",
        }}
      ></input>
    </div>
  );
}

export default DateCollector;
