import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";
import { Sidebar } from "../components/sidebar/Sidebar";
import { Topbar } from "../components/topbar/topbar";
import { ContentWrapper } from "./styles";

export const DashboardLayout = ({
  setThemeValueToLight,
  setThemeValueToGray,
  setThemeValueToDark,
}: {
  setThemeValueToLight: any;
  setThemeValueToGray: any;
  setThemeValueToDark: any;
}) => {
  const [todos, setTodos] = useState(
    JSON.parse(localStorage.getItem("todos")!) || []
  );
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")!) || {}
  );
  const [categories, setCategories] = useState(
    JSON.parse(localStorage.getItem("categories")!) || []
  );

  useEffect(() => {
    localStorage.setItem("todos", JSON.stringify(todos));
  }, [todos]);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    localStorage.setItem("categories", JSON.stringify(categories));
  }, [categories]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        position: "fixed",
      }}
    >
      <Sidebar />
      {/* <Sidebar /> */}

      <ContentWrapper
      // style={{
      //   width: "100%",
      //   position: "fixed",
      //   display: "flex",
      //   flex: "0 0 100vw",
      //   boxSizing: "border-box",
      // }}
      >
        <div
          style={{
            // marginLeft: isMobile ? "0px" : "50px",
            // position: "fixed",
            width: "100%",
            // flex: 1,
            // height: "100vh",
            // overflowY: "clip",
          }}
        >
          <Topbar
            setThemeValueToLight={setThemeValueToLight}
            setThemeValueToGray={setThemeValueToGray}
            setThemeValueToDark={setThemeValueToDark}
            todos={todos}
            setTodos={setTodos}
            user={user}
            setUser={setUser}
            categories={categories}
            setCategories={setCategories}
          />
          <Toaster position="bottom-right" reverseOrder={false} />
          <Outlet
            context={[
              todos,
              setTodos,
              user,
              setUser,
              categories,
              setCategories,
            ]}
          />
        </div>
      </ContentWrapper>
    </div>
  );
};
