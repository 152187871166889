import { CalendarTodayOutlined } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  ClickAwayListener,
  Tooltip,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDrag } from "react-dnd";
import { toast } from "react-hot-toast";
import {
  UPDATE_TODO_CATEGORY_UUID,
  removeTodoStart,
  updateTodoDrag,
  updateTodoDuration,
  updateTodoState,
  updateTodoTitle,
} from "../../graphql/mutations/todo";
import { client } from "../../utils/apollo";
import {
  ADD_DUE,
  ADD_START,
  COMPLETE_TODO,
  DELETE_TODO,
  EDIT_TODO,
  MAINBAR_RIGHT_CLICK,
  REMOVE_START,
  STAGGER_CHILDREN,
  timeDuration,
} from "../../utils/constants";
import { DraggingContext } from "../../utils/contexts";
import { ItemTypes } from "../../utils/types";
import { HoverTypography } from "../HoverTypography";
import { CheckIcon } from "../Icons/CheckIcon";
import CryptographicString from "../glovebox/CryptographicString";
import DurationBox from "../glovebox/DurationBox";
import { EditToDoModal } from "../glovebox/EditToDoModal";
import { StudyTypographyMediumSubtitle } from "../glovebox/StudyTypography";
import {
  ProfileBlockPopper,
  SwitchCategoryContainer,
  ToolbarBackground,
  ToolbarToDoDurationText,
  ToolbarToDoLeftSide,
  ToolbarToDoSubtitle,
  ToolbarToDoTitle,
} from "./styles";

export const ToDoBar = ({
  data,
  index,
  toolbar,
  handleDeleteToDo,
  schedule,
  todos,
  setTodos,
}: // updateTodoList,
{
  data: any;
  index: any;
  toolbar: boolean;
  handleDeleteToDo: any;
  schedule?: any;
  todos: any;
  setTodos: any;
  // updateTodoList: any;
}) => {
  const [editTodo, setEditTodo] = useState(false);
  const [editTodoText, setEditTodoText] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const { user, setUser } = useContext(UserContext);
  const [textFocused, setTextFocused] = useState("fc-event");
  const [selectedTask, setSelectedTask] = useState<any>();
  const [openEditTodo, setOpenEditTodo] = useState<any>();

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")!) || []
  );
  const [categories, setCategories] = useState<any>(
    JSON.parse(localStorage.getItem("categories")!) || []
  );
  useEffect(() => {
    localStorage.setItem("categories", JSON.stringify(categories));
  }, [categories]);
  // const [todos, setTodos] = useState(JSON.parse(localStorage.getItem('todos')!) || [])
  // // const { id: userId } = useUserContext();
  const [value, setValue] = useState<any>(moment().format("HH:MM"));
  const [timeOpen, setTimeOpen] = useState(false);
  const [remove, setRemove] = useState(false);
  const handleChange = (newValue: any) => {
    setValue(newValue);
  };
  const userId = localStorage.getItem("userId");
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [dateAnchorEl, setDateAnchorEl] = useState<null | HTMLElement>(null);
  const handleClickDate = (event: React.MouseEvent<HTMLElement>) => {
    setDateAnchorEl(dateAnchorEl ? null : event.currentTarget);
  };
  const dateOpen = Boolean(dateAnchorEl);

  const [categoryAnchorEl, setCategoryAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const handleCategoryClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setCategoryAnchorEl(categoryAnchorEl ? null : event.currentTarget);
  };
  const categoryOpen = Boolean(categoryAnchorEl);

  const [clickAnchorEl, setClickAnchorEl] = useState<null | HTMLElement>();
  const clickOpen = Boolean(clickAnchorEl);

  const returnHours = (time: any) => {
    var hours = time.split(":")[0];
    return hours;
  };
  const returnMinutes = (time: any) => {
    var minutes = time.split(":")[1];
    return minutes;
  };

  const handleRightClick = (event: any) => {
    event.preventDefault();
    console.log("IM BEING PRESSED !");
    setClickAnchorEl(clickAnchorEl ? null : event.currentTarget);
    // Trigger your custom action here
  };

  // const [updateTodo, { data: utdata }] = useMutation(UPDATE_TODO_UUID, {
  //   refetchQueries: [
  //     {
  //       query: GET_USER_TODOS,
  //       variables: { userId: userId },
  //     },
  //     "GetUserTodos",
  //   ],
  //   // refetchQueries()
  // });

  useEffect(() => {
    // console.log(todos)
    localStorage.setItem("todos", JSON.stringify(todos));
    // console.log(JSON.parse(localStorage.getItem('todos')!));
  }, [todos]);

  const updateTodo = (todoUUID: string, dropInfo: any) => {
    toast.promise(
      updateTodoDrag(dropInfo, todoUUID, todos, setTodos),

      {
        loading: "adding todo..",
        success: <b>todo saved!</b>,
        error: <b>Could not save.</b>,
      },
      {
        duration: 4000,
        style: {
          fontSize: "12px",
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.primary,
        },
      }
    );
  };

  const getCategory = (categoryId: any) => {
    const category =
      categories[
        categories.findIndex((category: any) => categoryId === category?.uuid)
      ];
    return category;
  };

  const updateTodoStateHelper = async (
    oldTodo: any,
    completed: boolean,
    deleted: boolean
  ) => {
    await updateTodoState(oldTodo, completed, deleted, todos, setTodos);
  };

  const updateTodoCategory = (todoUUID: string, category: any) => {
    let newTodos = [...todos];
    newTodos = newTodos?.map((todo) => {
      let newTodo = { ...todo };
      if (todo.uuid === todoUUID) {
        newTodo.categoryId = category?.uuid;
      }
      return newTodo;
    });
    setTodos(newTodos);
    client.mutate({
      mutation: UPDATE_TODO_CATEGORY_UUID,
      variables: {
        uuid: todoUUID,
        categoryId: category?.uuid,
        categoryLabel: category?.label,
        category: category?.name,
      },
    });
  };

  const updateTodoTitleHelper = (oldTodo: any) => {
    toast.promise(
      updateTodoTitle(oldTodo, editTodoText, todos, setTodos),
      {
        loading: "adding todo..",
        success: <b>todo saved!</b>,
        error: <b>Could not save.</b>,
      },
      {
        duration: 4000,
        style: {
          fontSize: "12px",
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.primary,
        },
      }
    );
  };

  const handleFocus = (event: any) => event.target.select();

  const nullTodoStart = (todo: any) => {
    toast.promise(
      removeTodoStart(data, todos, setTodos),
      {
        loading: "updating todo..",
        success: <b>start time update saved!</b>,
        error: <b>Could not save.</b>,
      },
      {
        duration: 4000,
        style: {
          fontSize: "12px",
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.primary,
        },
      }
    );
  };

  const updateTodoDurationHelper = (oldTodo: any, duration: number) => {
    toast.promise(
      updateTodoDuration(oldTodo, duration, todos, setTodos),
      {
        loading: "adding todo..",
        success: <b>todo saved!</b>,
        error: <b>Could not save.</b>,
      },
      {
        duration: 4000,
        style: {
          fontSize: "12px",
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.primary,
        },
      }
    );
  };

  const { isComponentDragging, setIsDragging } = useContext(DraggingContext);
  const theme = useTheme();
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.BOX,
    item: index,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      setIsDragging(false);

      if (item && dropResult) {
        updateTodo(data?.uuid, dropResult);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  useEffect(() => {
    if (isDragging) {
      setIsDragging(true);
    }
  }, [isDragging]);
  // console.log(data)

  // const StyledTimePicker = styled(TimePicker)`
  //   .popupStyles {
  //     background-color: ${theme.palette.primary.main};
  //     color: ${theme.palette.text.primary};
  //   }
  //   .ant-picker-content {
  //     background-color: red;
  //   }
  // `;
  return (
    <motion.div
      variants={STAGGER_CHILDREN}
      className={textFocused}
      id={data?.uuid}
    >
      {selectedTask ? (
        <EditToDoModal
          todo={selectedTask}
          todos={todos}
          setTodos={setTodos}
          openEdit={openEditTodo && selectedTask?.uuid === data?.uuid}
          setOpenEditTodo={setOpenEditTodo}
          title={selectedTask?.title}
        />
      ) : null}
      <ToolbarBackground
        ref={drag}
        onContextMenu={handleRightClick}
        // onClick={(e: any) => {
        //   e.stopPropagation();
        //   e.preventDefault();

        //   console.log("YOOO");
        //   console.log(clickAnchorEl);
        //   const holdClickAnchor = clickAnchorEl;
        //   setClickAnchorEl(holdClickAnchor ? null : e.currentTarget);
        // }}
        onDoubleClick={isMobile ? handleRightClick : setValue}
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <motion.div
          key={data?.uuid}
          style={{
            height: toolbar ? "40px" : `${data?.duration * 1.5}px`,
            marginBottom: toolbar ? "4px" : "0px",
            border: clickOpen
              ? `2px solid ${theme.palette.text.primary}`
              : `0px`,
            borderRadius: schedule ? "0px" : "4px",
            alignItems: "center",
            padding: user?.compact ? "2px 8px 2px 8px" : "8px",
            backgroundColor: theme.palette.primary.light,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          whileHover={{
            scale: 0.98,
            borderRadius: "4px",
          }}
          whileTap={{
            borderRadius: "8px",
          }}
          // style={{ backgroundColor: "red" }}
          onClick={(event: any) => {
            event.stopPropagation();
            event.preventDefault();
            setOpenEditTodo(true);
            setSelectedTask(data);
          }}
        >
          <ToolbarToDoLeftSide>
            <motion.div
              whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
              whileHover={{ rotate: 10 }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 8,
              }}
              style={{
                alignItems: "center",
                alignSelf: "center",
                display: "flex",
              }}
            >
              <CheckIcon
                height={user?.compact ? "18px" : "24px"}
                width={user?.compact ? "18px" : "24px"}
                active={data?.completedAt !== null}
                clicked={(event: any) => {
                  event?.stopPropagation();
                  updateTodoStateHelper(
                    data,
                    data?.completedAt ? false : true,
                    false
                  );
                }}
              />
            </motion.div>
            {editTodo ? (
              <div
                style={{
                  width: "100%",
                  marginLeft: "12px",
                  alignItems: "flex-start",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
              >
                <ClickAwayListener
                  onClickAway={() => {
                    setEditTodo(false);
                  }}
                >
                  <form
                    onSubmit={(event: any) => {
                      event?.preventDefault();
                      updateTodoTitleHelper(data);
                      setEditTodo(false);
                    }}
                    style={{ width: "100%" }}
                  >
                    <input
                      autoFocus
                      suppressContentEditableWarning={true}
                      contentEditable={true}
                      // onFocus={handleFocus}
                      draggable={true}
                      onDragStart={(event) => event.preventDefault()}
                      style={{
                        border: "0px",
                        backgroundColor: theme.palette.primary.light,
                        width: "100%",
                        height: "15px",
                        alignSelf: "center",
                        alignItems: "center",
                        display: "flex",
                        // paddingTop: "10px",
                        // paddingBottom: "10px",
                        // paddingLeft: "12px",
                        padding: "0px",
                        margin: "0px",
                        outlineWidth: 0,
                        // borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: "lighter",
                        fontStyle: "Space Grotesk",
                        color: theme.palette.text.primary,
                      }}
                      onClick={(event: any) => {
                        event?.preventDefault();
                        event?.stopPropagation();
                      }}
                      // onMouseEnter={() => setTextFocused(true)}
                      // onMouseLeave={() => setTextFocused(false)}
                      placeholder={"text new todo.."}
                      type={"text"}
                      name="title"
                      value={editTodoText}
                      onChange={(text) => {
                        setEditTodoText(text.target.value);
                      }}
                    ></input>
                  </form>
                </ClickAwayListener>

                <Breadcrumbs style={{ height: "18px", flex: 0 }}>
                  {moment(data?.startTime).date() === new Date().getDate() ? (
                    <ToolbarToDoSubtitle>
                      {moment(data?.startTime).format("h:mma")}
                    </ToolbarToDoSubtitle>
                  ) : moment(data?.startTime) &&
                    moment(data?.startTime).isValid() ? (
                    <ToolbarToDoSubtitle>
                      {moment(data?.startTime).format("M/DD")}
                    </ToolbarToDoSubtitle>
                  ) : null}
                  {moment(data?.dueAt).date() === new Date().getDate() &&
                  moment(data?.dueAt).isValid() ? (
                    <ToolbarToDoSubtitle
                      style={{ color: theme.palette.error.light }}
                    >
                      {moment(data?.startTime).format("h:mma")}
                    </ToolbarToDoSubtitle>
                  ) : (data?.dueAt ||
                      (moment(data?.dueAt) && !data?.completedAt)) &&
                    moment(data?.dueAt).isValid() ? (
                    <ToolbarToDoSubtitle
                      style={{ color: theme.palette.error.light }}
                    >
                      {moment(data?.dueAt).format("M/DD")}
                    </ToolbarToDoSubtitle>
                  ) : null}
                </Breadcrumbs>
              </div>
            ) : (
              <div
                style={{
                  marginLeft: "12px",
                }}
              >
                <ToolbarToDoTitle
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                  }}
                  onClick={(event: any) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setTextFocused("null");
                    setEditTodo(true);
                    setEditTodoText(data?.title);
                  }}
                  onDrag={() => {
                    setTextFocused("fc-event");
                  }}
                >
                  <CryptographicString target={data?.title} />
                </ToolbarToDoTitle>

                <Breadcrumbs>
                  {moment(data?.startTime).isValid() ? (
                    moment(data?.startTime).date() === new Date().getDate() ? (
                      <ToolbarToDoSubtitle>
                        {moment(data?.startTime).format("h:mma")}
                      </ToolbarToDoSubtitle>
                    ) : (
                      <ToolbarToDoSubtitle>
                        {moment(data?.startTime).format("M/DD")}
                      </ToolbarToDoSubtitle>
                    )
                  ) : null}
                  {moment(data?.dueAt).isValid() ? (
                    moment(data?.dueAt).date() === new Date().getDate() ? (
                      <ToolbarToDoSubtitle
                        style={{ color: theme.palette.error.light }}
                      >
                        {moment(data?.dueAt).format("h:mma")}
                      </ToolbarToDoSubtitle>
                    ) : (
                      <ToolbarToDoSubtitle
                        style={{ color: theme.palette.error.light }}
                      >
                        {moment(data?.dueAt).format("M/DD")}
                      </ToolbarToDoSubtitle>
                    )
                  ) : null}
                </Breadcrumbs>
              </div>
            )}
          </ToolbarToDoLeftSide>
          {editTodo ? (
            <Button
              variant="text"
              color="secondary"
              sx={{
                textTransform: "none",
                marginLeft: "12px",
                padding: "4px",
                backgroundColor: theme.palette.secondary.light,
              }}
              onClick={(event: any) => {
                // updateTodo({
                //   variables: { title: editTodoText, uuid: data?.uuid },
                // });
                event.stopPropagation();
                updateTodoTitleHelper(data);
                setEditTodo(false);
              }}
            >
              save
            </Button>
          ) : (
            <ClickAwayListener
              onClickAway={() => {
                setAnchorEl(null);
              }}
            >
              <DurationBox
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                num={
                  data.reoccurring
                    ? data?.todoReplicas[0]?.duration
                    : data?.duration
                }
                user={user}
              />
            </ClickAwayListener>
          )}

          <ProfileBlockPopper
            open={open}
            anchorEl={anchorEl}
            placement={"bottom-start"}
            modifiers={[{ name: "arrow", enabled: true }]}
            style={{
              backgroundColor: "rgba(0,0,0,0)",
              margin: 0,
              paddingRight: 0,
              zIndex: 100,
              paddingTop: "8px",
            }}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          >
            <div
              style={{
                alignItems: "center",
                display: "inline-grid",
                justifyContent: "center",
                backgroundColor: theme.palette.primary.main,
                border: `1px solid ${theme.palette.secondary.light}`,
                padding: "4px",
                borderRadius: "4px",
              }}
            >
              {timeDuration.map((time) => (
                <ToolbarToDoDurationText
                  style={{
                    padding: "8px",
                    textAlign: "center",
                    color: theme.palette.text.primary,
                  }}
                  onClick={(event: any) => {
                    // updateTodo({
                    //   variables: { duration: time, uuid: data?.uuid },
                    // });
                    event?.stopPropagation();
                    setAnchorEl(null);
                    updateTodoDurationHelper(data, time);
                  }}
                >
                  {time} min
                </ToolbarToDoDurationText>
              ))}
            </div>
          </ProfileBlockPopper>
          <ClickAwayListener
            onClickAway={() => {
              setCategoryAnchorEl(null);
            }}
          >
            <Tooltip
              title={getCategory(data?.categoryId)?.name}
              placement={"top"}
            >
              {getCategory(data?.categoryId)?.label === "Google Calendar" ? (
                <div onClick={handleCategoryClick}>
                  <CalendarTodayOutlined
                    style={{
                      padding: 0,
                      margin: 0,
                      width: "18px",
                      height: "18px",
                      marginLeft: "12px",
                    }}
                  />
                </div>
              ) : (
                <div
                  onClick={handleCategoryClick}
                  style={{
                    whiteSpace: "nowrap",
                    width: "14px",
                    height: "14px",
                    border: `2px solid ${getCategory(data?.categoryId)?.label}`,
                    borderRadius: "4px",
                    marginLeft: "12px",
                  }}
                ></div>
              )}
            </Tooltip>
          </ClickAwayListener>

          <ProfileBlockPopper
            open={categoryOpen}
            anchorEl={categoryAnchorEl}
            placement={"bottom-start"}
            modifiers={[{ name: "arrow", enabled: true }]}
            style={{
              backgroundColor: "rgba(0,0,0,0)",
              margin: 0,
              paddingRight: 0,
              paddingTop: "8px",
              zIndex: 100,
            }}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          >
            <div
              style={{
                alignItems: "center",
                display: "inline-grid",
                justifyContent: "center",
                backgroundColor: theme.palette.primary.main,
                border: `1px solid ${theme.palette.secondary.light}`,
                padding: "4px",
                borderRadius: "4px",
              }}
            >
              {categories.map((category: any) => (
                <SwitchCategoryContainer
                  onClick={() => {
                    updateTodoCategory(data?.uuid, category);
                    setCategoryAnchorEl(null);
                  }}
                >
                  {category.label === "Google Calendar" ? (
                    <div onClick={handleCategoryClick}>
                      <CalendarTodayOutlined
                        style={{
                          padding: 0,
                          margin: 0,
                          width: "18px",
                          height: "18px",
                          marginRight: "12px",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "14px",
                        height: "14px",
                        border: `2px solid ${category?.label}`,
                        borderRadius: "4px",
                        marginRight: "12px",
                      }}
                    ></div>
                  )}
                  <StudyTypographyMediumSubtitle
                    style={{
                      textAlign: "left",
                    }}
                    onClick={() => {
                      // updateTodo({
                      //   variables: { duration: time, uuid: data?.uuid },
                      // });
                      // updateTodoDuration(data?.uuid, time);
                    }}
                  >
                    {category?.name}
                  </StudyTypographyMediumSubtitle>
                </SwitchCategoryContainer>
              ))}
            </div>
          </ProfileBlockPopper>

          <ClickAwayListener
            onClickAway={() => {
              setClickAnchorEl(null);
            }}
          >
            <ProfileBlockPopper
              open={clickOpen}
              anchorEl={clickAnchorEl}
              placement={toolbar ? "bottom-end" : "bottom"}
              modifiers={[{ name: "arrow", enabled: true }]}
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                paddingTop: "8px",
              }}
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
            >
              <div
                style={{
                  alignItems: "center",
                  display: "inline-grid",
                  justifyContent: "center",
                  backgroundColor: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.secondary.light}`,
                  padding: "8px",
                  borderRadius: "4px",
                }}
              >
                {MAINBAR_RIGHT_CLICK.map((item) => {
                  return (
                    <HoverTypography
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        paddingBottom: "4px",
                        paddingTop: "4px",

                        borderRadius: "4px",
                        color:
                          item.name === "delete todo"
                            ? theme.palette.error.dark
                            : theme.palette.text.primary,
                      }}
                      onClick={(event: any) => {
                        // setClickAnchorEl(null);
                        event.stopPropagation();
                        event.preventDefault();
                        if (item.name === EDIT_TODO) {
                          setEditTodo(true);
                          setEditTodoText(data?.title);
                        } else if (item.name === REMOVE_START) {
                          nullTodoStart(data);
                        } else if (item.name === COMPLETE_TODO) {
                          // updateTodo({
                          //   variables: {
                          //     uuid: data?.uuid,
                          //     completed: true,
                          //   },
                          // });
                          updateTodoStateHelper(data, true, false);
                        } else if (item.name === DELETE_TODO) {
                          // updateTodo({
                          //   variables: {
                          //     uuid: data?.uuid,
                          //     deleted: true,
                          //   },
                          // });
                          // updateTodoState(data, true, false)

                          handleDeleteToDo(data);
                        } else if (item.name === ADD_START) {
                          handleClickDate(event);
                        } else if (item.name === ADD_DUE) {
                          handleClickDate(event);
                        }
                      }}
                    >
                      {item.icon}
                      <StudyTypographyMediumSubtitle
                        style={{
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          borderRadius: "4px",
                          paddingLeft: "8px",
                          color:
                            item.name === "delete todo"
                              ? theme.palette.error.dark
                              : theme.palette.text.primary,
                        }}
                      >
                        {item.name}
                      </StudyTypographyMediumSubtitle>
                    </HoverTypography>
                  );
                })}
              </div>
            </ProfileBlockPopper>
          </ClickAwayListener>
          {/* <ClickAwayListener
          onClickAway={() => {
            setClickAnchorEl(null);
          }}
        >
          <ProfileBlockPopper
            open={dateOpen}
            anchorEl={clickAnchorEl}
            placement={toolbar ? "bottom-end" : "bottom"}
            modifiers={[{ name: "arrow", enabled: true }]}
            style={{
              backgroundColor: theme.palette.secondary.light,
              padding: "4px",
              color: theme.palette.text.primary,
              alignItems: "center",
              display: "flex",
            }}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          >
            <StyledTimePicker
              use12Hours
              format="h:mm a"
              onChange={(date) => {
                setValue(date);
              }}
              open={timeOpen}
              placeholder={""}
              showNow={false}
              suffixIcon={null}
              onOk={(date) => {
                updateTodoStartTime(dayjs(date).toDate(), data?.uuid);
                setClickAnchorEl(null);
                setDateAnchorEl(null);
                setTimeOpen(false);
              }}
              style={{
                backgroundColor: theme.palette.secondary.light,
                color: "white",
                border: "none",
                opacity: 0,
                width: "0px",
                padding: "0px",
              }}
              popupStyle={{
                backgroundColor: "rgba(0,0,0,0)",
                color: theme.palette.text.primary,
              }}
            />
            <StudyTypographyMediumSubtitle
              sx={{
                padding: "4px",
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                setTimeOpen(!timeOpen);
              }}
            >
              Select time
            </StudyTypographyMediumSubtitle>
            <CloseRounded
              sx={{
                width: "16px",
                height: "16px",
                marginLeft: "12px",
                borderRadius: "2px",
                padding: "4px",
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  cursor: "pointer",
                },
              }}
              onClick={(event: any) => {
                event.preventDefault();
                setDateAnchorEl(null);
              }}
            />
          </ProfileBlockPopper>
        </ClickAwayListener> */}
        </motion.div>
      </ToolbarBackground>
    </motion.div>
  );
};
