import { Add } from "@mui/icons-material";
import { alpha, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { isMobile } from "react-device-detect";
import { useHotkeys } from "react-hotkeys-hook";
import { useOutletContext } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  CONNECT_BASE_BULK,
  DELETE_CATEGORY_UUID,
} from "../../../graphql/mutations/categories";
import {
  DELETE_CHILD_TODO,
  DeleteTodo,
  DELETE_TODO_UUID,
  UPDATE_TODO_CATEGORY_UUID,
  UPDATE_TODO_UUID,
} from "../../../graphql/mutations/todo";
import { ADD_USER_CATEGORY } from "../../../graphql/mutations/user";
import { client } from "../../../utils/apollo";
import { STUDYO_GRADIENT } from "../../../utils/constants";
import { HOTKEYS } from "../../../utils/hotkeyHelper";
import { AddIcon } from "../../Icons/AddIcon";
import {
  HoldTodoLines,
  TodoAddContainerLine,
  TodoLineAndBoxContainer,
  TodosBoxContainer,
} from "./styles";
import { ToDoContainer } from "./ToDoContainer";

export const TodosMain = () => {
  const [todos, setTodos, user, setUser, categories, setCategories] =
    useOutletContext<any>();
  const [title, setTitle] = useState("");
  const [duration, setDuration] = useState(30);
  // const [categories, setCategories] = useState<any[]>([]);
  const [newCategory, setNewCategory] = useState("");
  const [newCategoryLabel, setNewCategoryLabel] = useState("");
  // const [categories, setCategories] = useState<any>(
  //   JSON.parse(localStorage.getItem("categories")!) || []
  // );
  // // console.log(categories);
  // const [user, setUser] = useState(
  //   JSON.parse(localStorage.getItem("user")!) || []
  // );
  // const [todos, setTodos] = useState(
  //   JSON.parse(localStorage.getItem("todos")!) || []
  // );

  // const [categories]
  // console.log(categories);
  // console.log(JSON.parse(localStorage.getItem("categories")!));

  const [showPinned, setShowPinned] = useState(false);
  const [showInput, setShowInput] = useState(true);

  const inputRef = useRef<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  // const { id: userId } = useUserContext();
  const userId = localStorage.getItem("userId");

  const theme = useTheme();

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    localStorage.setItem("categories", JSON.stringify(categories));
  }, [categories]);

  useEffect(() => {
    localStorage.setItem("todos", JSON.stringify(todos));
  }, [todos]);

  const addCategory = () => {
    let newCategoryUUID = uuid();
    let updatedCategories = [
      ...categories,
      {
        id: null,
        name: newCategory,
        uuid: newCategoryUUID,
        label: "#FFFFFF",
        __typename: "Category",
      },
    ];
    setCategories(updatedCategories);
    client
      .mutate({
        mutation: ADD_USER_CATEGORY,
        variables: {
          userId: user.id,
          category: newCategory,
          uuid: newCategoryUUID,
          categoryLabel: "#FFFFFF",
        },
      })
      .then(() => {
        setNewCategory("");
      });
  };

  // const [deleteTodo] = useMutation(DELETE_TODO_UUID, {
  //   refetchQueries: [
  //     {
  //       query: GET_USER_TODOS,
  //       variables: { userId: userId },
  //     },
  //     "GetUserTodos",
  //   ],
  //   awaitRefetchQueries: true,
  // });

  // const handleDeleteToDo = (uuid: string) => {
  //   deleteTodo({
  //     variables: {
  //       uuid: uuid,
  //     },
  //   });
  // };

  const handleDeleteTodoHelper = async (todo: any) => {
    await DeleteTodo(todo, todos, setTodos);
  };

  const handleSetCategory = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setNewCategory(e.target.value);
  };

  useHotkeys(HOTKEYS.ENTER_TODO, () => {
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  });

  const onDragEnd = (result: any, categories: any, setCategories: any) => {
    // console.log(result)
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      // const editCategories = categories;
      const sourceIndex = categories.findIndex(
        (category: any) => category?.id === source.droppableId
      );
      const destinationIndex = categories.findIndex(
        (category: any) => category?.uuid === destination.droppableId
      );
      // console.log(result.draggableId);
      // const sourceColumn = categories[sourceIndex];
      // const destColumn = categories[destinationIndex];
      // const sourceItems = [...sourceColumn.todos];
      // const destItems = [...destColumn.todos];
      // const [removed] = sourceItems.splice(source.index, 1);
      // destItems.splice(destination.index, 0, removed);
      // editCategories[sourceIndex].todos = sourceItems;
      // editCategories[destinationIndex].todos = destItems;
      // setCategories(editCategories);
      let newTodos = [...todos];
      newTodos = newTodos?.map((todo) => {
        let newTodo = { ...todo };
        if (todo.uuid === result.draggableId) {
          newTodo.categoryId = categories[destinationIndex].uuid;
        }
        return newTodo;
      });
      setTodos(newTodos);
      client.mutate({
        mutation: UPDATE_TODO_CATEGORY_UUID,
        variables: {
          uuid: result.draggableId,
          category: categories[destinationIndex]?.name,
          categoryId: categories[destinationIndex]?.uuid,
          categoryLabel: categories[destinationIndex]?.label,
          oldCategoryId: categories[sourceIndex]?.uuid,
        },
      });

      // console.log(editCategories);
    }
    // else {
    //   const sourceIndex = categories.findIndex(
    //     (category: any) => category?.id === source.droppableId
    //   );
    //   const editCategories = categories;
    //   const column = categories[sourceIndex];
    //   const copiedItems = [...column.todos];
    //   const [removed] = copiedItems.splice(source.index, 1);
    //   copiedItems.splice(destination.index, 0, removed);
    //   editCategories[sourceIndex].todos = copiedItems;
    //   setCategories(editCategories);
    //   // console.log(editCategories);
    // }
  };

  return (
    <DragDropContext
      onDragEnd={(result) => {
        onDragEnd(result, categories, setCategories);
      }}
      onDragStart={() => {}}
    >
      <TodosBoxContainer>
        {categories?.length === 0 ? (
          <TodoLineAndBoxContainer>
            <Typography>Create first category!</Typography>
            <input
              type="text"
              placeholder="Add a new Category"
              onChange={handleSetCategory}
              value={newCategory}
              required
            />
            <AddIcon
              clicked={() => {
                addCategory();
              }}
            />
          </TodoLineAndBoxContainer>
        ) : null}
        {isMobile
          ? categories?.map((category: any, index: number) => (
              <Droppable droppableId={category?.uuid} key={category?.uuid}>
                {(provided, snapshot) => {
                  // console.log(categories)
                  // console.log(Object.getOwnPropertyNames(category))
                  return (
                    <TodoLineAndBoxContainer
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <ToDoContainer
                        handleDeleteToDo={handleDeleteTodoHelper}
                        category={category}
                        categoryIndex={index}
                        categories={categories}
                        todos={todos}
                        setTodos={setTodos}
                        setCategories={setCategories}
                        showInput={showInput}
                      />
                    </TodoLineAndBoxContainer>
                  );
                }}
              </Droppable>
            ))
          : categories?.map((category: any, index: number) => (
              <Droppable droppableId={category?.uuid} key={category?.uuid}>
                {(provided, snapshot) => {
                  // console.log(categories)
                  // console.log(Object.getOwnPropertyNames(category))
                  return (
                    <TodoLineAndBoxContainer
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <ToDoContainer
                        handleDeleteToDo={handleDeleteTodoHelper}
                        category={category}
                        categoryIndex={index}
                        categories={categories}
                        todos={todos}
                        setTodos={setTodos}
                        setCategories={setCategories}
                        showInput={showInput}
                      />

                      <HoldTodoLines
                        onClick={() => {
                          addCategory();
                        }}
                      >
                        <TodoAddContainerLine second={false} />
                        <Add
                          style={{
                            width: "16px",
                            height: "16px",
                            alignSelf: "center",
                            color: theme.palette.text.primary,
                          }}
                        />
                        <TodoAddContainerLine second={true} />
                      </HoldTodoLines>
                    </TodoLineAndBoxContainer>
                  );
                }}
              </Droppable>
            ))}
        {isMobile ? (
          <div>
            <Button
              onClick={() => {
                addCategory();
              }}
              fullWidth
              style={{
                background: STUDYO_GRADIENT,
                backgroundColor: "white",
                marginTop: "12px",
                textTransform: "unset",
              }}
            >
              add category
            </Button>
            {isMobile ? (
              <div style={{ height: "80px", width: "100%" }}></div>
            ) : null}
          </div>
        ) : null}
        <div style={{ marginRight: "50px" }}></div>
      </TodosBoxContainer>
    </DragDropContext>
  );
};
