import { gql } from "@apollo/client";

export const GET_TODOS = gql`
  query GetTodos {
    getTodos {
      id
      title
      startTime
      duration
    }
  }
`;

export const GET_USER_TODOS = gql`
  query GetUserTodos($userId: String!) {
    getUserTodos(userId: $userId) {
      id
      title
      duration
      startTime
      completedAt
      deletedAt
      createdAt
      dueAt
      categoryId
      uuid
      gCald
      event
      reoccurring
      todoReplicas {
        id
        duration
        startTime
        completedAt
        todoId
        event
        uuid
      }
    }
  }
`;

export const GET_FRIEND_TODOS = gql`
  query GetUserTodos($userId: String!) {
    getUserTodos(userId: $userId) {
      id
      duration
      startTime
      todoReplicas {
        id
        duration
        startTime
        completedAt
        todoId
        uuid
      }
      completedAt
    }
  }
`;
export const GET_FRIEND_TODOS_TITLE_PERMISION = gql`
  query GetUserTodos($userId: String!) {
    getUserTodos(userId: $userId) {
      id
      duration
      startTime
      title
      todoReplicas {
        id
        duration
        startTime
        completedAt
        todoId
        uuid
      }
      completedAt
    }
  }
`;


export const USER_GOOGLE_CAL_TODOS = gql`
query GetUserGoogleCalTodos($userId: String!) {
    getUserGoogleCalTodos(userId: $userId) {
        id
        uuid
        gCalId
    }
}`
// query getTodos($userId: ID!) {
//     getTaskTemplatesByUserId(userId: $userId) {
//       ...TaskTemplateFragment
//     }
//   }
//   ${TaskTemplateFragment}
