/* eslint-disable jsx-a11y/alt-text */
import { MoreHorizOutlined } from "@mui/icons-material";
import { alpha, Box, Grid, useTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { random_rgba } from "../../../utils/constants";
import { StudyTypographyMediumTitle } from "../../glovebox/StudyTypography";
import { ProfileBlockPopper } from "../../toolbar/styles";

export const SmallCoworkGroup = ({
  data,
  invite,
  mutation,
  handleSetNewGroup,
  height,
}: {
  data: any;
  invite: boolean;
  mutation: any;
  handleSetNewGroup: any;
  height?: any;
}) => {
  //   const [groups, setGroups] = useState(data);
  //   const [group, setGroup] = useState(
  //     JSON.parse(localStorage.getItem("group")!) || {}
  //   );
  const navigate = useNavigate();
  //   console.log(group);
  //   useEffect(() => {
  //     localStorage.setItem("group", JSON.stringify(group));
  //   }, [group]);

  const [recievedAnchorEl, setRecievedAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const recievedOpen = Boolean(recievedAnchorEl);

  const handleRecievedClick = (event: React.MouseEvent<HTMLElement>) => {
    setRecievedAnchorEl(recievedAnchorEl ? null : event.currentTarget);
  };

  const theme = useTheme();
  return (
    <Grid item xs={4} width={"100%"} key={data?.uuid} data-testid={`box`}>
      <div
        onClick={() => {
          if (!invite) {
            navigate(`/app/cowork/${data.uuid}`);
          }
        }}
        style={{
          backgroundColor: theme.palette.primary.main,
          border: `3px solid ${theme.palette.primary.light}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px",
          borderRadius: "4px",
          flexDirection: "row",
          marginBottom: "8px",
          // height: height ? height : "200px",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            display: "flex",
          }}
        >
          {data.profileImageURL ? (
            <img
              src={`https://${process.env.REACT_APP_PUBLIC_AWS_S3_BUCKET_NAME}.s3.amazonaws.com/${data.id}`}
              // {...register('image', { required: true })}
              style={{
                borderRadius: "4px",
                marginRight: "12px",
                height: "30px",
                width: "30px",
                cursor: "pointer",
              }}
            />
          ) : (
            <div
              style={{
                borderRadius: "4px",
                marginRight: "12px",
                height: "30px",
                width: "30px",
                cursor: "pointer",
                background: `linear-gradient(135deg, ${alpha(
                  random_rgba(),
                  1
                )} 0%, ${alpha(random_rgba(), 1)} 100%)`,
                backgroundColor: "white",
              }}
            />
          )}
          <StudyTypographyMediumTitle>{data.name}</StudyTypographyMediumTitle>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <MoreHorizOutlined
            onClick={(event: any) => {
              event.stopPropagation();

              handleRecievedClick(event);
            }}
            sx={{
              padding: "4px",

              "&:hover": {
                backgroundColor: theme.palette.primary.light,
                borderRadius: "4px",
                cursor: "pointer",
              },
            }}
          />
        </div>
        {invite ? (
          <ProfileBlockPopper
            open={recievedOpen}
            anchorEl={recievedAnchorEl}
            placement={"bottom"}
            modifiers={[{ name: "arrow", enabled: true }]}
            style={{
              backgroundColor: "rgba(0,0,0,0)",
              margin: 0,
              paddingRight: 0,
              zIndex: 100,
              paddingTop: "8px",
            }}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "inline-grid",
                justifyContent: "center",
                backgroundColor: theme.palette.secondary.light,
                // padding: "8px",
                borderRadius: "4px",
                margin: "4px",
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "inline-grid",
                  justifyContent: "center",
                  backgroundColor: theme.palette.secondary.light,
                  padding: "8px 16px 8px 16px",
                  borderRadius: "4px",
                  margin: "4px",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.light,

                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  mutation(data, true);
                  setRecievedAnchorEl(null);
                }}
              >
                Accept
              </Box>
              <Box
                sx={{
                  alignItems: "center",
                  display: "inline-grid",
                  justifyContent: "center",
                  backgroundColor: theme.palette.secondary.light,
                  padding: "8px 16px 8px 16px",
                  borderRadius: "4px",
                  margin: "4px",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.error.main,
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  mutation(data, false);
                  setRecievedAnchorEl(null);
                }}
              >
                Reject
              </Box>
            </Box>
          </ProfileBlockPopper>
        ) : (
          <ProfileBlockPopper
            open={recievedOpen}
            anchorEl={recievedAnchorEl}
            placement={"bottom"}
            modifiers={[{ name: "arrow", enabled: true }]}
            style={{
              backgroundColor: "rgba(0,0,0,0)",
              margin: 0,
              paddingRight: 0,
              zIndex: 100,
              paddingTop: "8px",
            }}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "inline-grid",
                justifyContent: "center",
                backgroundColor: theme.palette.secondary.light,
                // padding: "8px",
                borderRadius: "4px",
                margin: "4px",
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "inline-grid",
                  justifyContent: "center",
                  backgroundColor: theme.palette.secondary.light,
                  padding: "8px 16px 8px 16px",
                  borderRadius: "4px",
                  margin: "4px",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.light,

                    cursor: "pointer",
                  },
                }}
                onClick={(event: any) => {
                  event.stopPropagation();
                  mutation(data.uuid);
                  setRecievedAnchorEl(null);
                }}
              >
                Leave
              </Box>
            </Box>
          </ProfileBlockPopper>
        )}

        {/* <AvatarGroup
          max={4}
          sx={{ color: theme.palette.primary.main, border: 0 }}
        >
          {group.users.map((user: any) => (
            <ThemedAvatar
              alt="Remy Sharp"
              sizes="small"
              sx={{
                border: 0,
                color: theme.palette.text.primary,
              }}
            >{`${user.firstName[0]}${user.lastName[0]}`}</ThemedAvatar>
          ))}
        </AvatarGroup> */}
        {/* {group?.users?.map((item: any, index: any) => {
          client
            .query({
              query: GROUP_INFO,
              variables: {
                uuid: item.uuid,
              },
            })
            .then(({ data: groupData }) => {
              setGroup(groupData.getGroup);
            });
          return (
            <div
              key={index}
              style={{ flex: "0 0 300px", width: "400px", marginRight: "16px" }}
            >
              <StudyTypographyMediumSubtitle>
                {item.firstName} {item.lastName}
              </StudyTypographyMediumSubtitle>
               <CoworkSchedule groupUser={item} /> 
            </div>
          );
        })} */}
      </div>
    </Grid>
  );
};
