import {
  CalendarTodayOutlined,
  CalendarViewMonthOutlined,
  FormatListBulletedOutlined,
} from "@mui/icons-material";
import { Fab, useTheme, Zoom } from "@mui/material";
import { ContextType, useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useOutletContext } from "react-router-dom";
import { ScheduleMain } from "../../../components/mainbar/schedule/ScheduleMain";
import { Toolbar } from "../../../components/toolbar/Toolbar";
import {
  GET_USER_INFO,
  GET_USER_INFO_REFRESH,
} from "../../../graphql/queries/user";
import { client } from "../../../utils/apollo";
import { FABS } from "../../../utils/constants";
import { DraggingContext, PageContext } from "../../../utils/contexts";

interface MyProps {
  showIsDragging: () => void;
}

export const ScheduleLayout = () => {
  const [todos, setTodos, user, setUser, categories, setCategories] =
    useOutletContext<any>();
  const [isComponentDragging, setIsDragging] = useState(false);
  // const [todos, setTodos] = useState(
  //   JSON.parse(localStorage.getItem("todos")!) || []
  // );
  // const [categories, setCategories] = useState(
  //   JSON.parse(localStorage.getItem("categories")!) || []
  // );
  const [groupInvites, setGroupInvites] = useState(
    JSON.parse(localStorage.getItem("groupInvites")!) || []
  );
  const [allTodos, setAllTodos] = useState<any[]>([]);
  const { page, showMainPage, setShowMainPage } = useContext(PageContext);
  const theme = useTheme();
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    localStorage.setItem("groupInvites", JSON.stringify(groupInvites));
  }, [groupInvites]);

  useEffect(() => {
    const getData = async () => {
      await client
        .query({
          query: GET_USER_INFO_REFRESH,
          variables: {
            userId: userId,
            getUserTodosUserId2: userId,
            getUserTodoInvitesUserId2: userId,
            getUserGroupInvitesUserId3: userId,
          },
          fetchPolicy: "network-only",
        })
        .then(({ data: queryData }) => {
          // console.log(queryData);
          // localStorage.setItem(
          //   "categories",
          //   JSON.stringify(queryData.getUserCategories)
          // );
          // localStorage.setItem(
          //   "todos",
          //   JSON.stringify(queryData.getUserTodos)
          // );
          setTodos(queryData.getUserTodos);
          setCategories(queryData.getUserCategories);
          setGroupInvites(queryData.getUserGroupInvites);
        })
        .catch((queryError) => {
          console.log(queryError.code + ": " + queryError.message);
        });
    };
    getData();
  }, []);

  const fab = FABS.findIndex((fab) => fab.label === "Schedule");

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  useEffect(() => {
    localStorage.setItem("todos", JSON.stringify(todos));
    // console.log(
    //   todos
    //     ?.map((parent: any) => {
    //       if (parent.todoReplicas) {
    //         let children = parent.todoReplicas.map((child: any) => ({
    //           ...child,
    //           title: parent.title,
    //         }));
    //         return [parent, ...children];
    //       }
    //       return [parent];
    //     }) // Merge parent and children into an array
    // ?.reduce((prev: any, curr: any) => prev.concat(curr), null) // Concatenate all arrays into a single array)
    // );
    // console.log(todos);
    if (todos.length !== 0) {
      setAllTodos(
        todos
          ?.map((parent: any) => {
            if (parent.todoReplicas && parent.todoReplicas.length > 0) {
              let children = parent.todoReplicas?.map((child: any) => ({
                ...child,
                title: parent.title,
              }));
              return [parent, ...children];
            }
            return [parent];
          }) // Merge parent and children into an array
          ?.reduce((prev: any, curr: any) => prev.concat(curr))
      ); // Concatenate all arrays into a single array
    }
  }, [todos]);
  useEffect(() => {
    localStorage.setItem("categories", JSON.stringify(categories));
  }, [categories]);

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        marginBottom: isMobile ? "75px" : "0px",
      }}
    >
      <DraggingContext.Provider value={{ isComponentDragging, setIsDragging }}>
        <DndProvider backend={HTML5Backend}>
          {/* <ContentWrapper> */}
          {(isComponentDragging && isMobile) || showMainPage ? null : (
            <Toolbar
              todos={todos}
              setTodos={setTodos}
              categories={categories}
              setCategories={setCategories}
            />
          )}
          {!isMobile || isComponentDragging || showMainPage ? (
            <ScheduleMain
              todos={todos}
              setTodos={setTodos}
              allTodos={allTodos}
              setAllTodos={setAllTodos}
              categories={categories}
              setCategories={setCategories}
            />
          ) : null}
          {/* </ContentWrapper> */}
          {isMobile ? (
            <Zoom
              key={FABS[fab].color}
              in={page === FABS[fab].label}
              timeout={transitionDuration}
              style={{
                transitionDelay: `${
                  page === FABS[fab].label ? transitionDuration.exit : 0
                }ms`,
              }}
              unmountOnExit
            >
              <Fab
                sx={FABS[fab].sx}
                aria-label={FABS[fab].label}
                color={FABS[fab].color}
                onClick={() => {
                  setShowMainPage(!showMainPage);
                }}
              >
                {showMainPage ? FABS[fab].iconTool : FABS[fab].iconMain}
              </Fab>
            </Zoom>
          ) : null}
        </DndProvider>
      </DraggingContext.Provider>
    </div>
  );
};

// const ContentWrapper = styled.div`
//   margin-left: 4rem;
//   height: 100vh;
//   width: 100%;
//   display: flex;
//   box-sizing: border-box;
//   font-size: 1rem;
//   a {
//     &:hover {
//       text-decoration: underline;
//     }
//   }

//   @media (max-width: 850px) {
//     margin-left: 0px;
//   }
// `;
