// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Auth0Provider } from '@auth0/auth0-react';

// const Auth0ProviderWithHistory = ({ children }) => {
//   const domain = process.env.AUTH0_ISSUER_BASE_URL;
//   const clientId = process.env.AUTH0_CLIENT_ID;

//   const history = useNavigate();

//   const onRedirectCallback = (appState) => {
//     history(appState?.returnTo || window.location.pathname);
//   };

//   return (
//     <Auth0Provider
//       domain={process.env.AUTH0_ISSUER_BASE_URL}
//       clientId={process.env.AUTH0_CLIENT_ID}
//       redirectUri={window.location.origin}
//       onRedirectCallback={onRedirectCallback}
//     >
//       {children}
//     </Auth0Provider>
//   );
// };

// export default Auth0ProviderWithHistory;

import React from "react";
import { useNavigate  } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();

  // personal update.  you probably want process.env
  /*
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  */
  // const domain = "http://dev-rdi5d2lle0sz4tps.us.auth0.com";
  // const clientId = "OzakaeyuV12yKobJSAgW5WKfe2s0JKKT";
  const domain = process.env.REACT_APP_AUTH0_ISSUER_BASE_URL;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_REDIRECT_URL;
  // const audience = window._env_.REACT_APP_AUTH0_AUDIENCE;


  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };
  // console.log("http://dev-rdi5d2lle0sz4tps.us.auth0.com")
  // console.log(domain)
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      // redirectUri={window.location.origin}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      // audience={audience}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
