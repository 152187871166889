import { Box, styled } from "@mui/material";
import { StudyDivider } from "../Divider";
import { StudyTypographySmallSubtitle } from "../glovebox/StudyTypography";
import StyleGuide from "../StyleGuide";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";

export const MainbarContainer = styled("div")({
  width: "100%",
  paddingLeft: isMobile ? "16px" : "0px",
  paddingRight: "16px",
  overflowY: "hidden",
  scrollbarWidth: "none",
  height: "90vh",

  flex: 1,
  "&::-webkit-scrollbar": {
    display: "none",
    width: "0px",
  },
});

export const MainbarPinnedTasksContainer = styled("div")({
  margin: "16px",
  width: "100%",
  display: "inline-block",
  flex: 1,
});
export const MainbarPinnedTasksBox = styled("div")(({ theme }) => ({
  borderRadius: "4px",
  marginTop: "8px",
  padding: "8px",
  backgroundColor: theme.palette.primary.main,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

export const MainbarPinnedTasksColorBox = styled("div")(({ color }) => ({
  width: "20px",
  height: "20px",
  backgroundColor: color,
  borderRadius: "4px",
  marginRight: "8px",
}));

export const MainbarCalendarHeader = styled("div")(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const MainbarCalendarScheduleLine = styled(StudyDivider)(
  ({ theme }) => ({
    width: "100%",
    backgroundColor: theme.palette.primary.light,
  })
);

export const MainbarCalendarScheduleTime = styled(StudyTypographySmallSubtitle)`
  width: 40px;
`;

export const MainbarCalendarScheduleTimeComponent = styled("div")({
  flexDirection: "row",
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
});

export const MainbarCalendarScheduleBox = styled(Box)`
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: ${StyleGuide.Colors.darkGray};
  :hover {
    background-color: ${StyleGuide.Colors.gray};
    cursor: pointer;
  }
`;

// export const MainbarPinnedTasksContainer = styled.div`
//   margin: "18px";
//   width: "100%";
//   display: inline-block;
//   flex: 1;
// `;

// export const MainbarPinnedTasksBox = styled.div`
//   border-radius: 4px;
//   margin-top: 8px;
//   padding: 8px;
//   background-color: ${StyleGuide.Colors.lightestGray};
//   display: flex;
//   flex-direction: row;
//   align-items: center;
// `;

// export const MainbarPinnedTasksColorBox = styled.div`
//   width: 20px;
//   height: 20px;
//   background-color: ${(props) => props.color};
//   border-radius: 4px;
//   margin-right: 8px;
// `;

// export const MainbarCalendarHeader = styled.div`
//   color: ${StyleGuide.Colors.darkGray};
// `;

// export const MainbarCalendarScheduleLine = styled(Box)`
//   height: 1px;
//   width: 100%;
//   background-color: gray;
// `;

// export const MainbarCalendarScheduleTime = styled(StudyTypographySmallSubtitle)`
//   width: 40px;
// `;

// export const MainbarCalendarScheduleTimeComponent = styled.div`
//   flex-direction: row;
//   width: 100%;
//   display: flex;
//   align-items: flex-start;
//   justify-content: flex-start;
// `;

// export const MainbarCalendarScheduleBox = styled(Box)`
//   display: flex;
//   width: 100%;
//   height: 50px;
//   border-radius: 4px;
//   align-items: center;
//   justify-content: center;
//   background-color: ${StyleGuide.Colors.darkGray};
//   :hover {
//     background-color: ${StyleGuide.Colors.gray};
//     cursor: pointer;
//   }
// `;
