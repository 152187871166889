import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import StanfordLogo from "../../assets/Universities/StanfordLogo.png";
import PrincetonLogo from "../../assets/Universities/PrincetonLogo.png";
import MITLogo from "../../assets/Universities/MITLogo.png";
import HarvardLogo from "../../assets/Universities/HarvardLogo.png";
import BrownLogo from "../../assets/Universities/BrownLogo.png";
import UCBerkeleyLogo from "../../assets/Universities/UCBerkeleyLogo.png";
import CornellLogo from "../../assets/Universities/CornellLogo.png";
import UPennLogo from "../../assets/Universities/UPennLogo.png";
import StudyoApp from "../../assets/StudyoLogo.png";
import { Box, useTheme } from "@mui/material";
const universities = [
  StanfordLogo,
  PrincetonLogo,
  MITLogo,
  HarvardLogo,
  BrownLogo,
  UCBerkeleyLogo,
  CornellLogo,
  UPennLogo,
  StanfordLogo,
  PrincetonLogo,
  MITLogo,
  HarvardLogo,
  BrownLogo,
  UCBerkeleyLogo,
  CornellLogo,
  UPennLogo,
  StanfordLogo,
  PrincetonLogo,
  MITLogo,
  HarvardLogo,
  BrownLogo,
  UCBerkeleyLogo,
  CornellLogo,
  UPennLogo,
  StanfordLogo,
  PrincetonLogo,
  MITLogo,
  HarvardLogo,
  BrownLogo,
  UCBerkeleyLogo,
  CornellLogo,
  UPennLogo,
];

const Slider = () => {
  const [xPos, setXPos] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  useEffect(() => {
    setSlideWidth(300); // Set slide width to 300px
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setXPos((xPos) => {
        if (xPos <= -slideWidth * (universities.length - 1)) {
          return 0;
        } else {
          return xPos - 0.2;
        }
      });
    }, 1); // Move slider towards the right every 10 milliseconds
    return () => clearInterval(intervalId);
  }, [slideWidth]);

  const handlePrev = () => {
    if (xPos === 0) {
      setXPos(-slideWidth * (universities.length - 1));
    } else {
      setXPos(xPos + slideWidth);
    }
  };

  const theme = useTheme();

  const handleNext = () => {
    if (xPos === -slideWidth * (universities.length - 1)) {
      setXPos(0);
    } else {
      setXPos(xPos - slideWidth);
    }
  };

  return (
    <div style={{ overflow: "auto" }}>
      <Box
        style={{
          transform: `translateX(${xPos}px)`,
          width: `${slideWidth * universities.length}px`,
          display: "flex",
          flexDirection: "row",
        }}
      >
        {universities.map((university, index) => (
          <img
            src={university}
            style={{
              width: "300px",
              height: "100px",
              borderRadius: "12px",
              padding: "12px",
              marginRight: "24px",
              backgroundColor: theme.palette.action.active,
            }}
          />
        ))}
      </Box>
    </div>
  );
};

export default Slider;
