/* eslint-disable react/jsx-key */
import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { Link, Link as RouterLink } from "react-router-dom";
import { set, sub, formatDistanceToNow } from "date-fns";
import { Icon } from "@iconify/react";
import bellFill from "@iconify/icons-eva/bell-fill";
import clockFill from "@iconify/icons-eva/clock-fill";
import doneAllFill from "@iconify/icons-eva/done-all-fill";
// material
import { alpha, useTheme } from "@mui/material/styles";
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
// utils
import { useSelector } from "react-redux";

// components
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import { mockImgAvatar } from "../../utils/mockImages";
import {
  StudyTypographyMegaSubtitle,
  StudyTypographyMediumText,
} from "../glovebox/StudyTypography";

// ----------------------------------------------------------------------

const NOTIFICATIONS = [
  {
    id: "1",
    title: "Your order is placed",
    description: "waiting for shipping",
    avatar: null,
    type: "order_placed",
    createdAt: set(new Date(), { hours: 10, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: "2",
    title: "yo yo yo yo ",
    description: "answered to your comment on the Minimal",
    avatar: mockImgAvatar(2),
    type: "friend_interactive",
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: "3",
    title: "You have new message",
    description: "5 unread messages",
    avatar: null,
    type: "chat_message",
    createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    id: "4",
    title: "You have new mail",
    description: "sent from Guido Padberg",
    avatar: null,
    type: "mail",
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    id: "5",
    title: "Delivery processing",
    description: "Your order is being shipped",
    avatar: null,
    type: "order_shipped",
    createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
];

function renderContent(notification: any) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  );

  if (notification.type === "order_placed") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/static/icons/ic_notification_package.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "order_shipped") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/static/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "mail") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/static/icons/ic_notification_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "chat_message") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/static/icons/ic_notification_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: <img alt={notification.title} src={notification.avatar} />,
    title,
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
};

function NotificationItem({ notification }: { notification: any }) {
  const { avatar, title } = renderContent(notification);
  const theme = useTheme();
  return (
    <ListItemButton
      to={notification.link}
      disableGutters
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead && {
          bgcolor: theme.palette.primary.light,
        }),
      }}
    >
      {/* <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar> */}
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Box
              component={Icon}
              icon={clockFill}
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {formatDistanceToNow(new Date(notification.createdAt))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover({
  anchorEl,
  notificationOpen,
}: {
  anchorEl: any;
  notificationOpen: any;
}) {
  const [notifications, setNotifications] = useState(NOTIFICATIONS);
  const totalUnRead = notifications.filter(
    (item) => item.isUnRead === true
  ).length;
  const numMessages = 7;
  const numFriend = 3;

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  const getNotificationNumber = () => {
    if (numMessages) {
      if (numFriend) {
        return numMessages + numFriend;
      }
      return numMessages;
    }
    if (numFriend) {
      return numFriend;
    }
    return 0;
  };

  const theme = useTheme();

  return (
    <MenuPopover
      open={notificationOpen}
      onClose={() => {}}
      anchorEl={anchorEl}
      sx={{ position: "absolute", right: "100px" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          py: 2,
          px: 2.5,
          backgroundColor: theme.palette.primary.main,
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          borderRight: `solid 1px ${theme.palette.primary.light}`,
          borderTop: `solid 1px ${theme.palette.primary.light}`,
          borderLeft: `solid 1px ${theme.palette.primary.light}`,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <StudyTypographyMegaSubtitle>
            Notifications
          </StudyTypographyMegaSubtitle>
          <StudyTypographyMediumText>
            NOTIFICATIONS COMING SOON!
            {/* You have {numMessages} unread messages */}
          </StudyTypographyMediumText>
        </Box>

        {/* {totalUnRead > 0 && (
          <Tooltip title=" Mark all as read">
            <IconButton color="primary" onClick={handleMarkAllAsRead}>
              <Icon icon={doneAllFill} width={20} height={20} />
            </IconButton>
          </Tooltip>
        )} */}
      </Box>

      <Divider />

      <Scrollbar
        sx={{
          height: { xs: 340, sm: "auto" },
          backgroundColor: theme.palette.primary.main,
          borderRight: `solid 1px ${theme.palette.primary.light}`,
          borderBottom: `solid 1px ${theme.palette.primary.light}`,
          borderLeft: `solid 1px ${theme.palette.primary.light}`,
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <List
          disablePadding
          subheader={
            <ListSubheader
              disableSticky
              sx={{ py: 1, px: 2.5, typography: "overline" }}
            >
              New
            </ListSubheader>
          }
        >
          {notifications.slice(2, 3).map((notification) => (
            <div>
              {numMessages ? (
                <NotificationItem
                  key={notification.id}
                  notification={{
                    id: "7",
                    description: "NOTIFICATIONS COMING SOON!",
                    // description: `You have ${numMessages} unread messages`,
                    title: "You have new message(s)",
                    isUnRead: true,
                    type: "chat_message",
                    avatar: mockImgAvatar(2),
                    createdAt: sub(new Date(), {
                      days: 1,
                      hours: 3,
                      minutes: 30,
                    }),
                    link: "/dashboard/messages",
                  }}
                />
              ) : null}
              {numFriend ? (
                <NotificationItem
                  key={notification.id}
                  notification={{
                    id: "6",
                    description: "NOTIFICATIONS COMING SOON!",

                    // description: `${numFriend} person has recently added you!`,
                    title: "New friend requests",
                    isUnRead: true,
                    type: "mail",
                    avatar: mockImgAvatar(2),
                    createdAt: sub(new Date(), {
                      days: 1,
                      hours: 3,
                      minutes: 30,
                    }),
                    link: "/dashboard/user",
                  }}
                />
              ) : null}
            </div>
          ))}
        </List>
        {/* 
      <List
        disablePadding
        subheader={
          <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
            Before that
          </ListSubheader>
        }
      >
        {notifications.slice(2, 5).map((notification) => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
      </List> */}
      </Scrollbar>

      {/* <Divider /> */}

      {/* <Box
        sx={{
          p: 1,
          backgroundColor: theme.palette.primary.main,
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          style={{
            borderColor: `linear-gradient(135deg, ${alpha(
              "#FBDA61",
              0.5
            )} 0%, ${alpha("#FF5ACD", 0.5)} 100%)`,
            color: `linear-gradient(135deg, ${alpha(
              "#FBDA61",
              0.5
            )} 0%, ${alpha("#FF5ACD", 0.5)} 100%)`,
            //   backgroundColor: "white",
          }}
        >
          View All
        </Button>
      </Box> */}
    </MenuPopover>
  );
}
