import { signOut } from "@firebase/auth";
import {
  AccessAlarmRounded,
  AccountBoxOutlined,
  CalendarMonthOutlined,
  CalendarTodayRounded,
  FormatListBulletedRounded,
  ListAltOutlined,
  PeopleAltOutlined,
  SendRounded,
  SettingsOutlined,
} from "@mui/icons-material";
import "./styles.css";

import {
  Backdrop,
  Badge,
  Breadcrumbs,
  Button,
  CircularProgress,
  ClickAwayListener,
  Stack,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { client } from "../../utils/apollo";
import { PageContext, ThemeContext } from "../../utils/contexts";
import { auth } from "../../utils/firebase";
import { HOTKEYS } from "../../utils/hotkeyHelper";
import { StudyDivider } from "../Divider";
import {
  StudyTypographyMediumSubtitle,
  StudyTypographySmallText,
} from "../glovebox/StudyTypography";
import { HoverTypography } from "../HoverTypography";
import { NotificationIcon } from "../Icons/NotificationIcon";
import { SearchIcon } from "../Icons/SearchIcon";
import { SegmentedControl } from "../SegmentedControl";
import { ProfileBlockAvatar, ProfileBlockPopper } from "../toolbar/styles";
import NotificationsPopover from "./NotificationPopover";

import { LoadingButton } from "@mui/lab";
import { Divider } from "antd";
import { Command } from "cmdk";
import { isMobile } from "react-device-detect";
import { CREATE_BUG } from "../../graphql/mutations/bugs";
import { GET_USER_BUGS } from "../../graphql/queries/bugs";
import { PriorityInput, STUDYO_GRADIENT } from "../../utils/constants";
import { HotkeyContext } from "../../utils/contexts";
import { EditToDoModal } from "../glovebox/EditToDoModal";
import { CheckIcon } from "../Icons/CheckIcon";
import {
  StyledBox,
  StyledCloseButton,
  StyledDialog,
  StyledDialogTopBar,
  StyledHeader,
} from "../mainbar/calendar/styles";
import { XIcon } from "../sidebar/Icons";
var Sherlock = require("sherlockjs");

export const Topbar = ({
  setThemeValueToLight,
  setThemeValueToGray,
  setThemeValueToDark,
  todos,
  setTodos,
  user,
  setUser,
  categories,
  setCategories,
}: {
  setThemeValueToLight: any;
  setThemeValueToGray: any;
  setThemeValueToDark: any;
  todos: any;
  setTodos: any;
  user: any;
  setUser: any;
  categories: any;
  setCategories: any;
}) => {
  // console.log(todos);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElPriority, setAnchorElPriority] = useState<null | HTMLElement>(
    null
  );
  const [openSearch, setOpenSearch] = useState(false);
  const [openEditTodo, setOpenEditTodo] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [pages, setPages] = useState<string[]>(["home"]);
  const [editTodoText, setEditTodoText] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [bugsModal, setBugsModal] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [localTheme, setLocalTheme] = useState(localStorage.getItem("theme"));
  const [description, setDescription] = useState("");
  const [inputValue, setInputValue] = useState("");

  const theme = useTheme();

  const [priority, setPriority] = useState({
    name: "Low",
    // icon:
    color: theme.palette.warning.light,
    priority: PriorityInput.LOW,
  });
  // const user = JSON.parse(localStorage.getItem("user")!);
  // const [user, setUser] = useState(
  //   JSON.parse(localStorage.getItem("user")!) || []
  // );
  const [search, setSearch] = useState("");

  // const { id: userId } = useUserContext();
  const userId = localStorage.getItem("userId");
  const { showBadge, setShowBadge } = useContext(HotkeyContext);
  const { themeValue, setThemeValue } = useContext(ThemeContext);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [durationAnchorEl, setDurationAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const durationOpen = Boolean(durationAnchorEl);

  const handleDurationClick = (event: React.MouseEvent<HTMLElement>) => {
    setDurationAnchorEl(durationAnchorEl ? null : event.currentTarget);
  };
  const [notificationAnchorEl, setNotificationAnchorEl] =
    useState<null | HTMLElement>(null);

  const notificationOpen = Boolean(notificationAnchorEl);

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(notificationAnchorEl ? null : event.currentTarget);
  };
  const handleNotificationClickByHotkey = (notificationRef: any) => {
    setNotificationAnchorEl(notificationAnchorEl ? null : notificationRef);
  };

  const handleProfileClickByHotkey = (profileRef: any) => {
    setAnchorEl(anchorEl ? null : profileRef);
  };

  const [searchAnchorEl, setSearchAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const searchOpen = Boolean(searchAnchorEl);

  const handleSearchClick = (event: React.MouseEvent<HTMLElement>) => {
    setSearchAnchorEl(searchAnchorEl ? null : event.currentTarget);
  };

  // useEffect(() => {
  //   const down = (e: any) => {
  //     e.preventDefault();
  //     if (e.key === "f" && e.metaKey) {
  //       setOpenSearch((openSearch) => !openSearch);
  //     }
  //   };

  //   document.addEventListener("keydown", down);
  //   return () => document.removeEventListener("keydown", down);
  // }, []);
  useHotkeys("command+f", (event: any) => {
    event.preventDefault();
    setOpenSearch(!openSearch);
  });
  // console.log(openSearch);

  const [titleAnchorEl, setTitleAnchorEl] = useState<null | HTMLElement>(null);
  const [titleAnchorElPriority, setTitleAnchorElPriority] =
    useState<null | HTMLElement>(null);

  const titleOpen = Boolean(titleAnchorEl);
  const priorityOpen = Boolean(titleAnchorElPriority);
  const handleTitleClick = (event: React.MouseEvent<HTMLElement>) => {
    setTitleAnchorEl(titleAnchorEl ? null : event.currentTarget);
  };

  const handlePriorityClick = (event: React.MouseEvent<HTMLElement>) => {
    setTitleAnchorElPriority(
      titleAnchorElPriority ? null : event.currentTarget
    );
  };

  useHotkeys(
    HOTKEYS.OPEN_NOTIFICATION,
    (event: any) => {
      if (notificationAnchorEl === null) {
        handleNotificationClickByHotkey(notificationRef.current);
      } else {
        handleNotificationClickByHotkey(notificationRef.current);
      }
    },
    [notificationAnchorEl]
  );

  useHotkeys(
    HOTKEYS.OPEN_PROFILE,
    (event: any) => {
      if (notificationAnchorEl === null) {
        handleProfileClickByHotkey(profileRef.current);
      } else {
        handleProfileClickByHotkey(profileRef.current);
      }
    },
    [anchorEl]
  );
  const handleClickAway = () => {
    setAnchorEl(null);
  };
  // const [todos, setTodos] = useState(
  //   JSON.parse(localStorage.getItem("todos")!) || []
  // );

  // useEffect(() => {
  //   localStorage.setItem("todos", todos);
  // }, [todos]);

  // useEffect(() => {
  //   localStorage.setItem("user", user);
  // }, [user]);

  // const allTodos = todos
  //   .map((parent: any) => {
  //     if (parent.todoReplicas) {
  //       let children = parent.todoReplicas.map((child: any) => ({
  //         ...child,
  //         title: parent.title,
  //       }));
  //       return [parent, ...children];
  //     }
  //     return [parent];
  //   }) // Merge parent and children into an array
  //   .reduce((prev: any, curr: any) => prev.concat(curr));

  // const imageSrc = user?.profileImageURL;

  const setPageWithUrl = (path: any) => {
    if (path.includes("app/schedule")) {
      return "Schedule";
    } else if (path.includes("app/calendar")) {
      return "Calendar";
    } else if (path.includes("app/todos")) {
      return "Todos";
    } else if (path.includes("app/cowork")) {
      return "Cowork Space";
    } else if (path.includes("app/friends")) {
      return "Friends";
    } else if (path.includes("app/addfriend")) {
      return "Add Friends";
    } else if (path.includes("app/settings")) {
      return "Settings";
    }
  };

  const { page, setPage, showMainPage, setShowMainPage } =
    useContext(PageContext);

  useEffect(() => {
    setPage(setPageWithUrl(location.pathname));
  }, [location]);

  const listItems = [
    {
      name: "Profile",
      icon: (
        <AccountBoxOutlined style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "settings",
    },
    {
      name: "Friends",
      icon: (
        <PeopleAltOutlined style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "friends",
    },
    {
      name: "Settings",
      icon: (
        <SettingsOutlined style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "settings",
    },
  ];
  const notificationRef = useRef(null);
  const profileRef = useRef(null);
  // let inputRef: any = useRef(null);
  // useHotkeys(HOTKEYS.GLOBAL_SEARCH, () => {
  //   //@ts-ignore
  //   inputRef?.current?.focus();
  //   setIsFocused(true);
  // });

  const priorityItems = [
    {
      name: "Low",
      // icon:
      color: theme.palette.warning.light,
      priority: PriorityInput.LOW,
    },
    {
      name: "Medium",
      // icon:
      color: theme.palette.warning.main,

      priority: PriorityInput.MEDIUM,
    },
    {
      name: "High",
      // icon:
      color: theme.palette.error.light,

      priority: PriorityInput.HIGH,
    },
    {
      name: "Urgent",
      // icon:
      color: theme.palette.error.dark,

      priority: PriorityInput.URGENT,
    },
  ];

  const titleListItems = [
    {
      name: "Calendar",
      icon: (
        <CalendarMonthOutlined
          style={{ color: theme.palette.secondary.dark }}
        />
      ),
      to: "/app/calendar",
    },
    {
      name: "Todos",
      icon: <ListAltOutlined style={{ color: theme.palette.secondary.dark }} />,
      to: "/app/todos",
    },
    {
      name: "Schedule",
      icon: (
        <AccessAlarmRounded style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "/app/schedule",
    },
    // {
    //   name: "Cowork",
    //   icon: (
    //     <MeetingRoomOutlined style={{ color: theme.palette.secondary.dark }} />
    //   ),
    //   to: "/app/cowork",
    // },
    // {
    //   name: "Friends",
    //   icon: (
    //     <PeopleAltOutlined style={{ color: theme.palette.secondary.dark }} />
    //   ),
    //   to: "/app/friends",
    // },
    // {
    //   name: "AddFriends",
    //   icon: (
    //     <GroupAddOutlined style={{ color: theme.palette.secondary.dark }} />
    //   ),
    //   to: "/app/addfriend",
    // },
    {
      name: "Settings",
      icon: (
        <SettingsOutlined style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "/app/settings",
    },
  ];

  const mobileTitleListItems = [
    {
      name: "Calendar",
      icon: (
        <CalendarMonthOutlined
          style={{ color: theme.palette.secondary.dark }}
        />
      ),
      to: "/app/calendar",
    },
    {
      name: "Todos",
      icon: <ListAltOutlined style={{ color: theme.palette.secondary.dark }} />,
      to: "/app/todos",
    },
    {
      name: "Schedule",
      icon: <ListAltOutlined style={{ color: theme.palette.secondary.dark }} />,
      to: "/app/schedule",
    },
    // {
    //   name: "Cowork",
    //   icon: (
    //     <MeetingRoomOutlined style={{ color: theme.palette.secondary.dark }} />
    //   ),
    //   to: "/app/cowork",
    // },
    // {
    //   name: "Friends",
    //   icon: (
    //     <PeopleAltOutlined style={{ color: theme.palette.secondary.dark }} />
    //   ),
    //   to: "/app/friends",
    // },
    // {
    //   name: "AddFriends",
    //   icon: (
    //     <GroupAddOutlined style={{ color: theme.palette.secondary.dark }} />
    //   ),
    //   to: "/app/addfriend",
    // },
    {
      name: "Settings",
      icon: (
        <SettingsOutlined style={{ color: theme.palette.secondary.dark }} />
      ),
      to: "/app/settings",
    },
  ];
  const handleBugSubmit = () => {
    setRefreshing(true);
    setBugsModal(false);
    client
      .mutate({
        mutation: CREATE_BUG,
        variables: {
          userId: user.id,
          description: description,
          priority: { priority: priority.priority },
        },
        refetchQueries: [
          {
            query: GET_USER_BUGS,
            variables: { userId: userId },
          },
          "GetUserSubmittedBugs",
        ],
      })
      .then(() => {
        setDescription("");
        setPriority(priorityItems[0]);
        setRefreshing(false);
        setBugsModal(false);
      });
  };

  const ref = useRef<HTMLDivElement | null>(null);

  const popPage = useCallback(() => {
    setPages((pages) => {
      const x = [...pages];
      x.splice(-1, 1);
      return x;
    });
  }, []);
  const activePage = pages[pages.length - 1];

  const isHome = activePage === "home";

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (isHome || inputValue.length) {
        return;
      }

      if (e.key === "Backspace") {
        e.preventDefault();
        popPage();
      }
    },
    [inputValue.length, isHome, popPage]
  );

  function bounce() {
    if (ref.current) {
      ref.current.style.transform = "scale(0.96)";
      setTimeout(() => {
        if (ref.current) {
          ref.current.style.transform = "";
        }
      }, 100);

      setInputValue("");
    }
  }

  return (
    <div
      style={{
        padding: "16px",
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 100,
        height: "32px",

        // background: `linear-gradient(180deg, ${alpha(
        //   "#FF5ACD",
        //   0.2
        // )} -500%, ${alpha("#FBDA61", 0.0)} 100%)`,
      }}
    >
      {selectedTask ? (
        <EditToDoModal
          openEdit={openEditTodo}
          todo={selectedTask}
          todos={todos}
          setTodos={setTodos}
          setOpenEditTodo={setOpenEditTodo}
          title={selectedTask.title}
        />
      ) : null}

      <div style={{ alignItems: "center", display: "flex" }}>
        <StyledDialog
          open={bugsModal}
          onClose={() => {}}
          aria-labelledby="archive-task-modal"
          aria-describedby="modal-modal-description"
          style={{ zIndex: 1 }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          <StyledBox>
            <StyledDialogTopBar>
              <StyledHeader
                style={{
                  marginLeft: 0,
                }}
              >
                Report Bug
              </StyledHeader>
              <StyledCloseButton onClick={() => setBugsModal(false)}>
                <XIcon />
              </StyledCloseButton>
            </StyledDialogTopBar>
            {/* <Box sx={style}> */}
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={refreshing}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <form
              onSubmit={(event: any) => {
                event.preventDefault();
                handleBugSubmit();
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  display={"flex"}
                  width={"100%"}
                  height={"100%"}
                  flex={1}
                  marginBottom={"12px"}
                >
                  <textarea
                    style={{
                      border: "0px",
                      backgroundColor: theme.palette.primary.light,
                      width: "80%",
                      height: "100px",
                      padding: "12px",
                      paddingLeft: "16px",
                      marginRight: "12px",
                      // paddingTop: "10px",
                      // paddingBottom: "10px",
                      boxSizing: "border-box",
                      outlineWidth: 0,
                      borderRadius: "4px",
                      color: theme.palette.text.primary,
                    }}
                    placeholder={"enter bug description.."}
                    // type={"text"}
                    name="title"
                    value={description}
                    onChange={(text) => {
                      setDescription(text.target.value);
                    }}
                  ></textarea>
                  <Button
                    onClick={handlePriorityClick}
                    style={{
                      width: "25%",
                      cursor: "pointer",
                      height: "100px",
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.primary.light,
                      textTransform: "none",
                      marginRight: "12px",
                      alignSelf: "center",
                    }}
                  >
                    <Stack
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      display={"flex"}
                      width={"100%"}
                      height={"100%"}
                      flex={1}
                    >
                      <div
                        style={{
                          width: "6px",
                          height: "20px",
                          borderRadius: "4px",
                          marginRight: "12px",
                          backgroundColor: priority.color,
                        }}
                      />
                      <StudyTypographyMediumSubtitle>
                        {priority.name}
                      </StudyTypographyMediumSubtitle>
                    </Stack>
                  </Button>

                  <ProfileBlockPopper
                    open={priorityOpen}
                    anchorEl={titleAnchorElPriority}
                    placement={"bottom-start"}
                    style={{
                      paddingTop: "8px",
                      backgroundColor: "rgba(0,0,0,0)",
                      zIndex: 1000,
                    }}
                    modifiers={[{ name: "arrow", enabled: true }]}
                    nonce={undefined}
                    onResize={undefined}
                    onResizeCapture={undefined}
                  >
                    <div
                      style={{
                        backgroundColor: theme.palette.primary.main,
                        border: `1px solid ${theme.palette.secondary.light}`,
                        padding: "8px",
                        borderRadius: "4px",
                      }}
                    >
                      {priorityItems.map((item) => (
                        <HoverTypography
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                            padding: "2px 8px 2px 8px",
                            borderRadius: "4px",
                          }}
                          onClick={() => {
                            setTitleAnchorElPriority(null);
                            setPriority(item);
                          }}
                        >
                          <div
                            style={{
                              width: "6px",
                              height: "20px",
                              borderRadius: "4px",
                              marginRight: "12px",
                              backgroundColor: item.color,
                            }}
                          />
                          <StudyTypographyMediumSubtitle
                            style={{
                              paddingTop: "6px",
                              paddingBottom: "6px",
                              borderRadius: "4px",
                            }}
                          >
                            {item.name}
                          </StudyTypographyMediumSubtitle>
                        </HoverTypography>
                      ))}
                    </div>
                  </ProfileBlockPopper>
                  <LoadingButton
                    size="small"
                    type="submit"
                    variant="outlined"
                    disabled={!description}
                    loading={refreshing}
                    sx={{
                      // width: "100%",
                      color: theme.palette.primary.main,
                      height: "100px",
                      display: "flex",
                      backgroundColor: theme.palette.primary.light,
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: theme.palette.secondary.light,
                      },
                    }}
                    onClick={(event: any) => {
                      event.preventDefault();
                      handleBugSubmit();
                    }}
                  >
                    <SendRounded />
                  </LoadingButton>
                </Stack>
              </div>
            </form>
          </StyledBox>
        </StyledDialog>
        {/* <ClickAwayListener
                onClickAway={(event: any) => {
                  event.stopPropagation();
                  // setSearchAnchorEl(null);
                  ;
                }}
              > */}
        <Button
          onClick={handleTitleClick}
          style={{
            cursor: "pointer",
            height: "32px",
            margin: 0,
            padding: "0px 12px 0px 12px",
            verticalAlign: "flex-start",
            alignSelf: "center",
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.light,
            justifyContent: "flex-start",
            display: "flex",
            textTransform: "none",
          }}
        >
          <Breadcrumbs>
            <StudyTypographySmallText>
              {user.firstName}
            </StudyTypographySmallText>
            <StudyTypographySmallText>{page}</StudyTypographySmallText>
          </Breadcrumbs>
        </Button>
        {/* </ClickAwayListener> */}
        {/* <Button
          onClick={() => {
            setBugsModal(!bugsModal);
          }}
          style={{
            cursor: "pointer",
            height: "32px",
            margin: 5,
            padding: "0px 12px 0px 12px",
            verticalAlign: "flex-start",
            alignSelf: "center",
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.light,
            justifyContent: "flex-start",
            display: "flex",
            textTransform: "none",
          }}
        >
          <ReportGmailerrorredRounded
            style={{ marginRight: "8px", height: "18px", width: "18px" }}
          />
          <StudyTypographySmallText>Bugs</StudyTypographySmallText>
        </Button> */}
      </div>

      <ProfileBlockPopper
        open={titleOpen}
        anchorEl={titleAnchorEl}
        placement={"bottom-start"}
        style={{
          paddingTop: "8px",
          backgroundColor: "rgba(0,0,0,0)",
        }}
        modifiers={[{ name: "arrow", enabled: true }]}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        <div
          style={{
            backgroundColor: theme.palette.primary.main,
            border: `1px solid ${theme.palette.secondary.light}`,
            padding: "8px",
            borderRadius: "4px",
          }}
        >
          {titleListItems.map((item) => (
            <Link
              to={item.to}
              onClick={() => {
                setTitleAnchorEl(null);
                setPage(item.name);
              }}
            >
              <HoverTypography
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  padding: "2px 8px 2px 8px",
                  borderRadius: "4px",
                }}
              >
                {item.icon}
                <StudyTypographyMediumSubtitle
                  style={{
                    paddingTop: "6px",
                    paddingBottom: "6px",
                    borderRadius: "4px",
                    paddingLeft: "16px",
                  }}
                >
                  {item.name}
                </StudyTypographyMediumSubtitle>
              </HoverTypography>
            </Link>
          ))}
        </div>

        {/* <Button
          fullWidth
          color="primary"
          variant="outlined"
          size="small"
          style={{
            width: "100%",
            flex: 1,
            marginTop: "8px",
            textTransform: "none",
            color: theme.palette.text.primary,
            borderColor: theme.palette.text.primary,
          }}
          onClick={() => {
            setShowMainPage(!showMainPage);
          }}
        >
          {showTextForTitle()}
        </Button> */}
      </ProfileBlockPopper>

      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          height: "32px",
        }}
      >
        {isMobile ? null : (
          <div>
            {/* <ProfileBlockPopper
              open={open}
              anchorEl={anchorEl}
              placement={"bottom-start"}
              modifiers={[{ name: "arrow", enabled: true }]}
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                padding: "0px",
                paddingTop: "12px",
                zIndex: 1000,
              }}
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
            > */}

            <StyledDialog
              open={openSearch}
              onClose={() => {}}
              aria-labelledby="archive-task-modal"
              aria-describedby="modal-modal-description"
              style={{
                zIndex: 1,
                backgroundColor: "transparent",
                borderRadius: "12px",
              }}
              PaperProps={{
                style: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              }}
            >
              <ClickAwayListener
                onClickAway={(event: any) => {
                  event.stopPropagation();
                  // setSearchAnchorEl(null);
                  setOpenSearch(false);
                }}
              >
                <StyledBox>
                  <Command
                    ref={ref}
                    className="vercel"
                    filter={(value, search) => {
                      if (value.includes(search.toLowerCase()) && search !== "")
                        return 1;
                      return 0;
                    }}
                    onKeyDown={(e: any) => {
                      if (e.key === "Enter") {
                        bounce();
                      }
                      if (isHome || inputValue.length) {
                        return;
                      }

                      if (e.key === "Backspace") {
                        e.preventDefault();
                        popPage();
                        bounce();
                      }
                    }}
                    // open={openSearch}
                    // onOpenChange={setOpenSearch}
                    label="search..."
                  >
                    <Stack
                      alignItems={"center"}
                      display="flex"
                      flexDirection={"row"}
                    >
                      <Command.Input
                        value={search}
                        onValueChange={setSearch}
                        autoFocus
                        placeholder="What do you need?"
                        color={
                          search === ""
                            ? theme.palette.primary.light
                            : theme.palette.primary.light
                        }
                        style={{
                          margin: "0px",
                          padding: "0px",
                          paddingLeft: "12px",
                          color:
                            search === ""
                              ? theme.palette.secondary.light
                              : theme.palette.text.primary,
                        }}
                      />
                      <StyledCloseButton onClick={() => setOpenSearch(false)}>
                        <XIcon />
                      </StyledCloseButton>
                    </Stack>

                    <Divider
                      style={{
                        width: "90%",
                        height: "2px",
                        backgroundColor: theme.palette.primary.light,
                        borderRadius: "4px",
                      }}
                    />
                    <Command.List>
                      <Command.Empty>No results found.</Command.Empty>
                      {/* <Home /> */}
                      <Command.Group heading="Todos" hidden={false}>
                        {todos ? (
                          todos
                            ?.filter((todo: any) => {
                              return !todo.event;
                            })
                            ?.map((todo: any) => {
                              return (
                                <Command.Item
                                  className="vercel"
                                  onSelect={(value) => {
                                    setOpenSearch(false);
                                    setOpenEditTodo(true);
                                    setSelectedTask(todo);
                                  }}
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <FormatListBulletedRounded />
                                  {todo.title}
                                </Command.Item>
                              );
                            })
                        ) : (
                          <Command.Empty>No results found.</Command.Empty>
                        )}
                      </Command.Group>
                      <Command.Separator />
                      <Command.Group heading="Events" hidden={false}>
                        {todos ? (
                          todos
                            ?.filter((todo: any) => {
                              return todo.event;
                            })
                            ?.map((todo: any) => {
                              return (
                                <Command.Item
                                  className="vercel"
                                  onSelect={(value) => {
                                    setOpenSearch(false);
                                    setOpenEditTodo(true);
                                    setSelectedTask(todo);
                                  }}
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <CalendarTodayRounded />
                                  {todo.title}
                                </Command.Item>
                              );
                            })
                        ) : (
                          <Command.Empty>No results found.</Command.Empty>
                        )}
                      </Command.Group>
                      <Command.Separator />

                      <Command.Group heading="Friends" hidden={false}>
                        {user.friends ? (
                          user.friends?.map((friend: any) => {
                            return (
                              <Command.Item
                                className="vercel"
                                onSelect={(value) =>
                                  console.log("Selected", value)
                                }
                              >
                                {friend.firstName} {friend.lastName}
                              </Command.Item>
                            );
                          })
                        ) : (
                          <Command.Empty>No results found.</Command.Empty>
                        )}
                      </Command.Group>
                      <Command.Separator />
                      <Command.Group
                        heading="Settings"
                        hidden={false}
                      ></Command.Group>
                      <Command.Separator />
                      <Command.Group
                        heading="Events"
                        hidden={false}
                      ></Command.Group>
                    </Command.List>
                  </Command>
                </StyledBox>
              </ClickAwayListener>
            </StyledDialog>
          </div>
        )}

        <div
          onClick={(event: any) => {
            event.stopPropagation();
            setOpenSearch(true);
          }}
          style={{
            marginRight: "12px",
            marginBottom: "0px",
            alignSelf: "center",
            height: "32px",
          }}
        >
          <Badge
            badgeContent={HOTKEYS.GLOBAL_SEARCH}
            color={"primary"}
            invisible={!showBadge || isMobile}
          >
            <SearchIcon
              // style={{ border: "10px", marginRight: "12px" }}
              active={false}
              clicked={() => {}}
            />
          </Badge>
        </div>

        <ClickAwayListener
          onClickAway={() => {
            setNotificationAnchorEl(null);
          }}
        >
          <div
            onClick={handleNotificationClick}
            ref={notificationRef}
            style={{
              marginRight: "12px",
              marginBottom: "0px",
              alignSelf: "center",
              height: "32px",
            }}
          >
            <NotificationIcon
              // style={{ border: "10px", marginRight: "12px" }}
              active={notificationOpen}
              clicked={() => {}}
            />
            <NotificationsPopover
              anchorEl={notificationAnchorEl}
              notificationOpen={notificationOpen}
            />
          </div>
        </ClickAwayListener>
        <Badge
          badgeContent={HOTKEYS.OPEN_PROFILE}
          color={"primary"}
          invisible={!showBadge || isMobile}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <ProfileBlockAvatar
            ref={profileRef}
            variant="rounded"
            style={{ alignSelf: "center", margin: "0px", padding: "0px" }}
            onClick={handleClick}
          >
            {user.profileImageURL ? (
              <img
                src={user.profileImageURL}
                style={{
                  width: "32px",
                  height: "32px",
                  margin: "0px",
                  padding: "0px",
                }}
              />
            ) : (
              <div
                style={{
                  borderRadius: "4px",
                  height: "100%",
                  width: "100%",
                  cursor: "pointer",
                  color: theme.palette.primary.main,
                  background: STUDYO_GRADIENT,
                  backgroundColor: "white",
                }}
              />
            )}
          </ProfileBlockAvatar>
        </Badge>
      </div>

      <ProfileBlockPopper
        open={open}
        anchorEl={anchorEl}
        placement={"bottom-start"}
        modifiers={[{ name: "arrow", enabled: true }]}
        style={{
          backgroundColor: "rgba(0,0,0,0)",
          padding: "0px",
          paddingTop: "12px",
          zIndex: 1000,
          width: "350px",
        }}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        <div
          style={{
            padding: "16px",
            border: `1px solid ${theme.palette.secondary.light}`,
            borderRadius: "4px",
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <StudyTypographyMediumSubtitle style={{ marginBottom: "8px" }}>
            Name
          </StudyTypographyMediumSubtitle>
          <Stack
            flexDirection="row"
            alignItems="flex-start"
            sx={{ marginTop: "12px", marginBottom: "12px" }}
          >
            {user.profileImageURL ? (
              <img
                // {...register('image', { required: true })}
                src={`https://${process.env.REACT_APP_PUBLIC_AWS_S3_BUCKET_NAME}.s3.amazonaws.com/${user.id}`}
                style={{
                  borderRadius: "4px",
                  height: "18px",
                  width: "18px",
                  cursor: "pointer",
                  marginRight: "12px",
                }}
              />
            ) : (
              <div
                style={{
                  borderRadius: "4px",
                  height: "18px",
                  width: "18px",
                  cursor: "pointer",
                  marginRight: "12px",
                  background: STUDYO_GRADIENT,
                  backgroundColor: "white",
                }}
              />
            )}
            <StudyTypographyMediumSubtitle style={{ marginBottom: "8px" }}>
              {`${user.firstName} ${user.lastName}`}
            </StudyTypographyMediumSubtitle>
          </Stack>

          <StudyDivider
            style={{ marginTop: "12px", marginBottom: "12px" }}
          ></StudyDivider>
          <StudyTypographyMediumSubtitle style={{ marginBottom: "8px" }}>
            Theme
          </StudyTypographyMediumSubtitle>
          <SegmentedControl
            id={"theme"}
            onSegment1Tap={() => {
              document
                .querySelector('meta[name="theme-color"]')
                ?.setAttribute("content", "#E9EbED");
              localStorage.setItem("theme", "light");
              setLocalTheme("light");
              setThemeValue("light");
            }}
            onSegment2Tap={() => {
              document
                .querySelector('meta[name="theme-color"]')
                ?.setAttribute("content", "#232529");

              localStorage.setItem("theme", "gray");
              setLocalTheme("gray");
              setThemeValue("");
            }}
            onSegment3Tap={() => {
              document
                .querySelector('meta[name="theme-color"]')
                ?.setAttribute("content", "#000000");

              localStorage.setItem("theme", "dark");
              setLocalTheme("dark");
              setThemeValue("dark");
            }}
            defaultSegment={
              localTheme === "light" ? 0 : localTheme === "dark" ? 2 : 1
            }
            backgroundColor={"white"}
            position={0}
            width={275}
            fullWidth={true}
            padding={""}
            isSelected={false}
          />
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            sx={{ marginBottom: "12px", marginTop: "12px" }}
          >
            <motion.div
              whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
              whileHover={{ rotate: 10 }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 8,
              }}
              style={{
                alignItems: "center",
                alignSelf: "center",
                display: "flex",
                marginRight: "12px",
              }}
            >
              <CheckIcon
                height={"24px"}
                width={"24px"}
                active={user?.compact ? user.compact : false}
                clicked={() => {
                  const holdUser = user;
                  if (holdUser.compact) {
                    holdUser.compact = !holdUser.compact;
                  } else {
                    holdUser.compact = true;
                  }
                  setUser(holdUser);
                  localStorage.setItem("user", JSON.stringify(holdUser));
                  window.location.reload();
                }}
              />
            </motion.div>
            <StudyTypographyMediumSubtitle>
              compact
            </StudyTypographyMediumSubtitle>
          </Stack>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            sx={{ marginBottom: "12px" }}
          >
            <motion.div
              whileTap={{ rotate: [90, 0], scale: [1, 1.1, 1] }}
              whileHover={{ rotate: 10 }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 8,
              }}
              style={{
                alignItems: "center",
                alignSelf: "center",
                display: "flex",
                marginRight: "12px",
              }}
            >
              <CheckIcon
                height={"24px"}
                width={"24px"}
                active={showBadge}
                clicked={() => {
                  setShowBadge(!showBadge);
                }}
              />
            </motion.div>
            <StudyTypographyMediumSubtitle>
              show hints
            </StudyTypographyMediumSubtitle>
          </Stack>
          <StudyDivider
            style={{ marginTop: "12px", marginBottom: "12px" }}
          ></StudyDivider>
          <StudyTypographyMediumSubtitle style={{ marginTop: "8px" }}>
            Hotkeys
          </StudyTypographyMediumSubtitle>
          <div style={{ paddingBottom: "12px", paddingTop: "12px" }}>
            {listItems.map((item) => (
              <HoverTypography
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "8px",
                  borderRadius: "4px",
                }}
                onClick={() => {
                  navigate(item.to);
                }}
              >
                {item.icon}
                <StudyTypographyMediumSubtitle
                  style={{
                    paddingTop: "6px",
                    paddingBottom: "6px",
                    borderRadius: "4px",
                    paddingLeft: "8px",
                  }}
                >
                  {item.name}
                </StudyTypographyMediumSubtitle>
              </HoverTypography>
            ))}
          </div>
          <Link to="/login" style={{ textDecoration: "none" }}>
            <Button
              fullWidth
              color="secondary"
              variant="outlined"
              size="small"
              style={{
                width: "100%",
                flex: 1,
                margin: 0,
                padding: 0,
                textTransform: "none",
                color: theme.palette.error.main,
                borderColor: theme.palette.error.main,
              }}
              onClick={() => {
                // localStorage.removeItem("userId");
                // localStorage.removeItem("googleUser");
                // localStorage.removeItem("user");
                localStorage.clear();
                client.resetStore();
                signOut(auth).catch((error: any) => {});
              }}
            >
              Logout
            </Button>
          </Link>
        </div>
      </ProfileBlockPopper>
    </div>
  );
};

function ToDoItem(todo: any) {
  return (
    <Command.Item
      className="vercel"
      onSelect={(value) => console.log("Selected", value)}
      style={{ alignItems: "center", display: "flex" }}
    >
      <FormatListBulletedRounded />
      {todo.todo.title}
    </Command.Item>
  );
}

function EventItem(event: any) {
  return (
    <Command.Item
      className="vercel"
      onSelect={(value) => console.log("Selected", value)}
      style={{ alignItems: "center", display: "flex" }}
    >
      <CalendarTodayRounded />
      {event.todo.title}
    </Command.Item>
  );
}

function Home() {
  return (
    <>
      <Command.Group heading="Projects">
        <Item
          shortcut="S P"
          onSelect={() => {
            // searchProjects();
          }}
        >
          <ProjectsIcon />
          Search Projects...
        </Item>
        <Item>
          <PlusIcon />
          Create New Project...
        </Item>
      </Command.Group>
      <Command.Group heading="Teams">
        <Item shortcut="⇧ P">
          <TeamsIcon />
          Search Teams...
        </Item>
        <Item>
          <PlusIcon />
          Create New Team...
        </Item>
      </Command.Group>
      <Command.Group heading="Help">
        <Item shortcut="⇧ D">
          <DocsIcon />
          Search Docs...
        </Item>
        <Item>
          <FeedbackIcon />
          Send Feedback...
        </Item>
        <Item>
          <ContactIcon />
          Contact Support
        </Item>
      </Command.Group>
    </>
  );
}

function Item({
  children,
  shortcut,
  onSelect = () => {},
}: {
  children: React.ReactNode;
  shortcut?: string;
  onSelect?: (value: string) => void;
}) {
  return (
    <Command.Item onSelect={onSelect}>
      {children}
      {shortcut && (
        <div cmdk-vercel-shortcuts="">
          {shortcut.split(" ").map((key) => {
            return <kbd key={key}>{key}</kbd>;
          })}
        </div>
      )}
    </Command.Item>
  );
}

function ProjectsIcon() {
  return (
    <svg
      fill="none"
      height="24"
      shapeRendering="geometricPrecision"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M3 3h7v7H3z"></path>
      <path d="M14 3h7v7h-7z"></path>
      <path d="M14 14h7v7h-7z"></path>
      <path d="M3 14h7v7H3z"></path>
    </svg>
  );
}

function PlusIcon() {
  return (
    <svg
      fill="none"
      height="24"
      shapeRendering="geometricPrecision"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M12 5v14"></path>
      <path d="M5 12h14"></path>
    </svg>
  );
}

function TeamsIcon() {
  return (
    <svg
      fill="none"
      height="24"
      shapeRendering="geometricPrecision"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
      <circle cx="9" cy="7" r="4"></circle>
      <path d="M23 21v-2a4 4 0 00-3-3.87"></path>
      <path d="M16 3.13a4 4 0 010 7.75"></path>
    </svg>
  );
}

function CopyIcon() {
  return (
    <svg
      fill="none"
      height="24"
      shapeRendering="geometricPrecision"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M8 17.929H6c-1.105 0-2-.912-2-2.036V5.036C4 3.91 4.895 3 6 3h8c1.105 0 2 .911 2 2.036v1.866m-6 .17h8c1.105 0 2 .91 2 2.035v10.857C20 21.09 19.105 22 18 22h-8c-1.105 0-2-.911-2-2.036V9.107c0-1.124.895-2.036 2-2.036z"></path>
    </svg>
  );
}

function DocsIcon() {
  return (
    <svg
      fill="none"
      height="24"
      shapeRendering="geometricPrecision"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z"></path>
      <path d="M14 2v6h6"></path>
      <path d="M16 13H8"></path>
      <path d="M16 17H8"></path>
      <path d="M10 9H8"></path>
    </svg>
  );
}

function FeedbackIcon() {
  return (
    <svg
      fill="none"
      height="24"
      shapeRendering="geometricPrecision"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
    </svg>
  );
}

function ContactIcon() {
  return (
    <svg
      fill="none"
      height="24"
      shapeRendering="geometricPrecision"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
      <path d="M22 6l-10 7L2 6"></path>
    </svg>
  );
}

{
  /* </ProfileBlockPopper> */
}

{
  /* <ClickAwayListener
              onClickAway={() => {
                setSearchAnchorEl(null);
                setIsFocused(false);
              }}
            >
              <Badge
                badgeContent={HOTKEYS.GLOBAL_SEARCH}
                color={"primary"}
                invisible={!showBadge}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <motion.div
                  onClick={handleSearchClick}
                  layout
                  style={{
                    marginRight: "12px",
                    marginBottom: "0px",
                    alignSelf: "center",
                    width: isFocused ? "300px" : "200px",
                    height: "32px",
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 700,
                    damping: 30,
                  }}
                  whileFocus={{ scale: 1.2 }}
                >
                  <SearchBarInput style={{ width: "100%" }}>
                    <SearchIcon active={false} clicked={() => {}} />

                    <input
                      onFocus={() => {
                        setIsFocused(true);
                      }}
                      ref={inputRef}
                      aria-label="Demo input"
                      placeholder="Search coming soon!"
                      onChange={(text) => {
                        setTitle(text.currentTarget.value);
                      }}
                      style={{
                        padding: 0,
                        margin: 0,
                        backgroundColor: theme.palette.primary.light,
                        border: "0px",

                        boxSizing: "border-box",

                        outlineWidth: 0,

                        borderRadius: "4px",

                        color: theme.palette.text.primary,
                      }}
                      value={title}
                    />
                  </SearchBarInput>
                </motion.div>
              </Badge>
            </ClickAwayListener> */
}
