import { useTheme } from "@mui/material";

export const AIPreview = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        alignItems: "flex-start",
        width: "80%",
        justifyContent: "center",
        alignSelf: "center",
        height: "100%",
        flex: 1,
        display: "flex",
        border: "0px solid black",
        overflowY: "auto",
        overflow: "auto",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: "0px",
        marginTop: "32px",
      }}
    >
      <img
        src={require("../../assets/StudyoDemoAI.png")}
        style={{ width: "100%" }}
      />
    </div>
  );
};
