import { useTheme } from "@mui/material";
import styled from "styled-components";
import {
  STUDYO_PRIMARY_GRADIENT,
  STUDYO_SECONDARY_GRADIENT,
} from "../../utils/constants";
import { HoverPointerStyle } from "./HoverPointerStyle";

export function AddIcon(props: {
  clicked: Function;
  width?: string;
  height?: string;
  disabled?: boolean;
}) {
  const theme = useTheme();
  return (
    <HoverPointerStyle
      width={props?.width || "30"}
      height={props?.height || "30"}
      viewBox={`0 0 30 30`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        props.clicked();
      }}
    >
      <rect
        width={props?.width || "30"}
        height={props?.height || "30"}
        rx="4.125"
        fill={theme.palette.secondary.light}
      />

      <path
        d="M15 9V21M21 15H9"
        stroke={"#FFFFFF"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <defs>
        {/* <linearGradient
          id="paint0_linear_26_268"
          x1="0"
          y1="0"
          x2="30"
          y2="30"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={STUDYO_PRIMARY_GRADIENT} />
          <stop offset="1" stopColor={STUDYO_SECONDARY_GRADIENT} />
        </linearGradient> */}
      </defs>
    </HoverPointerStyle>
  );
}
