import { Box, styled, Typography } from "@mui/material";
import StyleGuide from "../StyleGuide";

export const StudyTypographyMegaTitle = styled("div")`
  font-size: 32px;
  font-weight: bold;
  font-family: "Space Grotesk";
  padding: 0px;
  margin-top: 0px;
`;

export const StudyTypographySmallTitle = styled(Box)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "100",
  fontFamily: "Space Grotesk",
  color: theme.palette.text.primary,
}));
export const StudyTypographySmallSubtitle = styled(Box)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "100",
  fontFamily: "Space Grotesk",
  color: theme.palette.text.primary,
}));

export const StudyTypographyXSmallSubtitle = styled(Box)(({ theme }) => ({
  fontSize: "10px",
  fontWeight: "lighter",
  fontFamily: "Space Grotesk",
  color: theme.palette.text.primary,
}));

export const StudyTypographySmallText = styled(Box)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "100",
  fontFamily: "Space Grotesk",
  color: theme.palette.text.primary,
}));

export const StudyTypographyMegaSubtitle = styled(Box)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "300",
  fontFamily: "Space Grotesk",
  color: theme.palette.text.primary,
}));

export const StudyTypographyMega = styled(Box)(({ theme }) => ({
  fontSize: "32px",
  fontWeight: "bold",
  fontFamily: "Space Grotesk",
  color: theme.palette.text.primary,
}));

export const StudyTypographyMediumTitle = styled(Box)(({ theme }) => ({
  fontSize: "20x",
  fontWeight: "bold",
  fontFamily: "Space Grotesk",
  color: theme.palette.text.primary,
}));

export const StudyTypographyMediumSubtitle = styled(Box)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "normal",
  fontFamily: "Space Grotesk",
  color: theme.palette.text.primary,
}));

export const StudyTypographyMediumText = styled(Box)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "normal",
  fontFamily: "Space Grotesk",
  color: theme.palette.text.primary,
}));
