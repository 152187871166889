import { ContentCopyRounded } from "@mui/icons-material";
import { alpha, styled } from "@mui/material";
import { STUDYO_GRADIENT } from "../../../utils/constants";

export const AddFriendsToGroupBox = styled("div")<{ filled: any }>(
  ({ theme, filled }) => ({
    padding: "16px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    height: "200px",
    display: "flex",
    border: `3px dashed ${theme.palette.primary.light}`,
    borderRadius: "4px",
    background: filled ? STUDYO_GRADIENT : "rgba(0,0,0,0)",

    backgroundColor: filled
      ? "rgba(255,255,255,0.2)"
      : theme.palette.primary.main,
  })
);

export const AddFriendsToGroupBoxFilled = styled("div")<{ filled: any }>(
  ({ theme, filled }) => ({
    padding: "16px",
    alignItems: "flex-start",
    justifyContent: "space-between",
    height: "200px",
    display: "flex",
    border: `3px dashed ${theme.palette.primary.light}`,
    borderRadius: "4px",
    background: filled ? STUDYO_GRADIENT : "rgba(0,0,0,0)",

    backgroundColor: filled
      ? "rgba(255,255,255,0.2)"
      : theme.palette.primary.main,
  })
);

export const ContentIcon = styled(ContentCopyRounded)(({ theme }) => ({
  cursor: "pointer",
  padding: "4px",
  borderRadius: "4px",
  backgroundColor: theme.palette.primary.light,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));
