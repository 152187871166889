import { Button, Stack, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import { AddIcon } from "../Icons/AddIcon";
import { HorizontalContainer } from "../glovebox/HorizontalContainer";
import {
  StudyTypographyMediumText,
  StudyTypographyMediumTitle,
  StudyTypographySmallSubtitle,
} from "../glovebox/StudyTypography";
import { XIcon } from "../sidebar/Icons";
import "./Line.css";
import { IPhoneBlock, IPhoneDescription } from "./styles";

function ShareScheduleBlock() {
  const [hovering, setHovering] = useState<boolean>(false);
  const [shareBlockActive, setShareBlockActive] = useState(false);
  const [friendsActive, setFriendsActive] = useState<any>([]);
  const theme = useTheme();

  const users = [
    { name: "Platon", color: theme.palette.success.main, initials: "PM" },
    { name: "Luis", color: theme.palette.action.active, initials: "LA" },
    { name: "Mene", color: theme.palette.info.main, initials: "MM" },
  ];
  console.log(friendsActive);

  const Block = ({
    user,
    color,
    initials,
  }: {
    user: any;
    color: string;
    initials: string;
  }) => {
    const theme = useTheme();

    return (
      <motion.div
        style={{
          width: "64px",
          height: "64px",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexShrink: 0,
          borderRadius: "4px",
          perspectiveOrigin: "0, 0",
          transformOrigin: "0, 0",
          backgroundColor: theme.palette.primary.light,
          border: `1.5px solid ${theme.palette.primary.dark}`,
        }}
        animate={{
          backgroundColor: friendsActive.find(
            (friend: { name: any }) => friend.name === user
          )
            ? color
            : theme.palette.primary.light,
        }}
        transition={{ duration: 0.5 }}
      >
        {friendsActive.find((friend: { name: any }) => friend.name === user) ? (
          <StudyTypographyMediumText>{initials}</StudyTypographyMediumText>
        ) : null}
      </motion.div>
    );
  };

  const TitleBlock = ({ user, color }: { user: any; color: string }) => {
    const theme = useTheme();

    return (
      <motion.div
        style={{
          width: "80%",
          height: "18px",
          marginBottom: "10px",
          backgroundColor: theme.palette.primary.light,
          border: `1.5px solid ${theme.palette.primary.dark}`,
          borderRadius: "8px",
        }}
        animate={{
          backgroundColor: friendsActive.find(
            (friend: { name: any }) => friend.name === user
          )
            ? color
            : theme.palette.primary.light,
        }}
        transition={{ duration: 0.5 }}
      ></motion.div>
    );
  };

  return (
    <motion.div
      style={{
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
      }}
      animate={hovering ? "open" : "closed"}
      transition={{ duration: 3 }}
    >
      <IPhoneBlock
        backgroundColor={theme.palette.primary.main}
        backgroundColor2={theme.palette.primary.dark}
      >
        <HorizontalContainer
          sx={{ justifyContent: "space-between", marginBottom: "12px" }}
        >
          <StudyTypographyMediumTitle style={{ marginBottom: "4px" }}>
            hello, platon
          </StudyTypographyMediumTitle>
        </HorizontalContainer>
        {users.map((friend) => (
          <HorizontalContainer
            sx={{
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: "12px",
              gap: "12px",
            }}
          >
            <Block
              user={friend.name}
              initials={friend.initials}
              color={friend.color}
            />
            <Stack flexDirection={"column"} width={"100%"}>
              <TitleBlock user={friend.name} color={friend.color} />
              <IPhoneDescription
                backgroundColor={theme.palette.primary.light}
              />
              <IPhoneDescription
                backgroundColor={theme.palette.primary.light}
              />
            </Stack>
          </HorizontalContainer>
        ))}
        <Button
          onClick={() => {
            setShareBlockActive(!shareBlockActive);
          }}
          color="primary"
          fullWidth
          style={{
            //   backgroundColor: "white",
            marginTop: "48px",
            borderRadius: "4px",
            textTransform: "none",
            color: theme.palette.text.primary,
            outlineColor: theme.palette.primary.dark,
            backgroundColor: theme.palette.action.active,
            marginBottom: "12px",
          }}
        >
          share!
        </Button>
        <StudyTypographySmallSubtitle>
          hint. click button ^
        </StudyTypographySmallSubtitle>
      </IPhoneBlock>
      <motion.div
        style={{
          width: "225px",
          height: "350px",
          borderRadius: "12px",
          padding: "24px",
          border: "1px solid rgba(255, 255, 255, 0.2)",
          // boxShadow: `inset 0 1px 2px ${theme.palette.primary.dark} `,
          backgroundColor: theme.palette.primary.main,
          zIndex: 10,
        }}
        animate={{ y: shareBlockActive ? 200 : 500 }}
      >
        <HorizontalContainer
          sx={{ justifyContent: "space-between", marginBottom: "18px" }}
        >
          <StudyTypographyMediumTitle>share!</StudyTypographyMediumTitle>
          <XIcon
            onClick={() => {
              setShareBlockActive(false);
            }}
          />
        </HorizontalContainer>
        {users.map((friend) => (
          <HorizontalContainer
            sx={{ justifyContent: "space-between", marginBottom: "16px" }}
          >
            <HorizontalContainer sx={{ gap: "12px" }}>
              <div
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "4px",
                  backgroundColor: friend.color,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <StudyTypographySmallSubtitle>
                  {friend.initials}
                </StudyTypographySmallSubtitle>
              </div>
              <StudyTypographyMediumTitle>
                {friend.name}
              </StudyTypographyMediumTitle>
            </HorizontalContainer>

            {friendsActive.find(
              (user: { name: any }) => user.name === friend.name
            ) ? (
              <XIcon
                onClick={() => {
                  const holdFriends = friendsActive.filter(
                    (person: any) => person.name !== friend.name
                  );
                  setFriendsActive(holdFriends);
                  // setFriendsActive();
                }}
              />
            ) : (
              <div
                onClick={() => {
                  setFriendsActive([...friendsActive, friend]);
                }}
              >
                <AddIcon clicked={() => {}} />
              </div>
            )}
          </HorizontalContainer>
        ))}
      </motion.div>
    </motion.div>
  );
}

export default ShareScheduleBlock;
