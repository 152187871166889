import { useEffect, useState } from "react";
import { ToolbarToDoTitle } from "../toolbar/styles";

const getRandomChar = () =>
  String.fromCharCode(65 + Math.floor(Math.random() * 26)).toLowerCase();

function CryptographicString({ target }: { target: string }) {
  const [displayString, setDisplayString] = useState(
    Array(target.length).fill(" ")
  );

  useEffect(() => {
    let timer = 0;
    for (let i = 0; i < target.length; i++) {
      setTimeout(() => {
        for (let j = 0; j < 3; j++) {
          setTimeout(() => {
            setDisplayString((prev) => {
              const newArr = [...prev];
              newArr[i] = getRandomChar();
              return newArr;
            });
          }, j * 100);
        }
        setTimeout(() => {
          setDisplayString((prev) => {
            const newArr = [...prev];
            newArr[i] = target[i];
            return newArr;
          });
        }, 300);
      }, timer);
      timer += 30;
    }
  }, []);

  return <ToolbarToDoTitle>{displayString}</ToolbarToDoTitle>;
}

export default CryptographicString;
