// ----------------------------------------------------------------------

export const mockImgCover = (index: any) =>
  `/p-static/mock-images/covers/cover_${index}.jpg`;
export const mockImgProduct = (index: any) =>
  `/p-static/mock-images/products/${index}.png`;
export const mockImgProfile = (index: any) =>
  `/p-static/mock-images/products/${index}`;
export const mockImgAvatar = (index: any) =>
  `/p-static/mock-images/avatars/avatar_${index}.jpg`;
